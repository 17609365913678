import React, {useState, useContext, useEffect } from "react";
import {UserLogInContext} from '../App.js'
//import DashboardIcon from "@mui/icons-material/Dashboard";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
//import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HandymanIcon from '@mui/icons-material/Handyman';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
// import CreditCardIcon from "@mui/icons-material/CreditCard";
// import StoreIcon from "@mui/icons-material/Store";
// import InsertChartIcon from "@mui/icons-material/InsertChart";
// import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
// import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import GridViewIcon from '@mui/icons-material/GridView';
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";// import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
//import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import BatchPredictionIcon from '@mui/icons-material/BatchPrediction';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import SettingsInputCompositeOutlinedIcon from '@mui/icons-material/SettingsInputCompositeOutlined';
import HomeIcon from '@mui/icons-material/Home';
import "./sidebar.scss";
import {Stack} from "@mui/material";
import {useAuthenticator, Menu, MenuButton, MenuItem, Divider, useTheme  } from "@aws-amplify/ui-react";  
import { useNavigate, useLocation, redirect  } from "react-router-dom";   //import { Link } from "react-router-dom";//import { DarkModeContext } from "../../context/darkModeContext"; //const style={marginLeft:'5px', fontFamily:'cursive' };


const{REACT_APP_API_HTTP, REACT_APP_API_LOCAL_DOMAIN, REACT_APP_API_LOCAL_PORT, NODE_ENV, REACT_APP_DEBUG}=process.env
const DEBUG = REACT_APP_DEBUG ;
const productionHost = REACT_APP_API_HTTP + window.location.host;
const localHost = REACT_APP_API_HTTP + REACT_APP_API_LOCAL_DOMAIN + REACT_APP_API_LOCAL_PORT;
const DOMAIN = `${NODE_ENV==='production' ? productionHost : localHost}`; 

// cookie
const Sidebar = () => {
    const {group,  role,  logout} = useContext(UserLogInContext);    // account,customerrole,userfullname, isSuperUser, DEBUG && console.log(role, subrole)    // DEBUG && console.log(account);     //const [isAdmin, setIsAdmin]=useState ( group[0]!=='Admin');
    const isAdmin = group[0] =='Admin' || role =='Administration' || role =='Administrator';    //const [menuOpen, setMenuOpen] = useState(false);    //const [isRole, setIsRole]=useState(role==="No Role Detected"?false: role ==="tech2"?true:false )  //const [fullName, setFullName] = useState()
    const navigate = useNavigate();    // const location = useLocation();
    const { user, signOut, route, authStatus} = useAuthenticator((context) =>[context.user, context.route, context.authStatus]);    //const lg=()=>{window.location.assign(`${process.env.NODE_ENV==='production' ? 'https://autom8systems.com.au/': 'http://localhost:3000/'} `) }
  
    const { tokens } = useTheme();
    const getHome= async()=>{      //window.history.pushState({ name: "Example" }, "pushState example", "page3.html");      // DEBUG && DEBUG && console.log("dddddddddddddddddd" )
       navigate('/');   
       signOut();      
    }
  
    const handleTabClose = (e) => {
      e.preventDefault();
      //console.log()
      console.log('BEFORE UNLOAD event triggered ', e);      //const confirm_result = confirm("Are you sure you want to quit?");
      getHome()      //localStorage.clear()      //window.open('', '_self', '').close();
      console.log(authStatus)  //logout();   
      signOut()
      return (e.returnValue ='Are you sure you want to exit ?');
    };

    const out =()=>{
      getHome()
      signOut()
    }
  
    useEffect(() => {  
      window.addEventListener('beforeunload', handleTabClose);
      return () => {window.removeEventListener('beforeunload', handleTabClose)  };
   },[]);


      
  return (
      <Stack className="sidebar">          {/* <Stack className="top"> */}{/* <span className="logo">{customerrole === 'No User Name'? user: customerrole}</span> */}{/* <span className="logo">{ role }</span> */}{/* </Stack> */}
              
        <Stack className ='ul'>
        
          <Menu
            onOpenChange={()=>{ navigate("/") }}
            onClick={()=>{ console.log('Testing') }}
            trigger={ <MenuButton variation='menu' > <Stack direction ='row' gap={2} className="line"><HomeIcon />Home</Stack> </MenuButton> }
          >   {/* <MenuItem width={0} height={0} isDisabled={true}> </MenuItem> */}
          </Menu>

          <Menu 
            onOpenChange={()=>{ navigate("/dashboard") }}
            trigger={ <MenuButton variation='menu' > <Stack direction ='row' gap={2} className="line"><DashboardOutlinedIcon /> Dashboard</Stack> </MenuButton>} >
            {/* <MenuItem gap="1rem" onClick={() => navigate("/dashboard")}>< DashboardIcon /> <span className="line" >Dashboard</span></MenuItem>   */}
          </Menu>
    
          <Menu 
            onOpenChange={(open)=>{navigate("/jobs")}}
            trigger={ <MenuButton variation='menu'> <Stack direction ='row' gap={2} className="line"><ContentPasteGoIcon />Jobs</Stack> </MenuButton> }   
          > 
           {/* <MenuItem direction ='row' gap={2} onClick={()=> navigate("/jobs")}> Job's Book</MenuItem>  */}
          </Menu>        

          {isAdmin && 
            <Stack gap={3} >
              <Menu trigger={<MenuButton variation='menu'> <Stack direction ='row' gap={2} className="line"><SettingsInputCompositeOutlinedIcon />Administration</Stack></MenuButton>}>

                <Stack gap={3} >

                  <MenuItem onClick={() => { navigate("/clients") }} > <Stack  sx={{mt:3}} direction ='row' gap={2} className="line">< SupervisedUserCircleOutlinedIcon />Clients</Stack> </MenuItem>

                  <MenuItem onClick={() => { navigate("/customers")}}><Stack direction ='row' gap={2} className="line"><CoPresentIcon  />Customers</Stack> </MenuItem>

                  <MenuItem onClick={() => { navigate("/users")}} > <Stack direction ='row' gap={2}  className="line"> <GroupTwoToneIcon />Users</Stack> </MenuItem>

                  <MenuItem onClick={() => { navigate("/sites")}}><Stack direction ='row' gap={2}  className="line"><GridViewIcon  />Sites</Stack> </MenuItem>

                  <MenuItem onClick={() => { navigate("/jobparts")}}><Stack direction ='row' gap={2} className="line"><LocalShippingIcon />Parts</Stack> </MenuItem>

                  <MenuItem onClick={() => { navigate("/warehouses")}}><Stack direction ='row' gap={2} className="line"><LocalShippingIcon />Warehouses</Stack></MenuItem>

                  <MenuItem onClick={() =>{ navigate("/contractors")}}><Stack  sx={{mb:3}} direction ='row' gap={2} className="line"><HandymanIcon />Service Providers</Stack></MenuItem>
                
                </Stack>
              
              </Menu>

              <Menu  trigger = { <MenuButton variation='menu' > <Stack direction ='row' gap={2} className="line"> <BatchPredictionIcon/>Batches</Stack> </MenuButton> } >

                <Stack gap={3} >
              
                    <MenuItem  className="line" onClick={() => navigate('/batches')}><Stack sx={{mt:2}} direction ='row' gap={2} className="line"><SettingsSystemDaydreamOutlinedIcon /> Batch Jobs 🚀 </Stack> </MenuItem>
              
                    <MenuItem  className="line"onClick={() => navigate('/staff')}  > <Stack direction ='row' gap={2} className="line"><LocationOnOutlinedIcon />Techn Locations</Stack> </MenuItem>

                    <MenuItem  className="line" onClick={() => navigate('/suppliers')}  > <Stack direction ='row' gap={2} className="line"><LocationOnOutlinedIcon  />Contractors Locations </Stack> </MenuItem>

                    <Divider />
                  
                    <MenuItem isDisabled={isAdmin} className="line" onClick={() => alert('System')}><Stack  sx={{mb:2}}  direction ='row' gap={2} className="line"><SettingsSystemDaydreamOutlinedIcon /> System</Stack></MenuItem>
              
                </Stack>

              </Menu>
            </Stack>
          }

          <Menu trigger={ <MenuButton variation='menu' ><Stack direction ='row' gap={2} className="line"> <ManageAccountsOutlinedIcon/>Account</Stack> </MenuButton> } >

            <MenuItem className="line" onClick={() => navigate('/profile')} > <Stack sx={{mt:2}} direction ='row' gap={2} className="person">Profile</Stack>  </MenuItem>

            <MenuItem className="line"onClick={() => navigate('/about')} ><Stack color="text.secondary" direction ='row' gap={2} className="sparkle">About   ⌘C </Stack> </MenuItem>

            <MenuItem  backgroundColor={tokens.colors.red[40]}  className="line" onClick={()=>out()} > <Stack sx={{mb:1}} direction ='row' gap={2}  className="exit">LogOut </Stack></MenuItem>

          </Menu>

        </Stack>

      </Stack>                
  );
};

export default Sidebar;

   //const openMenu = (open) => { setMenuOpen(open) };   const closeMenu = () => { setMenuOpen(false) };

    //DEBUG && console.log(isLogging)
    // {isLogging && logout() }
    //  const location = useLocation();

    // debug && DEBUG && console.log(group)
    // debug && DEBUG && console.log(group[0])
    // debug && DEBUG && console.log(role)
    // debug && DEBUG && console.log(customerrole)
    // debug && DEBUG && console.log(isAdmin)
      // async function getFullName(url) {
    //   DEBUG && console.log(url)
    //   const response = await fetch(url)
    //   const responseData = await response.json();  
    //   debug && DEBUG && console.log(responseData[0].userFullName);
    //   //setFullName(responseData)  
    //   setFullName(responseData[0].userFullName ?? responseData[0].sub ?? "NO DATA AVAILABLE FOR THIS USER")};

  //  useEffect(() => {getFullName(`${DOMAIN}${REACT_APP_API_USER_GET_SUB}${subId}`)}, []);


            //   onOpenChange={(open)=>{ openMenu(open)}} 
                        
            //   <Menu  
            //   name ='Services'            
            //   menuAlign="start"           
            //   isDisabled    
            //   trigger={
            //       <MenuButton 
            //         size="small"
            //         isFullWidth={true}
            //         isDisabled
            //         variation='menu' 
            //       >
            //         <Stack direction ='row' gap={2} className="line"><ContentPasteGoIcon /></Stack> 
            //         <Stack className="line1">Services </Stack>                                         
            //       </MenuButton>
            //   }                    
            // >            
            //   <MenuItem onClick={()=> navigate("/products")} isDisabled={true}><Stack direction ='row' gap={2} className="line"> <StoreIcon /></Stack><Stack className="line1">Products</Stack></MenuItem>
            //   <MenuItem onClick={() => navigate("/orders")} isDisabled={true}><Stack direction ='row' gap={2} className="line"><CreditCardIcon  /></Stack><Stack className="line1">Orders</Stack></MenuItem>
            //   <MenuItem onClick={() => navigate("/delivery")}isDisabled={true}><Stack direction ='row' gap={2} className="line"><LocalShippingIcon /></Stack><Stack className="line1">Deliveries 🚀</Stack></MenuItem>
            // </Menu>       




              // {/* <Link  to="/jobs" style={{ textDecoration: "none" }}      >
              //   <li>
              //     <ContentPasteGoIcon  />
              //     <span className="line">Jobs</span>
              //   </li>
              // </Link>

              // <Link 
              // to={isAdmin?"/sites":"/"}
              // style={{ textDecoration: "none" }} 
              // >
              //   <li>
              //     <SettingsInputCompositeOutlinedIcon  />
              //     <span className="line"  >Sites</span>
              //   </li>
              // </Link>
              
              // <Link 
              //   to={isAdmin?"/products":"/"} 
              //   style={{ textDecoration: "none" }}
              // >
              //   <li>
              //     <StoreIcon  />
              //     <span className="line"  >Products</span>
              //   </li>
              // </Link>

              // <Link  to={isAdmin?"/orders":"/"}  style={{ textDecoration: "none" }}>
              //     <li>
              //       <CreditCardIcon  />
              //       <span className="line"  >Orders</span>
              //     </li>

              // </Link>

              // <Link 
              //   to={isAdmin?"/delivery":"/"} 
              //   style={{ textDecoration: "none" }}
              //   >
              //     <li >
              //       <LocalShippingIcon  />
              //       <span className="line" >Delivery</span>
              //     </li>
              // </Link> */}

              // {/* <p className="title"  ></p> */}

              //               {/* <Link to={isAdmin || isRole?"/clients":"/"} style={{ textDecoration: "none" }} >
              //   <li>
              //     <PersonOutlineIcon  />
              //     <span className="line"  >Clients</span>
              //   </li>
              // </Link>

              // <Link to={isAdmin?"/customers":"/"}  style={{ textDecoration: "none" }} >
              //   <li>
              //     <CoPresentIcon  />
              //     <span className="line"  >Customers</span>
              //   </li>
              // </Link>
              // <Link to={isAdmin?"/users":"/"}   style={{ textDecoration: "none" }}      >
              //   <li>
              //     <GroupTwoToneIcon  />
              //     <span className="line"  >Users</span>
              //   </li>
              // </Link> */}












              // {/* <br /> */}
              // {/* <hr />*/}
              // {/* <div className="bottom">
              //   <div  className="colorOption"    //onClick={() => dispatch({ type: "LIGHT" })}
              //   ></div>
              //   <div  className="colorOption"  //onClick={() => dispatch({ type: "DARK" })}
              //   > </div>
              // </div> */}



                //   {/* <p className="title"></p> */}

                //   {/* <Menu  menuAlign="end" onOpenChange={(open)=>logout()}
                //     trigger={<MenuButton gap="1rem" variation='menu' width="98%" ><ExitToAppIcon /><span className="line">LogOut</span></MenuButton>}                    
                //   ></Menu>         */}

                //   {/* <Link to="/profile" style={{ textDecoration: "none" }}>
                //     <li>
                //       <AccountCircleOutlinedIcon />
                //       <span className="line" >Profile</span>
                //     </li>
                //   </Link> */}
                //   {/* //<div className="item" onClick={logout}> */}
                // {/* 
                //   <Link to='/' style={{ textDecoration: "none" }}> 
                //       <li onClick={logout} > 
                      
                //         <ExitToAppIcon />
                //         <span className="line">Log Out</span>
                //       </li>
                //   </Link>   */}

                  
                //   {/* //  </div>        */}
                //   {/* <Link 
                //     to="/about" 
                //     style={{ textDecoration: "none" }}>
                //     <li>
                //       <StoreIcon />
                //       <span className="line">About</span>
                //     </li>
                //   </Link> */}

// {/* <p className="title">USEFUL</p>

              // <li>
              //   <InsertChartIcon />
              //   <span className="line"  >Stats</span>
              // </li>
              // <li>
              //   <NotificationsNoneIcon />
              //   <span className="line" >Notifications</span>
              // </li> */}



      // const user = useContext();
      // DEBUG && console.log(user)
      // const { dispatch } = useContext(DarkModeContext);


                  //       {/* <Link
                  //   to='/'
                  //   style={{ textDecoration: "none" }}
                  // >
                  //     <li >
                  //       <HomeIcon />
                  //       <span className="line">Home</span>
                  //     </li>
                  // </Link>

                  // <Link to='/dashboard' style={{ textDecoration: "none" }}>
                  //     <li >
                  //       <DashboardIcon />
                  //       <span className="line" >Dashboard</span>
                  //     </li>
                  // </Link>    NODE_ENV==='production'*/}
      