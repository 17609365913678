import React, {useState, createContext, useEffect} from 'react'; //import './app.scss';//import '../../Client/src/styles/main.scss';
import AppRoutes from './components/AppRoutes.jsx';
import { Amplify, Hub } from 'aws-amplify';  //Auth Cache
import { Authenticator, useTheme, Heading, View, Image, Button, useAuthenticator } from '@aws-amplify/ui-react';  // withAuthenticator,  
import '@aws-amplify/ui-react/styles.css';
import pic from '../src/resource/images/logo.png';//import { useNavigate, useLocation } from "react-router-dom";
import config from './aws-exports';
import { useJsApiLoader } from '@react-google-maps/api';// import {useUnload} from '../../Client/src/util/Functions.jsx';//import { } from '@mui/material';
import { Typography } from '@mui/material';

Amplify.configure(config)

const DEBUG = true //process.env.REACT_APP_DEBUG;
const productionHost = process.env.REACT_APP_API_HTTP + window.location.host;
const localHost = process.env.REACT_APP_API_HTTP + process.env.REACT_APP_API_LOCAL_DOMAIN + process.env.REACT_APP_API_LOCAL_PORT;
const DOMAIN = `${process.env.NODE_ENV==='production'?productionHost:localHost}`; 

//EXPORT logged in user details for other modules
export const UserLogInContext = createContext("Default value")     //{ signOut, user }

function App() {
  
  const [userId, setUserId]= useState(1);  ///const [loging, setLoging] =useState(false)   //const [sub, setSub]= useState('');  //const [alert, setAlert]= useState(true);
  const [libraries, setLibraries] =useState(['localContext','places','geometry', 'marker'])

  useEffect(() => {
    
    Hub.listen('auth', (data) => {
      // console.log("----------------------------------",window.document.body.style.zoom)
      const { payload } = data       //window.document.body.style.zoom='93%';
      DEBUG && console.log(payload)  // DEBUG && console.log(data)      //  console.log(`A new auth event has happened at: ${new Date()} `, payload.data.attributes['custom:Role']??"No Role has been setup") //Date.now()
    
      if (payload.event === 'signIn') {
    
        //role = payload.data.attributes['custom:Role'] ?? "No Role has been setup"         //window.location.assign(`${process.env.NODE_ENV==='production' ? 'https://autom8systems.com.au/': 'http://localhost:3000/'} `)
        getSub(`${url_main}${payload.data.attributes.sub}`)
        //setLoging(false)        //window.location.assign(`${process.env.NODE_ENV==='production' ? 'https://autom8systems.com.au/': 'http://localhost:3000/'} `)
        DEBUG &&  console.log(payload.data, " -----------" + payload.message)  
        sessionStorage.setItem("username", payload.data.username);
        sessionStorage.setItem('test',"testing")
      }else  if (payload.event === 'signOut') {
          //setLoging(false)
          //document.cookie = "username=John Jack; expires=Thu, 1 Dec 1970 12:00:00 UTC";
          // window.localStorage.clear()           // window.location.reload()
          
          DEBUG && console.log(payload.data.username, " ---------- " + payload.message) 
          //window.location.assign(`${process.env.NODE_ENV==='production' ? 'https://autom8systems.com.au/': 'http://localhost:3000/'} `)
          ///setSub('')
      }else{
          console.log(payload.event )
      }
    });
  },[]);



//   useEffect(() => {  
//     document.addEventListener('click', ()=>console.log('e'));
//     return () => { document.removeEventListener('click', ()=>console.log('e'))};
//  },[]);

  const formFields = {
    signIn: {
      username: {
        labelHidden: true,
        placeholder: 'Username',      //dialCodeList: ['+1', '+123', '+227', '+229']//
      },
      password: {
        labelHidden: true,
        placeholder: 'Password',      //dialCodeList: ['+1', '+123', '+227', '+229']//
      },
    },
    // signUp: {
    //   // username: {    //   placeholder: 'User Name',    // },
    //   email: {
    //     placeholder: 'Email',
    //     order: 2,
    //   },
    //   password: {
    //     label: 'Password:',
    //     placeholder: 'Password',
    //     order: 3,
    //   },
    //   confirm_password: {
    //     label: 'Confirm Password:',
    //     placeholder: 'Confirm Password',
    //     order: 4,
    //   },
    // }
  
  };
  
  const AuthenticatorComponent = {

    Header() {
      const { tokens } = useTheme();
      return (

        <View 
          textAlign="center" 
          padding={tokens.space.m}
          border='1px solid lightgray'
          borderRadius='5px'
          marginTop='15ch'
          backgroundColor='white'
          
        >
          <Image
            alt="A8 logo"
            marginTop={1}
            src={pic}//"https://docs.amplify.aws/assets/logo-dark.svg"
          />
      </View>
        // <Heading 
        //   backgroundColor="var(--amplify-colors-white)" 
        //   //width='100%'  //height="auto" 
        //   border='.5px solid black'
        //   borderRadius='5px'
        // > 
          // <Image            
          //     width="100%"
          //     marginTop={5}
          //     height={115}
          //     objectFit="none"
          //     objectPosition="50% 50%"
          //     src={pic}
          //     alt="Sign In Autom8"
          //     overflow='hidden' 
          //     onClick={() => alert('📸 Say cheese!')}
          // />
        // </Heading>
      )
    },
  //
    Footer() {
      const { tokens } = useTheme();
      //const authStatus = useAuthenticator();
      
      return (
        <View 
            textAlign="center" 
            //border="1px solid gray" 
            borderRadius='3px'
            backgroundColor="var(--amplify-colors-white)"  
            padding={tokens.space.large}  
            border='1px solid lightgray'
            //backgroundColor='lightblue'
        >
          <Typography fontSize={14} fontWeight={600} color='darkblue' > &copy; All Rights Reserved  </Typography>
            {/* as="p" 
            color='darkblue' 
            fontSize={14}
            fontWeight={400}
            marginTop={5} */}
          
          

          {/* <Button
              fontWeight="normal"
              //onClick={()=>console.log(authStatus)}
              marginTop={5}
              onClick={()=>setAlert(!alert)}
              //size="large"
              variation="link"
          ></Button> */}
        </View>
      )
    },
    
    SignIn: {
      Header() {
        const { tokens } = useTheme()
  
        return (
            <Heading
              textAlign ="center"
              color='darkred'               
              padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
              //level={3}
            > <Typography fontSize={22} fontWeight={600}>Sign into your Account</Typography>
            </Heading>
        )
      },
  
      Footer() {
        const { toResetPassword } = useAuthenticator();       //const { tokens } = useTheme()
  
        return (
          <View textAlign="center">
            <Button
              onClick={toResetPassword}
              size="large"             
              isFullWidth={true}
              width={410}              //paddingLeft={-1}
              marginBottom={20}
              variation="link"             //  variation="primary"
            ><Typography fontSize={16} fontWeight={600}> Forgot Password 🚀</Typography>
            </Button>
          </View>
        );
      },
  
    },
  
    ResetPassword: {
      Header() {
        //const { tokens } = useTheme()
        return (
              <Heading
                color='darkred' 
                textAlign ="center"
                marginTop={10}
                marginBottom={20}                                          //  padding={`${tokens.space.l} 0 0 ${tokens.space.l}`}              
            ><Typography fontSize={22} fontWeight={600}>Reset Password</Typography>
            </Heading>                                                     //  {/* //<Heading>Reset your password</Heading> //パスワード再交付 */}
  
        )
      },

    },  
  
  }  //const log = useRef(false)

  // const AlertExample = () => {
  //   return (
  //       <Alert
  //           hasIcon={true}
  //           heading="Alert "
  //           variation="error"
  //           isDismissible={true}
  //         //  onDismiss={() =>setAlert(false)}
  //         >If you want to set up New or Reset fogotten password select 'Set New / Reset Password' button, otherwise provide username, password and select 'Sign in' button.
  //         </Alert>
  //   )
  // };

  const url_main=`${DOMAIN}${process.env.REACT_APP_API_USER_GET_SUB}`;  

  const {isLoaded} =  useJsApiLoader({ 
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP,
    libraries:libraries,
    region:'Australia'
  })

  const getSub = async(url)=>{
    const response = await fetch(url);
    const responseData =  await response.json();  
    console.log(responseData[0])
    setUserId(responseData[0].userId)        //responseData[0].active === 0 && setLoging (true)
  }



  // console.log(window.document.cookie)
  // console.log(window.document.body.style)

  return (
      
    <Authenticator
      loginMechanisms={['username']}           //{['email']}/
      formFields={formFields}
      components={AuthenticatorComponent}
      variation="modal"                      //backgroundColor='green'
      initialState="Signin"
      hideSignUp={true}
    >
        {({ signOut, user }) => (

            <UserLogInContext.Provider             
                value={{
                    user: user.username,                                                               //signInUserSession.idToken.payload['cognito:username'],                 //loggedInUser,     
                    group: user.signInUserSession.idToken.payload['cognito:groups'] ?? ["NOGROUP"],   //loggedInGroup,
                    subId: user.attributes.sub, 
                    role: user.attributes['custom:Role'] ?? "No Role Detected" ,                          //user.signInUserSession.idToken.payload['custom:Role'] ?? "No Role Detected",  
                    subrole: user.attributes['custom:SubRole'] ?? "No Sub Role Detected",                  //user.signInUserSession.idToken.payload['custom:SubRole'] ?? "No SubRole Detected",  
                    customerrole: user.attributes['custom:CustomerRole'] ?? "No CustomerRole Detected",  //user.signInUserSession.idToken.payload['custom:CustomerRole'] ?? 'No User Name',
                    account: user.attributes['custom:account'] ?? "No Account Detected",
                    userfullname: user.attributes.name, 
                    userId: userId,  //fullName:user.attributes.sub,// getSub(`${DOMAIN}${process.env.REACT_APP_API_USER_GET_SUB}${user.attributes.sub}`),
                    time:user.signInUserSession.idToken.payload.auth_time,
                    isLoaded: isLoaded,
                    isSuperUser: user.signInUserSession.idToken.payload['cognito:groups'] =='Admin',

                    //setLoging:setLoging,
                    logout: signOut
                }}
            >          
              <AppRoutes />              
            </UserLogInContext.Provider> 
        
        )}  
    </Authenticator>
      
  )
}

export default (App);

  //    {/* {alert &&  <AlertExample/>  } */}

////////////////////////////////////////
          //   useEffect(() => {
          //     const handleTabClose = (e) => {
          //       e.preventDefault();
          //       console.log('beforeunload event triggered');
          //       log.current=true
          //       console.log(e);
          //       console.log(log.current);
          //       //window.location.reload()
          //       return (e.returnValue ='Are you sure you want to exit?');
          //     };

          //     window.addEventListener('beforeunload', handleTabClose);

          //     return () => {
          //       log.current= !log.current
          //       window.removeEventListener('beforeunload', handleTabClose) 
          //       console.log('tttttttttttttt', log.current)              
          //     };
          //  });
//////////////////////////////////////////////////





          //<View 
            //textAlign="center" paddingTop={tokens.space.xl} width="100%"
          //>
          //   <Text
          //     color='darkred' 
          //     fontWeight={700} 
          //     fontSize={26}
          //     fontStyle="normal"
          //     //textDecoration="none"
          //   >Sign In</Text>
          // </View>

    // Footer() {
    //   const { tokens } = useTheme()
    //   return (
    //     <View textAlign="center" padding={tokens.space.xxxl}>
    //       <Text color={`${tokens.colors.neutral['80']}`}>
    //         &copy; All Rights Reserved
    //       </Text>
    //     </View>
    //   )
    // },

  // signUp: {
  //   Header() {
  //     const { tokens } = useTheme()
  //     return (
  //        <View textAlign="center" paddingTop={tokens.space.xl} width="100%">
  //           <Text
  //             color='darkred' 
  //             fontWeight={700} 
  //             fontSize={26}
  //             fontStyle="normal"
  //             //textDecoration="none"
  //           >Create </Text>
  //         </View>
  //     )
  //   },

  //   Footer() {
  //     const { tokens } = useTheme()
  //     return (
  //       <View textAlign="center" padding={tokens.space.xxxl}>
  //         <Text color={`${tokens.colors.neutral['80']}`}>
  //           &copy; All Rights Reserved hello
  //         </Text>
  //       </View>
  //     )
  //   },  // },


    //  {/* {debug && console.log(user)} */}
    //        {/* {debug && console.log(user.attributes['custom:Role']??"No Role has been setup")}
    //        {debug && console.log(user.attributes['custom:CustomerRole']??"No Customer Role has been setup")} */}
    //        {/* <button onClick={signOut}>Sign out</button> */}


// const components = {    
//   tokens: {
//     colors: {
//       background: {
//         primary: { value: tokens.colors.neutral['90'].value, },
//         secondary: {value: tokens.colors.neutral['100'].value, },
//       },
//       font: {
//         interactive: { value: tokens.colors.red['60'].value, },
//         default: {
//          variable: { value: 'Raleway, sans-serif' },
//            static: { value: 'Raleway, sans-serif' },
//         },
//       },
//       brand: {
//         primary: {
//           '10': tokens.colors.teal['100'],
//           '80': tokens.colors.teal['40'],
//           '90': tokens.colors.teal['20'],
//           '100': tokens.colors.teal['10'],
//         },
//       },
//     },
//     components: {
//       tabs: {
//         item: {
//             _focus: {
//               color: {value: tokens.colors.white.value,},
//             },
//             _hover: {
//               color: {value: tokens.colors.yellow['60'].value,},
//             },
//             _active: {
//               color: { value: tokens.colors.white.value, },
//             },
//         },
//       },
//     },
//  },
// };
///////////////

      // {/* {console.log(user.signInUserSession.idToken)},   */}
      //           {/* {console.log(user)}  */}
      //           {/* <button width="30px" height ="10px" onClick={checkUser}>Check User</button> */}
      //           {/* {localStorage.setItem('isReloaded', true)} */}

      //           {/* {console.log(Auth.currentlyAuthenticatedUser())}          */}





    // signOut={signOut} 
    // loggedInUser={loggedInUser} 
    // loggedInGroup={loggedInGroup}


  //useEffect(() => {
    // Hub.listen('auth', (data) => {
    //    const { payload } = data
    //    console.log('A new auth event has happened: ', data)
    //    if (payload.event === 'signIn') {
    //      console.log(payload.message) 
    //      console.log(payload.data.username ) 
    //       //checkUser()
    //      setLoggedInUser(user.signInUserSession.idToken.payload['cognito:username'])
    //      setLoggedInGroup(user.signInUserSession.accessToken.payload['cognito:groups'][0])
         
    //    }
    //    if (payload.event === 'signOut') {
    //       setLoggedInUser('')
    //       setLoggedInGroup("")
    //       console.log(payload.message,'  a user has signed out!')
    //       console.log(payload.data.username )

    //       console.log(loggedInUser)
    //    }
    // // })
    // setLoggedInUser(user.signInUserSession.idToken.payload['cognito:username']||'')
    // setLoggedInGroup(user.signInUserSession.idToken.payload['cognito:groups'][0]||'')
    //console.log(user)

 // }, [])

  // function checkUser() {
  //   Auth.currentAuthenticatedUser()
  //     .then(user => console.log( user))//['username'] ))
  //     .catch(err => console.log(err))
  // }


// import Main from './Main.jsx'
// import Home from '../src/pages/home/Home.jsx'
/////////////////////////
// import Table from './components/Table.jsx';
// // import Header from './components/Header.jsx';
// //import Modal from "react-modal";
// //import {columnsCustomers, columnsClients, columnsJobbook} from './Columns.js';
// import GetTableData from './components/TableData.jsx';
// import GetTableColumns from './components/TableColumns.jsx'
// import AddNewClientScreen from './components/AddNewClientScreen.js';
// import ApplicationBar from './components/AppBar.jsx';

// import NavButtons from './components/NavButtons.jsx';
//  import Navbar from './components/Navbar.jsx';
//  import Sidebar from './components/Sidebar.jsx'


  // const DATA = useRef([]);
  // const [isOpen, setIsOpen] = useState(false);
  // const [serverData, setServerData]= useState([{}]);
  // //const [query, setQuery] = useState('SELECT * FROM customers');
  // const [table, setTable] = useState({query : "SELECT * FROM clients",
  //                                     tableName: 'clients', });
 
  // useEffect(()=>{
  //   loadDataFromServer(table.query, table.tableName);
  // },[table])

  // function toggleModal() {
  //   setIsOpen(!isOpen);
  // }
  
  // const respData = (inp)=>{setServerData(inp)}
  // async function loadDataFromServer(query, tableN) {
  //     const response = await fetch(`http://localhost:3002/api?query=${query}`, 
  //         { method: 'POST',
  //           headers: {'Content-Type': 'application/json; text/plain; */*; charset=utf-8' },
  //           body: ''
  //         });
  //     const responseData = await response.json();         
  //     setServerData(responseData)
  // };

        
//           {/* <div className='appContainer'>
            
//             <Navbar/>
            
      
            
//             </div>  */}
//              {/* <Sidebar/> */}
//             {/* <div className="appContainer">
//               <Navbar/>
              
//               {/* <AddNewClientScreen isOpen={isOpen} setIsOpen={setIsOpen} /> */}
//               {/* <Navbar/> */}
//             {/* ideba  <Sr/> */}
//               {/* <ApplicationBar /> */}
//               {/* <NavButtons toggleModal={toggleModal} setTable={setTable}/>
//               <Table columns= {GetTableColumns(table.tableName, {table} )} data={GetTableData( serverData, table.tableName)}/>
//               *}
//             </div>  
//             {/* <AppRoutes /> */}
//              {/* <Main/>
//             </div> */} 
           
//             {/* <Home/> */}

// /////////////
// {/* <SectionStyle></SectionStyle> */}

// <Header title={''} children={''}>
// {/* <Input className="something" placeholder="Search" backgroundColor="white" textColor="red" size=".2em"/>
// <PasswordInput className="something"  backgroundColor="white" size=".3em"/> */}
// {/* <Label><CheckInput className="something"  defaultChecked /><LabelText $mode="">Foo</LabelText></Label> */}
// </Header>       {/* &lt; 💅🏾 */}


//////////////IMPORTS

//import styled from 'styled-components'
//import { useTable } from 'react-table'
// import {
//           Title,
//           SectionStyle,
//           //..ButtonStyle,
//           TomatoButton,
//           //..Thing,
//           //..RotateDiv,
//           FadeInButton,
//           Spinner, 
//           //...CheckInput, 
//           //... Label, 
//           //....LabelText,
//          } from './components/Styles.jsx'

// import * as dotenv from 'dotenv'
// dotenv.config({path: '../env'})
//require('dotenv').config()
// Modal.setAppElement("#root");


      //  setLoggedInUser('')  //  setLoggedInGroup('') 
            // setLoggedInUser(payload.data.username )
      // setLoggedInGroup(payload.data.signInUserSession.idToken.payload['cognito:groups'][0])
      //console.log(payload)       //checkUser()

      
  // ConfirmSignIn: {
  //   Header() {
  //     return <Heading>ログイン</Heading>
  //   },
  // },

  // ResetPassword: {
  //   Header() {
  //     return <Text
  //             color='darkred' 
  //             fontWeight={600} 
  //             fontSize={18}
  //             fontStyle="normal"
  //             textDecoration="none"
  //           >
  //             Reset your password
  //           </Text> //<Heading>Reset your password</Heading> //パスワード再交付
  //   },
  // },


                // <Heading 
              //   padding={tokens.space.xxxl}
              //   width='100%' 
              //   //  height="50%" 
              //   color="green"
              //   fontWeight="bold" 
              //   level={5} 
              //   //paddingTop ='45px' 
              //   //marginLeft = '30px'
              //   //border='1px dotted red'
              // >     // </Heading>