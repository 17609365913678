import React, {useState, useRef, useEffect } from 'react';
import {Button, TextField, Stack, Typography, Box} from '@mui/material';     
import './newitem.scss';
import { mailformat,  companyABN, phonenum, BSBformat, Accountformat, notEmpty} from '../Regex.js';
import { useParams, useNavigate} from "react-router-dom";// import { InputAdornment } from '@mui/material';// import { AccountCircle, FitScreen } from '@mui/icons-material';//import { TabItem, Tabs, Text, View, ThemeProvider, Theme, useTheme } from '@aws-amplify/ui-react';
import NewContact from './NewContact';// import { blue, grey } from '@mui/material/colors';
import { Table, TableCell, TableBody, TableRow} from '@aws-amplify/ui-react';
import styled from 'styled-components';//import { ModPrompt } from '../../util/Functions.jsx';
import { SearchAddress } from '../../util/Functions.jsx';
import { DialogueResponce } from '../DialogueAlert.jsx';

const Container = styled.div`
    //background: ${props => props.bgc};
    //color: ${props => props.primary ? "black" : "transparent"};
    marginRight: 1ch;
    //padding: 1ch 1ch;
    //padding-top:2ch;
    //border: 3px solid ${props=> props.bc } ;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    height:${props=>props.h};
    width:${props=>props.w};
    box-shadow:0px 0px 2px 1px lightgray;
`;

const {
    REACT_APP_API_HTTP,
    REACT_APP_API_LOCAL_DOMAIN,
    REACT_APP_API_LOCAL_PORT,
    REACT_APP_API_ADD_NEW_CLIENT,
    REACT_APP_API_UPDATE_CLIENT_DATA,
    REACT_APP_API_GET_CLIENT_DATA,
    NODE_ENV,
  } = process.env;

const NewClient = ({action}) => {  

    const [inputs, setInputs] = useState({});
    const [isOpen, setIsOpen] =useState(false);
    const [modResponse, setModResponse] =useState(false);
    const VALID_businessName = useRef(false);
    const VALID_registeredBisinessName = useRef(false);
    const VALID_email = useRef(false);
    const VALID_abn = useRef(false);
    const VALID_clientPhoneNumber = useRef(false);  //"clientPhoneNumber"
    const  VALID_clientBSB = useRef(false);
    const  VALID_clientAccountNumber = useRef(false);
    const [clientAddress,setClientAddress] = useState('')
    const [clientSuburb, setClientSuburb]= useState('')
    const [clientState, setClientState] = useState('');
    const [clientPostCode, setClientPostCode] = useState('');
    const [siteZone,setSiteZone] =useState('');
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const windowWidth = useRef('');   
    const lat = useRef(0);
    const lng = useRef(0);

    let {id} = useParams(); //this is a client id
    const navigate = useNavigate();  //   const { tokens } = useTheme();

    const productionHost = REACT_APP_API_HTTP + window.location.host;
    const localHost = REACT_APP_API_HTTP + REACT_APP_API_LOCAL_DOMAIN + REACT_APP_API_LOCAL_PORT;
    const DOMAIN = `${NODE_ENV==='production'?productionHost:localHost}`; 
    const isDebug = process.env.REACT_APP_DEBUG;

    useEffect(() => {        
        if (modResponse){
            deleteData(`${DOMAIN}${process.env.REACT_APP_API_GET_CLIENT_DATA}delete/${id}/d`)      /// `http://localhost:3002/api/${type}/delete/${id}`           //console.log('TTTTTTTTTTTTTTTTT')
            navigate('/clients')
        }
        setModResponse(false)
        setIsOpen(false)  
      }, [isOpen,  modResponse]);


      useEffect(() => {
        console.log(action)
        if (action ==='update' ){
             getClientData(`${DOMAIN}${REACT_APP_API_GET_CLIENT_DATA}${id}`) //getContactData(`${DOMAIN}${REACT_APP_API_GET_CONTACT_DATA}${'clientId'}/${id}`) // handleGetDataForUpdate()      //setLoaded(false)
        }else {
            setInputs(values=>({...values, ['clientName']: ''})) 
            setInputs(values=>({...values, ['clientRegName']: ''})) 
            setInputs(values=>({...values, ['clientEmail']: ''})) 
            setInputs(values=>({...values, ['clientPhoneNumber']: ''})) 
            setInputs(values=>({...values, ['clientABN']: ''})) 
            setInputs(values=>({...values, ['clientBSB']: ''})) 
            setInputs(values=>({...values, ['clientAccountNumber']: ''}))  

        }        
      }, []);

      useEffect(()=>{        
        setInputs(values=>({...values, ['clientAddress']: clientAddress}));
        //setInputs(values=>({...values, ['position']: {x:parseFloat(lng.current), y:parseFloat(lat.current)}}));
        setInputs(values=>({...values, ['clientSuburb']: clientSuburb }))    
        setInputs(values=>({...values, ['clientPostCode']: clientPostCode})); 
        setInputs(values=>( {...values, ['clientState']: clientState})) 
        //setInputs(values=>( {...values, ['siteZone']: calculateZone(lng.current, lat.current, siteState)})) 
        //setSiteZone( siteState ===""?'' :   calculateZone(lng.current, lat.current, siteState))

    },[clientAddress]);

      useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());            //setZoom()
        }
        window.addEventListener('resize', handleWindowResize);       
        return ()=>{ window.removeEventListener('resize', handleWindowResize)  };
     },[] );

     useEffect(() => {
        windowWidth.current = ((windowSize.innerWidth)/(windowSize.innerWidth/660)).toString()
     }, [windowSize]);
      

    const postNewClientData = async(Url, BodyInput)=> {
        console.log(BodyInput)
        const requestOptions = {   
            method: 'POST', 
            body: JSON.stringify({ BodyInput }),    
            headers: {'Content-Type':'application/json; charset=utf-8' },   
        } 
        const response = await fetch(Url, requestOptions);
        const responseData = await response.json();          //setNewClientId(responseData.insertId)        //setContactInputs(values=>({...values, ['clientId']: responseData.insertId})) //console.log(responseData.insertId)                                 
    }

     const getClientData = async(Url)=>{      
        const response = await fetch(Url);
        const responseData = await response.json();  
        const clientData = responseData[0]

        const keys = Object.keys(clientData)
        const values = Object.values(clientData)

        console.log(keys)
        console.log(values)
        keys.forEach(key => setInputs(values=>({...values, [key]: clientData[key]})) )

        setClientAddress(clientData.clientAddress)  

        keys.forEach(k => {if(k == 'clientName'){if(clientData[k].length > 0){VALID_businessName.current =true}else{VALID_businessName.current =false } } } )
        keys.forEach(k => {if(k == 'clientRegName'){if(clientData[k].length > 0){VALID_registeredBisinessName.current =true}else{VALID_registeredBisinessName.current =false } } } )
        keys.forEach(k => {if(k == 'clientEmail'){if(clientData[k].length > 0){VALID_email.current =true}else{VALID_email.current =false } } } )
        keys.forEach(k => {if(k == 'clientABN'){if(clientData[k].length > 0){VALID_abn.current =true}else{VALID_abn.current =false } } } )
        keys.forEach(k => {if(k == 'clientPhoneNumber'){if(clientData[k].length > 0){VALID_clientPhoneNumber.current =true}else{VALID_clientPhoneNumber.current =false } } } )
        keys.forEach(k => {if(k == 'clientBSB'){if(clientData[k].length > 0){VALID_clientBSB.current =true}else{VALID_clientBSB.current =false } } } )
        keys.forEach(k => {if(k == 'clientAccountNumber'){if(clientData[k].length > 0){VALID_clientAccountNumber.current =true}else{VALID_clientAccountNumber.current =false } } } )
    
    }
    
    function getWindowSize (){
        const {innerWidth, innerHeight, outerWidth} = window; // let zoom = (outerWidth / innerWidth).toFixed(2) // DEBUG && console.log('zoom: ', parseFloat(zoom)) // DEBUG && console.log(outerWidth, innerWidth)
        return {innerWidth, innerHeight};
     }


    // async function getContactData(Url) {   
    //     isDebug && console.log(Url)   
    //     const response = await fetch(Url);
    //     const contacts = await response.json();  
    //     // setContactsNumbers(contacts.length)
    //     // setContactsData(contacts)
    //     isDebug && console.log(contacts)

    //     const keys= Object.keys(contacts)
    //     isDebug && console.log(keys)
    //     //setContactKey(contacts.length)
    //     //contacts.forEach(k => setContactsData(values =>({...values, k})) )
    // }

    //isDebug && console.log(BodyInput) /// text/plain; */*; charset=utf-8 //
    async function putData(Url, BodyInput) {    
        const requestOptions = {   
            method: 'PUT', 
            body: JSON.stringify({ BodyInput }),    
            headers: {'Content-Type':'application/json; charset=utf-8' },   
        } 

        console.log(requestOptions)
        const response = await fetch(Url, requestOptions);
        const responseData = await response.json();  
    }


    const handleAdd=()=>{
        isDebug && console.log(inputs)
        postNewClientData(DOMAIN + REACT_APP_API_ADD_NEW_CLIENT, inputs) 
        navigate(-1)
    }

    const handleUpdate=()=>{
        console.log(REACT_APP_API_UPDATE_CLIENT_DATA)
        
        putData(`${DOMAIN}${REACT_APP_API_UPDATE_CLIENT_DATA}${id}`, inputs);
        navigate(-1); 
    } 
    
    const handleClose=()=>{
        isDebug && console.log(`I am out from ${action==='update'?"update":" add new"} client`, inputs)
        navigate(-1)
    }

    const validateField = (textToTest, regExp) => { return regExp === ''? true: textToTest.match(regExp)? true: false }       //return  VALID.current 
    
    async function deleteData(a) {
        isDebug && console.log(a)
        const response = await fetch(a, {method: 'DELETE'});
        const responseData = await response.json();                               
    }
        
    const handleDelete = ()=>{
        setIsOpen(true)

        //let a = prompt(`You are about to Deactivate this Client! If You wish to proceed - type 'Yes' and then click OK`,'NO')
        // if(a===null)(a='NO')    
        // if (a.toLowerCase() === 'yes'){
        //     deleteData(`${DOMAIN}${process.env.REACT_APP_API_GET_CLIENT_DATA}delete/${id}/d`)      /// `http://localhost:3002/api/${type}/delete/${id}`
        //     navigate(-1)
        // }        
    }

    return (
        <Box 
            component="form" 
            sx={{'& .MuiTextField-root':{ width:'100%'} }} 
            autoComplete="off"
        >
            <Stack sx={{flex: '1 1 100%',  alignItems:'center'}}>               

                <Stack name='TABLE' sx={{boxShadow:'1px 1px 2px 1px gray', width: '85ch'}}>     
                    <Table
                        caption={
                            <Stack
                                direction='row' sx={{flex:'1 1 100%', border:'1px solid black', paddingY:1, backgroundColor : 'lightsteelblue'}} 
                                gap={2} 
                                justifyContent='center'
                            > 
                                <Typography color={'InfoText'}  fontWeight={600} >{action=='update' ? 'Updating Client:':'Adding Client:'}</Typography>  
                                <Typography  color={'red'} fontWeight={600} >{inputs.clientName}</Typography>
                            </Stack>
                        }                                      //{<text style={{backgroundColor:'steelblue', marginY:4, fontSize:16, fontWeight:600, fontFamily:'cursive'}}>Updating Client - {businessName.toUpperCase()}</text>}
                        size="small"                           //highlightOnHover={true}                         //variation="bordered"                      //backgroundColor={'antiquewhite'}    
                        highlightOnHover={true}
                        variation="striped"
                        backgroundColor={'antiquewhite'}                    
                    >    
                        <TableBody>     
                            <TableRow >                                                
                                <TableCell border='1px solid lightgray' ><Typography style={{ color:'#2d187be0', fontWeight:600, fontSize:14}}>Business Name</Typography></TableCell>

                                <TableCell border='1px solid lightgray'>
                                    <TextField                                              
                                        required
                                        autoFocus={true}
                                        size='small'
                                        value={inputs.clientName}      ///setBusinessName(e.target.value)                                      //defaultValue={businessName}
                                        name ="clientName" 
                                        error ={!VALID_businessName.current}                                            // InputProps={{startAdornment: ( <InputAdornment position="start"><AccountCircle/>   </InputAdornment> ), }} 
                                        helperText={inputs.clientName ==='' && <Typography fontSize={11}>Provide value for the Business Name</Typography> }
                                        onChange={(e)=>{                                            
                                            VALID_businessName.current = validateField(e.target.value, notEmpty)
                                            setInputs(values=>({...values, [e.target.name]: e.target.value}))
                                        }}
                                /> </TableCell>          

                            </TableRow>                                                                                                                                 

                            <TableRow >                                                
                                <TableCell  border='1px solid lightgray' ><Typography style={{color:'#2d187be0', fontWeight:600, fontSize:14}}>Registered Name</Typography></TableCell>
                                <TableCell  border='1px solid lightgray' >
                                    <TextField                                          
                                        required
                                        value ={inputs.clientRegName}//{registeredBisinessName}     // setRegisteredBisinessName(e.target.value)                                                                   
                                        size='small'
                                        name ="clientRegName"        
                                                                         
                                        error ={!VALID_registeredBisinessName.current }// InputProps={{startAdornment: (//     <InputAdornment position="start">      //         <AccountCircle/> //     </InputAdornment> //     ),       // }}     
                                        helperText={inputs.clientRegName ==='' && <Typography fontSize={11}>Please provide value for the Registered Business Name</Typography>}
                                        onChange={(e)=>{                                            
                                            VALID_registeredBisinessName.current = validateField(e.target.value, notEmpty)
                                            setInputs(values=>({...values, [e.target.name]: e.target.value}))
                                        }}
                                    />  
                                </TableCell>                                                
                            </TableRow>

                            <TableRow>                                                
                                <TableCell  border='1px solid lightgray' ><Typography style={{color:'#2d187be0', fontWeight:600, fontSize:14}}>ABN Number </Typography></TableCell>
                                <TableCell  border='1px solid lightgray' >
                                    <TextField  
                                        value={inputs.clientABN} //setAbn(e.target.value)
                                        required
                                        size="small"
                                        name ="clientABN" 
                                        error={ !VALID_abn.current }  
                                        helperText={ VALID_abn.current ? '' : inputs.clientABN === ''?'':<Typography fontSize={11}>ABN format is INVALID</Typography>}  
                                        onChange={(e)=>{                                            
                                            VALID_abn.current = validateField(e.target.value, companyABN)
                                            setInputs(values=>({...values, [e.target.name]: e.target.value})) 
                                        }}                                        
                                    /> 

                                    
                                </TableCell>                                                
                            </TableRow>

                            <TableRow >                                                
                                <TableCell border='1px solid lightgray' ><Typography style={{ color:'#2d187be0', fontWeight:600, fontSize:14}}>Bank Account</Typography></TableCell>
                                <TableCell border='1px solid lightgray'>
                                    <TextField                                      
                                        size='small'
                                        style={{width:'15ch' }}
                                        value={inputs.clientBSB} //setContactBSB(e.target.value)
                                        name = "clientBSB"
                                        error={ !VALID_clientBSB.current }  
                                        helperText={ VALID_clientBSB.current ? '' : inputs.clientBSB === ''?'':<Typography fontSize={11}>BSB format is INVALID</Typography>}                              
                                        placeholder={'BSB'}                  
                                        onChange={(e)=>{                                            
                                            VALID_clientBSB.current = validateField(e.target.value, BSBformat)
                                            setInputs(values=>({...values, [e.target.name]: e.target.value}))
                                        }}            
                                    />  
                                    
                                    <TextField  
                                        placeholder={'Account Number'}
                                        size='small' 
                                        style={{width:'62ch', marginLeft:-1}}            
                                        value={inputs.clientAccountNumber}   // setContactAccountNumber(e.target.value)
                                        name ="clientAccountNumber" 
                                        error={ !VALID_clientAccountNumber.current }  
                                        helperText={ VALID_clientAccountNumber.current ? '' : inputs.clientAccountNumber === ''?'':<Typography fontSize={11}>Account Number format is INVALID</Typography>} 
                                        onChange={(e)=>{
                                             VALID_clientAccountNumber.current = validateField(e.target.value, Accountformat)
                                            setInputs(values=>({...values, [e.target.name]: e.target.value}))
                                        }}        
                                    />        
                                    
                                </TableCell>                                                
                            </TableRow>
                            
                            <TableRow >                                                
                                <TableCell border='1px solid lightgray' ><Typography style={{ color:'#2d187be0', fontWeight:600, fontSize:14}}>GC Customer ID</Typography></TableCell>
                                <TableCell border='1px solid lightgray' >                                     
                                    {/* <TextField  
                                        value={inputs.clientLogo}  //setFileName(e.target.value)
                                        size='small'
                                        name ="clientLogo"
                                        onChange={(e)=>{                             
                                            setInputs(values=>({...values, [e.target.name]: e.target.value}))//VALID_fileName.current = validateField(e.target.value, '')
                                        } }                                        
                                    />  */}
                                    <TextField  
                                        value={inputs.GC_customerUUID}      //setFileName(e.target.value)
                                        size='small'
                                        name ="GC_customerUUID"
                                        disabled={true}                                   
                                    /> 
                                                                        
                                </TableCell>                                                
                            </TableRow>

                            <TableRow >                                                
                                <TableCell border='1px solid lightgray' width='45ch'><Typography style={{ color:'#2d187be0', fontWeight:600, fontSize:14}}>GC Bank ID</Typography></TableCell>
                                <TableCell border='1px solid lightgray' >                                     
                                    <TextField  
                                        value={inputs.GC_bankaccountUUID}    
                                        size ='small'
                                        name ="GC_bankaccountUUID"
                                        disabled={true}                                   
                                    /> 
                                                                        
                                </TableCell>                                                
                            </TableRow>

                            <TableRow >                                                
                                <TableCell border='1px solid lightgray' width='45ch'><Typography style={{ color:'#2d187be0', fontWeight:600, fontSize:14}}>GC Mandate ID</Typography></TableCell>
                                <TableCell border='1px solid lightgray' >                                     
                                    <TextField  
                                        value={inputs.GC_mandateUUID}    
                                        size ='small'
                                        name ="GC_mandateUUID"
                                        disabled={true}                                   
                                    /> 
                                                                        
                                </TableCell>                                                
                            </TableRow>

                            <TableRow >                                                
                                <TableCell border='1px solid lightgray' ><Typography style={{color:'#2d187be0', fontWeight:600, fontSize:14}}>Email</Typography></TableCell>
                                <TableCell border='1px solid lightgray'>
                                    
                                    <TextField  
                                        value={inputs.clientEmail} //  defaultValue={emailBusness} //                        setEmailBusness(e.target.value)
                                        required
                                        size='small'
                                        name ="clientEmail"                                        //sx={{border:'none', outline: 'none'}}
                                        error={!VALID_email.current} 
                                        helperText= { VALID_email.current  ? '' : inputs.clientEmail=='' ? '':<Typography fontSize={11}>Email format is INVALID</Typography>}
                                        onChange={(e)=>{                    
                                            VALID_email.current = validateField(e.target.value, mailformat) //console.log(VALID_email.current)
                                            setInputs(values=>({...values, [e.target.name]: e.target.value})) 
                                        }}  // onKeyDown ={handleKeyDown} //e.key==="Enter" && 
                                    /> 
                                </TableCell>                                                
                            
                            </TableRow>

                            <TableRow>
                                <TableCell border='1px solid lightgray' > <Typography style={{color:'#2d187be0', fontWeight:600, fontSize:14}}>Addtess</Typography> </TableCell>
                                <TableCell border='1px solid lightgray' >
                                <Stack sx={{ml:'-2.9ch'}} >
                                    <SearchAddress
                                        destination={clientAddress}  
                                        setLat={lat}
                                        setLng={lng}
                                        setDestination={setClientAddress} 
                                        setSiteState={setClientState}
                                        setSitePostCode={setClientPostCode}
                                        setSiteSuburb={setClientSuburb}
                                        
                                        size={windowWidth.current}        
                                    />
                                </Stack>
                                    
                                </TableCell>


                            </TableRow>

                            <TableRow >                                        
                                <TableCell border='1px solid lightgray' ><Typography style={{color:'#2d187be0', fontWeight:600, fontSize:14}}>Phone</Typography></TableCell>
                                <TableCell border='1px solid lightgray'>
                                    <TextField  

                                        value={inputs.clientPhoneNumber}      //setClientPhoneNumber(e.target.value)                                          
                                        required
                                        size='small'
                                        name ="clientPhoneNumber" 
                                        error={!VALID_clientPhoneNumber.current }  
                                        helperText={VALID_clientPhoneNumber.current ? '' : inputs.clientPhoneNumber === '' ? '':<Typography fontSize={11}>Phone Number format is INVALID</Typography>} 
                                        onChange={(e)=>{                                            
                                            VALID_clientPhoneNumber.current = validateField(e.target.value, phonenum)
                                            setInputs(values=>({...values, [e.target.name]: e.target.value})) 
                                        }}
                                    />   
                                                                                        
                                </TableCell>                                                                            
                            </TableRow>      
                                              
                        </TableBody >  
                    </Table>  
                </Stack>

                <Stack name="CONTACTS" direction="row" sx={{ flex: '1 1 100%', alignItems:'center', mt:2 }}>  
                    {action==='update' && <NewContact entityID={id} entitySearchField={'clientId'}/>}
                </Stack>

                <Stack className='stackButtons'  sx={{flex: '1 1 100%', mt:4, mb:1}} direction="row" gap={6}  justifyContent="center" alignItems="center"   >           
                
                        <Button 
                            onClick= {action==='update'? handleUpdate : handleAdd} 
                            disabled={!VALID_clientPhoneNumber.current || !VALID_email.current }
                            className='Button1' 
                            sx={{fontSize:11}} 
                        ><Typography style={{fontSize:12, fontWeight:800}}>{action==='update'? 'Update ' : 'Add '}</Typography></Button>

                        {action==='update'&& 
                            <Button 
                                onClick={handleDelete}
                                className='Button1' 
                                sx={{ color:'red', fontSize:11, width: '20ch'}} 
                            > <Typography style={{fontWeight:800, fontSize:12}}>deactivate</Typography></Button>
                        }
                        
                        <Button 
                            className='Button1' 
                            sx={{ fontSize:11,  borderRadius:'5px'}}                             
                            onClick={handleClose} 
                        >  <Typography style={{ fontWeight:800, fontSize:12}}>close</Typography></Button>                
                </Stack>
            </Stack>

            <DialogueResponce
                    isOpen={isOpen} 
                    setIsOpen={setIsOpen} 
                    setValue={setModResponse} //setDialogValue
                    title={`${inputs.active !==1 ? 'Reactivation Warning':'Deactivation Warning'}`} 
                    dialogueTxt= {` You are about to change the client from ${inputs.active===1 ? 'active':'inactive'} to ${inputs.active===1 ? 'inactive':'active'}. Would You like to proceed with ${inputs.active !==1 ? 'activation':'deactivation'}?` }
            />  

            {/* <ModPrompt isOpen={isOpen} description={`Confirm Deactivate for this Client by typing 'Yes' and then clicking OK buton`}  setResponse={setModResponse} isPassword={false} />  */}
        </Box>  
    )
} 
export default NewClient



    // const [DOMAIN, setDOMAIN]= useState(`${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_API_PORT}`);  
    // const [DOMAIN, setDOMAIN]= useState(`${window.location.host}:${process.env.REACT_APP_API_PORT}`); 
    // const [businessName, setBusinessName] = useState('');
    // const [registeredBisinessName, setRegisteredBisinessName] = useState('');
    // const [contactName, setContactName] = useState('');
    // const [contacEmail, setContactEmail] = useState('');
    // const [contactNumber, setContactNumber] = useState('');
    // const [contactId, setContactId] =useState('')
    // const [newClientId, setNewClientId] = useState('')
    // const [newContactId, setNewContactId] = useState('')
    //const [contactInputs, setContactInputs] = useState({});
    // const [contactBSB, setContactBSB] = useState('');
    //const [abn, setAbn] = useState('');
    // const [emailBusness, setEmailBusness] = useState('');
    // const [clientPhoneNumber, setClientPhoneNumber] = useState('');
    // const [fileName, setFileName] = useState('');

    // const [contactAccountNumber, setContactAccountNumber] = useState('');
    //const [errorFields, setErrorFields] = useState([]);
        // const [contactsData, setContactsData] = useState({});
    // const [contactsNumbers,setContactsNumbers] =useState(0);
    // const [contactKey,setContactKey] =useState(0);
    //const [loaded, setLoaded] = useState(true);
// "scripts": {
//     "start": "react-scripts start",
//     "build": "rimraf ./build && react-scripts build && rimraf ./build/**/*.map",
//     "test": "react-scripts test --env=jsdom",
//     "eject": "react-scripts eject"
//     }
// GENERATE_SOURCEMAP=false in .env in src folder


// INLINE_RUNTIME_CHUNK=true to file .env.production (recommend add with GENERATE_SOURCEMAP=false to make sure *.map file will not generated after build

// Modifying package.json:
// "scripts": {
//     ...
//     "build": "GENERATE_SOURCEMAP=false react-scripts build",
//     ...
//   }, 







    // const handleAddContact = ()=>{
    //     //isDebug && console.log(contactsData[3])
    //      isDebug && console.log(contactInputs)
    //     postNewContactData(DOMAIN + REACT_APP_API_ADD_NEW_CONTACT, contactInputs)
    //     setContactInputs('');
    //     setContactName('');
    //     setContactNumber('');
    //     setContactEmail('');
    //     //getContactData(`${DOMAIN}${REACT_APP_API_GET_CONTACT_DATA}${id}`)
    //     window.location.reload()

    // }

    //Update the client//const handleGetDataForUpdate=()=>{getClientData(`${process.env.REACT_APP_API_GET_CLIENT_DATA}${id}`)}


    
    // async function DeleteData(Url) {    

    //     isDebug && console.log(Url)
    //     const requestOptions = {method: 'DELETE'} 
    //     const response = await fetch(Url, requestOptions);
    //     const responseData = await response.json();  
    // }


    // {/* <Stack name= 'FIELDS' spacing={3}>  */}
    //                     {/* <Stack namw = 'B N'direction="row" > */}
    //                         {/* <TextField  
    //                             label="Business Name" 
    //                             required
    //                             autoFocus={true}
    //                             size='small'
    //                             style={{width:'50ch', }}
    //                             //sx={{boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, }}
    //                             value={businessName}
    //                             //defaultValue={businessName}
    //                             name ="clientName" 
    //                             error ={businessName === ''||!VALID_businessName.current? true : false}
    //                             // InputProps={{startAdornment: (
    //                             //     <InputAdornment position="start">
    //                             //         <AccountCircle/>
    //                             //     </InputAdornment>
    //                             // ), }} 
    //                             helperText={
    //                                 businessName ===''?'Provide value for the Business Name': 
    //                                 !VALID_businessName.current? "Proided value is invalid":''
    //                             }
    //                             onChange={(e)=>{
    //                                 setBusinessName(e.target.value)
    //                                 VALID_businessName.current = validateField(e.target.value, notEmpty)
    //                                 handleChange(e)
    //                             }}
    //                         />  */}

    //                         {/* <TextField  
    //                             label="Registered Business Name" required
    //                             value ={registeredBisinessName}
    //                             style={{width:'50ch', }}
    //                             //sx={{boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, }}
    //                             size='small'
    //                             name ="clientRegName" 
    //                             error ={registeredBisinessName === '' || registeredBisinessName === null || !VALID_registeredBisinessName.current ? true : false}
    //                             // InputProps={{startAdornment: (
    //                             //     <InputAdornment position="start">
    //                             //         <AccountCircle/>
    //                             //     </InputAdornment>
    //                             //     ),
    //                             // }}     
    //                             helperText={businessName ===''?'Value for the Business Name': !VALID_businessName.current? "Provided value is invalid":''} 
    //                             onChange={(e)=>{
    //                                 setRegisteredBisinessName(e.target.value)
    //                                 VALID_registeredBisinessName.current = validateField(e.target.value, notEmpty)
    //                                 handleChange(e)
    //                             }}
    //                         />   */}
    //                     {/* </Stack>      */}

    //                     {/* <Stack direction="row" >             */}
    //                         {/* <TextField  
    //                             label="ABN Number" 
    //                             value={abn}
    //                             required
    //                             size="small"
    //                             name ="clientABN" 
    //                             style={{width:'20ch', }}
    //                             //sx={{boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, }}
    //                             error ={abn === ''|| abn == null || !VALID_abn.current ? true : false}   
    //                             helperText={ abn ===''?'Enter valid ABN': VALID_abn.current?'': "ABN is invalid" } 
    //                             onChange={(e)=>{
    //                                 setAbn(e.target.value)
    //                                 VALID_abn.current = validateField(e.target.value, companyABN)
    //                                 handleChange(e)
    //                             }}
    //                             // onBlur={(e)=>isDebug && console.log(e.target.value)}
    //                         />  */}
    //                         {/* <TextField  
    //                             label="BSB" 
    //                             size='small'
    //                             style={{width:'14ch', height:'4ch'}}
    //                             //sx={{boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, }}
    //                             value={contactBSB} 
    //                             name = "clientBSB"
    //                             error={ contactBSB == "" || contactBSB == null || ! VALID_clientBSB.current ? true : false }   
    //                             helperText={contactBSB ===''?'Enter BSB': VALID_clientBSB.current? "": "BSB is invalid"}                          
    //                             onChange={(e)=>{
    //                                 setContactBSB(e.target.value)
    //                                  VALID_clientBSB.current = validateField(e.target.value, BSBformat)
    //                                 handleChange(e)
    //                             }}            
    //                         />  
    //                         <TextField  
    //                             label="Account Number" 
    //                             size='small' 
    //                             style={{width:'20ch',  marginLeft:-1}}
    //                             //sx={{boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, }}
    //                             value={contactAccountNumber} 
    //                             name ="clientAccountNumber" 
    //                             error={contactAccountNumber =="" || contactAccountNumber ==null  || ! VALID_clientAccountNumber.current ? true : false }   
    //                             helperText={ contactAccountNumber ==''? "Enter Accnt number": ! VALID_clientAccountNumber.current?'Acct number is invalid':''}
    //                             onChange={(e)=>{
    //                                 setContactAccountNumber(e.target.value)
    //                                  VALID_clientAccountNumber.current = validateField(e.target.value, Accountformat)
    //                                 handleChange(e)
    //                             }}        
    //                         />         */}

    //                         {/* <TextField  
    //                             label="Company Logo File" 
    //                             value={fileName}
    //                             size='small'
    //                             name ="clientLogo"
    //                             style={{width:'45ch',}}
    //                             //sx={{boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, }}
    //                             //helperText={'Logo File Name/URL'} 
    //                             onChange={(e)=>{
    //                                 setFileName(e.target.value)
    //                                 VALID_fileName.current = validateField(e.target.value, '')
    //                                 handleChange(e)}
    //                             }
                                
    //                         />  */}
                
    //                     {/* </Stack>   */}

    //                     {/* <Stack direction="row" >   
    //                         <TextField  
    //                             label="Email" 
    //                             value={emailBusness}
    //                             defaultValue={emailBusness} 
    //                             required
    //                             size='small'
    //                             name ="clientEmail"
    //                             style={{width:'57ch'}}                                // sx={{boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, }}
    //                             error={emailBusness === ''|| emailBusness === null || !VALID_email.current ? true : false} //VALID_email.current = validateField(e.target.value, mailformat)
    //                             helperText={ emailBusness === ''? "Please enter valid email": !VALID_email.current?"Email is invalid":''}
    //                             onChange={(e)=>{
    //                                 setEmailBusness(e.target.value)
    //                                 VALID_email.current = validateField(e.target.value, mailformat)
    //                                 handleChange(e)
    //                             }}  // onKeyDown ={handleKeyDown} //e.key==="Enter" && 
    //                         /> 

    //                         <TextField  
    //                             label="Contact Phone Number" 
    //                             value={clientPhoneNumber}
    //                             style={{width:'45ch', height:'3ch'}}
    //                             //sx={{boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, }}
    //                             required
    //                             size='small'
    //                             name ="clientPhoneNumber" 
    //                             error={clientPhoneNumber === ''|| clientPhoneNumber === null || !VALID_clientPhoneNumber.current ? true : false}  
    //                             helperText={clientPhoneNumber ==='' ? 'Please enter Phone Number': !VALID_clientPhoneNumber.current?"Phone Number is invalid":''} 
    //                             onChange={(e)=>{
    //                                 setClientPhoneNumber(e.target.value)
    //                                 VALID_clientPhoneNumber.current = validateField(e.target.value, phonenum)
    //                                 handleChange(e)
    //                             }}
    //                         />                           
                                
    //                     </Stack>  */}
    //                 {/* </Stack>       */}



    //const handleKeyDown = e => e.key == 'Enter' && handleChange  


    // const handleTabClick=(c, i)=>{
    //     isDebug && console.log(contactsData)  
    //     isDebug && console.log(c) 
    //     isDebug && console.log(i) 
    //     setContactKey(i)
    //     setContactName(c.contactName)
    //     setContactNumber(c.contactPhoneNumber)   
    //     setContactEmail(c.contactEmail) 
    //     setContactId(c.contactId)   
    //     setContactInputs(values=>({...values, ['contactName']: c.contactName})) 
    //     setContactInputs(values=>({...values, ['contactPhoneNumber']: c.contactPhoneNumber})) 
    //     setContactInputs(values=>({...values, ['contactEmail']: c.contactEmail})) 
    //     setContactInputs(values=>({...values, ['contactId']: c.contactId})) 
        
    //     VALID_contactNumber.current = validateField(c.contactPhoneNumber, phonenum)
    //     VALID_contacEmail.current = validateField(c.contactEmail, mailformat)
    // }

    // const handleNewTabClick=()=>{
    //     isDebug && console.log(contactsData)  
    //     setContactName("")
    //     setContactNumber('')   
    //     setContactEmail('') 
    //     setContactId('')   
    //     VALID_contactNumber.current = false
    //     VALID_contacEmail.current = false
    //     setContactInputs({}) 
    //     isDebug && console.log(contactsNumbers)
    // }

// import { styled } from '@mui/material/styles';
// import { Link } from "react-router-dom";
// import { css, cx } from '@emotion/react';
// import {ButtonStyle, Thing, Input, PasswordInput,  Label, LabelText, EmailInput } from './Styles.jsx';

// {/* <Link to={{pathname: `/clients`}} style={{ textDecoration: "none" }}>
//  <Link to={{pathname: `/users`}} style={{ textDecoration: "none" }}  > */}
//  {/* </Link>  */}
// {/* <ButtonStyle primary onClick={handleClose} > Close </ButtonStyle>               */}
// {/* <Link to={{pathname: `/clients`}} style={{ textDecoration: "none" }}  > */}
//                             {/* <Button  onClick={handleTest}>  test     </Button> */}
// {/* </Link> */}

// keys.forEach(k=>{
//     setInputs(values=>({...values, [k]: data[k], isValidated: true,}));

// })

// const validatedBN =useRef(false)
// const validatedRBN =useRef(false)
// const validatedABN =useRef(false)
// const validatedBCN =useRef(false)
// const validatedEmail =useRef(false)
// const validateContName = useRef(true)
// const validateContEmail = useRef(true)
// const validateContPhone = useRef(true)


//    //     if (earched==""){
    //       return true
    //     }else{
    //       return inputText.match(searched)? true: inputText.length==0?true: false
    //     }
    //  }

    //sx={{  
        // '& .MuiTextField-root': {m: 1, width: '65ch', height: '10ch'},
        // display: 'flex',
        // flexDirection: { xs: 'column', md: 'column' },
        // width: {
        //     xs: 450, // theme.breakpoints.up('xs')
        //     sm: 600, // theme.breakpoints.up('sm')
        //     md: 900, // theme.breakpoints.up('md')
        //     lg: 1200, // theme.breakpoints.up('lg')
        //     xl: 1300, // theme.breakpoints.up('xl')
        //   },
        // alignItems: 'center',
        // bgcolor: 'background.paper',
        // overflow: 'hidden',
        // boxShadow: 2,
        // borderRadius: 1,
        // p: 5,
    //}}




    
    //const [emailErr, setEmailErr] = useState(false);
    //const [pwdError, setPwdError] = useState(false);
    // const validated =useRef({
    //                         isBN:false,
    //                         isRBN:false,
    //                         isABN:false,
    //                         isBCN:false,
    //                         isEmail:false,
    //                         isContName:false,
    //                         isContEmail:false,
    //                         isContPhone:false
    //                     })


    // const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
//   }));


        //setIsOpen(false)
        // validatedBN.current=false;
        // validatedRBN.current=false;
        // validatedABN.current=false;
        // validatedBCN.current=false;
        // validatedEmail.current=false;
        // validateContName.current=false;
        // validateContEmail.current=false;
        // validateContPhone.current=false;


                // title: 'testTitle',
        // body: 'testBody',
        // userId: Math.random().toString(36).slice(2),

        // useEffect(()=> inputs.isValidated? isDebug && console.log('yes'):isDebug && console.log('not'), [inputs.isValidated])//const url=`http://localhost:3002/api/clients/addNew`;
    // {/* <Stack direction="row" height={65} width={1000}> 
    //                     <TextField  
    //                         label="BSB Number" 
    //                         size='small'
                            
    //                         value={contactBSB} 
    //                         name = "clientContactbSB"
    //                         error={ contactBSB !=="" && ! VALID_clientBSB.current ? true : false }   
    //                         helperText={
    //                             contactBSB !=='' && ! VALID_clientBSB.current ?'Invalid BSB':
    //                             contactBSB !=='' ?'':'format 123-456'
    //                         }                          
    //                         onChange={(e)=>{
    //                             setContactBSB(e.target.value)
    //                              VALID_clientBSB.current = validateField(e.target.value, BSBformat)
    //                             handleChange(e, VALID.current)
    //                         }}            
    //                     />  
    //                     <TextField  
    //                         label="Account Number" 
    //                         size='small' 
    //                         value={contactAccountNumber} 
    //                         name ="clientContactAccountNunber" 
    //                         error={contactAccountNumber !=="" && ! VALID_clientAccountNumber.current ? true : false }   
    //                         helperText={    
    //                             contactAccountNumber !=='' && ! VALID_clientAccountNumber.current?'Account Number is invalid': 
    //                             contactAccountNumber !==''? "":"Account number format either 1234678 or 1234 5678"
    //                         }
                            
    //                         onChange={(e)=>{
    //                             setContactAccountNumber(e.target.value)
    //                              VALID_clientAccountNumber.current = validateField(e.target.value, Accountformat)
    //                             handleChange(e)
    //                         }}        
    //                     />  
                            
    //                 </Stack>    */}


        // {/* <ThemeProvider theme={theme} colorMode="light">             */}
   
                     
        //                 {/* <TabItem title={<Text color="red" >New Contact </Text>}>
        //                     <Stack direction="row" height={85} width={1000} > 
        //                             <TextField  
        //                                 label="Name" 
        //                                 value = {contactName} 
        //                                 name ="contactName"
        //                                 size='small'
        //                                 helperText={contactName ===''?"Please enter Contact Name": ""}
        //                                 error={contactName ==""}
        //                                 onChange={(e)=>{
        //                                     setContactName(e.target.value)
        //                                     //validateField(e.target.value, "")
        //                                     handleContactChange(e)
        //                                 }}
        //                             />
                        
        //                         <TextField  
        //                             label="Phone Number" 
        //                             value={contactNumber}
        //                             name ="contactPhoneNumber" 
        //                             size='small'
        //                             helperText={contactNumber ===''?"Please enter phone number": !VALID_contactNumber.current?"Phone number is invalid":''}
        //                             error={contactNumber !=="" && !VALID_contactNumber.current? true:false}   
        //                             onChange={(e)=>{
        //                                     setContactNumber(e.target.value)
        //                                     VALID_contactNumber.current = validateField(e.target.value, phonenum)
        //                                     handleContactChange(e)
        //                             } }
        //                         /> 
        //                         </Stack>
                            
        //                         <Stack direction="row" height={80} width={500}> 
        //                             <TextField  
        //                                 label="Email" 
        //                                 size='small'
        //                                 value={contacEmail}
        //                                 name = "contactEmail"
        //                                 helperText={contacEmail ===''?"Please enter valid email": !VALID_contacEmail.current?"Email is invalid":''}
        //                                 error = {contacEmail !=="" && !VALID_contacEmail.current? true:false}  
        //                                 onChange={(e)=>{
        //                                     setContactEmail(e.target.value)
        //                                     VALID_contacEmail.current = validateField(e.target.value, mailformat)
        //                                     handleContactChange(e)
        //                                 }}        
        //                             />  
        //                             <Text  > </Text>
        //                         </Stack>

        //                         <Button 
        //                             className= 'Button1'
        //                             onClick= { handleAddContact} 
        //                             variant="outlined" 
        //                             size='small' 
        //                             disabled={action === 'new'? newClientId ===''?true:false:false }
        //                             sx={{fontWeight:600, fontSize:14, fontFamily:'cursive', ml:'46ch', mt:'3ch'}} 
        //                         >Add Contact</Button>

        //                 </TabItem> */}
        //                {/* {isDebug && console.log('tttttttttttttt', contactsData.length === undefined ? 0 : contactsData.length) */}

        


                //     {/* {action === 'update' &&   

                //         <TabItem title={<Text color="blue" >John Verylongname</Text>}>
                //             <Stack direction="row" height={85} width={1000} > 
                //             <TextField  
                //                 label="Name" 
                //                 value = {contactName} 
                //                 name ="contactName"
                //                 size='small'
                //                 onChange={(e)=>{
                //                     setContactName(e.target.value)
                //                     //validateField(e.target.value, "")
                //                     handleChange(e)
                //                 }}
                //             />
                    
                //             <TextField  
                //                 label="Phone Number" 
                //                 value={contactNumber}
                //                 name ="contactNumber" 
                //                 size='small'
                //                 error={contactNumber !=="" && !VALID_contactNumber.current? true:false}   
                //                 onChange={(e)=>{
                //                         setContactNumber(e.target.value)
                //                         VALID_contactNumber.current = validateField(e.target.value, phonenum)
                //                         handleChange(e)
                //                 } }
                //             /> 
                //             </Stack>
                    
                //             <Stack direction="row" height={80} width={500}> 
                //                 <TextField  
                //                     label="Email" 
                //                     size='small'
                //                     value={contacEmail}
                //                     name = "contactEmail"
                //                     error = {contacEmail !=="" && !VALID_contacEmail.current? true:false}  
                //                     onChange={(e)=>{
                //                         setContactEmail(e.target.value)
                //                         VALID_contacEmail.current = validateField(e.target.value, mailformat)
                //                         handleChange(e)
                //                     }}        
                //                 />  

                //             </Stack>
                //             <Button 
                //                     className= 'Button1'
                //                     onClick= { ()=>handleUpdateContact(24)} 
                //                     variant="outlined" 
                //                     size='small' 
                //                     sx={{fontWeight:600, fontSize:14, fontFamily:'cursive', ml:'46ch', mt:'3ch'}} 
                //             >  Update Contact </Button>
                //         </TabItem>
                //     }    

                //     {action === 'update' && 
                //         <TabItem 
                //             title={<Text color="blue" >Maria Who </Text>}
                            
                //             onClick={()=>isDebug && console.log('ttt')} 
                //         >
                //             <Stack direction="row" height={85} width={1000} > 
                //                 <TextField  
                //                     label="Name" 
                //                     value = {contactName} 
                //                     name ="contactName"
                //                     size='small'
                //                     onChange={(e)=>{
                //                         setContactName(e.target.value)
                //                         //validateField(e.target.value, "")
                //                         handleChange(e)
                //                     }}
                //                 />
                        
                //                 <TextField  
                //                     label="Phone Number" 
                //                     value={contactNumber}
                //                     name ="contactNumber" 
                //                     size='small'
                //                     error={contactNumber !=="" && !VALID_contactNumber.current? true:false}   
                //                     onChange={(e)=>{
                //                             setContactNumber(e.target.value)
                //                             VALID_contactNumber.current = validateField(e.target.value, phonenum)
                //                             handleChange(e)
                //                     } }
                //                 /> 
                //             </Stack>
                    
                //             <Stack direction="row" height={80} width={500}> 
                //                 <TextField  
                //                     label="Email" 
                //                     size='small'
                //                     value={contacEmail}
                //                     name = "contactEmail"
                //                     error = {contacEmail !=="" && !VALID_contacEmail.current? true:false}  
                //                     onChange={(e)=>{
                //                         setContactEmail(e.target.value)
                //                         VALID_contacEmail.current = validateField(e.target.value, mailformat)
                //                         handleChange(e)
                //                     }}        
                //                 />  
                //                 <Text> {contactInputs['clientId']}</Text>

                //             </Stack>

                //             <Button 
                //                     className= 'Button1'
                //                     onClick= { ()=>handleUpdateContact()} 
                //                     variant="outlined" 
                //                     size='small' 
                //                     sx={{fontWeight:600, fontSize:14, fontFamily:'cursive', ml:'46ch', mt:'3ch'}} 
                //             >  Update Contact </Button>
                //         </TabItem>
                //     }
                //     {action === 'update' && 
                //         <TabItem title={<Text color="blue" >Peter NotPanButRatherVerylongname</Text>}>

                //             <Stack direction="row" height={85} width={1000} > 
                //                 <TextField  
                //                     label="Name" 
                //                     value = {contactName} 
                //                     name ="contactName"
                //                     size='small'
                //                     onChange={(e)=>{
                //                         setContactName(e.target.value)
                //                         //validateField(e.target.value, "")
                //                         handleChange(e)
                //                     }}
                //                 />
                    
                //                 <TextField  
                //                     label="Phone Number" 
                //                     value={contactNumber}
                //                     name ="contactNumber" 
                //                     size='small'
                //                     error={contactNumber !=="" && !VALID_contactNumber.current? true:false}   
                //                     onChange={(e)=>{
                //                             setContactNumber(e.target.value)
                //                             VALID_contactNumber.current = validateField(e.target.value, phonenum)
                //                             handleChange(e)
                //                     } }
                //                 /> 
                //             </Stack>
                    
                //             <Stack direction="row" height={80} width={500}> 
                //                 <TextField  
                //                     label="Email" 
                //                     size='small'
                //                     value={contacEmail}
                //                     name = "contactEmail"
                //                     error = {contacEmail !=="" && !VALID_contacEmail.current? true:false}  
                //                     onChange={(e)=>{
                //                         setContactEmail(e.target.value)
                //                         VALID_contacEmail.current = validateField(e.target.value, mailformat)
                //                         handleChange(e)
                //                     }}        
                //                 /> 

                //             </Stack>

                //             <Button 
                //                     className= 'Button1'
                //                     onClick= { handleUpdateContact} 
                //                     variant="outlined" 
                //                     size='small' 
                //                     sx={{fontWeight:600, fontSize:14, fontFamily:'cursive', ml:'46ch', mt:'3ch'}} 
                //             >  Update Contact </Button>
                            
                //         </TabItem>
                //     }


                //     {action === 'update' && 
                //         <TabItem title={<Text color="blue" >Officer Crippker</Text>}>

                //             <Stack direction="row" height={85} width={1000} > 
                //                 <TextField  
                //                     label="Name" 
                //                     value = {contactName} 
                //                     name ="contactName"
                //                     size='small'
                //                     onChange={(e)=>{
                //                         setContactName(e.target.value)
                //                         //validateField(e.target.value, "")
                //                         handleChange(e)
                //                     }}
                //                 />

                //                 <TextField  
                //                     label="Phone Number" 
                //                     value={contactNumber}
                //                     name ="contactNumber" 
                //                     size='small'
                //                     error={contactNumber !=="" && !VALID_contactNumber.current? true:false}   
                //                     onChange={(e)=>{
                //                             setContactNumber(e.target.value)
                //                             VALID_contactNumber.current = validateField(e.target.value, phonenum)
                //                             handleChange(e)
                //                     } }
                //                 /> 
                //             </Stack>

                //             <Stack direction="row" height={80} width={500}> 
                //                 <TextField  
                //                     label="Email" 
                //                     size='small'
                //                     value={contacEmail}
                //                     name = "contactEmail"
                //                     error = {contacEmail !=="" && !VALID_contacEmail.current? true:false}  
                //                     onChange={(e)=>{
                //                         setContactEmail(e.target.value)
                //                         VALID_contacEmail.current = validateField(e.target.value, mailformat)
                //                         handleChange(e)
                //                     }}        
                //                 /> 

                //             </Stack>
                //             <Button 
                //                     className= 'Button1'
                //                     onClick= { handleUpdateContact} 
                //                     variant="outlined" 
                //                     size='small' 
                //                     sx={{fontWeight:600, fontSize:14, fontFamily:'cursive', ml:'46ch', mt:'3ch'}} 
                //             >  Update Contact </Button>

                //         </TabItem>
                //     } */}
                            

                    
                    
                //     {/* </Tabs> */}


// {/* </ThemeProvider>     */}


    // const handleContactChange=(e)=>{
    //     //action === 'new'?  setContactInputs(values=>({...values, ['clientId']: newClientId})) :  setContactInputs(values=>({...values, ['clientId']: id}))  
    //     setContactInputs(values=>({...values, ['clientId']: id}))
    //     const name= e.target.name ?? "KEY";
    //     const value = e.target.value ?? 'INVALID';
    //     setContactInputs(values=>({...values, [name]: value})) //: setContactInputs(values=>({...values, [name]: 'INVALID'}))   
    // } 
                        
    //update the contact for the client
    // const handleUpdateContact=(contID)=>{
    //      putData(`${DOMAIN}${REACT_APP_API_CONTACT_UPDATE}${contID}`, contactInputs);
    //      isDebug && console.log(contID)
    //      isDebug && console.log(id)    
         
    //      //getContactData(`${DOMAIN}${REACT_APP_API_GET_CONTACT_DATA}${id}`)
    //      window.location.reload()
    // } 

    // const handleDeleteContact=(contID)=>{
    //     const a = prompt('Are You sure you want to delete this contact?','No')
    //     isDebug && console.log(a)
    //     if (a === null || a === "No"){

    //         isDebug && console.log('contact ID : ' + contID, " client ID : " + id)
    //     }else{
    //         DeleteData(`${DOMAIN}${REACT_APP_API_CONTACT_DELETE}${contID}`);
    //         isDebug && console.log(contID);
    //         isDebug && console.log(id);    
    //         //getContactData(`${DOMAIN}${REACT_APP_API_GET_CONTACT_DATA}${id}`);
    //         window.location.reload()
    //     }
    // } 
    // const handleChange=(e)=>{
    //     const name= e.target.name ?? "KEY";
    //     const value = e.target.value ?? 'INVALID';
    //     VALID.current ? setInputs(values=>({...values, [name]: value})) : setInputs(values=>({...values, [name]: 'INVALID'}))   
    // }  

    
        //setBusinessName(data.clientName) //setEmailBusness(data.clientEmail) //setRegisteredBisinessName(data.clientRegName) 
        //setClientPhoneNumber(data.clientPhoneNumber) //setAbn(data.clientABN) //setFileName(data.clientLogo) //setContactBSB(data.clientBSB); //setContactAccountNumber(data.clientAccountNumber);
        // VALID_businessName.current = true;
        // VALID_registeredBisinessName.current = true;        
        // VALID_email.current = true;
        // VALID_abn.current = true;
        //VALID_clientPhoneNumber.current = true;
        //VALID_clientBSB.current = true;
        // VALID_clientAccountNumber.current = true;
        // VALID_fileName.current = true;

            // async function postNewContactData(Url, BodyInput) {
    //     isDebug && console.log(BodyInput)
    //     const requestOptions = {   
    //         method: 'POST', 
    //         body: JSON.stringify({ BodyInput }),    
    //         headers: {'Content-Type':'application/json; charset=utf-8' },   
    //     } 
    //     const response = await fetch(Url, requestOptions);
    //     const responseData = await response.json();  
    //     setNewContactId(responseData.insertId)
    //     isDebug && console.log(responseData.insertId)                    
    
    

    // }



    // const theme = {
    //     name: 'tabs-theme',
    //     tokens: {
    //       components: {
    //         tabs: {
    //           borderColor: { value: '{colors.neutral.40}' },
    //           item: {
    //             color: { value: '{colors.blue.80}' },
    //             fontSize: { value: '{fontSizes.l}' },
    //             fontWeight: { value: '{fontWeights.bold}' },
                
    //             _hover: {
    //               color: { value: '{colors.blue.60}' },
    //             },
    //             _focus: {
    //               color: { value: '{colors.blue.60}' },
    //             },
    //             _active: {
    //               color: { value: '{colors.blue.80}' },
    //               borderColor: { value: '{colors.blue.80}' },
    //               backgroundColor: { value: '{colors.blue.10}' },
    //             },
    //             _disabled: {
    //               color: { value: 'gray' },
    //               backgroundColor: { value: 'transparent' },
    //             },
    //           },
    //         },
    //       },
    //     },
    // };
