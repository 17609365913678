import React, {useState, useEffect, useContext} from 'react'
import './dashboard.scss';
// import Navbar from '../../components/Navbar.jsx';
// import Sidebar from '../../components/Sidebar.jsx';
import MainFrame from '../../components/MainFrame.jsx';
import { UserLogInContext } from '../../App'; //import Widget from '../../components/Widget.jsx'; // import Chart from '../../components/chart/Chart.jsx' //import EnhancedTable from '../../components/JobGrid.jsx'
import {CircularProgressbar, CircularProgressbarWithChildren, buildStyles} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";// import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";// import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";// import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import {Paper,Stack, Typography} from '@mui/material';  // import Stack from '@mui/material/Stack'; // import NewTable from '../../components/NewTable.jsx';// import EnhancedTable from '../../components/JobGrid.jsx'
import ApplicationBar from '../../components/AppBar.jsx';

const Dashboard = ( ) => {
//  const [tablename, setTableName] = useState('clients')
  const [msg, setMsg]=useState('');
  const [allJobs, setAllJobs]=useState(0);
  const [allAllocated, setAllAllocated]=useState(0);
  const [allConfirmed, setAllConfirmed,]=useState(0);
  const [allPending, setAllPending]=useState(0);
  const [allOnSitre, setAllOnSite]=useState(0);
  const [allTherest, setAllTherest]=useState(0);
  const [allNew, setAllNew]=useState(0);
  const [allScheduled, setAllScheduled]=useState(0);
  const [allDiagnostic, setAllDiagnostic]=useState(0);
  const [allDelivered, setAllDelivered]=useState(0);
  const [sla, setSla]=useState(0);
  const [isloadDashboard, setIsloadDashboard] = useState(false)

  const {user,  subrole, subId, role,} = useContext(UserLogInContext);    // userId, isSuperUser

  const productionHost = process.env.REACT_APP_API_HTTP + window.location.host;
  const localHost = process.env.REACT_APP_API_HTTP + process.env.REACT_APP_API_LOCAL_DOMAIN + process.env.REACT_APP_API_LOCAL_PORT;
  const DOMAIN = `${process.env.NODE_ENV==='production'?productionHost:localHost}`; 
  const DEBUG = process.env.REACT_APP_DEBUG;

  DEBUG && console.log(subrole, role)
  DEBUG && console.log(subId, user)

  ///DEBUG && console.log(window.location.host)

  useEffect(()=>{
    getData(role, subrole) 
    setIsloadDashboard(true)
  },[role, subrole])

  const getData = async(flag, value)=> {

    console.log(flag, value)

    let response, responseData

    response = await fetch(`${DOMAIN}/api/testing/${flag}/${value}/${'ALL'}`);
    responseData = await response.json();    
    setAllJobs(responseData[0].number);
    DEBUG && console.log(responseData[0])

    response = await fetch(`${DOMAIN}/api/testing/${flag}/${value}/${'Allocated'}`);
    responseData = await response.json();    
    setAllAllocated(responseData[0].number);

    response = await fetch(`${DOMAIN}/api/testing/${flag}/${value}/${'Confirmed'}`);
    responseData = await response.json();    
    setAllConfirmed(responseData[0].number);

    response = await fetch(`${DOMAIN}/api/testing/${flag}/${value}/${'Pending'}`);
    responseData = await response.json();    
    setAllPending(responseData[0].number);

    response = await fetch(`${DOMAIN}/api/testing/${flag}/${value}/${'Onsite'}`);
    responseData = await response.json();    
    setAllOnSite(responseData[0].number);

    response = await fetch(`${DOMAIN}/api/testing/${flag}/${value}/${'New'}`);
    responseData = await response.json();    
    setAllNew(responseData[0].number);

    response = await fetch(`${DOMAIN}/api/testing/${flag}/${value}/${'therest'}`);
    responseData = await response.json();    
    setAllTherest(responseData[0].number);

    response = await fetch(`${DOMAIN}/api/testing/${flag}/${value}/${'Scheduled'}`);
    responseData = await response.json();    
    setAllScheduled(responseData[0].number);

    response = await fetch(`${DOMAIN}/api/testing/${flag}/${value}/${'Diagnostic_Visit'}`);
    responseData = await response.json();    
    setAllDiagnostic(responseData[0].number);

    response = await fetch(`${DOMAIN}/api/testing/${flag}/${value}/${'Delivered'}`);
    responseData = await response.json();    
    setAllDelivered(responseData[0].number);

    response = await fetch(`${DOMAIN}/api/testing/${flag}/${value}/${'sla'}`);
    responseData = await response.json();    
    setSla(responseData[0].number);

    
  };

  // async function initMap() {
  //   // Request needed libraries.
  //   const { Map } = await window.google.maps.importLibrary("maps");
  //   const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");
  //   const map = new Map(document.getElementById("map"), {
  //     center: { lat: 37.4239163, lng: -122.0947209 },
  //     zoom: 14,
  //     mapId: "4504f8b37365c3d0",
  //   });
  //   const marker = new AdvancedMarkerElement({
  //     map,
  //     position: { lat: 37.4239163, lng: -122.0947209 },
  //   });
  // }

  const loadDashboard=()=>{

    return (
        
        <div className="row"  >
                          
            <div className="circularbarContainer" >                        

              <Stack className='circulatbarGroup'  >
                
                <Stack className="circularBar">
                    <Typography fontSize={14} fontWeight={600}> All Open Jobs</Typography>
                    <CircularProgressbarWithChildren                                                               
                      value={allJobs}
                      text={`${allJobs} open jobs`}
                      strokeWidth={35}  //background  //backgroundPadding={1} //counterClockwise 
                      background = {true}
                      backgroundPadding={15}
                      //minValue={0} 
                      maxValue={allJobs}                                  
                      styles = { buildStyles({
                          backgroundColor: 'transparent',//'transparent',//"#3e98c7",
                          strokeLinecap: 'butt', // can use 'butt' or 'round' - default
                          rotation: 0.0,
                          pathColor: "turquoise",
                          trailColor: "gold",///'transparent'
                          textColor: "steelblue",
                          textSize: 8,
                          pathTransition:'stroke-dashoffset 2s ease 0s',
                          pathTransitionDuration: 3,
                        }  ) }
                    >
                      {/* <Stack style={{ width: "80%" }}>  </Stack> */}
                    </CircularProgressbarWithChildren>                          
                </Stack>

                <Stack className="circularBar">
                <Typography fontSize={14} fontWeight={600}>Allocated: {allAllocated}</Typography>
                  <CircularProgressbar 
                      value={allAllocated/allJobs * 100}
                      text={`${allJobs === 0 ?'' : (allAllocated/allJobs *100).toFixed(2) + "%"}`}
                      strokeWidth={4}                                    
                      background = {true}
                      backgroundPadding={15}
                      styles={buildStyles(  {
                          pathColor: 'green',//"turquoise",
                          backgroundColor:'transparent',
                          trailColor: "gold",
                          textSize: 8, 
                          textColor:'steelblue',
                          pathTransition:'stroke-dashoffset 0.5s ease 0s',
                          pathTransitionDuration: 3,
                        } ) }              
                    />
                </Stack>

              </Stack> 

              <Stack className='circulatbarGroup' >

                  <Stack className="circularBar">
                    
                  <Typography fontSize={14} fontWeight={600}>Confirmed: {allConfirmed}</Typography>
                    <CircularProgressbar 
                      value={allConfirmed/allJobs *100}
                      text={`${allJobs === 0 ?'' : (allConfirmed/allJobs *100 ).toFixed(2) + "%"} `}
                      strokeWidth={4}                                
                      background = {true}
                      backgroundPadding={15}
                      styles={buildStyles({
                        pathColor: 'green',//"turquoise",
                        backgroundColor:'transparent',
                        trailColor: "gold",
                        textSize: 8, 
                        textColor:'text.secondary',//'steelblue',
                        pathTransition:'stroke-dashoffset 1s ease 0s',
                        pathTransitionDuration: 1,
                      })}   
                      
                      
                    />
                  </Stack>
                  <Stack className="circularBar">
                  <Typography fontSize={14} fontWeight={600}>Pending: {allPending}</Typography>
                    <CircularProgressbar 
                      value={allPending/allJobs *100}
                      text={`${allJobs === 0 ?'' :(allPending/allJobs *100 ).toFixed(2) + "%"}`}
                      strokeWidth={4}
                      background = {true}
                      backgroundPadding={15}                               //backgroundPadding={0}
                      styles={buildStyles({
                        pathColor: 'green', //"turquoise",
                        backgroundColor:'transparent',
                        trailColor: "gold",
                        textSize: 8, 
                        textColor:'steelblue',
                        pathTransition:'stroke-dashoffset 2s ease 0s',
                        pathTransitionDuration: 3,
                      })}              
                    />
                  </Stack>
              </Stack>  

              <Stack className='circulatbarGroup'>
                <Stack className="circularBar">
                <Typography fontSize={14} fontWeight={600}>On Site: {allOnSitre}</Typography>
                  <CircularProgressbar
                    value={allOnSitre/allJobs *100}
                    text={`${allJobs === 0 ?'' :(allOnSitre/allJobs *100 ).toFixed(2) + "%" }`} 
                    strokeWidth={4}                              
                    background = {true}
                    backgroundPadding={15}
                    styles={buildStyles({
                      pathColor: 'green',//"turquoise",
                      backgroundColor:'transparent',
                      trailColor: "gold",
                      textSize: 8, 
                      textColor:'steelblue',
                      pathTransition:'stroke-dashoffset 2s ease 0s',
                      pathTransitionDuration: 3,
                    })}              
                  />
                </Stack>  
                <Stack className="circularBar">
                <Typography fontSize={14} fontWeight={600}>Newly Added: {allNew}</Typography>
                  <CircularProgressbar 
                    value={allNew/allJobs *100}
                    text={`${allJobs === 0 ?'' : (allNew/allJobs *100 ).toFixed(2) + "%"}`}
                    strokeWidth={4}                                
                    background = {true}
                    backgroundPadding={15}
                    styles={buildStyles({
                      pathColor: 'green',//"turquoise",
                      backgroundColor:'transparent',
                      trailColor: "gold",
                      textSize: 8, 
                      textColor:'steelblue',
                      pathTransition:'stroke-dashoffset 3s ease 0s',
                      pathTransitionDuration: 3,
                    })}                                     
                    
                  />
                </Stack>                                
              </Stack> 

            </div>

            <Stack className="circularbarContainer" >                        

              <Stack className='circulatbarGroup' >
                
                  <Stack className="circularBar">
                  <Typography fontSize={14} fontWeight={600}>Scheduled: {allScheduled}</Typography>
                    <CircularProgressbar 
                      value={allScheduled/allJobs *100}
                      text={`${allJobs === 0 ?'' : (allScheduled/allJobs *100 ).toFixed(2) + "%"}`}
                      strokeWidth={4}                                
                      background = {true}
                      backgroundPadding={15}
                      styles={buildStyles({
                        pathColor: 'green',//"turquoise",
                        backgroundColor:'transparent',
                        trailColor: "gold",
                        textSize: 8, 
                        textColor:'steelblue',
                        pathTransition:'stroke-dashoffset 2s ease 0s',
                        pathTransitionDuration: 3,

                      })}                                                                       
                    />
                  </Stack>
                  <Stack className="circularBar">
                  <Typography fontSize={14} fontWeight={600}> Delivered: {allDelivered}</Typography>
                  <CircularProgressbar
                    value={allDelivered/allJobs *100}
                    text={`${allJobs === 0 ?'' : (allDelivered/allJobs *100 ).toFixed(2) + "%"}`} 
                    background = {true}
                    backgroundPadding={15}
                    strokeWidth={4}   
                                      //  backgroundPadding={0}
                    styles={buildStyles({
                      pathColor: 'green',//"turquoise",
                      backgroundColor:'transparent',
                      trailColor: "gold",
                      textSize: 8, 
                      textColor:'steelblue',
                      pathTransition:'stroke-dashoffset 2s ease 0s',
                      pathTransitionDuration: 3,
                    })}              
                  />
                  </Stack>
              </Stack>  

              <Stack className='circulatbarGroup'>
                <Stack className="circularBar">
                <Typography fontSize={14} fontWeight={600}>Diagnostic: {allDiagnostic}</Typography>        
                  <CircularProgressbar
                    value={allDiagnostic/allJobs * 100}
                    text={`${allJobs === 0 ?'' : (allDiagnostic/allJobs *100 ).toFixed(2) + "%"}`} 
                    strokeWidth={4}
                    background = {true}
                    backgroundPadding={15}
                    styles={buildStyles({
                      pathColor: 'green',//"turquoise",
                      backgroundColor:'transparent',
                      trailColor: "gold",
                      textSize: 8, 
                      textColor:'steelblue',
                      pathTransition:'stroke-dashoffset 2s ease 0s',
                      pathTransitionDuration: 3,
                    })}              
                  />
                </Stack>  

                <Stack className="circularBar">
                  <Typography fontSize={14} fontWeight={600}>Other statuses: {allTherest}</Typography>
                  <CircularProgressbar
                    value={allTherest/allJobs *100}
                    text={`${allJobs === 0 ?'' : (allTherest/allJobs *100 ).toFixed(2) + "%"}`} 
                    background = {true}
                    backgroundPadding={15}
                    strokeWidth={4}
                    styles={buildStyles({
                      pathColor: 'green',//"turquoise",
                      backgroundColor:'transparent',
                      trailColor: "gold",
                      textSize: 8, 
                      textColor:'steelblue',
                      pathTransition:'stroke-dashoffset 2s ease 0s',
                      pathTransitionDuration: 3,
                    })}              
                  />
                </Stack>                                
              </Stack> 

              <Stack className='circulatbarGroup'>
                <Stack className="circularBar">
                <Typography fontSize={14} fontWeight={600}>Missed SLA: {sla}</Typography>
                  <CircularProgressbar
                    value={sla/allJobs *100}
                    text={`${allJobs === 0 ?'' : (sla/allJobs *100 ).toFixed(2) + "%"}`} 
                    strokeWidth={4}                              
                    background = {true}
                    backgroundPadding={15}
                    styles={buildStyles({
                      pathColor: sla/allJobs * 100 > 10 ? 'red' : 'green', //"turquoise",
                      backgroundColor:'transparent',
                      trailColor: "gold",
                      textSize: 8, 
                      textColor:'steelblue',
                      pathTransition:'stroke-dashoffset 3s ease 0s',
                      //pathTransitionDuration: 3,
                    })}              
                  />
                </Stack>  
                <Stack className="circularBar">
                  {/* <h5 className="cursive"></h5>
                  <CircularProgressbar
                    value={sla/allJobs *100}
                    //text={`${allJobs === 0 ?'' : (sla/allJobs *100 ).toFixed(2)}%`} 
                    strokeWidth={0}
                    background = {false}
                    backgroundPadding={0}
                    styles={buildStyles({
                      pathColor: 'green',//"turquoise",
                      backgroundColor:'lightblue',
                      trailColor: "gold",
                      textSize: 6, 
                      textColor:'steelblue',
                    })}              
                  /> */}
                </Stack>  
                                        
              </Stack> 

            </Stack>

        </div>

              
    )

  }

  return (
    <>
      <ApplicationBar tableName={'home'} message={'msg'} setMessage={'test'} modalResponce={'modalResponce'}><div>t</div></ApplicationBar>
      <div className="dashboardContainer">        
        {isloadDashboard && loadDashboard()}
      </div>
    </>
    
  )

}
export default Dashboard


                    // <Stack className='dashboard'>
                    //   <Sidebar/>
                    //   <Stack className="dashboardContainer">
                    //       <Navbar tableName='DashBoard' message={msg} setMessage={setMsg}/>
                    //         {isloadDashboard && loadDashboard()}
                    //     </Stack>  
                    //   </Stack>
                                  
          //                   {/* <CircularProgressbar 
          //                     //className='cursive'
          //                     value={75} 
          //                     minValue={0}
          //                     maxValue={100}
          //                     strokeWidth={4}
                              
          //                     //backgroundPadding={0}
                              
          //                     styles={buildStyles({
          //                         textColor: "gray",
          //                         pathColor: "turquoise",
          //                         trailColor: "gold",
          //                         textSize: 10,
          //                         backgroundColor: 'lightgray'
                                      
          //                     })}  
                              
          //                     //buildStyles 
          //                     // rotation?: number;
          //                     // strokeLinecap?: any;
          //                     // textColor?: string;
          //                     // textSize?: string | number;
          //                     // pathColor?: string;
          //                     // pathTransition?: string;
          //                     // pathTransitionDuration?: number;
          //                     // trailColor?: string;
          //                     // backgroundColor?: string;

          //                   /> */}

          //     {/* <Paper 
          //       variant="elevation" 
          //       elevation={8}
          //       sx={{ width:'0%', height:'0ch', ml:0.5}}
          //     > */}
          //     {/* <Stack height='100px' border = '20px solid red'>
          //       <EnhancedTable tableName={'jobbook'} message={msg} setMessage={setMsg}/>   
          //     </Stack> */}
          //   {/* </Paper>  */}

          // {/* <Paper variant="elevation" elevation={4} sx={{ width:'95%', my:1,}} > */}
          //   {/* <Stack direction ='row' className="widgets">    
          //       <Widget 
          //           type="clients"
          //           direction 
          //           setTableName={setTableName} 
          //           icon={ <PersonOutlinedIcon className="icon"  style = {{backgroundColor:"rgba(255, 0, 0, 0.2)", color: "crimson"}}/> }
          //           diff={25}
          //       />

          //       <Widget 
          //           type="users" 
          //           direction 
          //           setTableName={setTableName} 
          //           icon={<ShoppingCartOutlinedIcon className="icon" style= {{backgroundColor: "rgba(218, 165, 32, 0.2)", color: "goldenrod",}}/> } 
          //           diff={33}
          //       />

          //       <Widget 
          //           type="customers" 
          //           direction ={false} 
          //           setTableName={setTableName} 
          //           icon={<MonetizationOnOutlinedIcon  className="icon"  style= {{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}/>} 
          //           diff={33}
          //       />
          //       <Widget 
          //           type="jobs" 
          //           direction ={false} 
          //           setTableName={setTableName} 
          //           icon={<AccountBalanceWalletOutlinedIcon  className="icon" style= {{backgroundColor: "rgba(128, 0, 128, 0.2)", color: "purple",}}/>  } 
          //           //diff={33}
          //       />
          //   </Stack> */}
          // {/* </Paper> */}










  // {/* <Stack  >
                //           <Chart title="New Chart for the last year revenue"  />
                // </Stack> */}








            // {/* <Paper 
            //       variant="elevation" 
            //       elevation={8}
            //       sx={{ width:'98%', height:'60ch',  ml:1, overflow: 'hidden'}}
            //     > 

            //         {/* <Stack className="graphContainer" > */}
            //         <Chart title="New Chart" aspect={48/8} />
                      
            //         {/* </Stack>  */}
            //     </Paper>

            //    */}

/////////////////////

  // {/*<Stack className="charts">
  //                         <Featured />
  //                         <Chart title="Last 6 Months (Revenue)" aspect={2 / 1} />
  //                       </Stack> 
  //                       <Stack className="tableContainer">
  //                       <h5>table</h5>
  //                       </Stack> */}


  //const [data,  setData] = useState()
  // useEffect(()=>{
  //   GetData();
  //   //console.log(table.tableName)
  // },[])
  // async function GetData() {
  //   const response = await fetch(`http://localhost:3002/api/customers/3`);
  //   const responseData = await response.json();    
  //   setData(responseData)
  // };

///////////////////
// </CircularProgressbarWithChildren>

// {/* <Stack className="circularBar"> 
//     <Stack style={{ 
//                 // fontSize: 16, 
//                 //marginRight: 25, 
//                 color:'red'
//                 }}
//     >
//         <strong color='red'>66%</strong> 
//     </Stack>
//     <Stack style={{ 
//                 //fontSize: 16,
//                 //marginLeft: '25px', 
//                 color:'turquoise'
//                 }}
//     >
//       <strong color='red'>50%</strong> 
//     </Stack> 
// </Stack>  */}



/////////////////

 // <NewTable tableName={tableName} /> {console.log(tableName)} 
  