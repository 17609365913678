import React, {useState, useContext, useEffect} from 'react';
import {UserLogInContext} from '../App.js';
//import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined"; // import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
// import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
// import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
// import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
// import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined"; // import ListOutlinedIcon from "@mui/icons-material/ListOutlined"; import  from '@mui/material/TextField';  
import {InputAdornment, Stack, TextField, Typography} from '@mui/material'; //import Papa from "papaparse";
import './navbar.scss';
import { useNavigate } from "react-router-dom";   ///useLocation
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import { ModAlert} from '../../src/util/Functions.jsx';
//export const Context = createContext('default value');
//const productionHost=process.env.REACT_APP_API_HTTP + window.location.host;
//const localHost=process.env.REACT_APP_API_HTTP + process.env.REACT_APP_API_LOCAL_DOMAIN + process.env.REACT_APP_API_LOCAL_PORT;
//const DOMAIN =`${process.env.NODE_ENV==='production'?productionHost:localHost}`;


const Navbar = ({tableName, message, setMessage, setModalResponse }) => { 

  //const {user, customerrole, role, isLogging, logout} = useContext(UserLogInContext);
  const {user, group, subId, role, subrole,customerrole, userfullname, userId, time, isLogging, logout} = useContext(UserLogInContext);

  //console.log(role, customerrole)

  const [msg, setMsg] = useState('');
  const [modal, setModal] = useState(false);
  //const [modeResponse, setModeResponse] =useState('')            // const [fileName, setFileName] = useState("");
  // const [error, setError] = useState("");

  // const [parsedData, setParsedData] = useState([]);  // State to store parsed data
  // const [tableRows, setTableRows] = useState([]);  //State to store table Column name
  // const [values, setValues] = useState([]); //State to store the values

//const allowedExtensions = ["csv"];
//   useEffect(()=>{      
//     file !=='' && handleParse();
//     console.log(file)

// }, [file]);

  useEffect(() => {
    isLogging && out()
  }, []);

  const navigate = useNavigate();

  const out = ()=>{
    //const a = ()=>{ navigate('/')}
    alert(`The user '${user}' account is inactive. Please refer to your Administrator.`);
    logout();
    window.open('', '_self', '').close();  
  }

  const handler = (flag )=>{
      const a = isNaN(msg);  // :console.log('table name is not supplied'); //console.log(isNaN(msg))
      console.log(tableName, msg);
      tableName !== undefined  ? tableName ==='jobbook' ? setMessage(a ? false : msg) : setMessage(msg) : setMsg('');
      !flag && setMsg('');   
      //setModeResponse('');   
  };



  return (
    
      <Stack className='navbar'>

        <Stack direction = 'row' className="wrapper">

            <Stack>
                <TextField 
                  className='search'
                  placeholder='Search...                     &#128269;'               
                  size='small'      
                  value ={msg} 
                  sx = {{width: 250}}
                  onChange={(e)=>{
                    setMsg(e.target.value)
                    //setModal(true)  
                  }}                                         ///{(e)=>message !== undefined && setMessage(e.target.value) }
                  onKeyDown={(k)=>{ k.key ==='Enter' && handler() }}
                  onClick={()=>{   
                    handler(true)                          
                    console.log(tableName)
                    //setModal(!modal)                              
                  }}    
                  onDoubleClick={()=>setModal(false)}
                  
                  // InputProps={{endAdornment: (
                  //     <InputAdornment position="start">
                  //         <SearchOutlinedIcon className='icon' 
                  //           onClick={()=>{   
                  //             handler(true)                          
                  //             console.log(tableName)
                  //             //setModal(!modal)                              
                  //           }}    
                  //           onDoubleClick={()=>setModal(false)}
                  //         />
                  //     </InputAdornment>
                  //   ), 
                  //}} 
                />            {/* <SearchOutlinedIcon className='icon'  onClick={()=>{console.log(message); console.log(tableName)} } /> */}
            </Stack>              {/*  ----{message !== undefined && setMessage("yeh yeh")} */}

            <ModAlert isOpen={modal} setIsOpen={setModal} title={'Title'} description={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, t laborum.'}  /> 

            <Stack sx={{flex:'1 1 100%'}}>
              <Typography sx={{pr:1}} className='logo2'>{userfullname}</Typography>
            </Stack>    
          
            <Stack className='avatar1' sx={{mr:1}}  
              onClick={()=>{
                navigate('/')
                logout();
                //window.open('', '_self', '').close();
                }}
            > <LoginRoundedIcon color="secondary" />
            </Stack>                {/* </div>   */}           {/* </div> */}
            
            
        </Stack>
        
      </Stack>
      
  
  )
}

export default Navbar

  // {/* <Stack className="logo1">{process.env.NODE_ENV==='development' && 'in dev mode'}</Stack> */}

  //           {/* <div className="items">          */}
  //           {/* <input className="custom-file-input"  onChange={handleFileChange}  name="file"  type="file"  accept=".csv"    /> */}     {/* <input class="styled styled1"  type="button" onClick ={handleFile} value=' Start Batch '/> */}     {/* } */}   {/* <div className="item"> <LanguageOutlinedIcon className='icon'  onClick={handlBatch}/> </div> */}
  //           {/* <div className="item"> <DarkModeOutlinedIcon className='icon' />  </div>    dispatch({ type: "TOGGLE" }) */}
  //           {/* <div className="item"> <FullscreenExitOutlinedIcon className='icon' onClick={()=>console.log(tableName ?? "no table slected")}/></div>   */}
  //           {/* <div className="item"> <NotificationsNoneOutlinedIcon className='icon'/>  <div className="counter">{1}</div>   </div>  
  //           <div className="item" ><ChatBubbleOutlineOutlinedIcon className='icon'/>  <div className="counter" onClick={(e)=>{console.log(e.target.textContent)}}>  5  </div>  
  //           </div>  */}
  //           {/* <div className="item"><NotificationsNoneOutlinedIcon className='icon'/></div> */}
  //           {/* <div className="item"><ChatBubbleOutlineOutlinedIcon className='icon'/> </div>   */}
  //           {/* <div className="item"> <ListOutlinedIcon className='icon'/>  </div>   */}
  //           {/* <div className="item clearfix">  */}
  //             {/* <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuWE1pRmfMlrFeTMyJCWWa4cDjNNTDBOGkus9ligxlnA&s" alt="Log out" className= 'avatar' /> */}









  // const handleFileChange = (e) => {
  //     const rowsArray = [];
  //     const valuesArray = [];

  //     Papa.parse(e.target.files[0], {
  //         header: true,
  //         skipEmptyLines: true,
  //         complete: function (results) {
  //           results.data.map((d) => {
  //             rowsArray.push(Object.keys(d));
  //             valuesArray.push(Object.values(d));
  //           });
  //           // setFileName(e.target.files[0].name.slice(0, e.target.files[0].name.length - 4))
  //           // setParsedData(results.data);// Parsed Data Response in array format
  //           // setTableRows(rowsArray[0]);// Filtered Column Names
  //           // setValues(valuesArray);// Filtered Values
  //           let a = prompt(`\n\nWARNING: You are about to Upload this file. If You want to proceed type 'yes' then click OK\n`,'NO')
  //           if (a===null){a='No'}
  //           if (a.toLowerCase()==='yes'){
  //             const body={header: rowsArray[0], rows: valuesArray}
  //           //  console.log(body)
  //             postNewBatch(`${DOMAIN}${process.env.REACT_APP_API_BATCH}/${e.target.files[0].name.slice(0, e.target.files[0].name.length - 4)}`, body)
  //           }
  //           // window.location.reload()
  //           navigate('/')
  //       },
  //     });    
  // };

  // const handleFile =()=>{
  //   console.log(parsedData);
  //   console.log(tableRows);
  //   console.log(values);

  //   const body={header:tableRows, rows:values}
  //   console.log(body);
  //   //postNewBatch(`${DOMAIN}${process.env.REACT_APP_API_BATCH}/${fileName}`, body)
  // }

  // async function postNewBatch(Url, BodyInput) {    //console.log(BodyInput) /// text/plain; */*; charset=utf-8
  //   const requestOptions = {   
  //       method: 'POST', 
  //       headers: {'Content-Type':'application/json; charset=utf-8' }, 
  //       body: JSON.stringify({ BodyInput }),    
  //   } 
  //   const response = await fetch(Url, requestOptions);
  //   const responseData = await response.json();  
  //   console.log(responseData)
  //   /////////window.location.reload()
  // }