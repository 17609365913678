import './staff.scss';  // import { Link } from "react-router-dom";// import Sidebar from "../../components/Sidebar.jsx";// import Navbar from "../../components/Navbar.jsx";
import '../../resource/images/autom8-logo.png'
import React, {useState, useContext, useEffect, useRef, useCallback } from "react";
import {UserLogInContext} from '../../App';// import {UserLogInContext} from '../../App';// import Button from '@mui/material/Button';  //import  {createFilterOptions } from '@mui/material/Autocomplete'; 
import {Stack,  Fab, Avatar, AvatarGroup, Tooltip, Typography } from '@mui/material'; 
import CenterFocusWeakRoundedIcon from '@mui/icons-material/CenterFocusWeakRounded';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';// import FolderIcon from '@mui/icons-material/Folder';// import PageviewIcon from '@mui/icons-material/Pageview';// import AssignmentIcon from '@mui/icons-material/Assignment';// import testImg from '../../resource/images/fred.jpg'
import { GoogleMap , MarkerF, useJsApiLoader,  DirectionsRenderer, withGoogleMap, InfoWindowF, InfoBox, OverlayView } from '@react-google-maps/api';
import { SelectField } from '@aws-amplify/ui-react';//import {SearchBox, dateConverter, GetLocation, ModComp} from '../../util/Functions'; //import {  SwitchField } from '@aws-amplify/ui-react'
import ApplicationBar from '../../components/AppBar.jsx';

//const DEBUG = process.env.REACT_APP_DEBUG;
const productionHost = process.env.REACT_APP_API_HTTP + window.location.host;
const localHost = process.env.REACT_APP_API_HTTP + process.env.REACT_APP_API_LOCAL_DOMAIN + process.env.REACT_APP_API_LOCAL_PORT;
const DOMAIN = `${process.env.NODE_ENV==='production'?productionHost:localHost}`; 

export const Staff = () => {

  const [tracker, setTracker]=useState();   //const [trackedEmployess, setTrackedEmployess] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(''); //  const [employee, setEmployee] =useState(false);  // const [destination, setDestination] = useState('');  // const [origin, setOrigin] =useState('');
  const [map, setMap] = useState(/** @type google.maps.Map*/(null));
  const [aa, setAa]=useState(true)     // const [centerSite, setCenterSite] = useState({lat: -38.05552, lng: 145.22209 });   // const [centerContract, setCenterContract] = useState({lat: -38.05552, lng: 145.22209 });
  const [defaultCenter, setDefaultCenter] = useState({lat: -38.05552, lng: 145.22209 });
  const centerEmployee = useRef('');
  const {role, isLoaded} = useContext(UserLogInContext);     // const {user, group, role, customerrole} = useContext(UserLogInContext);
  /* global google */ 
  const google = window.google      ////IMPORTANT FOR GOOGLE MAPS TO WORK
  // const header = new Headers();
  // header.append("Authorization", `Basic RmlndXJlOFNlcnZpY2VzOiFBdXRvbThfMTE/`); //REACT_APP_SHEQSY_AUTH
  // header.set('Access-Control-Allow-Origin', '*');
  // header.set('Access-Control-Allow-Methods', 'GET');
  // header.set('Access-Control-Allow-Headers', 'Content-Type');

  // const requestOptions = {
  //     method: 'GET',
  //     headers: header,
  //     mode: 'no-cors',
  //     redirect: 'follow'
  // };


//   const ss = async ()=>{
//     const response = await fetch('https://autom8systems.com.au/about', requestOptions) 
//     //const responseData = await response.json(); 
//     console.log(response)
//   //  console.log(responseData)
//     // console.log(google)
// }

  // useEffect(()=>{   
  //   //ss();
   //   const ws = new WebSocket('ws://localhost:3000/ws')
  //   ws.onopen = () => {console.log('connected')}
  //   ws.onmessage = e => {
  //     // listen to data sent from the websocket server
  //     const message = JSON.parse(e.data)
  //     //setState({dataFromServer: message})
  //     console.log(message)
  //   }
  //   ws.onclose = () => {
  //       console.log('disconnected')
  //       // automatically try to reconnect on connection loss
  //   }


  //   // getTracker();
  //   // console.log("trecker is changed !!!!!!!! >>>>>>>>>>>>>>>>>")
  // }, []);

  useEffect(()=>{      
    getTracker()
    console.log("trecker is changed !!!!!!!!  ????????????");
  },[selectedStaff]);

  useEffect(()=>{      
    let timer = setTimeout(() => {getTracker()}, 65000);      
    return () =>{clearTimeout(timer)}
  });


  const getTracker = async()=>{
      // const SHEQSY_Headers = new Headers();
  // SHEQSY_Headers.append("Authorization", `${process.env.REACT_APP_SHEQSY_AUTH}`);

    // const SHEQSY_Headers = new Headers();
    // SHEQSY_Headers.append("Authorization", `${process.env.REACT_APP_SHEQSY_AUTH}`);

    // const requestOptions = {
    //   method: 'GET',
    //   headers: SHEQSY_Headers,
    //   redirect: 'follow'
    // };
    const response = await fetch((`${DOMAIN}${process.env.REACT_APP_API_TRACKER }`) )//REACT_APP_API_TRACKER  //(process.env.REACT_APP_SHEQSY_TRACKER_URL, requestOptions)
    const responseData = await response.json();  
    console.log(responseData[0]);
    setTracker(responseData[0]);  
    //setTrackedEmployess(responseData[0].map((t)=>t.employee.name + " " + t.employee.surname ));
    // DEBUG && console.log(responseData.map((t)=> t.employee.name+ " " + t.employee.surname));
              
  };

  const onUnmount =  useCallback(function callback(map) {
    setMap(null)
  }, []);

  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  return (
    <>
      <ApplicationBar/>
      {/* <Sidebar /> */}
      
      <div className="homeContainerA" >
        {/* <Navbar /> */}
        
        <main className="mainContainerA" > 

            <Stack direction="column" sx = {{ width:'99%', height:'88%', ml:1, border:'1px solid blue'}} >  
              <SelectField
                value={ selectedStaff }  //isEmp:['test', 'test 2'] 
                options={ tracker !== undefined ? ['All Employees', ...tracker.map((t)=>t.employee.name.trim() + " " + t.employee.surname.trim()) ] : [''] }
                size="small" 
                marginTop={-7}
                onChange={(e) => {setSelectedStaff(e.target.value)  }}
              ></SelectField> 

              {isLoaded && 
                <GoogleMap 
                  center = {centerEmployee.current ==='' || selectedStaff === 'All Employees' ? {lat:-37.9887548247102, lng: 145.1528186796686} : centerEmployee.current } //{{lat:-29, lng:134}}  centerEmployee.current =='' ||
                  zoom={centerEmployee.current ==='' || selectedStaff === 'All Employees'  ? 5 : 12 }
                  mapContainerStyle = {{width:'100%', height:'100%'}}
                  onLoad={(map)=>{setMap(map)}}
                  onUnmount={onUnmount}
                ///  onClick={()=>console.log('test')}
                >
                  <>
                      <Fab 
                          variant="extended" 
                          size="small" 
                          color="primary"
                          sx={{ fontSize:11, width:'110px', height:'24px', mt:'1px', left:'78%'}}
                          onClick={()=>{getTracker()}}
                      > <FlipCameraAndroidIcon/><Typography fontSize={12}>refresh</Typography> </Fab>                   {/* {DEBUG && console.log('tracker 111 is ' , selectedStaff =="" ? "empty":selectedStaff)}    {DEBUG && console.log('centerEmployee 111 is ' , centerEmployee.current == ''? "mpty": centerEmployee.current)} */}
                      
                      { (tracker !== undefined) && tracker.map((t, i)=> {
                          //{DEBUG && console.log(t)} //t.employee.name.trim() + " " + t.employee.surname.trim() ==   console.log(t.activity.currentLocation.latitude,  t.activity.currentLocation.longitude)
                          if(selectedStaff ==='' || selectedStaff =='All Employees' ){
                            const Lat = t.type ==='Shift'? t.shift.currentLocation.latitude: t.activity.currentLocation.latitude
                            const Lng = t.type ==='Shift'? t.shift.currentLocation.longitude: t.activity.currentLocation.longitude                                   //centerEmployee.current = '' //({lat: t.activity.currentLocation.latitude,  lng:t.activity.currentLocation.longitude})                                // {DEBUG && console.log("ALLL", t.activity.currentLocation.latitude == null? ' NNNULL ': t.activity.currentLocation.latitude )}
                            return (
                              <>
                          
                                {/* <MarkerF 
                                    position= {Lat === null ? defaultCenter: {lat: Lat, lng: Lng} }                                    //label={<Avatar {...stringAvatar(`${t.employee.name} ${t.employee.surname}`)} />}  //{t.employee.name + " " + t.employee.surname}
                                    cursor= 'pointer'
                                    animation={ aa?2:1}
                                    onClick={(e)=>setAa(!aa) }
                                /> */}
                                  
                                <OverlayView
                                  position= {Lat === null ? defaultCenter: {lat: Lat, lng: Lng} }
                                  mapPaneName= {OverlayView.OVERLAY_MOUSE_TARGET}
                                  getPixelPositionOffset={(x1, y1)=> {return {y: y1-88, x:x1-60} }}
                                >
                                    <div
                                      // style={{ background: 'lightsteelblue',//`#203254`,
                                      //   //padding: `7px 12px`,
                                      //   fontSize: '12px',
                                      //   color: `black`,
                                      //   borderRadius: '3px'}}
                                    >                                        
                                        <Tooltip 
                                          title={`${t.employee.name} ${t.employee.surname}`} 
                                          arrow
                                        >
                                            <Avatar 
                                              variant="rounded" 
                                              {...stringAvatar(`${t.employee.name.trim()} ${t.employee.surname.trim()}`)} 
                                              onClick={(e)=>setAa(!aa) }

                                            />
                                          </Tooltip>
                                      
                                    </div>
                                </OverlayView>
                              </>
                            )
                          }else{

                            const Lat = t.type ==='Shift'? t.shift.currentLocation.latitude: t.activity.currentLocation.latitude
                            const Lng = t.type ==='Shift'? t.shift.currentLocation.longitude: t.activity.currentLocation.longitude
                            const Adress = t.type ==='Shift'? t.shift.currentLocation.address : t.activity.currentLocation.address

                              // {DEBUG && console.log( t.activity.currentLocation?.latitude ?? "NULLL")}

                              if(t.employee.name.trim() + " " + t.employee.surname.trim() ===  selectedStaff){

                                  centerEmployee.current = ( Lat === null ? defaultCenter : {lat: Lat, lng: Lng})
                          
                                  return (                                                                                         
                                      <> 
                                          <MarkerF 
                                              position={centerEmployee.current}//{lat: parseFloat(t.activity.currentLocation.latitude),  lng: parseFloat(t.activity.currentLocation.longitude)}}
                                              //label={ t.employee.name }
                                              cursor= 'pointer'
                                              animation={ aa?2:1}
                                              onClick={(e)=>setAa(!aa) }                             // {t.employee.name + " " + t.employee.surname}// + " active " + dateConverter(new Date(t.activity.startDateTime.dateTimeUTC), false)}                                                            
                                          />

                                          <OverlayView
                                            position= {centerEmployee.current }
                                            mapPaneName= {OverlayView.OVERLAY_MOUSE_TARGET}
                                            getPixelPositionOffset={(x1, y1)=> {   return {x: x1 < 100 ? x1-125: x1-175, y: y1-92} }}
                                          >
                                              <div
                                                style={{
                                                  background: 'steelblue',//`#203254`,
                                                  padding: `7px 12px`,
                                                  fontSize: '12px',
                                                  color: `white`,
                                                  borderRadius: '4px',
                                                }}>
                                                  <Typography>{t.employee.name} {t.employee.surname}</Typography>
                                              </div>
                                          </OverlayView>      
                                          
                                          {/* <SearchBox destination={Adress}/> */}
                                          <Fab 
                                              size="small" 
                                              sx={{ mt:.5, left:'66%', height:'2px'}}
                                              color="warning"
                                              onClick={()=>{
                                                map.panTo(centerEmployee.current)
                                                map.setZoom(15)
                                              }}
                                          > <CenterFocusWeakRoundedIcon/>
                                          </Fab>                                              

                                        <InfoWindowF 
                                          position={centerEmployee.current}  
                                        >
                                            <div 
                                              width ='100ch'
                                              style={{ backgroundColor: 'yellow', border: "1px solid gray", paddingLeft:4, paddingRight:4,}}
                                            >
                                                {/* {<img src= {image1} className='avatar'/>} */}
                                                {/* {t.employee.name + " " + t.employee.surname + " " } */}
                                                <h4 style={{fontWeight:400, padding:'1.5ch', width:'auto', height:'auto', color: 'darckblue'}}><Typography>{t.employee.name + " " + t.employee.surname  }</Typography> </h4>  
                                                {/* + " active since " + dateConverter(new Date(t.activity.startDateTime.dateTimeUTC), false) } */}
                                                { Lat === null || Lat === undefined? ` GEO LOCATION IS OFF `:``}
                                                {/* {<img src={image}/>} */}
                                                {/* // + new Date(t.activity.startDateTime.dateTimeUTC)} */}                  
                                            </div>
                                        </InfoWindowF>
                                          
                                      </>
                                  )
                          
                              }
                          }
                        }) 
                      }
                  </>  
                </GoogleMap>   
              }

            </Stack> 

        </main>   

      </div>
    </>
  );
};



  // const getPixel = (offsetWidth, offsetHeight, labelAnchor) => {
  //   return {
  //       x: offsetWidth + labelAnchor.x,
  //       y: offsetHeight + labelAnchor.y,
  //   };
  // };
  // const labelAnchor = { x: -30, y: -15 }


  // const {isLoaded} = useJsApiLoader({ 
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP,
  //   libraries:['places']
  // })





// <>
// <Fab 
//     variant="extended" 
//     size="small" 
//     color="primary"
//     sx={{ fontFamily:'cursive',fontSize:12, width:'90px', height:'22px', left:'80%'}}
//     onClick={getTracker}
// > {'Refresh'}    
// </Fab>

// {/* {DEBUG && console.log('tracker 111 is ' , selectedStaff =="" ? "empty":selectedStaff)}
// {DEBUG && console.log('centerEmployee 111 is ' , centerEmployee.current == ''? "mpty": centerEmployee.current)} */}


// { (tracker !== undefined)  && tracker.map((t, i)=> {

//         //{DEBUG && console.log(t)}
//             //t.employee.name.trim() + " " + t.employee.surname.trim() ==   console.log(t.activity.currentLocation.latitude,  t.activity.currentLocation.longitude)
//         if(selectedStaff =='' || selectedStaff =='All Employees' ){
//             //centerEmployee.current = '' //({lat: t.activity.currentLocation.latitude,  lng:t.activity.currentLocation.longitude})
//             {DEBUG && console.log("ALLL", t.activity.currentLocation.latitude == null? 'null': t.activity.currentLocation.latitude )}
//             return (

//                 <MarkerF 
//                     position= {t.activity.currentLocation.latitude == null ? defaultCenter: {lat: t.activity.currentLocation.latitude,  lng:t.activity.currentLocation.longitude} }
//                     label={t.employee.name + " " + t.employee.surname}
//                     cursor= 'pointer'
//                 />

//             )
//         }else{

//             {DEBUG && console.log("INDIV", t.activity.currentLocation.latitude ==null && "NULLLL")}

//             if(t.employee.name.trim() + " " + t.employee.surname.trim() ===  selectedStaff){

//                 centerEmployee.current =( t.activity.currentLocation.latitude == null ? defaultCenter: {lat: t.activity.currentLocation.latitude, lng: t.activity.currentLocation.longitude})
        
//                 return (                                                                                         
//                     <> 
//                         <MarkerF 
//                             position={centerEmployee.current}//{lat: parseFloat(t.activity.currentLocation.latitude),  lng: parseFloat(t.activity.currentLocation.longitude)}}
//                             label= {t.employee.name + " " + t.employee.surname  }
//                             cursor= 'pointer'
//                             title= {t.employee.name + " " + t.employee.surname + " active " + dateConverter(new Date(t.activity.startDateTime.dateTimeUTC), false)}                                                            
//                         />
                        
//                         <InfoWindowF position={centerEmployee.current}  >

//                             <div style={{ backgroundColor: 'yellow', border: "1px solid gray", paddingLeft:4,  paddingRight:4, fontSize: 12, fontFamily:'cursive', fontWeight:500}}>
//                                 {/* {<img src= {image1} className='avatar'/>} */}
//                                 {/* {t.employee.name + " " + t.employee.surname + " " } */}
//                                 {t.employee.name + " " + t.employee.surname + " active  " + dateConverter(new Date(t.activity.startDateTime.dateTimeUTC), false) }
//                                 { t.activity.currentLocation.latitude == null ? ` GEO LOCATION IS OFF - address - "${t.activity.currentLocation.address} "` 
//                                                                               :` - address is "${t.activity.currentLocation.address} "`}
//                                 {/* {<img src={image}/>} */}
//                                 {/* // + new Date(t.activity.startDateTime.dateTimeUTC)} */}
//                             </div>

//                         </InfoWindowF>
//                     </>
//                 )
        
//             }
//         }

//         }
//     ) 
// }




  // const getContractors = async()=>{

  //   const response = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_CONTRACTOR_DATA}${'1'}`);
  //   const responseData = await response.json();
  //   const data = responseData;  
  //   console.log(data)
  // }
// </>  