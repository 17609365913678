import {useState} from 'react'
import Home  from '../pages/home/Home.jsx';  //import EnhancedTable from '../components/JobGrid.jsx'
import Dashboard from '../pages/dashboard/Dashboard.jsx';
import { New } from "../pages/new/New.jsx";
import { Single } from '../pages/single/Single.jsx';
import { List } from '../pages/list/List.jsx';
import { Staff } from '../pages/staff/Staff.jsx';
import { Contr } from '../pages/staff/Contr.jsx';
import { About } from '../pages/about/About.jsx';
import  Batches  from '../pages/batches/Batches.jsx';
import {Profile} from '../pages/profile/Profle.jsx';
import { NoPage } from '../pages/nopage/NoPage.jsx';

// import { Login } from '../pages/login/Login.jsx'
import { BrowserRouter, Routes, Route} from "react-router-dom";           //, loggedInUser, loggedInGroup   //{signOut, loggedInUser, loggedInGroup}

const AppRoutes = ()=>{

  const[message, setMessage]=useState('')  
  return (
    <BrowserRouter future={{ v7_startTransition: true }}>
      
      <Routes>
            <Route path='/'>
              <Route index element= {<Home />} />   {/* <Route path='login' element={<Login/>} /> */}
            </Route>

            <Route path='clients'>
              <Route index element={<List type='clients' />} />
              {/* <Route path=":id" element={<Single type ='clients' action='update' />} /> */}
              <Route path="delete/:id" element={<Single type ='clients' action='delete'/>} />
              <Route path="new" element={<New type ='clients'/>} />
              <Route path="update/:id" element={<New type = 'clients' action='update' />} />
            </Route>  
      
            <Route path='customers'>
              <Route index element={<List type='customers' message={message} setMessage={setMessage}/>}/>
              {/* <Route path=":id" element={<Single type ='customers' action='update' />} /> */}
              {/* <Route path="delete/:id" element={<Single type ='customers' action='delete'/>} /> */}
              <Route path="new" element={<New type ='customers'/>} />
              <Route path="update/:id" element={<New type= 'customers' action='update' />} />
            </Route>  
      
            <Route path='contractors'>
              <Route index element={<List type='contractors' message={message} setMessage={setMessage}/>}/>
              {/* <Route path=":id" element={<Single type ='customers' action='update' />} /> */}
              {/* <Route path="delete/:id" element={<Single type ='contractors' action='delete'/>} /> */}
              <Route path="new" element={<New type ='contractors'/>} />
              <Route path="update/:id" element={<New type= 'contractors' action='update' />} />
            </Route>  

 
            <Route path='jobs'>
              <Route index element={<List type='jobbook' message={message} setMessage={setMessage}/>} />
              {/* <Route path=":id" element={<Single type ='jobbook' action='update'/>} /> */}
              <Route path="delete/:id" element={<Single type ='jobbook' action='delete'/>} />
              <Route path="update/:id" element={<New type= 'jobbook' action='update' />} />
              <Route path="new" element={<New type ='jobbook'/>} />
            </Route>

            <Route path='jobbook'>
              <Route index element={<List type='jobbook'/>}/>
              <Route path=":id" element={<Single type ='jobbook' action='update'/>} />
              <Route path="delete/:id" element={<Single type ='jobbook' action='delete'/>} />
              <Route path="update/:id" element={<New type= 'jobbook' action='update' />} />
              <Route path="new" element={<New type ='jobbook'/>} />
            </Route>

            <Route path='users'>
              <Route index element={<List type='users' message={message} setMessage={setMessage}/>} />
              {/* <Route path=":id" element={<Single type='users' action='update'/>} /> */}
              <Route path="delete/:id" element={<Single type ='users' action='delete'/>} />
              <Route path="new" element={<New type ='users'/>} />
              <Route path="update/:id" element={<New type= 'users' action='update' />} />

            </Route> 

            <Route path='jobparts'>
              <Route index element={<List type='jobparts'/>} />   
               <Route path="update/:id" element={<New type = 'jobparts' action='update' />} />
               {/* <Route path="delete/:id" element={<Single type ='jobparts' action='delete'/>} /> */}
              <Route path="new" element={<New type ='jobparts'/>} /> 
            </Route>  

            <Route path='warehouses'>
              <Route index element={<List type='warehouses'/>} />   
              <Route path="update/:id" element={<New type= 'warehouses' action='update' />} />
               {/* <Route path="delete/:id" element={<Single type ='jobparts' action='delete'/>} /> */}
              <Route path="new" element={<New type ='warehouses'/>} /> 
            </Route>  


            <Route path='staff'>
              <Route index element={<Staff />} />   
            </Route>  

            <Route path='suppliers'>
              <Route index element={<Contr />} />   
            </Route>  

            {/* <Route path='orders'>
              <Route index element={<List />} />            
              <Route path="delete/:id" element={<Single type ='orders' action='delete'/>} />
              <Route path="update/:id" element={<New type= 'orders' action='update' />} />
              <Route path="new" element={<New type ='orders'/>} />
            </Route>  

            <Route path='delivery'>
              <Route index element={<List />} />
              <Route path=":id" element={<Single type ='delivery'/>} />
              <Route path="delete/:id" element={<Single type ='delivery' action='delete'/>} />
              <Route path="new" element={<New type ='delivery'/>} />
            </Route>   */}

            <Route path='dashboard'>
              <Route index element={<Dashboard />} />
              {/* <Route path="update/:id" element={<New type= 'jobbook' action='update' />} />
              <Route path="delete/:id" element={<Single type ='jobbook' action='delete'/>} />
              <Route path="new" element={<New type ='jobbook'/>} /> */}
            </Route>  

            <Route path='sites'>
              <Route index element={<List type='sites'/>} />
              <Route path="delete/:id" element={<Single type ='sites' action='delete'/>} />
              <Route path="update/:id" element={<New type= 'sites' action='update' />} />
              <Route path="new" element={<New type ='sites'/>} />
              {/* <Route index element={<EnhancedTable  tableName='sites'/>} /> */}
            </Route>

            <Route path='profile'>
              <Route index element={<Profile action='update'/>} />
            </Route> 

            <Route path='about'>
              <Route index element={<About/>} />
            </Route> 

            <Route path='batches'>
              <Route index element={<Batches/>} />
            </Route> 

            <Route index path='callbacks'/>        {/* <Route index  /> */}  {/* </Route>  */}

            <Route path='*' element={<NoPage/>} />    {/* </Route> */}
  
    
      </Routes>
       
    </BrowserRouter>
  );
}

export default AppRoutes;