import React, {useState}  from 'react';
import './batches.scss';
// import Navbar from '../../components/Navbar.jsx';
// import Sidebar from '../../components/Sidebar.jsx';
import Papa from "papaparse"; //import { useLocation } from 'react-router-dom';
//import { useNavigate } from "react-router-dom";//import img from '../../resource/images/img_submit.gif' //import { Box, Button, TextField, Stack, Typography, FormControl, FormControlLabel, Radio, RadioGroup, Autocomplete, Fab,  } from '@mui/material';
import styled, { keyframes } from 'styled-components';//import { getLatLngFromAddress} from "../../util/Functions.jsx";//sendEmails, emailAttributes, addTime, //import {calculateTime, postActivity} from '../../util/Functions.jsx'//import Loader from '../../components/Loader.jsx'// import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";// import { Button, Heading } from '@aws-amplify/ui-react';// import ExitToAppIcon from "@mui/icons-material/ExitToApp";//  import Main from '../../components/Main.jsx';// import CountrySelect from '../../util/CountryProvider.jsx'//{signOut, loggedInUser, loggedInGroup}///
import { Stack, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
// import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { v4 as uuidv4 } from 'uuid';
import ApplicationBar from '../../components/AppBar.jsx';

// console.log(uuidv4().replace(/-/g, ""))
const{REACT_APP_API_HTTP, REACT_APP_API_LOCAL_DOMAIN, REACT_APP_API_LOCAL_PORT, NODE_ENV} = process.env

const Batches = () => {
  const [fileName, setFileName]=useState('');
  const [data, setData] = useState('1');
  const [columns, setColumns]=useState([]);
  const [values, setValues]=useState([]);  // const lat=useRef(0)  // const lng =useRef(0)  //const count = useRef(0)  // const [value, setValue] = React.useState('female');  //  const navigate = useNavigate();    // const handlBatch = ()=>{setIsBatch(!isBatch)};   const addr= ["Geelong Rd, Brooklyn VIC 3012, Australia","19/31 Fiveways Boulevard Keysborough VIC 3173", "Melbourne", ''];

  const productionHost = REACT_APP_API_HTTP + window.location.host;
  const localHost = REACT_APP_API_HTTP + REACT_APP_API_LOCAL_DOMAIN + REACT_APP_API_LOCAL_PORT;
  const DOMAIN = `${NODE_ENV==='production' ? productionHost : localHost}`; 

  const g = window.google;
  let gc = new g.maps.Geocoder();
  gc.geocode({ 'address': '10 Colin Rd, Clayton VIC 3168'},  (results, status)=> console.log(results));


  const Button = styled.button`
    --accent-color: lightblue;
    background: ${props => props.primary ? "#BF4F74" : "white"};
    color: ${props => props.primary ? "white" : "#BF4F74"};
    font-size: 1em;
    margin: .3em;
    //padding: .5em 1em;
    border: 1px solid var(--accent-color);
    border-radius: 10px;
    cursor: pointer;
    height:3.1em;
    width: 23em;
    box-shadow:0  2px 3px gray;
    display: inline-block;
    align-items: center;
    justify-content:center;
    display: flex;
    transition: all 200ms ease-in-out;
    &:hover{
      filter: brightness(0.9);
    }
    &:active {
      filter: brightness(1);
      box-shadow: 0 2px 10px gray;
    }
  `;

  const Fab = ({ className, children }) => (
    <div className={className}>
      {children}
    </div>
  );

  //mediumseagreen" rebeccapurple
  const SFab = styled(Fab)`
    --height:3.5em;
    --width:9em;
    color: mediumseagreen;
    background: papayawhip;
    font-weight: bold;
    box-shadow:0 2px 6px gray;
    border: 3px solid ${props=>props.theme.main};
    border-radius: 10px;
    cursor: pointer;
    display: inline-block;
     margin-bottom:20px;
    // margin-left: 20px;
    width: var(--width);
    height: var(--height);
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 2em 1em;
    &:hover{
      filter: brightness(0.9);
    }
    &:active {
      filter: brightness(1);
      box-shadow: 0 2px 10px gray;
    }
  `;

  SFab.defaultProps = {
    theme: {
      main: "mediumseagreen"
    }
  };

  // const ReversedButton = props => <Button {...props} children={props.children.split('').reverse()} />  //import styled from '@emotion/styled';
  const Container = styled.div`

    //background: ${props => props.bgc};
    //color: ${props => props.primary ? "black" : "transparent"};
    margin-top:1ch;
    padding: 4ch 4ch;
    ///padding-top:5ch;
    //border: 3px solid ${props=> props.bc } ;
    border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap:2em;
    height:${props=>props.h};
    width:${props=>props.w};
    box-shadow:0px 0px 3px 2px ${props=> props.bc };
    
  `;
  
  const rotate = keyframes`
    from {  transform: rotate(0deg) }
    to {  transform: rotate(360deg) }
  `;

  const move =  keyframes` {
    50% {flex-basis: 200px;}
  `;

  // Here we create a component that will rotate everything we pass in over some seconds
  const Rotate = styled.div.attrs(props=>({  $border:props.b ? '1px solid red':'',  
                                             $width:props.w || 'auto', 
                                             $fontsize:props.fs || '1em', 
                                             $speed: props.speed || '4s',
                                             $color: props.c ? 'lightblue' : 'transparent',
                                             $isSelected: props.r?true:false
                                            
                                          }))`

    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50px;
    background-color: ${props => props.$color};
    animation: ${props => props.$isSelected?rotate:move} ${props => props.$speed} linear infinite;
    //animation: ${rotate} ;
    padding: 1rem 2rem;
    width:${props => props.$width};
    border: ${props => props.$border};
    font-size: ${props => props.$fontsize};
  `;

  const Input = styled.input.attrs(props => ({type: "text", $margin: props.m || "1em",  $padding: props.p || ".5em",}))`
    color: #BF4F74;
    font-size: 1em;
    border: 2px solid #BF4F74;
    border-radius: 3px;
    margin: ${props => props.$margin};
    padding: ${props => props.$padding};
  `;

  const PasswordInput = styled.input.attrs({ type: "password",})`  
    border: 2px solid aqua;
  `;

  const PasswordInput1 = styled(Input).attrs(props=>({ type: "text", size: props.s || ".5em",}))`  
    border: 1px solid blue;
    margin: ${props => props.size};
    padding: ${props => props.size};
  `;

  const test= async (aa)=>( 
    await console.log([aa])
  
  )
///new window.google.maps.Geocoder({ 'address': '10 Colin Rd, Clayton VIC 3168'},  (results, status)=> {console.log(results)})
  const handleFileChange = async (e) => {
    const columnArray = [];
    const valuesArray = [];
    let siteDetailsArray =[];
    const google = window.google;
    let geocoder = new google.maps.Geocoder();
    let lat=0;
    let lng =0;
    let state =''
    let postCode=''
    let answer =''
    let fName=e.target.files[0].name.slice(0, e.target.files[0].name.length - 4);  // EXTRACT FILE NAME WITHOUT FILE EXT (.csv) local use
    setFileName(e.target.files[0].name.slice(0, e.target.files[0].name.length - 4))// EXTRACT FILE NAME WITHOUT FILE EXT (.csv)

    Papa.parse(e.target.files[0], {

      delimiter: '',	// auto-detect  //'\t',
      header: true,
      skipEmptyLines: 'greedy',
      delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP], ///An array of delimiters to guess from if the delimiter option is not set.

      complete: function (results) {
        results.data.map((d) => {
                                  columnArray.push(Object.keys(d));
                                  valuesArray.push(Object.values(d));
                                });
        setColumns([...columnArray[0]]);      
        setValues(valuesArray);           //  console.log(results.meta)//.delimiter);

        
        if (data ==='1'){       
          answer = prompt(`\n\nWARNING:\nYou are about to upload this file to DB. Type "yes" and then click OK button if you wish to proceed.\nAlternativly you can select 'No or Cancel button to see data in the table`  ,'NO') //if (a===null){}{a='No'}                           
        
          if (fName === 'DOT_SITES'){                        // this is only for the SITE file from DOT WA -- FILES NAME: DoTSites_order  DoT_sites        
            setValues([])
            for (let i = 0;  i < valuesArray.length ;  i++ ){
              const id = uuidv4().replace(/-/g, "");
              const siteDetails = valuesArray[i]
              const siteAddress = siteDetails[1]
              //IMPORTANT  !!! currently set DOT WA
              const customerId = 8 //IMPORTANT  !!! currently set DOT WA   //let body= {id:id, rows: siteDetails, lat:0, lng: 0}                      //const url =`${DOMAIN}/api/batchSites`                  //getLatLngFromAddress(siteAddress)
              siteDetailsArray=[...siteDetailsArray, [ ...siteDetails]]
              if(siteAddress !== '' && siteAddress !==null && siteAddress !== undefined ){            
                geocoder.geocode( { 'address': siteAddress}, function(results, status) {
                    if (status === google.maps.GeocoderStatus.OK) {                ///console.log(results)
                      lat = results[0].geometry.location.lat();
                      lng = results[0].geometry.location.lng();
                      results[0].address_components.forEach((a)=> {if(a.types[0] ==='administrative_area_level_1') state =  a.short_name})
                      results[0].address_components.forEach((a)=> {if(a.types[0] ==='postal_code') postCode =  a.short_name})    ///console.log ('Address: %s - Lat: %s, Lng: %s  STATE: %s  POST CODE: %s', siteAddress, lat, lng, state, postCode)
                       
                        if(answer ==='yes' ){postNewSite(`${DOMAIN}/api/batchSites`, {id:id, 
                                                                                      rows: siteDetails, 
                                                                                      coord:`'POINT(${lng} ${lat})'`, 
                                                                                      customerId: customerId, 
                                                                                      identifier:'DOTWA',  
                                                                                      state: state,
                                                                                      postCode:postCode } )    
                        } else{
                          setValues(values => ([...values, [ ...siteDetails, lat, lng, state, postCode]] ))
                        } 
                    }else{ console.log ('Unable to get Address: %s ', siteAddress) }
                  }
                ); 
              }              
            }
            answer !== 'yes' && setColumns([...columnArray[0], 'Lat', 'Lng', 'State', 'Post Code'])            

          }else{
            const body= {header: columnArray[0], rows: valuesArray}
            if(answer ==='yes' ) postNewBatch(`${DOMAIN}${process.env.REACT_APP_API_BATCH}/${fName}`, body)
          }
  
        }
      }
    });    
  };


  const postNewSite = async (url, BodyInput)=>{    
    const requestOptions = {   
      method: 'POST', 
      headers: {'Content-Type':'application/json; charset=utf-8' }, 
      body: JSON.stringify({ BodyInput }),    
    } 
    const response = await fetch(url, requestOptions);
    const responseData = await response.json();  
    console.log(responseData)
  }

  const postNewBatch = async(url, BodyInput)=>{    //console.log(BodyInput) /// text/plain; */*; charset=utf-8
        const requestOptions = {   
            method: 'POST', 
            headers: {'Content-Type':'application/json; charset=utf-8' }, 
            body: JSON.stringify({ BodyInput }),    
        } 
        const response = await fetch(url, requestOptions);
        const responseData = await response.json();  
        console.log(responseData.text)
  }
  const handleChange = (e) => {
    // console.log(  e.target.value)     // e.target.value == '1' ? setData(true) : setData(false)
   setData(e.target.value);
  };

  // const getLatLngFromAddress = async(address )=>{

  //   const google = window.google  

  //   address.map((a)=>{ 
  //     if(a !== '' && a !==null && a !== undefined ){
    
  //       let geocoder = new google.maps.Geocoder();
  //        geocoder.geocode( { 'address': a}, function(results, status) {
  //         if (status == google.maps.GeocoderStatus.OK) {
  //               //console.log(results)
  //               // lat.current =results[0].geometry.location.lat();
  //               // lng.current = results[0].geometry.location.lng();
  //               console.log ('%s - Lat: %s, Lng: %s  ', a, results[0].geometry.location.lat(), results[0].geometry.location.lng())
              
  //             }else{
  //               console.log ('Unable to get Address: %s ', a)
  //             }
  //         }
  //       ); 
  //     }

  //   } )
  // };

  // const decode = ()=>{ addr.forEach(()=>{getLatLngFromAddress(addr) }) }

  
  //{width:'50ch', marginTop:100, border:"1px solid green", borderRadius:'10px', display:'flex', flexDirection: 'column'}}

  // const title = 'TESTING EMAIL- SendGrid Email # 1000';
  // const toEmail =['aiampolski@figure8services.com.au'];///, 'gmaksymchuk@figure8services.com.au']
  // const fromEmail ='support@figure8services.com.au';

  // const postActivity1= async(name, lat, lng, duration, employee) =>{
  //       const Alex = 'd68f0155-2406-4dd4-8dd8-e77a281b2e5b'
  //       const Fred = 'a6c0fbfc-d118-4a67-a904-acb67ca1f223'
  //       const url=process.env.REACT_APP_SHEQSY_ACTIVITY_URL;

  //       const myHeaders = new Headers();
  //       myHeaders.append("Content-Type", "application/json; charset=utf-8");
  //       myHeaders.append("Authorization", `${process.env.REACT_APP_SHEQSY_AUTH}`);
  //       const D = new Date()

  //       const body = {
  //               "externalId": null,
  //               "name": `Job# ${name}`,
  //               "latitude": "-37.920399",
  //               "longitude": "145.057886",
  //               "plannedTimeUTC": D,
  //               "plannedDurationMinutes": 50,
  //               "activityType": "Daily Work Tasks",
  //               "employees": [`${Alex}`],
  //               "requiredNumberOfEmployees": 1,
  //               "address": null
  //             }

  //       const requestOptions = {
  //         method: 'POST',
  //         headers: myHeaders,
  //         body: JSON.stringify(body),
  //         redirect: 'follow'
  //       };

  //       console.log(D)
  //       // const response = await  fetch(url, requestOptions)
  //       // const responseData = await response.json(); 
  //       // console.log(responseData.activityGuid)
  // }

  // const postActivity2= async(name, lat, lng, address, employee, status = 1, update = false, activityUID)=>{
  //   const Alex = 'd68f0155-2406-4dd4-8dd8-e77a281b2e5b'
  //   const Fred = 'a6c0fbfc-d118-4a67-a904-acb67ca1f223' //799e4ef1-d9d4-4dda-af44-3ff3f47de05b
  //   const Peter = '799e4ef1-d9d4-4dda-af44-3ff3f47de05b'
  //   employee = Peter
  
  //   const ActivityUID = update ? `/${activityUID}` :''
  
  //   const url= `${process.env.REACT_APP_SHEQSY_ACTIVITY_URL}${ActivityUID}`;
  //   const myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json; charset=utf-8");
  //   myHeaders.append("Authorization", `${process.env.REACT_APP_SHEQSY_AUTH}`);

  
  //   console.log(url)
  //   console.log(employee, update)
  
  //   const bodyAdd = {
  //     "externalId": `${name}`,
  //     "name": `Job# ${name}`,
  //     "latitude": `${String(lat)}`,
  //     "longitude": `${String(lng)}`,
  //     "plannedTimeUTC": new Date(),
  //     "plannedDurationMinutes": 360,
  //     "activityType": "Daily Work Tasks",
  //     "employees": [`${employee}`],
  //     "requiredNumberOfEmployees": 1,
  //     "address": `${address}`
  //   }
  
  //   const bodyUpdate = {
  //     "status": `${status}`,
  //     "employees": [`${employee}`],
  //   }

  //     const requestOptions = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     body: JSON.stringify(update ? bodyUpdate: bodyAdd),
  //     redirect: 'follow'
  //   };
  
  //   console.log(update ? bodyUpdate: bodyAdd)
  //   const response = await fetch(url, requestOptions)
  //   const responseData = await response.json(); 
  //   console.log(response)
  // //  return responseData.activityGuid
  //   //return 'Testing Success: activityGuid' 
  // }

  /////////////////////////

  // const testCallBacks = async(method)=>{

  //   // setCount(()=>count+1)
  //   if (method === 'POST'){  count.current++ }

  //   const requestOptionsPOST = {   
  //     method: 'POST' , 
  //     headers: {'Content-Type':'application/json; charset=utf-8' }, 
  //     body: JSON.stringify({ 'Type':'Hello ' })   
  //   } 

  //   const requestOptionsGET = {   
  //     method: 'GET' , 
  //     headers: {'Content-Type':'application/json; charset=utf-8' },  
  //   } 

  //   const url= method ==='POST' ? process.env.REACT_APP_POST_TO_WEBHOOK : process.env.REACT_APP_POST_GET_FROM_WEBHOOK /// FOR Production
  //   //const url= method ==='POST' ?  `${DOMAIN}/api/callbacks` : `${DOMAIN}/api/callbacks_res` /// For DEVELOPMENT 
  //   console.log(url)

  //   const response = await fetch(url, method === 'POST' ? requestOptionsPOST : requestOptionsGET );
  //   console.log(response.status)
  //   const responseData = method === 'POST' ? await response.text(): await response.json();  
  //   console.log(responseData)


  // }

  return (
    <>
      <ApplicationBar/>        {/* <Stack direction='row'className='batchSideBar'>  <Sidebar /> */}
        
        <Stack direction='column' className="batchNavBar">          {/* <Navbar /> */}
          
          <Stack border = '1px solid gray'  style={{marginTop:15, marginBottom:25, marginLeft:10, marginRight:10}}> 
            
            <Stack direction='row' style={{marginTop:10, marginBottom:10, marginLeft:10,}} gap={5}>

              <form style={{marginTop:1 }}> {/* <input type="checkbox" id='1' style={{marginLeft:10, marginTop:10}} onClick={(e)=>setData(e.target.checked)}   />  <label htmlFor="1" style={{marginLeft:10, marginRight:10, fontSize:13, fontWeight:600}}> {data ? 'Upload & display data' : 'Display data only'}</label><br /> */}
                <RadioGroup
                  row
                  value={data}
                  onChange={handleChange}
                >
                  <FormControlLabel 
                    value="1" 
                    control={<Radio  size="small"/>} 
                    label={<Typography fontSize={13}>Upload (& Display) batch file </Typography>}
                  />
                  <FormControlLabel 
                    value="2" 
                    control={<Radio  size="small" />}
                    label={<Typography fontSize={13}>Display ONLY batch file</Typography>} 
                  />
                </RadioGroup>

              </form>

              <input 
                className="custom-file-input" 
                onChange={handleFileChange} 
                name="file" 
                type="file" 
                accept=".csv, .tsv"
    
              />

                            
              {/* <Button 
                primary 
                style={{marginTop:35, marginBottom:5, marginLeft:2, width:80, height: 30}}
                onClick={decode}
              ><Typography fontSize={14}>Decode</Typography> </Button>                                        */}
            </Stack>     
        
            <table style={{borderCollapse:'collapse', border :'1px solid black'}}>
              {fileName !== '' && <caption style={{fontSize:20, fontWeight:600}}>File Name: {fileName}</caption>}

              <thead>
                <tr>
                
                  {columns.map((col, i)=>(
                      <th key={i} style={{fontSize:11, fontWeight:600, border:'1px solid gray'}}>{col}</th>
                  ) )}
                </tr>
              </thead>

              <tbody>
              {/* {console.log(values)} */}
                  {values.map((v, i)=>(
                    <tr key ={i}>
                      {v.map((value, i)=>(
                          <td key ={i} style={{fontSize:11, border:'1px solid gray'}}>{value}</td>
                      ) )}                
                    </tr>
                  ))}
                  

              </tbody>

            </table>   
             
          </Stack>
          
        </Stack>   

      {/* </Stack> */}
    </>
  )
}
export default  Batches;




// // Parse CSV string
// var data = Papa.parse(csv);

// // Convert back to CSV
// var csv = Papa.unparse(data);

// // Parse local CSV file
// Papa.parse("http://example.com/big.csv", {  //not on my computer
  //  dynamicTyping: true
	//  download: true,    //indicates that the string you passed as the first argument to parse() is actually a URL from which to download a file and parse its contents.
  //  downloadRequestHeaders: {'Authorization': 'token 123345678901234567890',}
  //  downloadRequestBody	   //Use POST request on the URL of the download option. The value passed will be set as the body of the request.
	// 	console.log("Row:", row.data);
	//  },
// 	complete: function(results) {
// 		console.log("Finished:", results.data);
// 	}
// });

// // Stream big file in worker thread
// Papa.parse(bigFile, {
// 	worker: true,
// 	step: function(results, parser) {
// 		console.log("Row:", results.data);
//    ////parser.abort(); parser.pause(); parser.resume()
// 	}
// });


//   const productionHost = REACT_APP_API_HTTP + window.location.host;
//   const localHost = REACT_APP_API_HTTP + REACT_APP_API_LOCAL_DOMAIN + REACT_APP_API_LOCAL_PORT;
//   const DOMAIN = `${NODE_ENV==='production'?productionHost:localHost}`; 
 // const url_main=`${DOMAIN}${REACT_APP_API_TEST}`;  
// useEffect(()=>{ getData(url_main) },[]);   
//   useEffect(() => {
//     // execute on location change
//     setCount(c=>c + 1);
//     debug && console.log('Location changed!', location);
//   }, [location]);

//   async function getData(url) {
//     const response = await fetch(url)
//     const responseData = await response.json();  
//     //debug && console.log(responseData.Responce, responseData.Responce[2].customerName);
//     setTxt(responseData)     
//     setResp(responseData.Responce[2].customerName)                
//   };



//     let options = {
  //         //weekday: "short",
  //         day:"numeric",
  //         month:"numeric",
  //         year:"numeric",

  //         hour: "numeric",
  //         //era: "long",
  //         //dayPeriod:"long",
  //         //dayPeriod: "short",
  //         minute: "numeric",
  //         //second: "numeric",
  //         //timeZone: "Australia/Sydney",
  //         //hour12: false,
  //         //timeZoneName: "short",
  //     };
  //     //  let a = Date.parse(new Date).toString().slice(0,10)
  //     //  let b = Date.parse(new Date)
  //     //  console.log(a, b)
  //     return mainDate.toLocaleString('en-AU', options)


  // const TomatoContainer = styled(Container)`
  //     background: ${props => props.p ? "transparent" : "white"};
  //     color: tomato;
  //     border-color: tomato;
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     justify-content:center;
  //     height:${props=>props.h};
  //     width:${props=>props.w};
  // `;