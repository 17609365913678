import { useState, useRef, useEffect } from 'react';
import { Fab, Stack, Typography } from '@mui/material';

export const Canvas= (props)=>{
  const productionHost = process.env.REACT_APP_API_HTTP + window.location.host;
  const localHost = process.env.REACT_APP_API_HTTP + process.env.REACT_APP_API_LOCAL_DOMAIN + process.env.REACT_APP_API_LOCAL_PORT;
  const DOMAIN = `${process.env.NODE_ENV==='production'?productionHost:localHost}`; 
//  const testData = 'data:image/png;base64,R0lGODlhDAAMAKIFAF5LAP/zxAAAANyuAP/gaP///wAAAAAAACH5BAEAAAUALAAAAAAMAAwAAAMlWLPcGjDKFYi9lxKBOaGcF35DhWHamZUW0K4mAbiwWtuf0uxFAgA7'

  //const canvas = document.getElementById("canvasElem")
  //const on= useRef(false)
  const canvasRef = useRef(null);
  const canvas1  =useRef();    //const BB = useRef('data:image/png;base64,R0lGODlhDAAMAKIFAF5LAP/zxAAAANyuAP/gaP///wAAAAAAACH5BAEAAAUALAAAAAAMAAwAAAMlWLPcGjDKFYi9lxKBOaGcF35DhWHamZUW0K4mAbiwWtuf0uxFAgA7') ;
  const [linkA, setlinkA] =useState('data:image/png;base64,R0lGODlhDAAMAKIFAF5LAP/zxAAAANyuAP/gaP///wAAAAAAACH5BAEAAAUALAAAAAAMAAwAAAMlWLPcGjDKFYi9lxKBOaGcF35DhWHamZUW0K4mAbiwWtuf0uxFAgA7')
  const BB =useRef(false); //('data:image/png;base64,R0lGODlhDAAMAKIFAF5LAP/zxAAAANyuAP/gaP///wAAAAAAACH5BAEAAAUALAAAAAAMAAwAAAMlWLPcGjDKFYi9lxKBOaGcF35DhWHamZUW0K4mAbiwWtuf0uxFAgA7')

  //let BloB;
  //let newImg = new Image();
//  let linkA = document.createElement("a");
  
  const clear = ()=>{
    let context = canvas1.current.getContext('2d')
    context.clearRect(0, 0, props.width, props.height);
    context.fillStyle ='white';
    context.fillRect(0, 0, props.width, props.height);
    BB.current = false  //'data:image/png;base64,R0lGODlhDAAMAKIFAF5LAP/zxAAAANyuAP/gaP///wAAAAAAACH5BAEAAAUALAAAAAAMAAwAAAMlWLPcGjDKFYi9lxKBOaGcF35DhWHamZUW0K4mAbiwWtuf0uxFAgA7')
    setlinkA('data:image/png;base64,R0lGODlhDAAMAKIFAF5LAP/zxAAAANyuAP/gaP///wAAAAAAACH5BAEAAAUALAAAAAAMAAwAAAMlWLPcGjDKFYi9lxKBOaGcF35DhWHamZUW0K4mAbiwWtuf0uxFAgA7')
  }

  const load = async()=> {  
    let response = await fetch(`${DOMAIN}/api/jobs/signature1/${props.jpbNumber}`);
    if(response.status !== 310){
        if (response.status == 200) {BB.current= true}
        let resp = await response.blob()
        //console.log(resp)
        let link = document.createElement('a');
        link.download = `signature_${props.jpbNumber}.png`;
        let blob = new Blob([resp], {type: 'image/png'});
        let reader = new FileReader();
        reader.readAsDataURL(blob); // converts the blob to base64 and calls onload

        reader.onload = ()=>{
          link.href = reader.result; // data url
          setlinkA(link.href )
          //link.click();
          URL.revokeObjectURL(link.href);
        };
    }
  }

  const submit = async()=> {
      // if(on){      //   let link;      //   canvas.toBlob(function(blob){    // link.href = URL.createObjectURL(blob);  //  console.log(blob);      //     console.log(link.href); // this line should be here      //   },'image/png');
      let blob = await new Promise(resolve => canvas1.current.toBlob(resolve, 'image/png')) 
      let response = await fetch(`${DOMAIN}/api/jobs/signature/${props.jpbNumber}`);//     console.log(response.status)
      let resp = await response.json()
  
      if (resp[0]?.jobNo === undefined) {
        const options={      
            method:'POST',    //headers: {'Content-Type': 'octet-stream'},      //headers: {'Content-Type':'application/json; charset=utf-8' },
            body:blob,  // JSON.stringify({link}),
            redirect: 'follow'        
        }
        let response = await fetch(`${DOMAIN}/api/jobs/signature/${props.jpbNumber}`, options);        // let result = await response.text();        //console.log(result);  
        
      }else{
        const options={      
            method:'PUT',    //headers: {'Content-Type': 'octet-stream'},      //headers: {'Content-Type':'application/json; charset=utf-8' },
            body:blob,  // JSON.stringify({link}),
            redirect: 'follow'        
        }
        let response = await fetch(`${DOMAIN}/api/jobs/signature/${props.jpbNumber}`, options);
        let result = await response.text();        //console.log(result);  
      }    
  }

  useEffect(() => {    

    const canvas = canvasRef.current;
    canvas1.current = canvasRef.current;
    let context = canvas.getContext('2d')  
    context.fillStyle ='white';
    context.fillRect(0, 0, props.width, props.height);
    
    const mousemoveHandler = async (e) => {    
      e.buttons === 0 && context.beginPath();
      e.buttons === 1 && context.lineTo(e.clientX-(e.x - e.offsetX), e.clientY-(e.y - e.offsetY));
      e.buttons === 1 && context.stroke();              
    };
    canvas.addEventListener('mousemove', mousemoveHandler);

    return () => {canvas.removeEventListener('mousemove', mousemoveHandler)};

  },[]);


  return ( 

      <Stack direction="row" gap={5}  >

        <Stack marginTop={2} marginLeft={2} border='1px solid gray'>
          <canvas ref={canvasRef} width={250} height={100}></canvas>
        </Stack>
        <Stack >
          <Stack direction="column" width='30ch' gap={1} sx={{ mt:2, ml:0, boxShadow:'0 0 2px 1px lightgray', flex:'1 1 100%', justifyContent:'space-evenly', alignItems: 'center' }}>                  
            <Fab variant="extended" sx={{height:'3ch', width:'15ch', fontSize:12}} onClick={submit}><Typography fontWeight={600} fontSize={12}>{'save'}</Typography></Fab>
            <Fab variant="extended" sx={{height:'3ch', width:'15ch', fontSize:12}} onClick={clear}><Typography fontWeight={600} fontSize={12}>{'clear'}</Typography></Fab>
            <Fab variant="extended" sx={{height:'3ch', width:'15ch', fontSize:12}} onClick={load} ><Typography fontWeight={600} fontSize={12}>{'Load'}</Typography></Fab>
          </Stack>
        </Stack>

        {BB.current &&
          <Stack  border='1px solid lightgrey' marginTop={2} marginLeft={0} >    
            <img src={linkA} ></img>
          </Stack>   
        }

      </Stack>          

  )
}

//////////////////////////


// Create Base64 Object
// var Base64={_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},decode:function(e){var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/[^A-Za-z0-9\+\/\=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},_utf8_encode:function(e){e=e.replace(/\r\n/g,"\n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},_utf8_decode:function(e){var t="";var n=0;var r=c1=c2=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}}

// // Define the string
// var string = 'Hello World!';

// // Encode the String
// var encodedString = Base64.encode(string);
// console.log(encodedString);



///////////
  //if (e.buttons === 2){          
          // context.clearRect(0, 0, props.width, props.height);
          // context.fillStyle ='white';
          // context.fillRect(0, 0, props.width, props.height);
          //on.current=false
        //}

///////////////////
        // if(isLoad.current ===true){
        //   isLoad.current =false
        //   console.log('lllllllllllllllllll')
        //   console.log()
        //   //context.drawImage(Blob, props.width, props.height);
          
        // }        
  
      //   if(e.buttons === 4 && on.current===true ){canvas.toBlob(function(blob){
      //     let link=document.createElement("a");
      //     // link.download = "signature.png";
      //     link.href = URL.createObjectURL(blob);
      //     console.log(blob);
      //     console.log(link.href); // this line should be here
      //     //submit(link.href, blob)

      //     on.current= false
  
      //     //link.click("#Test")
      //   },'image/png');

      // }
////////////////////////////
      // if(on){
        //   let blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'))
        //   console.log(blob)
        //   let response = await fetch(`${DOMAIN}/api/jobs/signature`, 
        //         { method: 'POST', 
        //           headers: {'Content-Type':'application/octet-stream' }, 
        //           body:blob
        //         });
        //   let result = await response.text();// the server responds with confirmation and the image size
        //   console.log(result)
        //   setOn(false)
        // }

        ///  //  console.log(resp[0].signature.data);     //= new Blob(resp, {type: 'text/html'}) //link.download = "signature.png";
    //  resp =resp[0].signature.data

    //  console.log(window.btoa(resp))

    // let context = canvas1.current.getContext('2d')
    // context.clearRect(0, 0, props.width, props.height);
    // context.fillStyle ='white';
    // context.fillRect(0, 0, props.width, props.height);

    // const readableStream = resp.stream();
    // const stream = readableStream.getReader();
    // while (true) {
    //   // for each iteration: value is the next blob fragment
    //     let { done, value } = await stream.read();
    //     if (done) {
    //       // no more data in the stream
    //       console.log('all blob processed. ');         // do something with the data portion we've just read from the blob
    //       break;
    //     }
    //     console.log( value);

      //BloB= new Blob(['data:image/png;base64', resp], {type: 'image/png'})
      //  const resData = await blobToData(data)

        // BloB = new Blob([data],{type:'image/png'})
        // setBB( 'data:image/png;base64,' + (window.btoa(data)))
        // console.log(window.btoa(data))
        //  console.log(BloB)
        ////

        ///
              // link.href = URL.createObjectURL(blob);
      // linkA.current=link.href 

      // link.click();
        // linkA.current= URL.createObjectURL(resp);
        // console.log(linkA.current)
    //}


    // canvas1.current.toBlob((blob)=>{
    //   //let link=document.createElement("a");
    //   link.download = "signature.png";
    //   link.href = URL.createObjectURL(blob);
    //   console.log(resp);
    //   console.log(blob);
    //   console.log(link.href); // this line should be here
    // },'image/png');
    /////////////

