import React, {useState, useRef, useEffect, useCallback, useContext } from 'react';
import {UserLogInContext} from '../../App';
import Button from '@mui/material/Button';     
import Autocomplete from '@mui/material/Autocomplete';  
import TextField from '@mui/material/TextField';  
import Stack from '@mui/material/Stack'; 
import Typography from '@mui/material/Typography'; 
import Box from '@mui/material/Box'; 
import './newitem.scss';
//import { mailformat, notEmpty } from '../Regex.js';
import { useParams, useNavigate, useLocation } from "react-router-dom"; // import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';// import { Loader} from '@aws-amplify/ui-react';
import { v4 as uuidv4 } from 'uuid';
//import { Fab } from '@mui/material';
import { GoogleMap , MarkerF, InfoWindowF } from '@react-google-maps/api';
import { SearchAddress, calculateZone } from '../../util/Functions.jsx';
import styled from 'styled-components';
import {DialogueAlert, DialogueResponce} from '../../components/DialogueAlert.jsx'

const Container = styled.div`
  //background: ${props => props.bgc};
  // color: ${props => props.primary ? "black" : "transparent"};
 margin:1ch;
  //padding: 1ch 1ch;
  padding-top:1ch;
 // border: solid ${props=> props.bc || 'lightsteelblue' } ;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:'center';
  height:${props=>props.h};
  width:${props=>props.w};
  box-shadow:1px 1px 2px 1px lightsteelblue;
`;
//import {SearchBox, dateConverter, GetLocation, postActivity, sendNote, calculateTime} from '../../util/Functions';//import NewContact from './NewContact';//import { Menu, MenuButton, MenuItem } from '@aws-amplify/ui-react';
const productionHost=process.env.REACT_APP_API_HTTP + window.location.host
const localHost=process.env.REACT_APP_API_HTTP + process.env.REACT_APP_API_LOCAL_DOMAIN + process.env.REACT_APP_API_LOCAL_PORT
const DOMAIN =`${process.env.NODE_ENV==='production'?productionHost:localHost}`;


const NewSite = ({action}) => {
    const [siteName, setSiteName] = useState('');
    const [sitePhone, setSitePhone] = useState('');
    const [siteContact, setSiteContact] = useState('');
    const [siteAddress1,setSiteAddress1] = useState('')
    const [siteSuburb, setSiteSuburb]= useState('')
    const [siteState, setSiteState] = useState('');
    const [sitePostCode, setSitePostCode] = useState('');
    const [siteZone,setSiteZone] =useState('');
    const [centerSite, setCenterSite] = useState({lat: -37.988702772428745, lng:  145.15275396854955 })
    const [map, setMap] = useState(/** @type google.maps.Map*/(null));    //const [customersNames, setCustomersNames] = useState([]);
    const [customersAll, setCustomersAll] = useState([{}]);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const customersNames =useRef([]);    //const customerCurrent =useRef('');
    const [active, setActive] = useState(1);    
    const [inputs, setInputs] = useState({});
    const [aa, setAa]=useState(true);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [isOpen, setIsOpen] = useState(false);
    const [retValue, setRetValue] = useState(false);
    //const [map, setMap] = useState(/** @type google.maps.Map*/(null));

    const windowWidth = useRef('');    // const windowHeight = useRef(window.innerHeight);        
    const VALID = useRef(false);
    const lat = useRef(0);
    const lng = useRef(0);
    
    /* global google */ 
    const google = window.google      ////IMPORTANT FOR GOOGLE MAPS TO WORK

    const {role, subrole, isLoaded} = useContext(UserLogInContext);

    let { id } = useParams();
    const navigate = useNavigate();    //const location = useLocation();
    const DEBUG = process.env.REACT_APP_DEBUG;

    useEffect(()=>{   
        action==='new' && setInputs(values=>({...values, ['siteId']: uuidv4().replace(/-/g, "")}))
        action==='new' && setInputs(values=>({...values, ['active']: 1}))
        action ==='update' && handleGetDataForUpdate() 
        getCustomerList()        //getLongLat('')        // setLoaded(true)
    }, []);

    useEffect(()=>{
        
        setInputs(values=>({...values, ['siteAddress1']: siteAddress1}));
        setInputs(values=>({...values, ['position']: {x:parseFloat(lng.current), y:parseFloat(lat.current)}}));
        setInputs(values=>({...values, ['siteSuburb']: siteSuburb }))    
        setInputs(values=>({...values, ['sitePostCode']: sitePostCode})); 
        setInputs(values=>( {...values, ['siteState']: siteState})) 
        setInputs(values=>( {...values, ['siteZone']: calculateZone(lng.current, lat.current, siteState)})) 
        setSiteZone( siteState ===""?'' :   calculateZone(lng.current, lat.current, siteState))

    },[siteAddress1]);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
            //setZoom()
        }
        window.addEventListener('resize', handleWindowResize);       
        return ()=>{ window.removeEventListener('resize', handleWindowResize)  };
     },[] );

     useEffect(() => {
        windowWidth.current = ((windowSize.innerWidth)/(windowSize.innerWidth/750)).toString()
     }, [windowSize]);

     useEffect(() => {        
        if(retValue){          
            deleteData(`${DOMAIN}${process.env.REACT_APP_API_GET_SITE_DATA}delete/${id}/d`) /// `http://localhost:3002/api/${type}/delete/${id}`
            navigate(-1)                 
        }
        setRetValue(false)   
        setIsOpen(false)

     }, [retValue]);

     function getWindowSize (){
        const {innerWidth, innerHeight, outerWidth} = window;    

        // let zoom = (outerWidth / innerWidth).toFixed(2)
        // DEBUG && console.log('zoom: ', parseFloat(zoom))
        // DEBUG && console.log(outerWidth, innerWidth)

        return {innerWidth, innerHeight};
     }

    // const setZoom = ()=>{
    //     // DEBUG && console.log(window.matchMedia('(min-width: 780px) and (max-width: 1750px)').matches)
    //     // if (window.matchMedia('(min-width: 780px) and (max-width: 1700px)').matches) {
    //     //     document.body.style.zoom='93%';
    //     //     console.log(document.body.style.zoom)
    //     // } else {
    //     //     document.body.style.zoom='100%'
    //     //     console.log(document.body.style)
    //     // }
    // }

    const getCustomerList = async ()=>{
        const response = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_CUSTOMERS_LIST}`);
        const responseData = await response.json();  
        customersNames.current =  responseData.map( d =>( d.customerName ))
        setCustomersAll(responseData.map( d =>(
                                { 
                                    name:d.customerName,
                                    id:d.customerId 
                                }
                            )
        ) )
        //setInputs(values=>({...values, ['customerLogo']: ''})) //DEBUG && console.log( responseData.map( d =>({ name:d.customerName, id:d.customerId })) )        // customerCurrent.current = responseData.filter( d => d.customerId == selectedCustomer && d.customerName )           // DEBUG && console.log(customerCurrent.current)    
    };

    // const getLongLat= (siteAddress)=>{
    //     DEBUG && console.log( google, siteAddress)

    //     if(google !== undefined){

    //         const geocoder = new google.maps.Geocoder(); 
    //         geocoder.geocode( { 'address': siteAddress}, function(results, status) {

    //             if (status == google.maps.GeocoderStatus.OK) {
    //                 lat.current = results[0].geometry.location.lat();
    //                 lng.current = results[0].geometry.location.lng();

    //                 setInputs(values=>({...values, ['position']: {x:parseFloat(lng.current), y:parseFloat(lat.current)}}));
    //                 DEBUG && console.log(lat.current, lng.current);
    //             } else{
    //                 DEBUG && console.log('Wrong Address supplied')
    //             }
    //         }); 
    //     }
    // };

    // const {isLoaded} = useJsApiLoader({ googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP});
    
    const onUnmount =  useCallback(function callback(map) {  setMap(null)}, [])
    
    //getting data for update
    async function getSiteData(Url) {        
        
        const myHeaders = new Headers();
        myHeaders.append("Set-Cookie", "Tee=26hidkpm5r3nrf3mps8os9i91b");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };
        console.log(Url)
        const response = await fetch(Url, requestOptions);
        console.log(response)
        const responseData = await response.json();
        const data = responseData[0];  
         DEBUG && console.log(responseData)
        console.log(document.cookie)
        //return (responseData || "test");
         const keys= Object.keys(data)
         keys.forEach(key=>{setInputs(values=>({...values, [key]: data[key]}))})
        // DEBUG && console.log(inputs)

        setSiteName(data.siteName);
        setSitePhone(data.sitePhone)
        setSiteContact(data.siteManager)
        setSiteAddress1(data.siteAddress1)        
        setSiteSuburb(data.siteSuburb)
        setSiteState(data.siteState);
        setSitePostCode(data.sitePostCode);
        setSiteZone(data.siteZone);
        setActive(data.active)
        setSelectedCustomer(data.customerName)

        lat.current=data.position !== null ? data.position.y:0;
        lng.current=data.position !== null ? data.position.x:0;
        setCenterSite({lat: data.position.y, lng: data.position.x})
        //getCustomerList();
        // setActive(data.active);
        //DEBUG && console.log(data)
        VALID.current = true;
    };

    const handleClose=()=>{ 
       // DEBUG && console.log("I am out from customers")
        navigate(-1)
    };

    const handleAdd=()=>{ 
        //DEBUG && console.log(uuidv4().replace(/-/g, ""))
        DEBUG && console.log(inputs)
        DEBUG && console.log((DOMAIN + process.env.REACT_APP_API_ADD_NEW_SITE))
                        /// getClientList()
        postNewSitetData(DOMAIN + process.env.REACT_APP_API_ADD_NEW_SITE, inputs)
        navigate(-1)
    };

    const handleGetDataForUpdate=()=>{
        //getCustomerList()
        //DEBUG && console.log(`${DOMAIN}${process.env.REACT_APP_API_GET_SITE_DATA}${id}`)-31.951278064591605, 115.86709869362103
        getSiteData(`${DOMAIN}${process.env.REACT_APP_API_GET_SITE_DATA}${id}`)
    };

    const handleUpdate=()=>{ 
        DEBUG && console.log(inputs, id)
        putSiteData(`${DOMAIN}${process.env.REACT_APP_API_UPDATE_SITE_DATA}${id}`, inputs)
        navigate(-1); 
    }; 

    async function putSiteData(Url, BodyInput) {    //DEBUG && console.log(BodyInput) /// text/plain; */*; charset=utf-8
        const requestOptions = {   
            method: 'PUT', 
            body: JSON.stringify({ BodyInput }),    
            headers: {'Content-Type':'application/json; charset=utf-8' },   
        } 
         await fetch(Url, requestOptions);
        //const responseData = await response.json();  
    };

    async function postNewSitetData(Url, BodyInput) {    //DEBUG && console.log(BodyInput) /// text/plain; */*; charset=utf-8
        const requestOptions = {   
            method: 'POST', 
            headers: {'Content-Type':'application/json; charset=utf-8' }, 
            body: JSON.stringify({ BodyInput }),    
        } 
        const response = await fetch(Url, requestOptions);
        const responseData = await response.json();  
        DEBUG && console.log(responseData)
    };

    const validateField = (inputText, pattern)=>{
        return  pattern===""? true : 
                inputText.match(pattern)?true:
                inputText.length===0 ?true: false 
    };

    const handleChange=(e, validated)=>{
        const names= e.target.name
        const value = e.target.value
        setInputs(values=>({...values, [names]: value}));  //value, valid: 
    }; 

    async function deleteData(a) {
        DEBUG && console.log(a)
        const response = await fetch(a, {method: 'DELETE'});
        const responseData = await response.json();  
        //DEBUG && console.log(responseData.ok)  `${DOMAIN}/api/${type}/delete/${id}`                         
    };
    

    const handleDelete = ()=>{
        setIsOpen(true)

        //let a = prompt(`\n\nWARNING:\n\nYou are about to Deactivate this Site \nIf You wish to proceed - type 'yes' and then click OK button\n`,'NO')

        // if(a===null)(a="no")
        ///DEBUG && console.log(retValue)

        // if (retValue === 'yes'){
        //     // deleteData(`${DOMAIN}${process.env.REACT_APP_API_GET_SITE_DATA}delete/${id}/d`) /// `http://localhost:3002/api/${type}/delete/${id}`
        //     navigate(-1)
        // }
    };

    const ReActivate =()=>{
        
        let a = prompt(`\n  WARNING: You are about to Reactivate this Site! \nIf You wish to proceed type 'yes' and then click OK button\n`,'NO')
        if (a==null){a='NO'}
        if (a.toLowerCase() === 'yes'){

            //DEBUG && console.log(`${DOMAIN}${process.env.REACT_APP_API_GET_SITE_DATA}delete/${id}`)
            deleteData(`${DOMAIN}${process.env.REACT_APP_API_GET_SITE_DATA}delete/${id}/a`) /// `http://localhost:3002/api/${type}/delete/${id}`
            navigate(-1)
        }
    };

    const handlePosChange =(e)=>{ setInputs(values=>({...values, ['position']: {x:parseFloat(lng.current), y:parseFloat(lat.current)}}))    };

    
    return (
        <Box component="form" 
            sx={{'& .MuiTextField-root': { ml:5, width: '100%', mt:1 }}} 
            autoComplete="off"            
        > 

            <Container  h='85ch' w='87ch'>
                <DialogueResponce isOpen={isOpen} setIsOpen={setIsOpen} setValue={setRetValue} title={'Deactivation Warning'} 
                    dialogueTxt={`You are about to change this site status from  being active to inactive. Would You like to proceed with deactivation`}
                />

                <Stack sx={{display :'flex' }}>

                    <Stack sx={{height:'3ch', justifyContent:'center', alignItems:'center', mt:-3}}  >                     
                        <Typography color='darkblue' fontSize={20} fontWeight={600} > 
                            {action === 'update'? `Updating Site` : `Adding Site`}  
                        </Typography>        
                    </Stack> 
            
                    <Stack name= 'ROWS' gap={2} > 

                        <Stack name = 'FIRST ROW' direction="row" >                        
                            <TextField  
                                type='text'                                 
                                label={<Typography>Site Name</Typography> } 
                                value={siteName} 
                                autoFocus={true}
                                required
                                size='small'
                                name ="siteName" 
                                outline='none'
                                style={{width:'76.5ch' }}
                                sx={{boxShadow: `1px 1px 3px 1px rgba(0, 0, 0, 0.3)`}}                                //sx={{mt:'2ch' }}
                                error={!VALID.current || siteName === ''? true : false}    
                                helperText={!VALID.current || siteName === ''? <Typography fontSize={11}>Required field, populate with value</Typography>:''}                             
                                onChange={(e)=>{
                                    setSiteName(e.target.value)
                                    VALID.current = validateField(e.target.value, '')
                                    handleChange(e)
                                }}                                                
                            />
                        </Stack> 

                        <Stack name ="SECOND ROW" direction="row"  gap={1}>
                            
                            <Stack name ="PHONE"  direction="row"  >
                                <TextField  
                                    label={<Typography>Phone Number</Typography> } 
                                    value={sitePhone ??''} 
                                    size='small'
                                    name ="sitePhone" 
                                    style={{ width : '15ch'}}
                                    sx={{boxShadow: `1px 1px 3px 1px rgba(0, 0, 0, 0.3)`}}
                                    //sx={{boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`}}
                                    //helperText={contractorPhoneNumber ===''?"Enter valid Phone Number": contractorPhoneNumber !==null && !isValidField(contractorPhoneNumber, mobileNumber) ? "Invalid number":''}
                                    //error = {sitePhone !==null && !isValidField(sitePhone, mobileNumber)}  //helperText={!VALID.current? "Required field, populate with some value":''} 
                                    onChange={(e)=>{
                                        setSitePhone(e.target.value)// VALID.current = validateField(e.target.value, phonenum)
                                        handleChange(e)
                                    }}
                                />
                            </Stack>

                            <Stack name ="CONTACT NAME" direction="row" sx={{ml:-2}} >
                                <TextField  
                                    label={<Typography>Contact Name</Typography> } 
                                    value={siteContact ??''} 
                                    size='small'
                                    name ="siteManager" 
                                    style={{width:'30ch'}}

                                    sx={{boxShadow: `1px 1px 3px 1px rgba(0, 0, 0, 0.3)`}}
                                    //helperText={contractorPhoneNumber ===''?"Enter valid Phone Number": contractorPhoneNumber !==null && !isValidField(contractorPhoneNumber, mobileNumber) ? "Invalid number":''}
                                    //error = {sitePhone !==null && !isValidField(sitePhone, mobileNumber)}  //helperText={!VALID.current? "Required field, populate with some value":''} 
                                    onChange={(e)=>{
                                        setSiteContact(e.target.value)// VALID.current = validateField(e.target.value, phonenum)
                                        handleChange(e)
                                    }}
                                />
                            </Stack>

                            <Stack name ='CUST LIST' direction="row" sx={{ml:-2}}>
                                <Autocomplete 
                                    name='customersList' 
                                    value={selectedCustomer} 
                                    freeSolo={true}
                                    required = {true}
                                    size='small'
                                    id="customersList"
                                    options={ role ==='Customer' || role=='Client' || role=='Manager'? subrole.split(','):customersNames.current} //DEBUG && console.log(customersAll)//DEBUG && console.log(newInputValue)
                                    autoHighlight
                                    onChange={(event, newInputValue) => { customersAll.map( c => newInputValue === c.name && setInputs(values=>( {...values, ['customerId']: parseInt(c.id)}))) } }
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            style={{width:'25ch'}}
                                            sx={{boxShadow: `1px 1px 3px 1px rgba(0, 0, 0, 0.3)`}}
                                            label= {<Typography>Customers</Typography> } 
                                            inputProps = {{...params.inputProps, type: 'search', }}       
                                        />
                                    )}
                                />    
            
                            </Stack> 
                        </Stack> 

                        <Stack name ='THIRD ROW -- ADDRESS FIELDS'  direction="row"  >
                            
                            <Stack name ="Street" >
                                <TextField  
                                    value={siteAddress1} 
                                    label={<Typography>Street Address</Typography> }                                    
                                    disabled={true}
                                    size="small"
                                    style={{width:'76.5ch'}}
                                    sx={{ backgroundColor:'ivory', boxShadow: `1px 1px 3px 1px rgba(0, 0, 0, 0.3)`}}
                                />
                            </Stack>
                        
                        </Stack>      

                        <Stack name ='THIRD ROW A -- OTHER FIELDS'  direction="row"  gap={1} >                    
                            <Stack name ="Suburb" >
                                <TextField  
                                    label={<Typography>Suburb</Typography> } 
                                    //variant='filled'
                                    value={siteSuburb} 
                                    disabled={true}
                                    size="small"
                                    name ="siteSuburb" 
                                    style={{width:'20ch'}}
                                    sx={{backgroundColor:'ivory'}}

                                />
                            </Stack>

                            <Stack name ="Post Code" >
                                <TextField  
                                    label={<Typography>Post Code</Typography> }                                     //variant='filled'
                                    value={sitePostCode} 
                                    disabled={true}
                                    name ="sitePostCode" 
                                    size="small"                                    //error={!VALID.current || sitePostCode === ''? true:false}
                                    width='10ch'                                    //helperText={!VALID.current || siteName === ''?"Required field, populate with some value":''} 
                                    style={{width:'10ch', }}
                                    sx={{ backgroundColor:'ivory'}}

                                />
                            </Stack>

                            <Stack name ="State & ZONE"  direction ='row'>
                                <TextField  
                                    label={<Typography>State</Typography> } 
                                    size="small"
                                    disabled={true}
                                    value={siteState}                                     
                                    style={{width:'10ch'}}
                                    sx={{backgroundColor:'ivory'}}

                                />

                                <TextField  
                                    label={<Typography>Zone</Typography> } 
                                    size="small"
                                    disabled={true}
                                    value={siteZone} 
                                    style={{width:'15ch'}}
                                    sx={{backgroundColor:'ivory'}}

                                />
                            </Stack>
                        
                        </Stack>         
                            
                        <Stack  name="FORTH ROW for Lat Lng" direction="row"   gap={1}>

                                <TextField  
                                    label={<Typography>Lat</Typography> }
                                    size="small"
                                    value={lat.current} 
                                    disabled={true}
                                    style={{ width:'12ch'}}
                                    sx={{ backgroundColor:'ivory'}}

                                />    

                                <TextField  
                                    label={<Typography>Lng</Typography> }
                                    size="small"
                                    value={lng.current} 
                                    disabled={true}
                                    style={{ width:'12ch'}}
                                    sx={{ backgroundColor:'ivory'}}
                                />                   
                        
                        </Stack>   
                        
                        <Stack name=' ADDRESS FIELD GGOOOGLE' sx={{ml:'1.5ch'}} >
                            <SearchAddress
                                destination={siteAddress1}  
                                setDestination={setSiteAddress1} 
                                setLat={lat}
                                setLng={lng}
                                setSiteState={setSiteState}
                                setSitePostCode={setSitePostCode}
                                setSiteSuburb={setSiteSuburb}
                                size={windowWidth.current}        
                            />
                        </Stack>
                
                    </Stack> 

                    { isLoaded &&                
                        <Stack name="GOOGLE"
                                height={'30ch'} 
                                width={'76.9ch'} 
                                //marginRight={60} 
                                sx={{ ml:5,mt:5}}
                                border='1px solid blue'
                        >

                            <Box height={'100%'} width={'100%'} sx={{justifyContent:'center', alignItems:'center'}}  >                                              
                                <GoogleMap 
                                    center = {{lat: lat.current, lng: lng.current}}//{centerSite}
                                    zoom={17} 
                                    mapContainerStyle = {{width:'100%', height:'100%'}}
                                    onUnmount={onUnmount}
                                    onLoad={(map)=>{setMap(map)}}
                                    options={{  
                                        streetViewControl:false, 
                                        fullscreenControl: false, 
                                        keyboardShortcuts:false, 
                                        zoomControl:true,
                                        mapTypeControl: false,
                                        zoomControlOptions: {style:'SMALL',}, 
                                        disableDoubleClickZoom:true,                                         //mapTypeControlOptions:'ROADMAP', 
                                        gestureHandling:'none'
                                    }}
                                
                                > 
                                {(lat.current!==0 && lng.current !==0) &&                                    
                                    <MarkerF
                                        position= {{lat: lat.current, lng: lng.current}}//{centerSite}
                                        animation={ aa ? google.maps.Animation.DROP:google.maps.Animation.BOUNCE}
                                        onClick={(e)=>setAa(!aa) }
                                        title={siteName}
                                        icon={{
                                            path:"M8 12l-4.7023 2.4721.898-5.236L.3916 5.5279l5.2574-.764L8 0l2.3511 4.764 5.2574.7639-3.8043 3.7082.898 5.236z",
                                            fillColor: "yellow",
                                            fillOpacity: 0.9,
                                            scale: 1.7,
                                            strokeColor: "red",
                                            strokeWeight: 4,
                                            //title: "Title",
                                        }}
                                    >
                                        <InfoWindowF position={{lat: lat.current, lng: lng.current}}>
                                            <Typography style={{ backgroundColor: 'lightpink', border: `.1px solid gray`, padding:2}}>
                                                {siteName}
                                            </Typography>
                                        </InfoWindowF>
                                    </MarkerF>
                                }                                
                                </GoogleMap>       
                            </Box>
                        </Stack>  
                    }
                    
                    <Stack name = 'BUTTONS' direction="row" className="Buttons" gap={5} sx={{ml:30, mt:4, mb:4}}        >
                        {active === 1  && 
                            <Button 
                                className='Button1'
                                onClick={ action==='update'? handleUpdate : handleAdd }
                                variant="outlined" 
                                sx={{ fontSize:11, borderRadius:'5px'}} 
                            > <Typography fontSize={12} fontWeight={800}> {action==='update'? 'Update' : 'Add'} </Typography>
                            </Button>
                        }

                        {action==='update' && 
                            <Button 
                                className='Button1'
                                onClick={ handleDelete}
                                variant="outlined" 
                                sx={{color:'red', fontSize:11,  borderRadius:'5px'}} 
                            > <Typography fontSize={12} fontWeight={800}> {'Deactivate'}</Typography> </Button>
                        }

                        <Button 
                            className='Button1'
                            onClick={handleClose} 
                            variant="outlined" 
                            sx={{ fontSize:11,  borderRadius:'5px'}} 
                        > <Typography fontSize={12} fontWeight={800}>close</Typography> </Button>          {/* </Link> */}

                    </Stack>
                
                </Stack>
                
            </Container>               {/* <Stack direction='row'>     <h4>Width: {windowSize.innerWidth}</h4>  <h4>Height: {windowSize.innerHeight}</h4> </Stack> */}
        
        </Box>  
  )
}
export default NewSite


                            // {/* <Stack direction="row" name='lat' sx={{mt:1, ml:-2}} > */}
                            //     {/* <span style={{width:'40px', height:'20px', backgroundColor:'transparent', fontFamily:'cursive', fontSize:14, marginTop:4, marginLeft:20 }}>Lat :</span>
                            //     <input  
                            //         type='number' 
                            //         min={-90}
                            //         step={0.0001}
                            //         value={lat.current} 
                            //         name='lat'
                            //         outline='none'
                            //         style={{width:'135px', height:'36px', backgroundColor:'transparent', fontFamily:'cursive', fontSize:15, marginTop:-4, paddingLeft:15, outline:'none' }}
                            //         onChange={(e)=>{
                            //             //setCenterSite({lat: parseFloat(e.target.value), lng: })
                            //             lat.current=(e.target.value)// VALID.current = validateField(e.target.value, phonenum)
                            //             handlePosChange(e)
                            //         }}

                            //         // placeholder="123-45-678"
                            //         // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                            //     /> */}


                

                            // {/* <Stack direction="row" name='lng'  sx={{mt:1, ml:1}} > */}
                            //         {/* <span style={{width:'45px', height:'40px', backgroundColor:'transparent', fontSize:15, marginTop:4}}>Lng :</span>
                            //         <input 
                            //             type='number' 
                            //             min={0}
                            //             max={190}
                            //             // inputmmode='numreic'
                            //             // patern='[0-9]+'
                            //             step={0.0001}
                            //             value={lng.current} 
                            //             name='lng'
                            //             style={{
                            //                 width:'140px', 
                            //                 height:'35px', 
                            //                 backgroundColor:'transparent', 
                                            
                            //                 fontSize:15,
                            //                 marginTop:-4,
                            //                 paddingLeft:15
                            //             }}
                            //             onChange={(e)=>{
                            //                 lng.current=(e.target.value)
                            //                 handlePosChange(e)
                            //             }}
                            //         /> */}

                            //         <TextField  
                            //         label={<Typography style={{fontSize:'14px', fontWeight:600}}>Lng</Typography> }
                            //         type='number' 
                            //         size="small"
                            //         //variant='filled'
                            //         value={lng.current} 
                            //         disabled={true}
                            //         style={{  width:'21ch'}}
                            //         sx={{ backgroundColor:'ivory'}}
                            //         // onChange={(e)=>{
                            //         //     lng.current=(e.target.value)
                            //         //     //handlePosChange()
                            //         //     DEBUG && console.log(lng.current, lat.current)
                            //         //     setInputs(values=>({...values, ['position']: {x:parseFloat(lng.current), y:parseFloat(lat.current)}}));
                            //         // }}
                            //     /> 

                            // {/* </Stack> */}

                            // {/* <Stack direction="row" name='zone'  sx={{mt:1, ml:0}} > */}

                            //     {/* <Autocomplete 
                            //         name='siteZone' 
                            //         value={ siteZone}
                            //         freeSolo={true}
                            //         //required

                            //         size='small'
                            //         autoHighlight
                            //         sx={{pr:32,}}
                            //         //options={['Metro','Regional','Remote', 'Remote++']}

                            //         onChange={(event, newInputValue) => {
                            //             setSiteZone(newInputValue)
                            //             setInputs(values=>( {...values, ['siteZone']: newInputValue}))
                            //         } }
                            //         //disableClearable
                                    
                            //         renderInput={(params) => (
                            //             <TextField
                            //                 {...params}
                            //                 style={{width:'25ch'}}
                            //                 sx={{boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`}}
                            //                 label= 'Zone'
                            //                 inputProps = {{...params.inputProps, type: 'search', }}       
                            //             />
                            //         )}
                            //     />   */}










          //         <Link 
          //           to= {{pathname: `sites/new`}} 
          //           style={{textDecoration: "none" }} 
          //         >
                //           <Fab 
                //             variant="extended" 
                //             size="medium" 
                //             color="success" 
                //             aria-label="edit" 
                //             sx={{mr:'10px', mt:'2px', mr:'75px', fontFamily:'cursive',fontSize:18}}
                //           >
                //             <EditIcon />edit
                //           </Fab>
          //         </Link> 

        // {/* <TextField  
        //                 label="Site Branch" 
        //                 value={siteBranch} 
        //                 //defaultValue={customerName} 
        //                 required
        //                 size='small'
        //                 name ="siteBranch" 
        //                 error={!VALID.current === ''? true:false}
        //                 //helperText={!VALID.current || siteName === ''?"Required field, populate with some value":''} 
        //                 // sx = {{ width:'100px', height: '10px'}}
        //                 onChange={(e)=>{
        //                     setSiteBranch(e.target.value)
        //                     VALID.current = validateField(e.target.value,'')
        //                     handleChange(e)
        //                 }}        
            //    />

        //             <TextField  
        //                 label="Site Config" 
        //                 value={siteConfig} 
        //                 size='small'
        //                 name ="siteConfig" 
        //                 error={!VALID.current ? true:false}
        //                 //helperText={!VALID.current || siteName === ''?"Required field, populate with some value":''} 
        //                 // sx = {{ width: 600, height: '10px'}}
        //                 onChange={(e)=>{
        //                     setSiteConfig(e.target.value)
        //                     VALID.current = validateField(e.target.value, '')
        //                     handleChange(e)
        //                 }}
        //             /> 
    ///*/}


                    // {/* <Stack sx = {{  ml:50, height:'6ch'}}> */}
                    //             {/* <TextField  
                    //                 // label="State" 
                    //                 // value={siteState} 
                    //                 // //defaultValue={customerName} 
                    //                 // required
                    //                 // size='small'
                    //                 // name ="siteState" 
                    //                 // sx = {{   boxShadow: 2}}
                    //                 // error={!VALID.current || siteState === ''? true:false}
                    //                 // //helperText={!VALID.current || siteName === ''?"Required field, populate with some value":''} 
                                    
                    //                 // onChange={(e)=>{
                    //                 //     setSiteState(e.target.value)
                    //                 //     VALID.current = validateField(e.target.value, '')
                    //                 //     handleChange(e)
                    //                 // }}
                    //             /> */}

    // const onUnmount =  useCallback(function callback(map) {
    //     setMap(null)
    // }, [])

    // const {isLoaded} = useJsApiLoader({ 
    //     googleMapsApiKey:process.env.REACT_APP_GOOGLE_MAP,
    //     libraries:['localContext','places','geometry'],
    //     region:'Australia'
    // })

    // if (action ==='update' && loaded){
    //     handleGetDataForUpdate()
    //     setLoaded(false)
    // }

// <Stack direction="column" height={'35ch'} width={'50ch'}>
// <Box height={'100%'} width={'100%'} left={0} top={0} border='1px solid transparent'>

// {/* {isLoaded &&
//         <GoogleMap 
//                 center = {{lat: lat.current, lng: lng.current }}
//                 zoom={13} 
//                 mapContainerStyle = {{width:'100%', height:'100%'}}
//                 onUnmount={onUnmount}
//                 onLoad={(map)=>{ setMap(map) }  }
//                 options={{  
//                     streetViewControl:false, 
//                     fullscreenControl: false, 
//                     keyboardShortcuts:false, 
//                     zoomControl:true,
//                     mapTypeControl: false,
//                     // zoomControlOptions: {
//                     //     style:'SMALL',
//                     //     position:0.0
//                     // }, 
//                     disableDoubleClickZoom:true, 
//                     //mapTypeControlOptions:'ROADMAP', 
//                     gestureHandling:'none'
//                 }}
//         >
//             <MarkerF  position={{lat: lat.current, lng: lng.current }}/>
            
//         </GoogleMap>  
//      } */}
    
// </Box>
// </Stack> 











                                // var mapOptions {
                                //     panControl: true,
                                //     zoomControl: true,
                                //     mapTypeControl: true,
                                //     scaleControl: true,
                                //     streetViewControl: true,
                                //     overviewMapControl: true,
                                //     rotateControl: true
                                //   }