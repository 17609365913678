//export const validEmail = new RegExp('^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$'); /// ('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$','g');
// export const validEmail = new RegExp('^([-]?)*@([-]?)*(\.{2,3})+$');/             /////^\d{11}$/;

export const regExpr={
    validPassword : new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$'),
    mailformat : /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@\w+([-]?\w+)*(\.\w{2,3})+$/igm,
    phoneno : /^\d{10}$/igm,
    mobileNumber : /^(\+\d{1,3}[- ]?)?\d{4}[- ]?\d{3}[- ]?\d{3}$/gm,
    notEmpty : /^[a-zA-Z]+[a-zA-Z0-9 ]*$/

}

export const validPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');

export const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@\w+([-]?\w+)*(\.\w{2,3})+$/igm;

export const phoneno = /^\d{10}$/;

export const phonenum = /^\(?([0-9]{3})\)?[- ]?([0-9]{3})[- ]?([0-9]{4})$/;

export const companyABN = /^([0-9]{2})\s?([0-9]{3})\s?([0-9]{3})\s?([0-9]{3})$/;  // /^\(?([0-9]{2})\)?[ ]?([0-9]{3})[ ]?([0-9]{3})[ ]?([0-9]{3})$/;      

export const number0to999 = /^\d{3}$/;  

export const tripplDigit=  /^[1-9][0-9]{1,2}$|^\d$/; 

export const singleDigit=  /^[0-9]$/; 

export const doubleDigit=  /^[1-9]{1}[0-9]{1}$/; // /^[1-9]{1,2}$|^\d$/; 

export const fromZeroTo99 = /^[0-9]{1}$|^[1-9]{1}[0-9]{1}$/;

export const Numbers = /^[0-9]{2,7}$|^\d$/; 

export const oneOrTwo=  /^[0-1]$/; 

export const zeroToten= /^\d{1}$|^10$/;

export const BSBformat =/^\d{3}-?\d{3}$/;

export const Accountformat = /^\d{4}\s?\d{4}$/;////^\d{4}[ ]?\d{4}$/;

export const emailFormat= /\b[A-Z0-9._%+-]+ @[A-Z0-9.-]+\.[A-Z]{2,}\b/;
// /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/ig

export const notEmpty = /^[a-zA-Z]+[a-zA-Z0-9 ]*$/ //// /\b[A-Z0-9._%+-]+ @[A-Z0-9.-]+\.[A-Z]{2,}\b/;

export const validName = /^[a-zA-Z0-9]+[a-zA-Z0-9- ]*$/

export const extraIDs = /^[0-9,]+$/gm;

export const mobileNumber = /^(\+\d{1,3}[- ]?)?\d{4}[- ]?\d{3}[- ]?\d{3}$/gm;

export const eMail=/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;



// export const notEmpty = /^\(?!\s*$).+$/;  
///// /^[1-9][0-9]{1,2}$|^\d$
////test
//////
///

