import React, { useState, useRef, useEffect, useContext } from "react";
import { UserLogInContext } from "../../App.js";
import {
  Box,
  Typography,
  Stack,
  TextField,
  Autocomplete,
  Button,
  TableBody,
  createFilterOptions,
} from "@mui/material";
import "./newitem.scss";
import {
  regExpr,
  mailformat,
  Numbers,
  notEmpty,
  phonenum,
  mobileNumber,
} from "../Regex.js"; //tripplDigit, singleDigit, doubleDigit, oneOrTwo, extraIDs, zeroToten, fromZeroTo99
import { useParams, useNavigate } from "react-router-dom"; //import bcrypt from 'bcryptjs';
import CryptoJS from "crypto-js"; //import {v4 as uuidv4} from 'uuid';//import { Auth, Amplify, API } from 'aws-amplify';//import { useTheme , PhoneNumberField, SwitchField, Text } from '@aws-amplify/ui-react';//import { Alert,useTheme } from '@aws-amplify/ui-react';
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js"; // import AWS from 'aws-sdk';
import {
  ModAlert,
  authentUser,
  changePswd,
  sendEmails,
  dateConverter,
} from "../../util/Functions.jsx";
import styled from "styled-components";
import RoleBox from "../RoleBox.jsx";
import { Table, TableCell, TableRow } from "@aws-amplify/ui-react";
import { DialogueResponce } from "../../components/DialogueAlert.jsx"; ///DialogueAlert//import {useFetch} from '../../hooks/useFetch.jsx';
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";

// AWS.config.update({
//     region: process.env.REACT_APP_AWS_REGION,
//     accessKeyId: 'akid',
//     secretAccessKey: 'secret'
// })

// const CognitoIdentityServiceProvider = AWS.CognitoIdentityServiceProvider;
// const client = new CognitoIdentityServiceProvider({ apiVersion: '2016-04-19' });

const Container = styled.div`
  //background: ${(props) => props.bgc};
  //color: ${(props) => (props.primary ? "black" : "transparent")};
  margin-top: 2.5ch;
  margin-left: -25ch;

  //border: 3px solid ${(props) => props.bc} ;
  border-radius: 10px;
  // display: flex;
  // flex-direction: column;
  height: ${(props) => props.h};
  width: ${(props) => props.w};
  //box-shadow:0px 0px 3px 2px ${(props) => props.bc};
`; ///   aiampolski@figure8services.com.au

const actions = [
  { icon: <FileCopyIcon />, name: "Copy" },
  { icon: <SaveIcon />, name: "Save" },
  { icon: <PrintIcon />, name: "Print" },
  { icon: <ShareIcon />, name: "Share" },
  { icon: "hello", name: "hello" },
  { icon: "deativate", name: "deactivate" },
  { icon: "close", name: "close" },
];

const {
  REACT_APP_DEBUG,
  REACT_APP_API_HTTP,
  REACT_APP_API_LOCAL_DOMAIN,
  REACT_APP_API_LOCAL_PORT,
  NODE_ENV,
  REACT_APP_API_UPDATE_USERS_ACTIVE,
  REACT_APP_API_ADD_NEW_USER,
  REACT_APP_API_GET_USER_DATA,
  REACT_APP_API_UPDATE_USER_DATA,
  REACT_APP_SECRET_KEY,
  REACT_APP_USER_POOL_ID,
  REACT_APP_CLIENT_ID,
} = process.env;

const NewUser = ({ action }) => {
  const { group, subId, role, subrole, account, userfullname, isSuperUser } =
    useContext(UserLogInContext);

  //const {user, customerrole} = useContext(UserLogInContext);    //const [URL, setURL]= useState(`${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_API_PORT}`);
  //const [cognEmail, setCognEmail] = useState("aiampolski@figure8services.com.au");
  const [userName, setUserName] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [userSelectedType, setUserSelectedType] = useState("");
  const [userSelectedSubTypes, setUserSelectedSubTypes] = useState([]);
  const [userCurrentlySelectedSubType, setUserCurrentlySelectedSubType] =
    useState(""); //const [testPW, setTestPW] = useState("");   //const [hashPassWord, setHashPassWord] = useState('');    // const [passWord, setPassWord] = useState(REACT_APP_PSSW);
  ///////////////////////////////////////////////////////////// change (blank it) for production
  const [userEmail, setUserEmail] = useState(""); ////////////////     //const [verificationCode, setVerificationCode] = useState('');    //const [businesspartner, setBusinesspartner] = useState('');    //const [active, setActive] = useState(false);    //const [errorFields, setErrorFields] = useState([]);
  const [userType, setUserType] = useState([]);
  const [userSubType, setUserSubType] = useState([]);
  const [inputs, setInputs] = useState({}); //const [loaded, setLoaded] = useState(true);    ///const [showPassword, setShowPassword] = useState(false);
  //const [role, setRole]=useState('');    //const [customerrole,setCustomerRole]=useState('');    //const [isAdded, setIsAdded]=useState(false);
  //const [isNew, setIsNew] = useState(action === 'new')    //const [checked, setChecked] = useState(true);    //const [phoneNum, setPhoneNum] = useState('');  //0455230620    //const [dialCode, setDialCode]= useState('');
  const [subAttrib, setSubAttrib] = useState("");
  const [disLock, setDisLock] = useState(false); // const [successSignUp, setSuccessSignUp] = useState(false);    //const [inputRefValue, setInputRefValue] = useState('');
  const [alertBox, setAlertBox] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertTitle, setAlertTitle] = useState(""); //const [password, setPassword] = useState("");
  const [tempPassword, setTempPassword] = useState("");
  const [accnt, setAccnt] = useState("");
  const [navigateFlag, setNavigateFlag] = useState(false);
  const [isSelected, setIsSelected] = useState([]);
  const [isOpen, setIsOpen] = useState("");
  const [dResponse, setDResponse] = useState(false);
  const [emailTitle, setEmailTitle] = useState(
    `Welcome to Autom8 Systems on behalf of ${userfullname}`
  ); //const setDResponse =useRef(false)    // const { tokens } = useTheme();    // const inputRef = useRef('');

  const singleSelection = useRef(0);

  const VALID = useRef(false);
  const VALID_name = useRef(false);
  const VALID_fullName = useRef(false);
  const VALID_email = useRef(true);
  const VALID_active = useRef(false);

  let { id } = useParams();
  const navigate = useNavigate();

  const productionHost = REACT_APP_API_HTTP + window.location.host;
  const localHost =
    REACT_APP_API_HTTP + REACT_APP_API_LOCAL_DOMAIN + REACT_APP_API_LOCAL_PORT;
  const DOMAIN = `${NODE_ENV === "production" ? productionHost : localHost}`;
  const DEBUG = REACT_APP_DEBUG; // DEBUG && console.log(role, subrole, account)    // DEBUG && console.log(isSuperUser)

  useEffect(() => {
    getUserTypes();
    action === "update" &&
      getUserDataFromDB(`${DOMAIN}${REACT_APP_API_GET_USER_DATA}${id}`); // setLoaded(true)
  }, []);

  useEffect(() => {
    //disLock &&
    action == "new" && disLock && handleAdd();
    setDisLock(false);
  }, [subAttrib]);

  useEffect(() => {
    navigateFlag && navigate(-1);
  }, [navigateFlag]);

  useEffect(() => {
    dResponse && handleAddActive(inputs.active === 1 ? 0 : 1);
  }, [dResponse]);

  // const[data] = useFetch(`${DOMAIN}${REACT_APP_API_GET_USER_DATA}${id}`, action)
  // //setUserData(data)
  // DEBUG && console.log(data)

  const poolData = {
    UserPoolId: REACT_APP_USER_POOL_ID, // Your user pool id here
    ClientId: REACT_APP_CLIENT_ID, // Your client id here
  };

  const generatePassword = (passwordLength, L, U, N, S) => {
    let charset = "";
    let newPassword = "";

    if (S) charset += "!@#$%^&*()_!@#$%^&*()_!@#$";
    if (N) charset += "01234567891234567890123401";
    if (L) charset += "abcdefghijklmnopqrstuvwxyz";
    if (U) charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    //         Password requirements
    // Contains at least 1 number
    // Contains at least 1 special character
    // Contains at least 1 uppercase letter
    // Contains at least 1 lowercase letter

    for (let i = 0; i < passwordLength; i++) {
      // DEBUG && console.log(Math.floor(Math.random() * charset.length))
      newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    let newValuePsw = newPassword.concat("!Y3oe");

    DEBUG && console.log(newValuePsw);
    //setPassword(newPassword);
    setTempPassword(newValuePsw);
    return newValuePsw;
  };

  const changePassword = async (userName, oldUserPassword, newUserPassword) => {
    const userPool = new CognitoUserPool({
      UserPoolId: REACT_APP_USER_POOL_ID, // Your user pool id here
      ClientId: REACT_APP_CLIENT_ID, // Your client id here
    });
    const cognitoUser = new CognitoUser({
      Username: userName, //testaccount
      Pool: userPool,
    });

    const result = await authentUser(cognitoUser, userName, oldUserPassword); // if (flag==='confirm'){//const authenticationDetails = new AuthenticationDetails({Username : userName, Password : window.prompt("Please enter password","")})///"!Hello_11?"});   //authentUser();        //     //.deleteUser((a)=>{DEBUG && console.log(a)})); //DEBUG && console.log(user.getUsername());  //getUserSession();        // }else{        //     authentUser();        // }
    result
      ? setInputs((values) => ({ ...values, ["active"]: 1 }))
      : setInputs((values) => ({ ...values, ["active"]: 0 }));
    // confirm.current && await getUserSession(cognitoUser);
    // confirm.current && await updateAttributes(cognitoUser);
    await changePswd(cognitoUser, userName, oldUserPassword, newUserPassword);
    navigate("/");
  };

  const getUserTypes = async () => {
    // const response = await fetch(`${DOMAIN}${REACT_APP_API_GET_USERS_TYPE}`); const responseData = await response.json(); setUserType(responseData.map(d=>d.userType))
    setUserType([
      "Administration",
      "Manager",
      "Client",
      "Customer",
      "CustommerPrivate",
      "Senior Technician",
      "Field Technician",
      "Contractor",
    ]);
  };

  const fetchUserTypes = async (url) => {
    const response = await fetch(`${DOMAIN}/api/userdetails/cont`);
    //DEBUG && console.log(response.json())

    if (response.status >= 300) {
      throw "DB problem: can not fetch data. Try later ";
    } else {
      const contractorsList = await response.json();
      DEBUG && console.log(contractorsList);
      return contractorsList.map((s) => s);
    }
  };

  const getUserSubTypes = async (userType) => {
    let contractorsList;
    let techniciansList;
    let clientsList;
    let customersList;

    try {
      if (userType.toUpperCase() == "Contractor".toUpperCase()) {
        contractorsList = await fetchUserTypes(
          `${DOMAIN}/api/userdetails/cont`
        );
        // const response =await fetch(`${DOMAIN}/api/userdetails/cont`);

        // if(response.status>=300) {
        //     throw "DB problem: can not fetch data. Try later"
        // }else{
        //     contractorsList = await response.json();
        //     DEBUG && console.log(contractorsList.map((s)=>s.supplier));

        // }
      } else if (userType.toUpperCase() == "Field Technician".toUpperCase()) {
        const response = await fetch(`${DOMAIN}/api/userdetails/tech`);
        DEBUG && console.log(response);
        if (response.status >= 300) {
          throw `DB problem: can not fetch data. Try later. `;
        } else {
          techniciansList = await response.json();
          DEBUG && console.log(techniciansList.map((s) => s.supplier));
        }
      } else if ( userType.toUpperCase() == "Customer".toUpperCase() || userType.toUpperCase() == "Manager".toUpperCase() ) {
        const response = await fetch(`${DOMAIN}/api/userdetails/customers`);
        customersList = await response.json();
        DEBUG && console.log(customersList.map((s) => s.customerName));

      } else if (userType.toUpperCase() == "Client".toUpperCase()) {
        const response = await fetch(`${DOMAIN}/api/userdetails/client`);
        clientsList = await response.json();
        DEBUG && console.log(clientsList.map((s) => s.clientName));

      } else {
      }

      let subTypes = [];
      switch (userType) {
        case "Customer":
          subTypes = customersList.map((s) => s.customerName); //['AUSPOST','DOTWA','TOSHIBA'];
          break;

        case "Administration":
          subTypes =
            group[0] == "Admin"
              ? ["F8", "XYZ Co", "New Company Pty. Ltd"]
              : [""];
          break;

        case "Client":
          subTypes = clientsList; //['AB CORP','TOSHIBA','DIEBOLD'];
          break;

        case "Manager":
          subTypes = customersList.map((s) => s.customerName); // ['AUSPOST','DOTWA','TOSHIBA'];
          break;

        case "Senior Technician":
          subTypes = ["VIC", "TAS", "NSW", "ACT", "QLD", "NT", "WA", "SA"];
          break;

        case "Field Technician":
          subTypes = techniciansList; //['VIC Field Technician','NSW Field Technician','QLD Field Technician','WA Field Technician'];
          break;

        case "Contractor":
          subTypes = contractorsList;
          break;
      }
      DEBUG && console.log(subTypes);
      setUserSubType(subTypes);
    } catch (err) {
      setAlertBox(true);
      setAlertTitle("WARNING:");
      setAlertText(err);
      return;
    }
  };

  // const CONFIRM=(flag)=>{

  //     //const userName='aaalex_1138'//"aaa11"
  //     const userPool = new CognitoUserPool(poolData)
  //     const userData = {
  //         Username: userName,//'aaalex_1137',
  //         Pool: userPool,
  //     };
  //     const user = new CognitoUser(userData);

  //     // const confirmRegistration=() =>{
  //     //     let authCode = prompt('Please enter auhentication number \nthat we sent you in email','')
  //     //     if (authCode !==null) {
  //     //        new Promise((resolve, reject) =>
  //     //         user.confirmRegistration(authCode, false, function(err, result){
  //     //                 if (err) {
  //     //                     reject(err.message || JSON.stringify(err))
  //     //                     alert( err.message || JSON.stringify(err));
  //     //                     return;
  //     //                 }
  //     //                 resolve(result)
  //     //                 DEBUG && console.log(result);
  //     //                 if(result==='SUCCESS'){
  //     //                     // authCode =''

  //     //                 }
  //     //         } ))
  //     //         DEBUG && console.log(authCode)
  //     //     }
  //     //     else{
  //     //         ///DEBUG && console.log(FormDialog)
  //     //         DEBUG && console.log('FormDialog')
  //     //     }
  //     // };

  //     // const authentUser = () => {
  //     //     DEBUG && console.log(user.getUsername());

  //     //     if (user.getUsername() !=="" ) {

  //     //         let psw = !isNew ? passWord : prompt("Please enter password or leave it blank for the Original Password","")

  //     //         const authenticationData = {Username : userName, Password : psw};
  //     //         const authenticationDetails = new AuthenticationDetails(authenticationData);

  //     //         return new Promise((resolve, reject) =>
  //     //             user.authenticateUser(authenticationDetails, {
  //     //                 onSuccess: result =>{
  //     //                                         resolve(result);
  //     //                                         DEBUG && console.log(result, '  -----   success');
  //     //                                         setInputs(values=>({...values, ['active']:1}))
  //     //                                     },

  //     //                 onFailure: err => {
  //     //                                     reject(err)
  //     //                                     DEBUG && console.log(err.message)
  //     //                                     const missUserName='Missing required parameter USERNAME'
  //     //                                     const userNotConfirm='User is not confirmed'
  //     //                                     const incorectNamePsw ='Incorrect username or password'

  //     //                                     if (err.message.includes(missUserName)){
  //     //                                             alert( "You have not supplied the user name for confirmation" )
  //     //                                     }else if(err.message.includes(userNotConfirm)){
  //     //                                         alert( `User is not confirmed. \nValidation link has been sent to the user. \nUser has not used the supplied link yet to validate account's email`)
  //     //                                     }else if(err.message.includes(incorectNamePsw)){
  //     //                                         alert( "You have tried to confirm before Signing Up User" )
  //     //                                     }
  //     //                                     //alert(`Password you entered is different \nto the password we have for this user. This could either mean: \n\n1. You entered wrong password\n2. Password you entered is an original password \nand it assumed that user logged in to Autom8 \nand changed password successfuly`)
  //     //                                 }
  //     //             })
  //     //         );
  //     //     }else{
  //     //         DEBUG && console.log("test")
  //     //     }
  //     // };

  //     // const getUserSession = () => {
  //     //     return new Promise((resolve, reject) =>
  //     //         user.getSession( function (err, result) {
  //     //                     if (err) {
  //     //                         alert( err.message || JSON.stringify(err));
  //     //                         reject(err.message || JSON.stringify(err));
  //     //                         return;
  //     //                     }
  //     //                     resolve(result)
  //     //                     DEBUG && console.log(result)
  //     //                     // setInputs(values=>({...values, ['sub']: subAttrib}))
  //     //                     setInputs(values=>({...values, ['acive']: 1}))
  //     //                 },
  //     //         )
  //     //     );
  //     // }

  //         // DEBUG && console.log(cognitoUser.changePassword())
  // // user.getUserAttributes(function(err, result) {
  // //     if (err) {
  // //         alert(err.message || JSON.stringify(err));
  // //         return;
  // //     }
  // //     for (i = 0; i < result.length; i++) {
  // //         DEBUG && console.log(
  // //             'attribute ' + result[i].getName() + ' has value ' + result[i].getValue()
  // //         );
  // //     }
  // // });

  //     const updateAttributes = ()=>{
  //         const attributeList = [];
  //         // const AttribFullName = {
  //         //     Name: 'name',
  //         //     Value: userFullName,
  //         // };

  //         const AttribDataRole = {
  //             Name: 'custom:Role',
  //             Value: userSelectedType//'alexiampolski@optusnet.com.au',
  //         };

  //         const AttribDataCustRole = {
  //             Name: 'custom:CustomerRole',
  //             Value: userFullName//'alexiampolski@optusnet.com.au',
  //         };

  //         //const attributeFullName = new CognitoUserAttribute(AttribFullName);
  //         const attributeRole = new CognitoUserAttribute(AttribDataRole);
  //         const attribute2 = new CognitoUserAttribute(AttribDataCustRole);
  //         DEBUG && console.log(attributeRole.getValue())

  //         //attributeList.push(AttribFullName);
  //         attributeList.push(attributeRole);
  //         attributeList.push(attribute2);

  //         return new Promise((resolve, reject) =>
  //             user.updateAttributes( attributeList, function(err, result) {
  //                 if (err) {
  //                     reject(err.message || JSON.stringify(err));
  //                     alert( err.message || JSON.stringify(err));
  //                     return;
  //                 }
  //                 resolve(result)
  //                 DEBUG && console.log(result);
  //                 navigate(-1);

  //             })
  //         )
  //     }
  //                             // confirmRegistration()
  //     setInputs(values=>({...values, ['active']: 1}))
  //     if (flag==='confirm'){
  //         //REACT_APP_API_UPDATE_USERS_ACTIVE
  //         //const authenticationDetails = new AuthenticationDetails({Username : userName, Password : window.prompt("Please enter password","")})///"!Hello_11?"});
  //         DEBUG && console.log('jjjjjjjjjjjjjjjjj ', inputs.active)
  //         //authentUser();
  //         action==='update' && handleUpdate()
  //         //DEBUG && console.log(user.authenticateUser(authenticationDetails, (a)=>DEBUG && console.log(a)))    //.deleteUser((a)=>{DEBUG && console.log(a)}));
  //         //DEBUG && console.log(user.getUsername());
  //         //getUserSession();

  //     }else{
  //         //authentUser();
  //         //getUserSession();

  //         DEBUG && console.log(user.getUsername(), '------test')
  //         action==='update' && handleUpdate()
  //         ///updateAttributes();
  //     }
  //                     // const {authStatus} = useAuthenticator();
  //                     // DEBUG && console.log(authStatus)

  //                     // const params = {
  //                     //     UserPoolId: REACT_APP_USER_POOL_ID,
  //                     //     Username: userName,
  //                     //     UserAttributes: [  {
  //                     //         Name: 'email',
  //                     //         Value: cognEmail,
  //                     //       },  ],
  //                     //     ValidationData: [
  //                     //       {
  //                     //         Name: 'custom:Role', /* required */
  //                     //         Value: 'tech'
  //                     //       }, ]
  //                     //   };

  //                     //   cognitoidentityserviceprovider.adminCreateUser(params, function(err, data) {
  //                     //     if (err){
  //                     //         DEBUG && console.log(err, err.stack)
  //                     //         return;
  //                     //     }
  //                     //     DEBUG && console.log(data);
  //                     //   });

  //                     // cognitoUser.forgotPassword({
  //                         //                         onSuccess: function (result) {
  //                         //                             DEBUG && console.log('call result: ' + result);
  //                         //                         },
  //                         //                         onFailure: function(err) {
  //                         //                             alert(err);
  //                         //                         },
  //                         //                         inputVerificationCode() {
  //                         //                             const verificationCode = prompt('Please input verification code ' ,'');
  //                         //                             const newPassword = prompt('Enter new password ' ,'');
  //                         //                             cognitoUser.confirmPassword(verificationCode, newPassword, this);
  //                         //                         }
  //                         //     }
  //                         // );
  // };

  // var params = {
  //     // UserPoolId: REACT_APP_USER_POOL_ID, /* required */
  //     // ClientId: REACT_APP_CLIENT_ID,
  //     Username: 'ALEX111111', /* required */
  //     DesiredDeliveryMediums: ['EMAIL'],
  //     ForceAliasCreation: false,
  //     //MessageAction: 'SUPPRESS',
  //     TemporaryPassword: 'tempPassword1',
  //     UserAttributes: [
  //         {
  //             Name: 'email', /* required */
  //             Value: userEmail
  //         },
  //         {
  //             Name: 'name', /* required */
  //             Value: 'Al'
  //         },
  //         {
  //             Name: 'family_name', /* required */
  //             Value: 'PAN'
  //         },
  //         {
  //             Name: 'given_name',
  //             Value: 'PETER'
  //         },
  //         {
  //             Name: 'custom:account',
  //             Value: accnt,
  //         },
  //         {
  //             Name: 'custom:CustomerRole',
  //             Value: 'placeholder',
  //         },
  //         {
  //             Name: 'custom:Role',
  //             Value: userSelectedType,
  //         },
  //         {
  //             Name: 'custom:SubRole',
  //             Value: 'userSelectedSubTypes',
  //         }
  //         /* more items */
  //     ]
  // };

  const SIGNUP = async (tempPsw) => {
    DEBUG && console.log(inputs); // DEBUG && console.log(CognitoUserPool)  AdminCreateUser
    const userPool = new CognitoUserPool(poolData); //const missP=`2 validation errors detected: Value at`;

    const attributeList = [];
    const attributeEmail = new CognitoUserAttribute({
      Name: "email",
      Value: userEmail,
    });

    const attributeName = new CognitoUserAttribute({
      Name: "name",
      Value: userFullName,
    });

    const attributeFamName = new CognitoUserAttribute({
      Name: "family_name",
      Value: userFullName,
    });

    const attributeGivenName = new CognitoUserAttribute({
      Name: "given_name",
      Value: userFullName,
    });

    const attributeUserFullName = new CognitoUserAttribute({
      Name: "custom:CustomerRole",
      Value: "placeholder",
    });

    const attributeUserType = new CognitoUserAttribute({
      Name: "custom:Role",
      Value: userSelectedType,
    });

    const attributeUserSubType = new CognitoUserAttribute({
      Name: "custom:SubRole",
      Value:
        userSelectedType === "Field Technician" ||
        userSelectedType == "Contractor"
          ? inputs.userSubType.toString()
          : userSelectedSubTypes.toString(),
    });

    const attributeAccountName = new CognitoUserAttribute({
      Name: "custom:account",
      Value: accnt,
    });

    attributeList.push(attributeEmail);
    attributeList.push(attributeName);
    attributeList.push(attributeFamName);
    attributeList.push(attributeGivenName);
    attributeList.push(attributeUserFullName);
    attributeList.push(attributeUserType);
    attributeList.push(attributeUserSubType);

    setInputs((values) => ({
      ...values,
      ["userSubType"]: userSelectedSubTypes.toString(),
    }));
    attributeList.push(attributeAccountName); // attributeList.push(attributeProfile);    // setSubAttrib('444444' )      // setDisLock(true); // setInputs(values=>({...values, ['sub']: 'new_sub' })); // setInputs(values=>({...values, ['active']: 0}));
    DEBUG &&
      console.log(
        "tempPsw : " + tempPsw,
        " ----------   tempPassword :  " + tempPassword
      );

    // setSuccessSignUp(true)
    // setAlertTitle(`User Created Successsfuly`)
    // setAlertText(`User ${userName} has been created. An email has been sent to the user address ${userEmail} with password ${tempPsw}`)

    userPool.signUp(userName, tempPsw, attributeList, null, (err, user) => {
      if (err) {
        setAlertText(`Error Description : ${err.message}`);
        setAlertTitle("Error");
        setAlertBox(true);
        return;
      }
      setInputs((values) => ({ ...values, ["sub"]: user.userSub }));
      setInputs((values) => ({ ...values, ["active"]: 0 }));
      setInputs((values) => ({ ...values, ["userEmail"]: userEmail }));
      setInputs((values) => ({
        ...values,
        ["passWord"]: toHashPassword(tempPsw),
      }));

      sendEmails(
        userEmail,
        process.env.REACT_APP_API_SUPPORT_EMAIL,
        emailTitle,
        "newUser",
        userName,
        tempPsw,
        userFullName
      );

      setAlertTitle(`User Created Successsfuly`);
      setAlertText(
        `User ${userFullName} with username ${userName} has been created. An email has been sent to ${userEmail} with temporary password ${tempPsw}`
      );
      setAlertBox(true);
      setDisLock(true); //DEBUG && console.log(user, attributeList)
      setSubAttrib(user.userSub); //handleAddActive(1)
    });
    handleClose();
  };
  //Constan

  //////////////////

  // client.adminCreateUser(params, (err, data)=>{
  //     if (err) {DEBUG && console.log(err, err.stack)
  //     }else{
  //         DEBUG && console.log(data);
  //     }          // successful response
  //     //callback(null, data);
  // });
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleAddActive = async (status) => {
    const response = await fetch(
      `${DOMAIN}${REACT_APP_API_UPDATE_USERS_ACTIVE}${id}`,
      {
        method: "PUT",
        body: JSON.stringify({ active: status }),
        headers: { "Content-Type": "application/json; charset=utf-8" },
      }
    );
    const responseData = await response.json();
    DEBUG && console.log(responseData.affectedRows); //responseData.affectedRows ===1 ? setIsAdded(true): setIsAdded(false)
    navigate(-1);
  }; //getting data for update    //getUserData(`${DOMAIN}${REACT_APP_API_GET_USER_DATA}${id}`

  const getUserDataFromDB = async (Url) => {
    const response = await fetch(Url);
    const responseData = await response.json();
    const userData = responseData[0];
    DEBUG && console.log(userData); // DEBUG && console.log(id)

    const keys = Object.keys(userData);
    keys.forEach((k) => {
      setInputs((values) => ({ ...values, [k]: userData[k] }));
    });
    setUserName(userData.name);
    setUserFullName(userData.userFullName);
    setUserSelectedType(userData.userType);
    setUserSelectedSubTypes(userData.userSubType); ///fruits.toString(); fruits.join(" * ");
    DEBUG && console.log(userData.userSubType);
    // setHashPassWord(userData.passWord==='removed'||userData.passWord==='Removed' || userData.passWord ==''? '':''//verifyHashedPassword(userData.passWord)                );
    setUserEmail(userData.userEmail);
    //setBusinesspartner(userData.business_partner);
    //setPhoneNum(userData.phoneNumber); //.slice( userData.phoneNumber.indexOf(" ")).trim())     ///{(userData.phoneNumber ?? setDialCode('+61') )|| setDialCode(userData.phoneNumber.slice(0, userData.phoneNumber.indexOf(" ")).trim())}
    //setActive(userData.active ===1 ? true:false);
    //setChecked(userData.active===1 ? false:true)
    setSubAttrib(
      userData.sub === null || userData.sub === "null" || userData.sub === ""
        ? "EMPTY"
        : userData.sub
    );

    // if(userData.phoneNumber == null || userData.phoneNumber === 'null'|| userData.phoneNumber===''){
    //     setDialCode('')
    //     setPhoneNum('');
    //     dialCodeRef.current = '+61'
    //     setInputs(values=>({...values, ['phoneNumber']: ''}));
    //}else{
    // DEBUG && console.log(userData.phoneNumber.slice(1, userData.phoneNumber.indexOf(")")).trim() )
    // DEBUG && console.log(userData.phoneNumber.slice(userData.phoneNumber.indexOf(")")+1).trim())

    //dialCodeRef.current = userData.phoneNumber.slice(1, userData.phoneNumber.indexOf(")")).trim()
    // setDialCode(userData.phoneNumber.slice(2, userData.phoneNumber.indexOf(")")).trim());
    // setPhoneNum( userData.phoneNumber.slice(userData.phoneNumber.indexOf(")")+1).trim());
    //DEBUG && console.log(userData.phoneNumber.slice(2, userData.phoneNumber.indexOf(")").trim()),   "- " +  userData.phoneNumber.slice(userData.phoneNumber.indexOf(")")+1).trim())

    //}
    // setDisLock(true)
    DEBUG && console.log(verifyHashedPassword(userData.passWord));
    VALID.current = true;
    VALID_name.current = true;
    VALID_fullName.current = true;
    VALID_email.current = validateField(userData.userEmail, mailformat);
    VALID_active.current = true;
    ///VALID_businesParther.current=true;
  };

  const toHashPassword = (pwd) => {
    const ciphertext = CryptoJS.AES.encrypt(
      pwd,
      REACT_APP_SECRET_KEY
    ).toString();

    //setInputs(values=>({...values, ['passWord']: ciphertext}));
    DEBUG && console.log(ciphertext);
    return ciphertext;
  }; //setInputs(values=>({...values, ['passWord']: pw.trim() ==='' || pw.trim() ==='removed'? "removed": hashPassWord, isValidated: true,}))

  const verifyHashedPassword = (pwd) => {
    try {
      const bytes = CryptoJS.AES.decrypt(pwd.trim(), REACT_APP_SECRET_KEY);
      //DEBUG && console.log(bytes.toString(CryptoJS.enc.Utf8))
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (err) {
      //DEBUG && console.log('OLD PASSWORD ERROR :', err);
      return "Old Password cannot be retreaved";
    }
  };

  const handleClose = () => {
    navigate(-1);
  };

  const handleAdd = () => {
    //DEBUG && console.log(inputs)
    //SignUpUser()
    addNewUsertDataToDB(`${DOMAIN}${REACT_APP_API_ADD_NEW_USER}`, inputs);
    //!alertBox && handleClose()
  };

  const handleUpdate = () => {
    const invalidFields = [];

    for (const key in inputs) {
      //debug && DEBUG && console.log(`${key}: ${inputs[key]}`) ////to remove
      inputs[key] === "INVALID" && invalidFields.push(key);
    }

    if (invalidFields.length > 0) {
      //debug && DEBUG && console.log(invalidFields)  ////to remove//
      //setErrorFields(invalidFields)
    } else {
      // DEBUG && console.log(`${DOMAIN}${REACT_APP_API_UPDATE_USER_DATA}${id}`, inputs)
      updateUser(`${DOMAIN}${REACT_APP_API_UPDATE_USER_DATA}${id}`, inputs);
      navigate(-1);
    }
  };
  // const getDataForUpdate=()=>{ getUserData(`${DOMAIN}${REACT_APP_API_GET_USER_DATA}${id}`)  }

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    VALID.current
      ? setInputs((values) => ({ ...values, [name]: value }))
      : setInputs((values) => ({ ...values, [name]: "INVALID" }));
  };

  //const handleChangeRole = (e) => {setRole(e.target.value);}; //const handleChangeCustomerRole = (e) => {setCustomerRole(e.target.value);};

  const validateField = (inputText, flag) => {
    flag === ""
      ? (VALID.current = true)
      : inputText.match(flag)
      ? (VALID.current = true)
      : (VALID.current = false);
    return VALID.current;
  };

  async function updateUser(Url, BodyInput) {
    DEBUG && console.log(BodyInput); /// text/plain; */*; charset=utf-8
    DEBUG && console.log(Url);
    const requestOptions = {
      method: "PUT",
      body: JSON.stringify({ BodyInput }),
      headers: { "Content-Type": "application/json; charset=utf-8" },
    };

    const response = await fetch(Url, requestOptions);
    const responseData = await response.json();
  }

  const addNewUsertDataToDB = async (Url, BodyInput) => {
    //DEBUG && console.log(BodyInput) /// text/plain; */*; charset=utf-8
    DEBUG && console.log(BodyInput);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify({ BodyInput }),
    };

    const response = await fetch(Url, requestOptions);
    DEBUG && console.log(response.status);
    //const responseData = await response.json();
  };

  // const SignUpUser = async() =>{
  //     //debug && DEBUG && console.log(userName, passWord, role, customerrole)        //const email="aiampolski@figure8services.com.au"
  //     if(userName !=='' && passWord !== ''){

  //         try {
  //             const { user } = await Auth.signUp({
  //                 username:userName,
  //                 password:passWord,
  //                 attributes: {
  //                     email:userEmail,
  //                     'custom:Role': role,
  //                     'custom:CustomerRole': customerrole
  //                   },
  //                 }
  //             );

  //             ///setDisLock(true)
  //             DEBUG && console.log(user.username);
  //             ///get the current jwt Token for the curretly logged user
  //             //const a = await Auth
  //             // const {idToken}  = await Auth.currentSession()  //.currentAuthenticatedUser(username);
  //             // DEBUG && console.log(idToken.jwtToken)

  //             isNew && setInputs(values=>({...values, ['active']:1}))

  //         } catch (error) { DEBUG && console.log('error signing up:', error);  }
  //     }else{
  //         DEBUG && console.log('user name and/or password have not been supplied')
  //     }
  // };

  // const ConfirmSignUpUser = async ()=>{
  //     //debug && DEBUG && console.log(userName, verificationCode, role, customerrole)// debug && DEBUG && console.log(validateField (verificationCode, Numbers))// debug && DEBUG && console.log(action);

  //     if(validateField (verificationCode, Numbers)){
  //         try {
  //             const response = await Auth.confirmSignUp(userName, verificationCode);
  //             //debug && DEBUG && console.log(response);
  //             if (response === 'SUCCESS'){
  //                 isNew && handleAdd();
  //                 //setActive(true)
  //             }
  //         } catch (error) { DEBUG && console.log('ERROR confirming sign up:  >>> ', error);}
  //     }else{
  //         DEBUG && console.log('Code has been supplied in the wrong format')
  //     }
  //     setVerificationCode('')
  // };

  // const handleOnDialCodeChange = (e)=> {
  //     e.preventDefault();
  //     //setPhoneNum('');
  //     dialCodeRef.current= e.target.value
  //     setDialCode(e.target.value);
  //     DEBUG && console.log(e.target.value)
  //     DEBUG && console.log(dialCodeRef.current)
  //     DEBUG && console.log(`${dialCodeRef.current} ${phoneNum}` )

  //     //setInputs(values=>({...values, ['phoneNumber']: `(${e.target.value.trim()}) ${phoneNum}`}))
  // };

  // const onChangePhone=(e) =>{
  //     DEBUG && console.log(dialCode)
  //     DEBUG && console.log(e.target.value)
  //     //dialCodeRef.current.value="+10"
  //     setInputs(values=>({...values, ['phoneNumber']: `(${dialCode}) ${e.target.value}`}))
  //     DEBUG && console.log(`(${dialCode}) ${e.target.value}`)
  //     DEBUG && console.log(dialCodeRef.current )          //(e.target.value.replaceAll(' ',''))
  //     setPhoneNum(e.target.value); //setInputs(values=>({...values, ['phoneNumber']: `${dialCodeRef.current.value} ${e.target.value}`}))
  // };

  // const hasError=(e)=>{return e.target.value.match(phonenum) !== null? true:false }

  // const handleChangeCheck = (e) => {
  //     setChecked(e.target.checked);
  //         //if(e.target.checked){
  //         //         setActive(1)
  //         //         setInputs(values=>({...values, ['active']:1}))
  //         //     }else{
  //         //         ///e.target.checked=false
  //         //         setActive(0)
  //         //         setInputs(values=>({...values, ['active']:0}))
  //         //     }
  //     DEBUG && console.log(e.target.checked)
  // };

  const theme = {
    name: "phonenumber-theme",
    tokens: {
      components: {
        phonenumberfield: {
          //these design tokens will apply to both the select and input that are part of the phonenumber field
          color: { value: "{colors.blue.60}" },
          fontSize: { value: "{fontSizes.small}" },
          fontFamily: { value: "{fontFamily.cursive}" },
          borderColor: { value: "{colors.green.60}" },
          _focus: { orderColor: { value: "{colors.neutral.100}" } },
        },
      },
    },
  };

  let filteredVal = "";

  const filterArr = (val) => {
    DEBUG && console.log(val, filteredVal);

    if (val == filteredVal) {
      return "";
    } else {
      filteredVal = val;
      return val;
    }
  };

  const filterEmpty = (val) => {
    let tempArr = [];
    val.forEach((e) => e !== "" && tempArr.push(e));
    return tempArr;
  };

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    ignoreAccents: true,
    //stringify: (option) => option.title,
    trim: true,
    ignoreCase: true,
    //limit:10,
  });

  return (
    <Box
      component="form"
      sx={{ "& .MuiTextField-root": { ml: 0, mt: 0 } }}
      // autoComplete="off"
    >
      {/* <Container > */}
      {/* <ModAlert  isOpen={alertBox} setIsOpen={setAlertBox} title={alertTitle} description={alertText} setNavigateFlag={setNavigateFlag}/>  */}

      <DialogueResponce
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setValue={setDResponse}
        title={`${
          inputs.active !== 1 ? "Reactivation Warning" : "Deactivation Warning"
        }`}
        dialogueTxt={
          <Typography color="#2d187be0" fontWeight={600}>
            {` You are about to change this user from ${
              inputs.active === 1 ? "active" : "inactive"
            } to ${
              inputs.active === 1 ? "inactive" : "active"
            }. Would You like to proceed with ${
              inputs.active !== 1 ? "activation" : "deactivation"
            }?`}
          </Typography>
        }
      />

      <Stack
        direction="column"
        justifyContent="space-evenly"
        alignItems="stretch"
        spacing={3}
      >
        <Stack
          name="TABLE_BOX"
          direction="column"
          sx={{ boxShadow: "1px 1px 2px 1px gray" }}
        >
          {action !== "update" ? (
            <Stack name="Table_ON_ADD">
              <Table
                caption={
                  <Stack
                    direction="row"
                    backgroundColor="lightsteelblue"
                    boxShadow="1px 1px 2px 1px gray"
                    paddingY={1}
                    gap={2}
                    justifyContent="center"
                  >
                    <Typography color={"InfoText"} fontWeight={600}>
                      Inviting User{" "}
                    </Typography>
                    <Typography
                      color={"red"}
                      fontWeight={600}
                    >{`${userFullName}`}</Typography>
                  </Stack>
                }
                size="small"
                highlightOnHover={true}
                variation="striped"
                backgroundColor={"antiquewhite"}
                width={"80ch"}
              >
                <TableBody>
                  <TableRow>
                    <TableCell width="30ch" border="1px solid lightgray">
                      <Typography
                        style={{ color: "#2d187be0", fontWeight: 600 }}
                      >
                        Full Name
                      </Typography>
                    </TableCell>
                    <TableCell border="1px solid lightgray">
                      <TextField
                        sx={{
                          "& label.Mui-notFocused": { color: "yellow" },
                          "& label.Mui-focused": { color: "blue" },
                          "& .MuiInput-underline:after": {
                            borderColor: "green",
                          },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": { borderColor: "blue" },
                            "&:hover fieldset": { border: "3px solid green" },
                            "&.Mui-focused fieldset": {
                              borderColor: "steelblue",
                            }, //'&:hover': {  },
                          },
                        }}
                        value={userFullName} //required
                        disabled={action === "update"} //{disLock}
                        size="small"
                        name="name"
                        style={{
                          boxShadow: `0 0 2px 1px azure`,
                          zIndex: 0,
                          width: "60ch",
                        }}
                        error={
                          !VALID_fullName.current || userFullName === ""
                            ? true
                            : false
                        }
                        helperText={
                          userFullName === ""
                            ? ""
                            : !VALID_fullName.current
                            ? "Name consists of alpha characters only!"
                            : ""
                        }
                        onChange={(e) => {
                          setUserFullName(e.target.value); //{subAttrib !=='EMPTY' && subAttrib.length > 0 || action==='update'?true:false}
                          action === "new" &&
                            subAttrib.length === 0 &&
                            setUserName(
                              e.target.value
                                .trim()
                                .replaceAll(" ", "")
                                .toLowerCase()
                            );
                          //setUserLength(e.target.value.length)
                          VALID_fullName.current = validateField(
                            e.target.value,
                            notEmpty
                          );
                          setInputs((values) => ({
                            ...values,
                            ["userFullName"]: e.target.value,
                          }));
                          setInputs((values) => ({
                            ...values,
                            ["name"]:
                              action === "new" &&
                              e.target.value
                                .trim()
                                .replaceAll(" ", "")
                                .toLowerCase(),
                          }));
                        }}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell border="1px solid lightgray">
                      <Typography
                        style={{ color: "#2d187be0", fontWeight: 600 }}
                      >
                        Login ID
                      </Typography>
                    </TableCell>
                    <TableCell border="1px solid lightgray">
                      <Typography style={{ fontWeight: 400 }}>
                        {userName}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell border="1px solid lightgray">
                      <Typography
                        style={{ color: "#2d187be0", fontWeight: 600 }}
                      >
                        Email Address
                      </Typography>
                    </TableCell>
                    <TableCell border="1px solid lightgray">
                      <Stack
                        name="EMAIL"
                        direction="row"
                        sx={{ width: "60ch" }}
                      >
                        <TextField
                          sx={{
                            "& label.Mui-notFocused": { color: "yellow" },
                            "& label.Mui-focused": { color: "blue" },
                            "& .MuiInput-underline:after": {
                              borderColor: "green",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": { borderColor: "blue" },
                              "&:hover fieldset": { border: "3px solid green" },
                              "&.Mui-focused fieldset": {
                                borderColor: "steelblue",
                              }, //'&:hover': {  },
                            },
                          }}
                          style={{
                            boxShadow: `0 0 2px 1px azure`,
                            zIndex: 0,
                            width: "60ch",
                          }}
                          disabled={action === "update"}
                          // sx={{ boxShadow: `0 0 2px 1px azure`, zIndex:0}}  //'& .MuiOutlinedInput-root': { borderColor: 'red', },
                          value={userEmail} //required
                          size="small"
                          error={
                            userEmail === "" || !VALID_email.current
                              ? true
                              : false
                          } //InputProps={{ error: userEmail === '' || !VALID_email.current ? true:false,}}
                          helperText={
                            userEmail === "" ? (
                              ""
                            ) : !VALID_email.current ? (
                              <Typography fontSize={11}>
                                Email is INVALID
                              </Typography>
                            ) : (
                              ""
                            )
                          }
                          onChange={(e) => {
                            setUserEmail(e.target.value);
                            VALID_email.current = validateField(
                              e.target.value,
                              mailformat
                            );
                            handleChange(e);
                          }}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell border="1px solid lightgray">
                      <Typography
                        style={{ color: "#2d187be0", fontWeight: 600 }}
                      >
                        Type{" "}
                      </Typography>
                    </TableCell>
                    <TableCell border="1px solid lightgray">
                      <Stack name="USER TYPE" sx={{ width: "60ch" }}>
                        <Autocomplete
                          inputValue={userSelectedType}
                          //value = {userSelectedType}
                          disabled={action === "update"}
                          filterOptions={filterOptions}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          } //required                    //label= "Type"
                          size="small"
                          onInputChange={(event, newInputValue) => {
                            setIsSelected([]);
                            setUserSelectedType(newInputValue);
                            setInputs((values) => ({
                              ...values,
                              ["userType"]:
                                newInputValue.length > 0
                                  ? newInputValue
                                  : "INVALID",
                            })); //getUserSubTypes(newInputValue)                                      //VALID_type.current=false;
                            getUserSubTypes(newInputValue);
                            setUserSelectedSubTypes([]); ///    userSelectedSubTypes
                            setInputs((values) => ({
                              ...values,
                              ["userSubType"]: "",
                            }));
                            setUserCurrentlySelectedSubType("");
                            //DEBUG && console.log(userSubType)

                            if (
                              newInputValue == "Administration" ||
                              newInputValue == "Customer" ||
                              newInputValue == "Field Technician" ||
                              newInputValue == "Contractor"
                            ) {
                              singleSelection.current = 0;
                            }
                          }}
                          options={userType} //autoHighlight  //getOptionLabel={(userType) => userType}// renderOption={(props, option) => (// <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>  </Box>  // )}
                          renderInput={(params) => (
                            <TextField
                              {...params} //label = "Type" //defaultValue={"Select the type of the user"}
                              sx={{ boxShadow: `0 0 2px 1px azure`, zIndex: 0 }}
                              error={userSelectedType === "" ? true : false} //required   //error = {userSelectedType === ''? true:false}//value = {userSelectedType}// inputProps={{...params.inputProps}}//,  autoComplete: 'new-password', // disable autocomplete and autofill     // }}
                              //helperText= { userSelectedType===''?"Select value":""}// onBlur={(e)=>{//if (e.target.value.length>0){//setInputs(values=>( {...values, ['userType']: e.target.value} ) )//}else{//setInputs(values=>( {...values, ['userType']: "INVALID"} ) )//VALID.current=false//     }                            // }}
                            />
                          )}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>

                  {userSelectedType.trim().toUpperCase() !==
                    "Administration".toUpperCase() && (
                    <>
                      <TableRow>
                        <TableCell border="1px solid lightgray">
                          <Typography
                            style={{ color: "#2d187be0", fontWeight: 600 }}
                          >
                            Roles
                          </Typography>
                        </TableCell>
                        <TableCell border="1px solid lightgray">
                          <Stack name="USER ROLES" sx={{ width: "60ch" }}>
                            <Autocomplete
                              //inputValue = {userCurrentlySelectedSubType}
                              value={userCurrentlySelectedSubType} //disabled= {userSubType.length == 0} //isOptionEqualToValue={(option, value) => option.value === value.value}//required //label= "Type"
                              size="small"
                              disabled={action === "update"}
                              type={"search"}
                              isOptionEqualToValue={(option, value) =>
                                option === value
                              }
                              filterOptions={filterOptions}
                              onChange={(event, newValue) => {
                                DEBUG &&
                                  console.log(
                                    event.type,
                                    newValue === "" ? "no val" : newValue
                                  );
                                if (event.type === "change") return;
                                if (event.type === "click" && newValue == null)
                                  return;
                                if (userSelectedSubTypes.length == 0)
                                  singleSelection.current = 0; // DEBUG && console.log( singleSelection.current ) ///EBUG && console.log( userSelectedSubTypes )
                                try {
                                  if (
                                    userSelectedType == "Administration" ||
                                    userSelectedType == "Customer" ||
                                    userSelectedType == "Field Technician" ||
                                    userSelectedType == "Contractor"
                                  ) {
                                    singleSelection.current++;
                                  }
                                  //if(isSelected.length + 1 > userSubType.length) return;
                                  if (userSelectedSubTypes.includes(newValue))
                                    throw `The same value of '${newValue}' has already been entered`; //
                                  if (newValue == "") return;
                                  if (singleSelection.current > 1)
                                    throw `You can only select a sigle role for the ${userSelectedType} user type.`; //setUserCurrentlySelectedSubType(''
                                } catch (err) {
                                  setAlertTitle("WARNING:");
                                  setAlertText(err);
                                  setAlertBox(true);
                                  return;
                                } finally {
                                  //DEBUG && console.log('TEEESSSSSSTTTyyyyyyyyyyyyyyyyy')
                                  DEBUG && console.log(userSubType);
                                }

                                setUserSelectedSubTypes((d) => [
                                  ...d,
                                  newValue,
                                ]);
                                setInputs((values) => ({
                                  ...values,
                                  ["userSubType"]:
                                    newValue?.length > 0 ? newValue : "",
                                }));

                                if (
                                  userSelectedType.trim().toLowerCase() ==
                                  "Administration".trim().toLowerCase()
                                ) {
                                  setAccnt(newValue);
                                  setInputs((values) => ({
                                    ...values,
                                    ["account"]: newValue,
                                  })); // DEBUG && console.log('ZZZZZZZZZZZZZZZZZddddddddddddddddd    ', account)
                                  setUserSelectedSubTypes([account]);
                                } else {
                                  if (
                                    userSelectedType.trim().toLowerCase() ==
                                    "Contractor".trim().toLowerCase()
                                  ) {
                                    //userSubType.forEach((s)=> newValue == s.supplier && DEBUG && console.log(s.supplierId, s.supplier) )
                                    userSubType.forEach(
                                      (s) =>
                                        newValue == s.supplier &&
                                        setUserSelectedSubTypes([s.supplier])
                                    );
                                    userSubType.forEach(
                                      (s) =>
                                        newValue == s.supplier &&
                                        setInputs((values) => ({
                                          ...values,
                                          ["userSubType"]: s.supplierId,
                                        }))
                                    );
                                  } else if (
                                    userSelectedType.trim().toLowerCase() ==
                                    "Field Technician".trim().toLowerCase()
                                  ) {
                                    //userSubType.forEach((s)=> newValue == s.supplier && DEBUG && console.log(s.supplierId, s.supplier) )
                                    userSubType.forEach(
                                      (s) =>
                                        newValue == s.supplier &&
                                        setUserSelectedSubTypes([s.supplier])
                                    );
                                    userSubType.forEach(
                                      (s) =>
                                        newValue == s.supplier &&
                                        setInputs((values) => ({
                                          ...values,
                                          ["userSubType"]: s.supplierId,
                                        }))
                                    );
                                  } else if (
                                    userSelectedType.trim().toLowerCase() ==
                                    "Customer".toLowerCase()
                                  ) {
                                    userSubType.forEach(
                                      (s) =>
                                        newValue == s.supplier &&
                                        DEBUG &&
                                        console.log(s.customerName)
                                    ); //setUserSelectedSubTypes(['e', 'b', 'c','g'])
                                    userSubType.forEach(
                                      (s) =>
                                        newValue == s.supplier &&
                                        setUserSelectedSubTypes(s.customerName)
                                    );
                                    userSubType.forEach(
                                      (s) =>
                                        newValue == s.supplier &&
                                        setInputs((values) => ({
                                          ...values,
                                          ["userSubType"]: s.customerName,
                                        }))
                                    );
                                  } else if (
                                    userSelectedType.trim().toLowerCase() ==
                                    "Client".toLowerCase()
                                  ) {
                                    let tempArr = []; //DEBUG && console.log(userSubType)                                 //userSubType.forEach((s)=> DEBUG && console.log(s) )
                                    userSubType.forEach(
                                      (s) =>
                                        newValue == s.clientName &&
                                        tempArr.push(s.customerName)
                                    ); //DEBUG && console.log(tempArr)
                                    setUserSelectedSubTypes(tempArr); //['e', 'b', 'c','g'])                             //userSubType.forEach((s)=> newValue == s.supplier && setInputs(values=>( {...values, ['userSubType']: s.clientName} ) ))
                                  } else {
                                  }
                                  setAccnt(account);
                                  setInputs((values) => ({
                                    ...values,
                                    ["account"]: account,
                                  }));
                                }
                              }}
                              options={
                                userSelectedType == "Field Technician" ||
                                userSelectedType == "Contractor"
                                  ? userSubType.map((s) => s.supplier)
                                  : userSelectedType == "Client"
                                  ? filterEmpty(
                                      userSubType.map((s) =>
                                        filterArr(s.clientName)
                                      )
                                    )
                                  : userSubType
                              }
                              key={Math.floor(Math.random() * 10000)} //autoHighlight  //  getOptionLabel={(userType) => userType}    // renderOption={(props, option) => (// <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>  </Box>  // )}
                              renderInput={(params) => (
                                <TextField
                                  {...params} //inputProps = {{...params.inputProps, type: 'search', }}
                                  label="Roles" //+ userSelectedType +"'"}
                                  sx={{
                                    boxShadow: `0 0 2px 1px azure`,
                                    zIndex: 0,
                                  }} //required
                                  error={
                                    userSelectedSubTypes.toString() === ""
                                      ? true
                                      : false
                                  }
                                />
                              )}
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell border="1px solid lightgray">
                          <Typography
                            style={{ color: "#2d187be0", fontWeight: 600 }}
                          >
                            {userSelectedType === "Customer" ||
                            userSelectedType === "Client" ||
                            userSelectedType === "Manager"
                              ? userSelectedType === "Customer"
                                ? "Customer"
                                : "Customers"
                              : userSelectedType == "Senior Technician"
                              ? userSelectedSubTypes.length > 1
                                ? "States"
                                : "State"
                              : userSelectedType == "Field Technician"
                              ? "State"
                              : userSelectedType == "Contractor"
                              ? "Contractor"
                              : userSelectedSubTypes === ""
                              ? ""
                              : ""}
                          </Typography>
                        </TableCell>

                        <TableCell border="1px solid lightgray">
                          <Stack direction="column">
                            <RoleBox
                              userRoles={userSelectedSubTypes}
                              setRoles={setUserSelectedSubTypes}
                            />
                          </Stack>{" "}
                          {/* <text  style={{color:'#2d187be0', fontSize:14, fontWeight:400, fontFamily:'cursive'}}>{userSelectedSubTypes.toString() }</text> */}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </Stack>
          ) : (
            <Stack name="Table_ON_UPDATE">
              <Table
                caption={
                  <Stack
                    direction="row"
                    gap={2}
                    justifyContent="center"
                    backgroundColor="lightsteelblue"
                    border="1px solid lightgray"
                    paddingY={2}
                  >
                    <Typography color={"InfoText"} fontWeight={600}>
                      User:{" "}
                    </Typography>
                    <Typography color={"red"} fontWeight={600}>
                      {userFullName}
                    </Typography>
                  </Stack>
                }
                //size="small"
                highlightOnHover={true}
                variation="striped"
                backgroundColor={"antiquewhite"}
                width="80ch"
              >
                <TableBody>
                  <TableRow>
                    <TableCell border="1px solid lightgray">
                      <Typography
                        style={{ color: "#2d187be0", fontWeight: 600 }}
                      >
                        {" "}
                        Status
                      </Typography>
                    </TableCell>
                    <TableCell border="1px solid lightgray">
                      <Typography>
                        {inputs.active == 1 ? "Active" : "Not Active"}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell border="1px solid lightgray">
                      <Typography
                        style={{ color: "#2d187be0", fontWeight: 600 }}
                      >
                        {" "}
                        Loggin ID
                      </Typography>
                    </TableCell>
                    <TableCell border="1px solid lightgray">
                      <Typography>{userName}</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell border="1px solid lightgray">
                      <Typography
                        style={{ color: "#2d187be0", fontWeight: 600 }}
                      >
                        Cognito ID
                      </Typography>
                    </TableCell>
                    <TableCell border="1px solid lightgray">
                      <Typography> {subId}</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell border="1px solid lightgray">
                      <Typography
                        style={{ color: "#2d187be0", fontWeight: 600 }}
                      >
                        A8 ID{" "}
                      </Typography>
                    </TableCell>
                    <TableCell border="1px solid lightgray">
                      <Typography>{inputs.userId}</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell border="1px solid lightgray">
                      <Typography
                        style={{ color: "#2d187be0", fontWeight: 600 }}
                      >
                        Type{" "}
                      </Typography>
                    </TableCell>
                    <TableCell border="1px solid lightgray">
                      <Typography>{userSelectedType}</Typography>{" "}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell border="1px solid lightgray">
                      {" "}
                      <Typography
                        style={{ color: "#2d187be0", fontWeight: 600 }}
                      >
                        {" "}
                        Roles
                      </Typography>{" "}
                    </TableCell>
                    <TableCell border="1px solid lightgray">
                      {" "}
                      <Typography>{userSelectedSubTypes} </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell border="1px solid lightgray">
                      <Typography
                        style={{ color: "#2d187be0", fontWeight: 600 }}
                      >
                        Email{" "}
                      </Typography>
                    </TableCell>
                    <TableCell border="1px solid lightgray">
                      <Typography>{userEmail}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell border="1px solid lightgray">
                      <Typography
                        style={{ color: "#2d187be0", fontWeight: 600 }}
                      >
                        {inputs.passWord == "" || inputs.passWord == null
                          ? "Permanent Password"
                          : "Temporary Password"}
                      </Typography>
                    </TableCell>
                    <TableCell border="1px solid lightgray">
                      {" "}
                      <Typography>
                        {inputs.passWord == "" || inputs.passWord == null
                          ? "recorded in Cognito"
                          : verifyHashedPassword(inputs.passWord)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Stack>
          )}
        </Stack>

        <Stack
          name="BUTTONS"
          direction="row"
          sx={{ flex: "1 1 100%" }}
          justifyContent="center"
          alignItems="center"
          gap={6}
        >
          {action == "new" ? (
            <Button
              className="Button1"
              onClick={() => {
                const t_psw = generatePassword(8, true, true, true, true); // sendEmails (userEmail, process.env.REACT_APP_API_SUPPORT_EMAIL, 'Welcome to F8', userName, t_psw, '', '', '', '', '', 'newUser'))
                SIGNUP(t_psw); //successSignUp && sendEmails (userEmail, process.env.REACT_APP_API_SUPPORT_EMAIL, 'Welcome to F8', userName, t_psw, '', '', '', '', '', 'newUser');
              }} ///{SignUpUser}// variant="outlined"//height='100px'//size='large'// color="info"size='small'
              disabled={
                (subAttrib !== "EMPTY" && subAttrib.length > 0) ||
                userEmail == "" ||
                userName == "" ||
                userSelectedType == "" ||
                (userSelectedType !== "Administration" &&
                  userSelectedSubTypes.toString() == "")
                  ? true
                  : false
              }
              sx={{ fontSize: 11, width: "18ch", mt: "5ch" }} //fontWeight:800, fontSize:14, fontFamily:'cursive',
            >
              <Typography fontSize={12} fontWeight={800}>
                Invite
              </Typography>
            </Button>
          ) : (
            <Button
              className="Button1" //color={inputs.active ===1 && 'red'}   //disabled={inputs.passWord ==''  || inputs.passWord == null? false:true}
              sx={{ fontSize: 11, width: "18ch", mt: "5ch" }}
              onClick={() => {
                setIsOpen(true);
              }} ///{ConfirmSignUpUser}     // CONFIRM('confirm');//DEBUG && console.log(inputs.active) //handleAddActive(inputs.active===1? 0 : 1)
            >
              <Typography color="red" fontSize={12} fontWeight={800}>
                {inputs.active === 1 ? "Deactivate" : "Reactivate"}{" "}
              </Typography>
            </Button>
          )}

          <Button
            className="Button1"
            sx={{ fontSize: 11, width: "18ch", mt: "5ch" }}
            onClick={handleClose}
          >
            <Typography fontSize={12} fontWeight={800}>
              close{" "}
            </Typography>
          </Button>
        </Stack>

        <Box sx={{ height: 320, width: 5, flexGrow: 1 }}>
          <SpeedDial
            ariaLabel="SpeedDial"
            sx={{ position: "absolute", bottom: 10, right: 16 }}
            icon={<SpeedDialIcon />}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={() => {
                  if (action.name == "deactivate") {
                    setIsOpen(true);
                  }
                  if (action.name == "close") {
                    handleClose();
                  }
                  console.log(action.name);
                }}
              />
            ))}
          </SpeedDial>
        </Box>
      </Stack>
      {/* </Container> */}
    </Box>
  );
};
export default NewUser;

// {/* <Stack name='TEST_BUTTONS_TEST'
//                                     direction="row" className='stackButtons'
//                                     sx={{paddingTop:'30ch'}}
//                                 >
//                                     <Button
//                                         // className='Button1'
//                                         onClick={()=>generatePassword(8, true, true, true, true) }
//                                         variant="outlined"
//                                         size='small'
//                                         //style={{fontFamily:'cursive'}}
//                                         sx={{fontWeight:1000, fontSize:12, fontFamily:'cursive', borderRadius:'5px'}}
//                                     >gen password     </Button>

//                                     <Button
//                                         //className='Button1'  //onClick={SIGNUP}     ///{SignUpUser}// variant="outlined"//height='100px'//size='large'// color="info"size='small'
//                                         variant="outlined"
//                                         size='small'
//                                         sx={{fontWeight:1000, fontSize:12, fontFamily:'cursive', borderRadius:'5px'}} //fontWeight:800, fontSize:14, fontFamily:'cursive',
//                                         onClick={()=>changePassword('alexandr','Figure8_services','!Rachel_11?')} //curr !Rachel_11?'
//                                     >Test change Password
//                                     </Button>

//                                     <Button
//                                         // className='Button1'
//                                         onClick={()=>setAlertBox(true) }
//                                         variant="outlined"
//                                         size='small'
//                                         //style={{fontFamily:'cursive'}}
//                                         sx={{fontWeight:1000, fontSize:12, fontFamily:'cursive', borderRadius:'5px'}}
//                                     >Test MOD  </Button>

//                                 </Stack> */}

// {/* <Stack name='NAMES'
// direction="row"
// >
// {/* {action !=='update' &&
//     <>
//         <Stack name="User Name">
//             <TextField
//                 label="Full Name"
//                 value={userFullName} //required
//                 disabled = {action ==='update'} //{disLock}
//                 size='small'
//                 name ="name"
//                 style={{ height:'5ch', width:'60ch', boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, zIndex:0, }}
//                 error ={!VALID_fullName.current || userFullName === ''? true:false}
//                 helperText={userFullName===''?'': !VALID_fullName.current? "Name consists of alpha characters only!": ''}
//                 onChange={(e)=>{
//                     setUserFullName(e.target.value)                                        //{subAttrib !=='EMPTY' && subAttrib.length > 0 || action==='update'?true:false}
//                     action === 'new' && subAttrib.length === 0 && setUserName(e.target.value.trim().replaceAll(' ','').toLowerCase())
//                     setUserLength(e.target.value.length)
//                     VALID_fullName.current = validateField(e.target.value, notEmpty)
//                     setInputs(values=>({...values, ['userFullName']:  e.target.value}))
//                     setInputs(values=>({...values, ['name']: action === 'new' && !successSignUp && e.target.value.trim().replaceAll(' ','').toLowerCase() }  ))
//                 }}
//             />
//         </Stack>

//         <Stack name = "User LOGIN Name">
//             <TextField
//                 label="Login ID"
//                 sx={{boxShadow: `0 2px 6px  pink`, zIndex:0,  }}
//                 style={{ height:'5ch', width:'39ch'}}
//                 value={userName}
//                 disabled={true}
//                 size="small"
//             />

//         </Stack>
//     </>
// } */}
// </Stack>

// {action !=='update' &&
// <Stack name='EMAIL' direction="row">
//     {/* <TextField
//         label="Email"
//         disabled = {action ==='update'}
//         sx={{ boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, zIndex:0}}  //'& .MuiOutlinedInput-root': { borderColor: 'red', },
//         value={userEmail} //required
//         size="small"
//         name ="userEmail"
//         error ={ userEmail === '' || !VALID_email.current? true:false}                                      //InputProps={{ error: userEmail === '' || !VALID_email.current ? true:false,}}
//         helperText={userEmail===''?'': !VALID_email.current?'Email is invalid': ''}
//         style={{height:'5ch', width:'60ch'}}
//         onChange={(e)=>{
//             setUserEmail(e.target.value)
//             VALID_email.current = validateField(e.target.value, mailformat)
//             handleChange(e)
//         }}
//     />        */}
// </Stack>
// }

// <Stack name='TYPE/ROLES'
// direction="column"
// spacing={4}
// width='65ch'
// >
// {action !=='update'  &&

//     <>
//         {/* <Stack name='userSelectedType'>
//             <Autocomplete
//                 inputValue = {userSelectedType}
//                 value = {userSelectedType}
//                 disabled= {action ==='update'}
//                 isOptionEqualToValue={(option, value) => option.value === value.value}                                      //required                    //label= "Type"
//                 size = 'small'
//                 onInputChange={(event, newValue) => {
//                     setIsSelected([])
//                     setUserSelectedType(newInputValue)
//                     setInputs(values=>( {...values, ['userType']: (newInputValue.length>0)? newInputValue:'INVALID'} ) )    //getUserSubTypes(newInputValue)                                      //VALID_type.current=false;
//                     getUserSubTypes(newInputValue)
//                     setUserSelectedSubTypes([])   ///    userSelectedSubTypes
//                     setInputs(values=>( {...values, ['userSubType']:  ""} ) )
//                     setUserCurrentlySelectedSubType('')
//                 }}
//                 options={userType} //autoHighlight  //getOptionLabel={(userType) => userType}// renderOption={(props, option) => (// <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>  </Box>  // )}
//                 renderInput={(params) => (
//                     <TextField
//                         {...params}
//                         label = "Type"
//                         //defaultValue={"Select the type of the user"}
//                         sx={{boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, zIndex:0,}}
//                         style={{height:'5ch', width:'60ch'}}
//                         error = {userSelectedType === ''? true:false}//required   //error = {userSelectedType === ''? true:false}//value = {userSelectedType}// inputProps={{...params.inputProps}}//,  autoComplete: 'new-password', // disable autocomplete and autofill     // }}
//                         //helperText= { userSelectedType===''?"Select value":""}// onBlur={(e)=>{//if (e.target.value.length>0){//setInputs(values=>( {...values, ['userType']: e.target.value} ) )//}else{//setInputs(values=>( {...values, ['userType']: "INVALID"} ) )//VALID.current=false//     }                            // }}
//                     />

//                 )  }
//             />
//         </Stack>  */}

//         {/* <Stack name='userSelectedType'>
//             <Autocomplete
//                 inputValue = {userCurrentlySelectedSubType}
//                 value = { userCurrentlySelectedSubType} //disabled= {userSubType.length == 0} //isOptionEqualToValue={(option, value) => option.value === value.value}//required //label= "Type"
//                 size = 'small'
//                 disabled= {action ==='update'}
//                 isOptionEqualToValue={(option, value) => option.id === value.id}
//                 onInputChange={(event, newInputValue) => {
//                     if(isSelected.length + 1 > userSubType.length) return;
//                     if(userSelectedSubTypes.includes(newInputValue)) return;
//                     setUserSelectedSubTypes(d=>[...d, newInputValue])                                 //setUserCurrentlySelectedSubType(''
//                     setInputs(values=>( {...values, ['userSubType']: newInputValue.length>0 ? newInputValue : ""} ) ) // }else{ //     setInputs(values=>( {...values, ['userSubType']: "INVALID"} ) )// }
//                     if (userSelectedType.trim().toLowerCase() =='Administration'.trim().toLowerCase()){
//                         setAccnt(newInputValue)
//                         setInputs(values=>( {...values, ['account']: (newInputValue)} ) )
//                     } else{
//                         setAccnt(account)
//                         setInputs(values=>( {...values, ['account']: (account)} ) )
//                     }
//                 }}
//                 options={userSubType}                      //autoHighlight  //  getOptionLabel={(userType) => userType}    // renderOption={(props, option) => (// <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>  </Box>  // )}
//                 renderInput={(params) => (
//                     <TextField
//                         {...params}
//                         label = { "Roles " }//+ userSelectedType +"'"}
//                         sx={{boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, zIndex:0,}}
//                         style={{height:'5ch', width:'60ch'}}
//                         //required
//                         error = {userSelectedSubTypes.toString() === ''? true:false}

//                     />

//                 )  }
//             />
//         </Stack>     */}
//     </>

// }

// </Stack>    */}

// {/* <Stack name='BLUE_BOX'
// direction="row"
// border={userSelectedType !=='' ?"2px solid blue":"2px solid transparent" }
// padding={2}
// height={userSelectedType !=='' ?"40ch":"1ch" }
// width='105ch'
// sx={{  zIndex:0,}} //
// boxShadow={userSelectedType !=='' && `0 0 6px 2px rgba(0, 0, 0, 0.3)`} ///=
// onClick={testing}
// >

//     {action !=='update' ?
//         <Stack name='FIELDS_ON_ADD' direction ='column'>

//             {/* <Stack sx={{mb:5 }}>
//                 {userSelectedType !=='' ?
//                     <text style={{fontSize:14, fontFamily:'cursive', fontWeight:400}}>
//                         You have selected type
//                         <text style={{fontSize:16, fontFamily:'cursive', fontWeight:600}}> {userSelectedType} </text>
//                          for the user
//                          <text style={{fontSize:16, fontFamily:'cursive', fontWeight:600}}> {userName} </text>
//                     </text>
//                     :''
//                 }
//             </Stack> */}

//             {/* <Stack sx={{ mb:1.5 }}>
//                 {userSelectedSubTypes.length>0 ?
//                     <text style={{fontSize:14, fontFamily:'cursive', fontWeight:400}}>You have selected following roles:</text>
//                     :''
//                 }
//             </Stack> */}

//             {/* <Stack direction="row">
//                 <RoleBox userRoles={userSelectedSubTypes} setRoles ={setUserSelectedSubTypes}  />
//             </Stack> */}

//         </Stack>

//     :
//         <Stack name='Table_ON_UPDATE'
//             direction='column'
//             gap={1}                         //style={{width:'100ch',}}
//             sx={{flex: '1 1 100%',  justifyContent:'center', alignContent:'flex-start' ,  boxShadow: `1px 1px 3px 1px rgba(0, 0, 0, 0.3)`,  borderRadius:'5px'}}
//         >
//             {/* {DEBUG && console.log(userSelectedSubTypes.split(','))} */}
//             <Table
//                 caption={<Stack paddingY={2}><Typography color='#2d187be0' fontSize={20} fontWeight={600} fontFamily={'cursive'}>User: {userFullName}</Typography></Stack>}
//                 size="small"
//                 highlightOnHover={true}
//                 variation="striped"
//                 backgroundColor={'antiquewhite'}

//             >

//                 <TableRow height='5ch'>

//                     <TableCell width='35ch'><text  style={{color:'#2d187be0', fontSize:14, fontWeight:600, fontFamily:'cursive'}}> Status</text></TableCell>
//                     <TableCell><text  style={{color:'#2d187be0', fontSize:14, fontWeight:400, fontFamily:'cursive'}}>{inputs.active ==1 ?'Active':'Not Active'}</text></TableCell>
//                 </TableRow>

//                 <TableRow height='5ch'>

//                     <TableCell><text  style={{color:'#2d187be0', fontSize:14, fontWeight:600, fontFamily:'cursive'}}> Loggin ID</text></TableCell>
//                     <TableCell><text  style={{color:'#2d187be0', fontSize:14, fontWeight:400, fontFamily:'cursive'}}>{userName}</text></TableCell>
//                 </TableRow>

//                 <TableRow height='5ch'>

//                     <TableCell><text  style={{color:'#2d187be0', fontSize:14, fontWeight:600, fontFamily:'cursive'}}>Cognito ID</text></TableCell>
//                     <TableCell><text  style={{color:'#2d187be0', fontSize:14, fontWeight:400, fontFamily:'cursive'}}> {subId}</text></TableCell>

//                 </TableRow>

//                 <TableRow  height='5ch'>

//                     <TableCell><text  style={{color:'#2d187be0', fontSize:14, fontWeight:600, fontFamily:'cursive'}}>A8 ID </text></TableCell>
//                     <TableCell><text  style={{color:'#2d187be0', fontSize:14, fontWeight:400, fontFamily:'cursive'}}>{inputs.userId}</text></TableCell>
//                 </TableRow>

//                 <TableRow height='5ch'>

//                     <TableCell><text  style={{color:'#2d187be0', fontSize:14, fontWeight:600, fontFamily:'cursive'}}>Type </text></TableCell>
//                     <TableCell><text  style={{color:'#2d187be0', fontSize:14, fontWeight:400, fontFamily:'cursive'}}>{userSelectedType}</text> </TableCell>
//                 </TableRow>

//                 <TableRow height='5ch'>

//                     <TableCell><text  style={{color:'#2d187be0', fontSize:14, fontWeight:600, fontFamily:'cursive'}}>Roles</text></TableCell>
//                     <TableCell> <text  style={{color:'#2d187be0', fontSize:14, fontWeight:400, fontFamily:'cursive'}}>{userSelectedSubTypes} </text></TableCell>
//                 </TableRow>

//                 <TableRow height='5ch'>

//                     <TableCell><text  style={{color:'#2d187be0', fontSize:14, fontWeight:600, fontFamily:'cursive'}}>Group  </text></TableCell>
//                     <TableCell><text  style={{color:'#2d187be0', fontSize:14, fontWeight:400, fontFamily:'cursive'}}>{group}</text> </TableCell>
//                 </TableRow>

//                 <TableRow height='5ch'>

//                     <TableCell><text  style={{color:'#2d187be0', fontSize:14, fontWeight:600, fontFamily:'cursive'}}>Email </text></TableCell>
//                     <TableCell><text  style={{color:'#2d187be0', fontSize:14, fontWeight:400, fontFamily:'cursive'}}>{userEmail}</text></TableCell>
//                 </TableRow>

//                 <TableRow height='5ch'>

//                     <TableCell ><text  style={{color:'#2d187be0', fontSize:14, fontWeight:600, fontFamily:'cursive'}}>Password</text></TableCell>
//                     <TableCell > <text  style={{color:'#2d187be0', fontSize:14, fontWeight:400, fontFamily:'cursive'}}> {inputs.passWord !=='' && inputs.passWord !==null ? inputs.passWord :'Password has been set'}</text>  </TableCell>
//                 </TableRow>
//             </Table>
//         </Stack>
//     }

// </Stack>  */}

//1 Go to Amazon Web Services console and click on the name of your account
//(it is located in the top right corner of the console). Then, in the expanded drop-down list,
//select Security Credentials.
//2 Click the Continue to Security Credentials button.
//3 Expand the Access Keys (Access Key ID and Secret Access Key) option.

// const Config = {
//     accessKeyId: process.env.AWS_SECRET_KEY,
//     accessSecretKey: process.env.AWS_SECRET_KEY,
//     region: "ap-southeast-2"
// }
// AWS.config = Config //AWS.config.region = 'ap-southeast-2';

// import Hidden from '@mui/material/Hidden';
// import MenuItem from '@mui/material/MenuItem';
// import InputAdornment from '@mui/material/InputAdornment';// import AccountCircle from '@mui/icons-material/AccountCircle';
// import InputLabel from '@mui/material/InputLabel';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import IconButton from '@mui/material/IconButton';
// import FormControl from '@mui/material/FormControl';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import {FormDialog} from '../../util/CountryProvider'
// import Select from '@mui/material/Select'; //import FormGroup  from '@mui/material/FormGroup';
//import Switch  from '@mui/material/Switch';
//import CountrySelect from '../../util/Functions.jsx'
// import {UserLogInContext} from '../../App'
// import '@aws-amplify/ui-react/styles.css';
// import * as AWS from 'aws-sdk';
// import {useAuthenticator} from '@aws-amplify/ui-react';
//import config from '../../aws-exports';
// const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

// const onBlur1 = (e) => {
//     //e.preventDefault();

//     setInputRefValue(inputRef.current.value);
//     DEBUG && console.log(inputRef.current.value);
//     DEBUG && console.log(e.target.value)
//     DEBUG && console.log(dialCodeRef.current)
//     DEBUG && console.log(`(${dialCode}) ${phoneNum}`)
//     DEBUG && console.log(`(${dialCodeRef.current}) ${inputRef.current.value}`)
//     // dialCodeRef.current.valueOf('+64')
//     // DEBUG && console.log(dialCode)// DEBUG && console.log( dialCode + " "+ phoneNum)/setPhoneNum(inputRef.current.value);/setInputs(values=>({...values, ['phoneNumber']: `${dialCodeRef.current.value} ${phoneNum}`}))//DEBUG && console.log(e.target.value.match(phonenum))
//     if(phoneNum.match(phoneNum) !== null){
//         DEBUG && console.log(' onBlur if ',  dialCode +  ' === ' + phoneNum )
//         // setInputs(values=>({...values, ['phoneNumber']: `(${dialCode})${phoneNum}`}))
//         // setInputs(values=>({...values, ['active']:1}))
//         setInputs(values=>({...values, ['phoneNumber']: `(${dialCodeRef.current}) ${inputRef.current.value}`}))
//     }else{
//         DEBUG && console.log(' onBlur else ',  dialCode +  ' -- ' + phoneNum )
//         setPhoneNum('');
//         setDialCode('');
//         setInputs(values=>({...values, ['phoneNumber']: ``}))
//     }
//     // alert(`Phone number submitted with form: ${e.target.value}`);
// };

// const isPhoneNumberValid=(e)=>{phoneNum.match(phonenum) !== null ? true :false}

// function (result) {
//     const accessToken = result.getAccessToken().getJwtToken();
//     //DEBUG && console.log(accessToken)nn
//     /* Use the idToken for Logins Map when Federating User Pools with identity pools or when passing through an Authorization Header to an API Gateway Authorizer */
//     const idToken = result.idToken.jwtToken;
//     DEBUG && console.log(idToken)
// },

//     '& label.Mui-focused': {color: 'green', fontFamily:'cursive'},
//     '& .MuiInput-underline:after': {borderBottomColor: 'green', fontFamily:'cursive'},

//     '& .MuiOutlinedInput-root': {
//        '& fieldset': {borderColor: 'green', fontFamily:'cursive'},
//        '&:hover fieldset': {borderColor: 'yellow', fontFamily:'cursive'},
//        '&.Mui-focused fieldset': {borderColor: 'green', fontFamily:'cursive'},
//    }
//error ={!VALID_businesParther.current || businesspartner === ''? true:false}
//InputProps={{ error: !VALID.current || businesspartner ==='',}}
//helperText={businesspartner===''?"Required*":""}

// && loaded
// if (action ==='update' && loaded){
//     // getDataForUpdate()
//     setLoaded(false)  // }

//{/* {
// <Stack sx={{ mt:5 }} >
//     <Typography style={{color: 'red'}} variant="div" fontColor='red' fontWeight={400}>
//         <ul>
//             {errorFields.length>0?'Error in:':'' }
//             {errorFields.length>0?errorFields.map((d)=><li>{d}</li>):'' }
//         </ul>
//     </Typography>
// </Stack>

// } */}

// {/* <Typography
//                 sx={{ width:'10ch', }} // flex: '1 1 100%', //, width:'15ch'
//                 variant="div"
//                 //className='onefive'
//                 fontSize={18}
//                 fontWeight={700}
//                 fontFamily={'cursive'}
//                 border='1px solid blue'
//             >     </Typography>  */}
//return (responseData || "test");
// DEBUG && console.log(keys)
// DEBUG && console.log(userData)
// const a={[keys[2]]:data[keys[2]]}
// DEBUG && console.log(data[keys[2]])
// DEBUG && console.log(a)
//window.location.reload()
// const b= Date.now()
// DEBUG && console.log( uuidv4(b))
// DEBUG && console.log(Date())
// DEBUG && console.log(typeof Date())

// {/*
//                 <Stack direction="row" width ='320px' spacing={1}>
//                     <TextField
//                         label={action === 'update' ?
//                                 inputs['passWord'] === undefined || inputs['passWord'] === null || inputs['passWord'] === 'removed'|| inputs['passWord'] === 'Removed'?
//                                 `Password is NOT set!`:
//                                 testPW.length>0 ? testPW: `Password is set. `
//                                 :
//                                 `Enter Valid Password`
//                         }

//                         //type={hashPassWord ==='removed'|| hashPassWord==='Removed'? 'text' : "password"}

//                         // error ={ action === 'update' ?
//                         //     inputs['passWord'] === undefined || inputs['passWord'] === null || inputs['passWord'] === 'removed'|| inputs['passWord'] === 'removed'?
//                         //     true :
//                         //     testPW.length>0 ? testPW:  false:
//                         //     true}
//                         //defaultValue={hashPassWord}
//                         autoComplete="off"
//                         value={action==='update'? hashPassWord:passWord}
//                         required={true}
//                         name ="passWord"
//                         // InputProps={{
//                             //endAdornment: <InputAdornment position="end"><VisibilityIcon/></InputAdornment>,
//                           //}}
//                         //ref={passwordInputRef}
//                         //InputProps={{error: !VALID.current}}
//                         helperText={
//                             action === 'update' ?
//                                 inputs['passWord'] === undefined || inputs['passWord'] === null || inputs['passWord'] === 'removed'|| inputs['passWord'] === 'removed'?
//                                 `Password is NOT set!`:
//                                  testPW.length>0 ? testPW: `Password is set. `
//                                  :
//                                 `Please Enter Valid Password`
//                         }
//                         onChange={(e)=>{action === 'update'?setHashPassWord(e.target.value):setPassWord(e.target.value)} }
//                             // if(action === 'update'){
//                             //     setHashPassWord(e.target.value)
//                             //     //toHashPassword(e.target.value, true)
//                             //     // verifyPassword(hashPassWord, e.target.value)
//                             //     // setPassWord(e.target.value)

//                             // }else{
//                             //    // toHashPassword(e.target.value, true)
//                             //     setPassWord(e.target.value)
//                             //     //VALID.current = validateField(e.target.value, '')

//                             // }
//                             // setInputs(values=>({...values, ['passWord']: e.target.value.trim() ==='' ?"removed":hashPassWord, isValidated: true,}));

//                         onBlur={(e)=>{
//                                 //DEBUG && console.log(e.target.value)
//                                 toHashPassword(e.target.value)
//                             }
//                         }
//                     />
//             </Stack> *}

//     //VERIFY PASSWORD
//     //const verifyPassword = (hashedPassWord, PassWord)=>{
//         // bcrypt.compare(PassWord, hashedPassWord, function(err, res) {
//         //     //DEBUG && console.log(PassWord)
//         //         //res === true
//         //     //setPassWord(res)
//         //     DEBUG && console.log( res)
//         //     res ? setTestPW("ENTERED PASSWORD IS CURRENT"):setTestPW('Changing Password ...')
//         //     toHashPassword(PassWord, !res)
//         //     //DEBUG && console.log(process.env.REACT_APP_PA)
//         // }
//         // );

//         // DEBUG && console.log(pw)
//         // const bytes  = CryptoJS.AES.decrypt(pw, process.env.REACT_APP_SECRET_KEY);
//         // const originalText = bytes.toString(CryptoJS.enc.Utf8);
//         // DEBUG && console.log(originalText); // 'my message'
//     //}

// //{/* <Link to={{pathname: `/users`}} style={{ textDecoration: "none" }}  > */}

//   <Stack>
//                         <Autocomplete
//                             filterSelectedOptions
//                             //multiple
//                             getOptionLabel={(option) => option}
//                             // filterSelectedOptions
//                             // freeSolo
//                             // includeInputInList
//                             //getOptionLabel={(option) => option}
//                             //inputValue={userSelectedType}
//                             //value={userSelectedType}
//                            // required
//                            // onInputChange={(event, newInputValue) => {
//                                 //setUserSelectedType(newInputValue)
//                                 // setInputs(values=>(
//                                 //             {...values, ['userType']: newInputValue, isValidated: true,}
//                                 //             )
//                                 //         )

//                                   //  }
//                           //  }
//                           id="tags-outlined"
//                             options={userType}
//                             //autoHighlight

//                             // getOptionLabel={(userType) => userType}
//                             // renderOption={(props, option) => (
//                             // <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
//                             // </Box>
//                             // )}
//                             renderInput={(params) => (

//                                 <Stack direction="row" width ='250px'>
//                                     {DEBUG && console.log(params)}

//                                     <TextField
//                                         {...params}
//                                         //variant="filled"
//                                         label= "Test Type"
//                                         onChange={()=>{
//                                                 DEBUG && console.log("Test");
//                                                 setTest(params.inputProps.value);
//                                             }
//                                         }
//                                         onBlur={()=>{
//                                                 DEBUG && console.log("Test")
//                                                 setTest(params.inputProps);
//                                             }
//                                         }
//                                         //value={userSelectedType}
//                                         // inputProps={{...params.inputProps}}//,  //     autoComplete: 'new-password', // disable autocomplete and autofill     // }}
//                                         //helperText= {!VALID.current || userSelectedType===''?"Required field, select some value":""}

//                                     />
//                                 </Stack>
//                             )}
//                         />
//                  </Stack>

/////////////////

// <TextField
// label="Business Contact Number"
// defaultValue={contactNumberBusness}
// required
// name ="businessContactNumber"
// InputProps={{ error: !validatedBCN.current || contactNumberBusness ==='',}}
// helperText={contactNumberBusness===''?"Please populate this field. This is a required field":!validatedBCN.current?"Business Contact Number is invalid":''}
// // sx = {{ width: 700 }}
// onChange={(e)=>{
//     setContactNumberBusness(e.target.value)
//     validatedBCN.current = validateField(e.target.value, phonenum)
//     handleChange(e, validatedBCN.current)
// }}
// />

// const validatedUN =useRef(false)
// const validatedUFN =useRef(false)
// const validatedUEmail =useRef(false)
// const validatedPassword =useRef(false)
// const validatedEmail =useRef(false)
// const validateContName = useRef(true)
// const validateContEmail = useRef(true)
// const validateContPhone = useRef(true)

//    //     if (earched==""){
//       return true
//     }else{
//       return inputText.match(searched)? true: inputText.length==0?true: false
//     }
//  }

//sx={{
// '& .MuiTextField-root': {m: 1, width: '65ch', height: '10ch'},
// display: 'flex',
// flexDirection: { xs: 'column', md: 'column' },
// width: {
//     xs: 450, // theme.breakpoints.up('xs')
//     sm: 600, // theme.breakpoints.up('sm')
//     md: 900, // theme.breakpoints.up('md')
//     lg: 1200, // theme.breakpoints.up('lg')
//     xl: 1300, // theme.breakpoints.up('xl')
//   },
// alignItems: 'center',
// bgcolor: 'background.paper',
// overflow: 'hidden',
// boxShadow: 2,
// borderRadius: 1,
// p: 5,
//}}

//const [emailErr, setEmailErr] = useState(false);
//const [pwdError, setPwdError] = useState(false);
// const validated =useRef({
//                         isBN:false,
//                         isRBN:false,
//                         isABN:false,
//                         isBCN:false,
//                         isEmail:false,
//                         isContName:false,
//                         isContEmail:false,
//                         isContPhone:false
//                     })

// setInputs(values=>({...values, ['name']: data.name, isValidated: true,}));
// setInputs(values=>({...values, ['userFullName']: data.userFullName, isValidated: true,}));
// setInputs(values=>({...values, ['userType']: data.userType, isValidated: true,}));
// setInputs(values=>({...values, ['passWord']: data.passWord, isValidated: true,}));
// setInputs(values=>({...values, ['userEmail']: data.userEmail, isValidated: true,}));
// setInputs(values=>({...values, ['userSupplierId']: data.userSupplierId, isValidated: true,}));
// setInputs(values=>({...values, ['business_partner']: data.business_partner||'', isValidated: true,}));
// setInputs(values=>({...values, ['subcustomer_id']: data.subcustomer_id, isValidated: true,}));
// setInputs(values=>({...values, ['extraIds']: data.extraIds, isValidated: true,}));
// setInputs(values=>({...values, ['userCustomerId']: data.userCustomerId, isValidated: true,}));
// setInputs(values=>({...values, ['extraPrivalages']: data.extraPrivalages, isValidated: true,}));
// setInputs(values=>({...values, ['resetCode']: data.resetCode, isValidated: true,}));
// setInputs(values=>({...values, ['active']: data.active, isValidated: true,}));

//SETUPP HASH
// const toHashPassword=(pw)=>{
//     // bcrypt.genSalt(10, function(err, salt) {
//     //     //DEBUG && console.log(passWord)
//     //     bcrypt.hash(pw, salt, function(err, hash) {

//     //         //setInputs(values=>({...values, ['passWord']: hash, isValidated: true,}));
//     //         flag ? setInputs(values=>({...values, ['passWord']: pw.trim() ==='' || pw.trim() ==='removed'? "removed": hash, isValidated: true,})):
//     //          setInputs(values=>({...values, ['passWord']: pw.trim() ==='' || pw.trim() ==='removed'? "removed": hashPassWord, isValidated: true,}))

//     //          //DEBUG && console.log(inputs)
//     //          //DEBUG && console.log(pw)
//     //         //setHashPassWord('')
//     //         //VALID.current = true ///

//     //         });
//     //      });
//     const ciphertext = CryptoJS.AES.encrypt(pw, process.env.REACT_APP_SECRET_KEY).toString();
//     DEBUG && console.log(ciphertext);
//     setInputs(values=>({...values, ['passWord']: pw.trim() ==='' || pw.trim() ==='removed'? "removed": ciphertext, isValidated: true,}))//:
//      //setInputs(values=>({...values, ['passWord']: pw.trim() ==='' || pw.trim() ==='removed'? "removed": hashPassWord, isValidated: true,}))
// }

//const CryptoJS = require("crypto-js");
// Encrypt //
// const ciphertext = CryptoJS.AES.encrypt('testMessage', process.env.REACT_APP_SECRET_KEY).toString();

// Decrypt
// const bytes  = CryptoJS.AES.decrypt(ciphertext, process.env.REACT_APP_SECRET_KEY);
// const originalText = bytes.toString(CryptoJS.enc.Utf8);

// DEBUG && console.log(ciphertext);
// DEBUG && console.log(originalText); // 'my message'

//import { ContactPageSharp } from '@mui/icons-material';
// import '../../components/newitem/new.scss'

// {/* <TextField
//                                 label="User Name"
//                                 value={userName}
//                                 //defaultValue={userName}
//                                 size='small'
//                                 required
//                                 error ={userName === '' || !VALID_name.current ? true : false}
//                                 name ="name"
//                                 //InputProps={{error: !VALID.current || userName === ''? true:false}}
//                                 InputProps={{
//                                     readOnly: action === 'update'?true:false,
//                                     startAdornment: (
//                                     <InputAdornment position="start">
//                                         <AccountCircle />
//                                     </InputAdornment>
//                                     ),
//                                 }}
//                                 //helperText={ userName === ''|| !VALID_name.current ? "Required*":""}
//                                 helperText={userName ===''?'Provide value for the Client Name': !VALID_name.current? "Proided value is invalid": ''}
//                                 onChange={(e)=>{
//                                     setUserName(e.target.value.trim().replaceAll(' ',''))
//                                     VALID_name.current = validateField(e.target.value, notEmpty)
//                                     setInputs(values=>({...values, ['name']: e.target.value.trim().replaceAll(' ','')}))
//                                     handleChange(e)
//                                 }}
//                                 ///onBlur={(e)=>{DEBUG && console.log(e.target.value.trim().replaceAll(' ',''))}}
//                             />   */}

//                 {/* <Stack  direction="row"  width ='220px' >
//                     <TextField
//                         label="Supplier ID"
//                         //defaultValue = {userSupplierId}
//                         value={userSupplierId}
//                         name ="userSupplierId"
//                         required
//                         size="small"
//                         error ={!VALID_suplierID.current || userSupplierId === ''? true:false}
//                         //InputProps={{ error: userSupplierId ==="" ? true : !VALID.current? true:false}}
//                         helperText={ userSupplierId===''? "Required* range 0 - 999":!VALID_suplierID.current  ?"Invalid Input":'' }
//                         onChange={(e)=>{
//                             setUserSupplierId(e.target.value)
//                             VALID_suplierID.current = validateField(e.target.value, tripplDigit)
//                             handleChange(e)
//                         }}
//                     />
//                  </Stack>  */}

//                  {/* <Stack  direction="row" width ='220px' >
//                         <TextField
//                             label="User Customer ID"
//                             //defaultValue={userCustomerId}
//                             value={userCustomerId}
//                             required
//                             size="small"
//                             name ="userCustomerId"
//                             error ={!VALID_userCustomerID.current || userCustomerId === ''? true:false}
//                             //InputProps={{error: userCustomerId ==="" ? true :!VALID.current? true:false}}
//                             helperText={userCustomerId ==='' ? "Required* range 0 - 9":!VALID_userCustomerID.current ?"Invalid Input":''}
//                             onChange={(e)=>{
//                                 setUserCustomerId(e.target.value)
//                                 VALID_userCustomerID.current = validateField(e.target.value, singleDigit)
//                                 handleChange(e)
//                             }}
//                         />
//                    </Stack> */}

//                     {/* <Stack  direction="row" width ='220px'>
//                         <TextField
//                             label="Extra Privalages"
//                             //defaultValue={extraPrivalages}
//                             value={extraPrivalages}
//                             required
//                             size="small"
//                             name ="extraPrivalages"
//                             error ={ !VALID_extraPrival.current|| extraPrivalages === ''? true:false}
//                             //InputProps={{error: extraPrivalages ==="" ? true :!VALID.current? true:false}}
//                             helperText={extraPrivalages ==='' ? "Required* range 0 - 10":!VALID_extraPrival.current ? "Invalid Input":''}
//                             onChange={(e)=>{
//                                 setExtraPrivalages(e.target.value)
//                                 VALID_extraPrival.current = validateField(e.target.value, zeroToten)
//                                 handleChange(e)
//                             }}
//                         />
//                     </Stack> */}
// {/*
//                     <Stack  direction="row" width ='220px'>
//                         <TextField
//                             label="Reset Code"
//                             required
//                             size="small"
//                             //defaultValue={resetCode}
//                             value={resetCode}
//                             name ="resetCode"
//                             error ={!VALID_resetCode.current || resetCode === ''? true:false}
//                             //InputProps={{error: resetCode ==="" ? true :!VALID.current? true:false}}
//                             helperText={resetCode ==='' ?"Required* range 0 - 9999999":!VALID_resetCode.current ?"Invalid Input":''}
//                             onChange={(e)=>{
//                                 setResetCode(e.target.value)
//                                 VALID_resetCode.current = validateField(e.target.value, Numbers)
//                                 handleChange(e)
//                             }}
//                         />
//                     </Stack> */}

//                     {/* <Stack direction="row" width ='205px'>
//                         <TextField
//                             label="Sub Customer ID"
//                             //defaultValue={subcustomerid}
//                             value={subcustomerid}
//                             required
//                             size="small"
//                             name ="subcustomer_id"
//                             error ={!VALID_subCustomerID.current || subcustomerid === ''? true:false}
//                             ///InputProps={{error: subcustomerid ==="" ? true :!VALID.current? true:false}}
//                             helperText={subcustomerid ==='' ?"Required* range 0 - 99":!VALID_subCustomerID.current ?"Invalid Input":''}
//                             onChange={(e)=>{
//                                 setSubcustomerid(e.target.value)
//                                 VALID_subCustomerID.current= validateField(e.target.value, fromZeroTo99)
//                                 handleChange(e)
//                             }}
//                         />
//                     </Stack>  */}

//  {/* <InputLabel htmlFor="password" sx={{paddingTop:1, width:'8ch', fontFamily:'cursive', fontSize:18, fontWeight:700}}>  Password */}
//                         {/* {
//                             action === 'update' ?
//                             inputs['passWord'] === undefined || inputs['passWord'] ===null || inputs['passWord'] ===''||inputs['passWord'] ==='removed'?
//                             `Password is NOT set`:
//                             testPW.length>0 ? testPW: `Password is set`:
//                             `Please Enter Valid Password`
//                         }  */}
//                     {/* </InputLabel> */}

//             {/* <Stack direction="row" width ='500' >
//                 <TextField
//                     label="Extra IDs"
//                     //defaultValue={extraIds}
//                     value={extraIds}
//                     required
//                     size="small"
//                     name = 'extraIds'
//                     error ={!VALID_extraID.current || extraIds === '' ? true : false}
//                     //InputProps={{ error: extraIds ==="" ? true :!VALID.current? true:false}}
//                     helperText={ extraIds==='' ?"Required* numeric values separated by comma/s 0,1,22,333,4444,55555,...":!VALID_extraID.current ?"Invalid Input":''}
//                     // sx = {{ width: 700 }}
//                     onChange={(e)=>{
//                         setExtraIds(e.target.value)
//                         VALID_extraID.current  = validateField(e.target.value, extraIDs)
//                         handleChange(e)
//                     }}
//                 />
//             </Stack>
//  */}

// onChange={(e)=>{
//     if(e.target.checked){
//         setActive(1)
//         setInputs(values=>({...values, ['active']:1}))
//     }else{
//         ///e.target.checked=false
//         setActive(0)
//         setInputs(values=>({...values, ['active']:0}))
//     }
//   }

//<TextField
//label="Authentication Code"
// sx={{'& label.Mui-focused': {color: 'green', fontFamily:'cursive'},
//      '& .MuiInput-underline:after': {borderColor: 'green', fontFamily:'cursive'},
//      '& .MuiOutlinedInput-root': {
//         '& fieldset': {borderColor: 'green', fontFamily:'cursive'},
//         '&:hover fieldset': {borderColor: 'yellow', fontFamily:'cursive'},
//         '&.Mui-focused fieldset': {borderColor: 'green', fontFamily:'cursive'},//'&:hover': {  },
//     }
// }}

// value={verificationCode}                //defaultValue={userName}
// size='small'
// onChange={(e)=>{setVerificationCode(e.target.value.trim().replaceAll(' ','')) }}
//></TextField>

//                     <Stack              //**  Role drop down box
//                         direction="row"
//                         sx={{flex: '1 1 100%', justifyContent:'flex-start', alignItems:'flex-start' , height:'10ch'}}
//                         spacing={1.5}
//                         width='115ch'
//                         gap={.6}
//                         paddingTop={0}
//                         paddingLeft={12}               // border="1px solid red "
//                     >

//                         <FormControl  sx={{width:'34ch'}}>
//                             <InputLabel htmlFor="Role" sx={{paddingTop:0, fontFamily:'cursive', fontSize:16, fontWeight:600}}> Role</InputLabel>
//                             <Select
//                                 labelId="Role"
//                                 //id="role"
//                                 value={role}
//                                 onChange={handleChangeRole}
//                                 label="Role"
//                                 size='small'
//                             >
//                                 <MenuItem value="" sx={{paddingTop:0, fontFamily:'cursive', fontSize:16, fontWeight:600}}><em>None</em></MenuItem>
//                                 <MenuItem value={'tech'} sx={{paddingTop:0, fontFamily:'cursive', fontSize:16, fontWeight:600}}>Tech</MenuItem>
//                                 <MenuItem value={"tech2"} sx={{paddingTop:0, fontFamily:'cursive', fontSize:16, fontWeight:600}}>Tech2</MenuItem>
//                                 <MenuItem value={'Customer'} sx={{paddingTop:0, fontFamily:'cursive', fontSize:16, fontWeight:600}}>Customer</MenuItem>
//                             </Select>
//                         </FormControl>

//                         <FormControl  sx={{ m: 1, width: '35ch'}}>
//                             <InputLabel htmlFor="custrole" sx={{paddingTop:0, fontFamily:'cursive', fontSize:16, fontWeight:600}}
//                             > Customer Role </InputLabel>
//                             <Select
//                                 labelId="CustRole"
//                                 id="custrole"
//                                 value={customerrole}
//                                 onChange={handleChangeCustomerRole}
//                                 label="Custmer Roles"
//                                 size='small'
//                             >
//                                 <MenuItem value= "" sx={{paddingTop:0, fontFamily:'cursive', fontSize:16, fontWeight:600}}><em>None</em></MenuItem>
//                                 <MenuItem value= {'newtech'} sx={{fontFamily:'cursive', fontSize:16, fontWeight:600}}>New Technitian</MenuItem>
//                                 <MenuItem value= {'AusPost'} sx={{paddingTop:0, fontFamily:'cursive', fontSize:16, fontWeight:600}}>Australia Post</MenuItem>
//                                 <MenuItem value= {"Toshiba"} sx={{paddingTop:0, fontFamily:'cursive', fontSize:16, fontWeight:600}}>Toshiba</MenuItem>
//                                 <MenuItem value= {'BP'} sx={{paddingTop:0, fontFamily:'cursive', fontSize:16, fontWeight:600}}>BP</MenuItem>
//                                 <MenuItem value= {'711'} sx={{paddingTop:0, fontFamily:'cursive', fontSize:16, fontWeight:600}}>Seven/11</MenuItem>
//                                 <MenuItem value={'Foxtel'}sx={{paddingTop:0, fontFamily:'cursive', fontSize:16, fontWeight:600}}>Foxtel</MenuItem>
//                             </Select>
//                         </FormControl>

//                         <FormControlLabel
//                             label={active===1?"User Acivated in Cognito":"User Not Activated in Cognito"}
//                             disabled={false}
//                             control={<Switch  color="warning"/>
//                                         //checked={active === 1 ? true : false} //size='small'
//                             }
//                         />
//                     </Stack>
