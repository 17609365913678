import './staff.scss'; // import { Link } from "react-router-dom";// import Sidebar from "../../components/Sidebar.jsx";// import Navbar from "../../components/Navbar.jsx";
import '../../resource/images/autom8-logo.png'
import React, {useState, useContext, useEffect, useRef, useCallback } from "react";
import {UserLogInContext} from '../../App';// import {UserLogInContext} from '../../App';// import Button from '@mui/material/Button';  //import  {createFilterOptions } from '@mui/material/Autocomplete'; 
import Stack from '@mui/material/Stack'; // import Fab from '@mui/material/Fab';//import AdjustIcon from '@mui/icons-material/Adjust';// import CenterFocusWeakRoundedIcon from '@mui/icons-material/CenterFocusWeakRounded';// import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import { GoogleMap , MarkerF,  InfoWindowF,  OverlayView } from '@react-google-maps/api'; //useJsApiLoader,  DirectionsRenderer, withGoogleMap,InfoBox,
import { SelectField } from '@aws-amplify/ui-react';//import {SearchBox, dateConverter, GetLocation, ModComp} from '../../util/Functions';
import { Typography } from '@mui/material';
import ApplicationBar from '../../components/AppBar.jsx';//import {  SwitchField } from '@aws-amplify/ui-react';

//const DEBUG = process.env.REACT_APP_DEBUG ==='on';
const productionHost = process.env.REACT_APP_API_HTTP + window.location.host;
const localHost = process.env.REACT_APP_API_HTTP + process.env.REACT_APP_API_LOCAL_DOMAIN + process.env.REACT_APP_API_LOCAL_PORT;
const DOMAIN = `${process.env.NODE_ENV==='production'?productionHost:localHost}`; 

export const Contr = () => {

  const [tracker, setTracker]=useState();   //const [trackedEmployess, setTrackedEmployess] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(''); //  const [employee, setEmployee] =useState(false);  // const [destination, setDestination] = useState('');  // const [origin, setOrigin] =useState('');
  const [map, setMap] = useState(/** @type google.maps.Map*/(null));
  const [aa, setAa]=useState(false)     // const [centerSite, setCenterSite] = useState({lat: -38.05552, lng: 145.22209 });   // const [centerContract, setCenterContract] = useState({lat: -38.05552, lng: 145.22209 });
  const [defaultCenter, setDefaultCenter] = useState({lat: -19.71350089260208, lng: 132.0068163963189 });//-19.71350089260208, 132.0068163963189
//   const [isEmpl, setIsEmpl]=useState(true);//   const [contractors, setContractors] =  useState();

  const centerEmployee = useRef('');


  const { isLoaded} = useContext(UserLogInContext);     // const {user, group, role, customerrole} = useContext(UserLogInContext);
  /* global google */ 
  const google = window.google      ////IMPORTANT FOR GOOGLE MAPS TO WORK

  useEffect(()=>{      
    getTracker()  //  console.log("trecker is changed !!!!!!!!  ????????????");
  },[selectedStaff]);

//   useEffect(()=>{      
//     let timer = setTimeout(() => {
//         getTracker();
//         console.log("tick")
//     }, 65000);
      
//     return () =>{
//       console.log("tock")
//       clearTimeout(timer)
//     }
//   });

  const getTracker = async()=>{
    const response = await fetch(`${DOMAIN}${process.env.REACT_APP_API_GET_ALL_CONTRACTORS_DATA}${'1'}`);
    const responseData = await response.json();
  //  console.log(responseData);
    setTracker(responseData);            
  };

  const onUnmount =  useCallback(function callback(map) {setMap(null)}, []);

  const onLoad = useCallback(function callback(map) {
                  // This is just an example of getting and using the map instance!!! don't just blindly copy!
                  const bounds = new google.maps.LatLngBounds({lat: -19.71350089260208, lng: 132.0068163963189 });                  // console.log(bounds)                  // map.fitBounds(bounds);
                  setMap(map)
                }, [])
  
  return (
      <>
        <ApplicationBar/>
        {/* <Sidebar /> */}
        <div className="homeContainerA" >
          {/* <Navbar /> */}
          
          <main className="mainContainerA" > 
              <Stack direction="column" sx = {{ width:'99%', height:'88%', ml:1, border:'1px solid blue'}} >  

                <SelectField
                  value={ selectedStaff }  //isEmp:['test', 'test 2'] 
                  options={ tracker !== undefined ?  ['All Contractors', ...tracker.map((t)=>t.supplier) ] : [''] 
                            
                          }
                  size="small" 
                  marginTop={-7}
                  onChange={(e) => { setSelectedStaff(e.target.value)}}
                ></SelectField> 

                {isLoaded && 

                  <GoogleMap 
                    center = { centerEmployee.current ==='' || selectedStaff === 'All Employees' ? {lat:-37.9887548247102, lng: 145.1528186796686} : centerEmployee.current } 
                    zoom= { selectedStaff ==='' || selectedStaff === 'All Contractors'  ? 4 : 12 }
                    mapContainerStyle = {{width:'100%', height:'100%'}}
                    onLoad={onLoad}  //(map)=>{setMap(map)}}
                    onUnmount={onUnmount}
                  >
                    <>
                        { (tracker !== undefined) && tracker.map((t, i)=> {

                              if(selectedStaff ==='' || selectedStaff =='All Contractors' ){
                                const Lat = t.position !==null ? t.position.y : null;
                                const Lng = t.position !==null  ? t.position.x : null;
                                return (
                                  <>
                                    <MarkerF 
                                        position= {Lat === null ? defaultCenter: {lat: Lat, lng: Lng} }    //label={t.employee.name + " " + t.employee.surname}
                                        cursor= 'pointer'
                                      //  animation={ aa ? 2 : 1}
                                        onClick={(e)=>setAa(!aa) }
                                    />
                                    <OverlayView
                                      position= {Lat === null ? defaultCenter: {lat: Lat, lng: Lng} }
                                      mapPaneName= {OverlayView.OVERLAY_MOUSE_TARGET}
                                      getPixelPositionOffset={(x1, y1)=> { return {y: y1-82} }}
                                      pointer='cursor'
                                    >
                                        <Typography style={{ background: 'gray', padding: `5px 2px`, fontSize: '12px', color: `white`, borderRadius: '2px'}} > 
                                            {`${t.supplier }  ${ aa ? " - " + t.supplierContactPerson :''}   ${ aa ? " - " + t.supplierPhone : ''}`}
                                        </Typography>
                                    </OverlayView>
                                  </>

                                )
                              }else{

                                const Lat = t.position !==null ? t.position.y :null;
                                const Lng = t.position !==null ? t.position.x :null;               //const Adress = t.type ==='Shift'? t.shift.currentLocation.address : t.activity.currentLocation.address

                                if(t.supplier ===  selectedStaff){
                                    centerEmployee.current = ( Lat === null ? defaultCenter : {lat: Lat, lng: Lng})
                            
                                    return (                                                                                         
                                        <> 
                                            <MarkerF 
                                                position={centerEmployee.current} //{lat: parseFloat(t.activity.currentLocation.latitude),  lng: parseFloat(t.activity.currentLocation.longitude)}}
                                                cursor= 'pointer'
                                                //animation={ aa?2:1}
                                                onClick={(e)=>setAa(!aa) }     
                                                
                                                


                                            >
                                                <OverlayView
                                                position= {centerEmployee.current }
                                                mapPaneName= {OverlayView.OVERLAY_MOUSE_TARGET}
                                                getPixelPositionOffset={(x1, y1)=> {return {x: x1 < 100 ? x1-125: x1-175, y: y1-70} }}
                                              >
                                                <Typography 
                                                  style={{
                                                      background: 'steelblue',//`#203254`,
                                                      padding: `1px 1px`,
                                                      fontSize: '14px',
                                                      color: `white`,
                                                      borderRadius: '3px',
                                                  }}
                                                > {`${t.supplier }  ${ aa ? " - " + t.supplierContactPerson :''}  ${ aa ? " - " + t.supplierPhone : ''}`} </Typography>
                                              </OverlayView>

                                              <InfoWindowF position={centerEmployee.current}      >
                                                <Typography style={{ backgroundColor: 'yellow', border: "1px solid gray", padding:2, fontSize: 12}} > 
                                                    {`${t.supplier } - ${t.supplierContactPerson} -  ${t.supplierPhone}`}
                                                    { Lat === null || Lat === undefined? ` -  GEO LOCATION IS OFF `:``}
                                                </Typography>  
                                              </InfoWindowF>


                                            </MarkerF>

                                            
                                            
                                        </>
                                    )
                            
                                }
                              }
                            }
                          ) 
                        }
                    </>  
                  </GoogleMap>   
                }

              </Stack> 

          </main>   

        </div>
      </>
  );
};

