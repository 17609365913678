import React from 'react'
import {Stack, Typography} from '@mui/material'; 
import CloseIcon from '@mui/icons-material/Close';//import { Icon } from '@mui/material';

const RoleBox= ({userRoles, setRoles})=>{
    let userRolesTempArray = userRoles;
    let index;

    const handleDismiss=(i)=>{
        userRolesTempArray.splice(i, 1)
        setRoles([...userRolesTempArray])        
    }

    const refresh=(ar, flag)=>{            
        let realArray=[]
    
        ar?.map((a, i, arr)=>{
            index=i;
            realArray = arr.map((name, i,a)=>{ 
                return (
                    <Stack 
                        direction='row'
                        gap={0}
                        sx={{
                            flex:'1 1 100%', 
                            justifyContent:"flex-end", 
                            mt:'.5ch', 
                            ml:'.5ch', 
                            width:'22ch', 
                            paddingX:'1ch', 
                            paddingY:'1ch',
                            border:'1px solid gray', 
                            cursor:'pointer', 
                            boxShadow: `0 0 2px  rgba(0, 0, 0, 0.3)`
                        }} 
                        justifyContent="flex-start" 
                        alignItems="center"
                        height='5ch' 
                        backgroundColor='lightgray'
                        onClick={()=>handleDismiss(i)}
                        //icon={<CloseIcon />}
                    > 
                        <Stack width ='10ch' sx={{flex:'1 1 100%', justifyContent:"flex-end" }} ><Typography fontSize={12}>{name}</Typography> </Stack>
                        <Stack sx={{width:'1.5ch', }} ><Typography fontSize={12}><CloseIcon fontSize='' /></Typography> </Stack> 
                    </Stack>    
                )
            })         
        } );
  
        return realArray;
    }

    return refresh(userRolesTempArray, 1);
};

export default RoleBox;