import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@mui/material";

export const DialogueAlert = ({
  isOpen,
  openOff,
  setValue,
  title,
  dialogueTxt,
}) => {
  const [open, toggleOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState({
    title: "",
    year: "",
  });

  useEffect(() => {
    isOpen && trigger();
  }, [isOpen]);

  const handleClose = () => {
    setDialogValue({
      title: "",
      year: "",
    });
    openOff(false);
    toggleOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // setValue({
    //   title: dialogValue.title,
    //   year: parseInt(dialogValue.year, 10),
    // });
    setValue(dialogValue.title);
    handleClose();
  };

  const trigger = () => {
    setTimeout(() => {
      toggleOpen(true);
    });
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialogueTxt}</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.title}
              placeholder="No"
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  title: event.target.value,
                })
              }
              label=""
              type="text"
              variant="standard"
            />

            {/* 
                                          <TextField
                                                margin="dense"
                                                id="name"
                                                value={dialogValue.year}
                                                onChange={(event) =>
                                                    setDialogValue({
                                                    ...dialogValue,
                                                    year: event.target.value,
                                                    })
                                                    }
                                                    label="year"
                                                    type="number"
                                                    variant="standard"
                                          /> 
            */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Ok</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export const DialogueResponce = ({
  isOpen,
  setIsOpen,
  setValue,
  title,
  dialogueTxt,
}) => {
  const [open, toggleOpen] = useState(false);

  useEffect(() => {
    isOpen && trigger();
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
    toggleOpen(false);
  };

  const handleSubmit = () => {
    setValue(true);
    handleClose();
  };

  const trigger = () => {
    setTimeout(() => {
      toggleOpen(true);
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography fontSize={18} fontWeight={800}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography color="#2d187be0" fontWeight={600}>
            {dialogueTxt}
          </Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleSubmit}>
          <Typography fontWeight={600}>Yes</Typography>
        </Button>
        <Button onClick={handleClose}>
          <Typography fontWeight={600}>No</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const JustAlert = ({ isOpen, openOff, title, dialogueTxt }) => {
  const [open, toggleOpen] = useState(false);
  // const [obj, setObj] =useState({test:'hello', test1:'hellow again'} )

  const arr = ["ww", "dd"]; // for of
  //const obj = { test: "hello", test1: "hellow again" }; //for (const element in obj) {   console.log(obj[element])}
  const fruits = new Map([
    ["apples", 500],
    ["bananas", 300],
    ["oranges", 200],
  ]);

  useEffect(() => {
    console.log("test 1 ");
    return () => {
      console.log("test 2");
    };
  }, [isOpen]);

  useEffect(() => {
    try {
      for (const [key, value] of fruits) {
        console.log("wwwwwwwwwwwwwwww : ", key + " -- " + value);
      }

      for (const [key, value] of arr) {
        console.log("www-- : ", key + " -- " + value);
      }

      for (const e of arr) {
        console.log("arrrrr : ", e);
      }

      for (const [key, value] in arr) {
        console.log("arrrrr 222: ", arr[key]);
        console.log("1111: ", value);
      }
    } catch (err) {
      console.log(err);
    }
    isOpen && trigger();
  }, [isOpen]);

  const handleClose = () => {
    openOff(false);
    toggleOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose();
  };

  const trigger = () => {
    setTimeout(() => {
      toggleOpen(true);
    }, 100);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography sx={{ fontSize: 16 }}>{dialogueTxt}</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>OK</Button>
            {/* <Button type="submit">Ok</Button> */}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
