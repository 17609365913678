
import React, {useState} from 'react';
import "./about.scss";
import '../../styles/main1.scss'//import '../../pages/home/home.scss';//import { useContext } from "react";//import {UserLogInContext} from '../../App'// import { Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar.jsx";
import Navbar from "../../components/Navbar.jsx";
import '../../resource/images/autom8-logo.png';///import AWS from "aws-sdk"
import {DialogueAlert} from '../../components/DialogueAlert.jsx'
import styled, {css} from 'styled-components';
import { Table, TableBody, TableCell, TableRow } from '@aws-amplify/ui-react';
import { Stack, Typography} from "@mui/material"; // Button, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
import ApplicationBar from '../../components/AppBar.jsx';



const MenuContextContainer = styled.div`
  border: 1px solid #ffffff2d;
  border-radius: 4px;
  padding: 18px;
  margin: 5px 0;
  box-sizing: border-box;
`;
const ContextMenu = styled.div`
  position: absolute;
  width: 200px;
  background-color: #383838;
  border-radius: 5px;
  box-sizing: border-box;
  ${({ top, left }) => css`
    top: ${top}px;
    left: ${left}px;
  `}
  ul {
    box-sizing: border-box;
    padding: 10px;
    margin: 0;
    list-style: none;
  }
  ul li {
    padding: 18px 12px;
  }
  /* hover */
  ul li:hover {
    cursor: pointer;
    background-color: #000000;
  }
`;


export const About = () => {
  const isDebug = process.env.REACT_APP_DEBUG ;


  const [isOpen, setIsOpen] = useState(false);
  const [retValue, setRetValue] = useState();

  
  return (
    <>
    <ApplicationBar/>
      {/* <div className="sideBar">
        <Sidebar /> */}

        <div className="navBar" >
          {/* <Navbar /> */}
            
          <div className="aboutScr" >   

            <div className='newItem'>

                <div className="aboutTable">

                  <Table
                      caption={<Stack border='1px solid lightgray' paddingY={2}><Typography color='#2d187be0' fontWeight={600} >System Information</Typography></Stack>}
                      size="small"
                      highlightOnHover={true}
                      variation="striped"
                      backgroundColor={'antiquewhite'}
                      width='100ch'
                      //boxShadow=  '0 2px 2px 1px rgba(200, 191, 191, 0.939)'
                      //backgroundImage={new URL("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48cGF0aCBmaWxsPSIjY2JkNWUwIiBkPSJNMjcgMjQuNTdsLTUuNjQ3LTUuNjQ4YTguODk1IDguODk1IDAgMCAwIDEuNTIyLTQuOTg0QzIyLjg3NSA5LjAxIDE4Ljg2NyA1IDEzLjkzOCA1IDkuMDEgNSA1IDkuMDEgNSAxMy45MzhjMCA0LjkyOSA0LjAxIDguOTM4IDguOTM4IDguOTM4YTguODg3IDguODg3IDAgMCAwIDQuOTg0LTEuNTIyTDI0LjU2OCAyNyAyNyAyNC41N3ptLTEzLjA2Mi00LjQ0NWE2LjE5NCA2LjE5NCAwIDAgMS02LjE4OC02LjE4OCA2LjE5NSA2LjE5NSAwIDAgMSA2LjE4OC02LjE4OCA2LjE5NSA2LjE5NSAwIDAgMSA2LjE4OCA2LjE4OCA2LjE5NSA2LjE5NSAwIDAgMS02LjE4OCA2LjE4OHoiLz48L3N2Zz4=")}
                  >    
                    <TableBody >          
                        <TableRow >
                            <TableCell border='1px solid lightgray' width="20ch"><Typography style={{color:'#2d187be0', fontWeight:600, }}> Environment</Typography></TableCell>
                            <TableCell border='1px solid lightgray'><Typography >{process.env.NODE_ENV}</Typography></TableCell>                                                
                        </TableRow>

                        <TableRow >
                            
                            <TableCell border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontWeight:600, }}>Host</Typography></TableCell>
                            <TableCell border='1px solid lightgray'><Typography >{ window.location.host}</Typography></TableCell>                                                
                        </TableRow>

                        <TableRow >
                            
                            <TableCell  border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontWeight:600, }}>Client Version</Typography></TableCell>
                            <TableCell  border='1px solid lightgray'><Typography > {process.env.REACT_APP_CLIENT_VER}</Typography></TableCell>
                            
                        </TableRow>

                        <TableRow >
                          
                            <TableCell border='1px solid lightgray'><Typography style={{color:'#2d187be0', fontWeight:600, }}>Server Version </Typography></TableCell>
                            <TableCell border='1px solid lightgray'><Typography >{process.env.REACT_APP_BACKEND_VER}</Typography></TableCell>                                                
                        </TableRow>

                        <TableRow>
                          
                            <TableCell border='1px solid lightgray' width="20ch" ><Typography style={{color:'#2d187be0', fontWeight:600, }}>Debuging </Typography></TableCell>
                            <TableCell border='1px solid lightgray' width="35ch"><Typography >{isDebug ? 'ON':'OFF'}</Typography> </TableCell>                                                
                        </TableRow>
                    </TableBody >  
                  </Table>        
                  

                  {/* </Container>                   <Container primary bc='green' w='85ch'>  */}
                  {/* <button className="button-small"></button>
                  <div className="select"></div> */}


                  {/* <Dialog open={open} onClose={handleClose}>
                    <form onSubmit={handleSubmit}>
                      <DialogTitle>Add a new Item</DialogTitle>
                      <DialogContent>
                        <DialogContentText> This is a Test Text </DialogContentText>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            value={dialogValue.title}
                            onChange={(event) =>
                              setDialogValue({
                                ...dialogValue,
                                title: event.target.value,
                              })
                            }
                            label="title"
                            type="text"
                            variant="standard"
                          />
                          <TextField
                            margin="dense"
                            id="name"
                            value={dialogValue.year}
                            onChange={(event) =>
                              setDialogValue({
                                ...dialogValue,
                                year: event.target.value,
                              })
                            }
                            label="year"
                            type="number"
                            variant="standard"
                          />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                      </DialogActions>
                    </form>
                  </Dialog> */}
                  <DialogueAlert isOpen={isOpen} openOff={setIsOpen} setValue={setRetValue} title={'tittteeelele'} dialogueTxt={'tetxtxtxtxtxtxxt'}/>

                  {/* <Button onClick={()=> setIsOpen(true)}>tst</Button> */}

                  {/* <TextField
                    margin="dense"
                    //id="name"
                    value={retValue?.title}
                  //  value={isNaN(value?.year) ? ` ${value.title}`: `${value?.year.toString()} ${value.title}`}
                    // onChange={(event) =>
                    //   setDialogValue({
                    //     ...dialogValue,
                    //     year: event.target.value,
                    //   })
                    // }
                    // label="year"
                    // type="number"
                    variant="standard"
                  /> */}
                  
                </div>
                
              </div>
          </div>  

        </div>
      {/* </div> */}
    </>
  );
};