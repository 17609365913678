import React, {useState} from 'react';
import './nopage.scss'
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import Tab from '@mui/material/Tab';
import { Box, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { PhonelinkLockRounded } from '@mui/icons-material';



export const NoPage = () => {

  const [value, setValue] = useState(0);
  const [font, setFont]=useState({ fontWeight:800, fontSize:14})

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  // function a11yProps(index) {
  //   return {
  //     id: `simple-tab-${index}`,
  //     'aria-controls': `simple-tabpanel-${index}`,
  //   };
  // }
  
function BasicTabs() {
    const handleChange = (event, newValue) => {setValue(newValue)};

    return (
      <Box  sx={{ width: '100%', }}>

        <Box sx={{ borderBottom: 2, borderColor: 'gray'}}  >
          
          
          <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons  selectionFollowsFocus textColor="secondary" >

            <Tab icon={<PhonelinkLockRounded />} iconPosition="start"  label="Item One"  sx={font} />
            <Tab label="Item Two" sx={{ fontWeight:800, fontSize:14}}  /> 
            <Tab label="Item Three" sx={font} />
            <Tab label="Item Five" sx={font}/>
            <Tab label="Item Six" sx={font}/>


          </Tabs>
        </Box>

          <TabPanel value={value} index={0} x={'hello'}  b={25}>
              <div>Item One</div>
          </TabPanel>


          <TabPanel value={value} index={1} x={'hello'}  >
            <>
              <h4>404. Page Not found</h4>
            </>
              
          </TabPanel>


          <TabPanel value={value} index={2} x={'hello'}>
              Item Three
          </TabPanel>
        
      </Box>
    );
  }
  

  return (
    <div className='nopage'>
      <Sidebar />
        <div className="nopageContainer">
          <Navbar />
            <div className='others'>
                <div className='other1'> 
                    {/* <BasicTabs/>  */}
                    Page does not exist
                 </div>  
                {/* <<h2>404. Page Not found</h2>  */}
            </div>
        </div>
      </div>

  )
}

// {...a11yProps(1)} {...a11yProps(2)} 