import React, {useState, useRef, useEffect } from 'react';
import { Autocomplete, Box, Stack, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from "@mui/material";
import './newitem.scss';
// import { mailformat, notEmpty } from '../Regex.js';
import { useParams, useNavigate, useLocation } from "react-router-dom"; //import NewContact from './NewContact';//import { Menu, MenuButton, MenuItem } from '@aws-amplify/ui-react';
import { Table, TableCell, TableBody, TableHead, TableRow, Tabs, TabItem, SelectField, CheckboxField, useAuthenticator, Alert } from '@aws-amplify/ui-react';// {  Item, Tabs, SelectField, Icon } from '@aws-amplify/ui-react';
//import {} from '@aws-amplify/ui-react';
import {JustAlert} from '../DialogueAlert.jsx'


const NewPart = ({action}) => {
    //const [partsDescription, setPartDescription] = useState('');     //const [clientID, setClientID] = useState('');    //const [clientName, setClientName] = useState([]);
    //const [clientType, setClientType]= useState([{}]);
        //const [addPartInStockDetails, setAddPartInStockDetails]=useState();
    const [customersAll, setCustomersAll] = useState([{}]);
    const [selectedCustomer, setSelectedCustomer] = useState('');    //const [partNumber, setPartNumber] = useState('');    //const [customerEmail, setCustomerEmail] = useState('');
    const [inputs, setInputs] = useState({});
    const [openPartUpdate, setOpenPartUpdate] = useState(false);
    const [openPartInStockAdd, setOpenPartInStockAdd] = useState(false);
    const [dialogValue, setDialogValue] = useState({});
    const [dialogText, setDialogText] = useState('');
    const [dialogFieldName,setDialogFieldName] = useState('')
    const [alertActive, setAlertActive] = useState(false);
    const [tabPartValue, setTabPartValue]=useState(0);
    const [partInStockDetails, setPartInStockDetails]=useState();
    const [locationList, setLocationList] = useState()//{locationId:null, locationName:null});
    const [backGrounColor, setBackGrounColor] = useState({  color:null, 
                                                            index:null,
                                                            column:null });
    
    const [alertDetails, setAlertDetails] = useState({  text:'', 
                                                        heading:'', 
                                                        variation:'warning' })
    const VALID = useRef(false)
    const countEntries = useRef(0)
    const customersNames =useRef([]);
    let {id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const { route } = useAuthenticator((context) => [context.route]);
    const { authStatus } = useAuthenticator(context => [context.authStatus]);

    //console.log(user.attributes)
    console.log(route, authStatus )
    console.log(countEntries.current= countEntries.current +1)

    const {REACT_APP_API_GET_CUSTOMERS_LIST, 
            REACT_APP_DEBUG, 
            NODE_ENV, 
            REACT_APP_API_HTTP, 
            REACT_APP_API_LOCAL_DOMAIN, 
            REACT_APP_API_LOCAL_PORT,
            REACT_APP_API_GET_PART_LOCATIONS} = process.env

    const productionHost=REACT_APP_API_HTTP + window.location.host
    const localHost=REACT_APP_API_HTTP + REACT_APP_API_LOCAL_DOMAIN + REACT_APP_API_LOCAL_PORT
    const DOMAIN =`${NODE_ENV==='production' ? productionHost : localHost}`;    
    const DEBUG = REACT_APP_DEBUG;

    const getCustomersList = async ()=>{
        setInputs(values=>({...values, ['jobPartIsSerialized']: false}))
        const response = await fetch(`${DOMAIN}${REACT_APP_API_GET_CUSTOMERS_LIST}`);
        if(response.status ===200){
            console.log('server responded with status %i', response.status)
            const responseData = await response.json();  
            console.log(responseData)
            customersNames.current =  responseData.map( d =>( d.customerName ))            //setClientID(responseData.map(d=>d.clientId))
            setCustomersAll(responseData.map( d =>( { name:d.customerName, id:d.customerId } )) );
        }else{ console.log('server error with status %i', response.status) }        //setClientName(responseData.map(d=>d.clientName))        //setClientType(responseData)        //setInputs(values=>({...values, ['customerLogo']: ''}))
    }

    const getLocationList = async ()=>{
        const response = await fetch(`${DOMAIN}${REACT_APP_API_GET_PART_LOCATIONS}`);
        if(response.status ===200){
            const responseData = await response.json();  
            const body=[ {locationId: 1, locationName: 'Select Location'}, ...responseData]
            setLocationList(body) //.map(l =>({ locationId:l.locationId, locationName:l.locationName } ))  );
        }else{ console.log('server error with status %i', response.status) }
    }

    useEffect(()=>{
        getCustomersList(); // customers list for parts assigment                         //DEBUG && console.log('PART ID : ', id)
        getLocationList();
        //getTenant();
        action ==='update' && getPartsData(`${DOMAIN}${process.env.REACT_APP_API_GET_PARTS_DATA}${id}`) 
    }, []);


    const getTenant = async()=>{

        const myHeaders = new Headers();
        myHeaders.append("Accept-Version", "1");
        myHeaders.append("Authorization", `Bearer ${'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29wZSI6WyJhcGkiXSwiaXNzIjoiY2MtYXBpIiwiZXhwIjoxNzE4ODU0OTI4LCJ1c2VyIjp7ImlkIjoiMGY3OTdhYmUtOTE2My00N2NlLTllNWUtMDhiNjEzYmNlN2M4In0sImp0aSI6IjQxYjVhNGViLWU5M2EtNDQ2My04NzNlLTAzZjFmMzNkNTI5MCIsImNsaWVudF9pZCI6ImI2WTRSSHZDMnBCTnNTNGpKMlg4dm9ad0EifQ.q5Z8DHbIVRiuU9krRDEwdl1-iC3v_RnkPh-8LVVmeIljyz9Y9G8c-YRTZ3KUzV4PVIA8-0dDOpJlv9PrZTxeL6h8Fg49ds-xwaxK7SCo7TYXilDpWTKM9ZitQxuhxLZS3jHFNaBhtNank9k51m8hsa1Cbyqh_75Y1zjXTGg6CfX2fRraD26MW2p6BOoSWFgWtz1XZJE_8CeYXhLm9g-UU7_2bo_jb5zaPbWJzWDr0Folc2nq4IDbolhMJiDfPq5bRh_GI4PjyCg2ZxMu1kVeR09cRKYPISP6d3u3aZSVlvCf11saPkbzL3qWy24cAuXJsXwf5g9YIaVCOJTqr1UT8g'}`);

        const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
        };

        fetch("https://api.cartoncloud.com/uaa/userinfo", requestOptions)
        .then((response) => response.json())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));

    }

    const handleCloseDialog = ()=>{
        setDialogValue({});
        setDialogFieldName('')
        setOpenPartUpdate(false);
    };

    const handleCloseDialogAdd = ()=>{
        setDialogValue({});
        setOpenPartInStockAdd(false)
        console.log('hhhhhhh')
    
    }
          
    const handleDeletePart = (e) => {
        e.preventDefault();
        // if (dialogValue.toLowerCase() === 'yes'){
        //     deleteData(`${DOMAIN}${process.env.REACT_APP_API_DELETE_PART}${id}`) 
        //     navigate(-1)
        // }
        handleCloseDialog();
    };


    const handleChagesForPartInStock = async(e) => {
        e.preventDefault();
        console.log(dialogValue)

        if(dialogValue.fieldName !== undefined){
            const requestOptions = {   
                method: 'PUT', 
                headers: {'Content-Type':'application/json; charset=utf-8' }, 
                body: JSON.stringify({ dialogValue }),    
            } 
            const response = await fetch(`${DOMAIN}${'/api/stockpartsdata/1'}`, requestOptions);
            handleCloseDialog();
            getPartsData(`${DOMAIN}${process.env.REACT_APP_API_GET_PARTS_DATA}${id}`)
        }else{
            handleCloseDialog();
            console.log('underfined')
        }
    };

    const handleAddForPartInStock = async(e) => {
        e.preventDefault();
        console.log(dialogValue, id)

        if(dialogValue.locationId !== undefined){
            const requestOptions = {   
                method: 'POST', 
                headers: {'Content-Type':'application/json; charset=utf-8' }, 
                body: JSON.stringify({ dialogValue }),    
            } 
            const response = await fetch(`${DOMAIN}${'/api/stockpartsdata/1'}`, requestOptions);
            if (response.status == 200){
                // const r = await response.json(response) // console.log(r)
                setAlertDetails({ text:`You have added part to Stock repository`,heading:'',variation:'success' })
                getAlert()                //setAlertActive(!alertActive)

            }else{
                console.log("ERROR response : " + await response.json(response))
            }
        }else{
            setAlertDetails({ text:'You must supply Location value', heading:'Error Adding Part:', variation:'warning' })
            getAlert()            //setAlertActive(!alertActive)
        }
        handleCloseDialogAdd();
        getPartsData(`${DOMAIN}${process.env.REACT_APP_API_GET_PARTS_DATA}${id}`)
    };
    
    const openDialog = ()=>{ setTimeout(() => {setOpenPartUpdate(true) })  }
    const openDialogAdd = ()=>{setTimeout(() => {setOpenPartInStockAdd(true)})}

    const getAlert = ()=>{
        setAlertActive(true)
        setTimeout(() => {setAlertActive(false)}, 6000)
    }

    const changePartsInStockValues =(fieldName, fieldValue, stockPartId)=>{
        setDialogText(fieldName)
        console.log(fieldValue)
        setDialogValue(values=>({...values, ['stockpartsId']: stockPartId}))
        switch(fieldName){
            case "Serial Number":
                setDialogFieldName('partSerialNumber')
                setDialogValue(values=>({...values, ['partSerialNumber']: fieldValue}))
                break;

            case "Asset Number":
                setDialogFieldName('partsAssetNumber')
                setDialogValue(values=>({...values, ['partsAssetNumber']: fieldValue}))
                break;

            case "Quantity":
                setDialogFieldName('partQty')
                setDialogValue(values=>({...values, ['partQty']: fieldValue}))
                break;

            case "Location":
                setDialogFieldName('locationId')
                setDialogValue(values=>({...values, ['locationId']: fieldValue}))
                break;        
        }        
        //setDialogValue(values=>({...values, [dialogFieldName]: fieldValue}))
        openDialog()
    }

    const handleTaPartbClick=(v)=>{setTabPartValue(v)};
    const borderSize=(tabNumber, tabVal)=>{return tabNumber === tabVal ?'2px solid darkblue':'2px solid lightgray'};


//     const handleXML = async ()=>{
//         console.log('hhhh')


// // var book = `<ns1:TGCIGatewayRequest xmlns:ns1="https://tgcs04.toshibacommerce.com/TGCIGatewayXSD">
// // <ns1:PartnerReferenceNumber></ns1:PartnerReferenceNumber>
// // <ns1:TransactionType>UpdatesFromVendors</ns1:TransactionType>
// // <ns1:ServicePayload>
// // <ns2:TGCIUpdateTaskJobRequest xmlns:ns2="https://tgcs04.toshibacommerce.com/TGCIMicroServiceXSD">
// // <ns2:UpdateTaskRequest>
// // <ns2:TGCSSRNumber>433759</ns2:TGCSSRNumber>
// // <ns2:TaskNo>807214</ns2:TaskNo>
// // <ns2:TaskName>MONITOR NOT WORKING ON THE STORE PC</ns2:TaskName>
// // <ns2:TaskStatus>En Route</ns2:TaskStatus>
// // <ns2:TaskActualStartDate></ns2:TaskActualStartDate>
// // <ns2:TaskActualEndDate></ns2:TaskActualEndDate>
// // <ns2:TaskNotes>
// // <ns2:TaskNote>
// // <ns2:NoteDate>2018-12-03T13:02:55.000-04:00</ns2:NoteDate>
// // <ns2:Notes>New notes added to Task 807214: Our Technician is on the way.</ns2:Notes>
// // </ns2:TaskNote>
// // </ns2:TaskNotes>
// // </ns2:UpdateTaskRequest>
// // </ns2:TGCIUpdateTaskJobRequest>
// // </ns1:ServicePayload>
// // </ns1:TGCIGatewayRequest>
// // <ns1:TGCIGatewayRequest xmlns:ns1="https://tgcs04.toshibacommerce.com/TGCIGatewayXSD">
// // <ns1:PartnerReferenceNumber></ns1:PartnerReferenceNumber>
// // <ns1:TransactionType>VendorSearchActiveSR</ns1:TransactionType>
// // <ns1:ServicePayload>
// // <ns2:TGCISearchActiveSRJobRequest xmlns:ns2="https://tgcs04.toshibacommerce.com/TGCIMicroServiceXSD">
// // <ns2:SearchActiveSRRequest>
// // <ns2:TGCSSRNumber>446320</ns2:TGCSSRNumber>
// // <ns2:CustInternalSRNumber></ns2:CustInternalSRNumber>
// // <ns2:CustContactFirstName></ns2:CustContactFirstName>
// // <ns2:CustContactLastName></ns2:CustContactLastName>
// // <ns2:TGCSMachineType></ns2:TGCSMachineType>
// // <ns2:TGCSModel></ns2:TGCSModel>
// // <ns2:TGCSItemSerialNo></ns2:TGCSItemSerialNo>
// // <ns2:CustInternalPartNo></ns2:CustInternalPartNo>
// // <ns2:CustStoreNo></ns2:CustStoreNo>
// // <ns2:CustCountry></ns2:CustCountry>
// // </ns2:SearchActiveSRRequest>
// // </ns2:TGCISearchActiveSRJobRequest>
// // </ns1:ServicePayload>
// // </ns1:TGCIGatewayRequest>`;

// // var parser = new DOMParser();
// // var xmlDoc = parser.parseFromString(book, "text/xml");
// // var first = xmlDoc.getElementsByTagName("ns2:TaskNo")[0].childNodes[0].nodeValue;

// // console.log(first);

//         const response= await fetch(DOMAIN + '/api/toshiba')
        
//         // .then(response => response.text())
//         // .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
//         // .then(data => console.log(data));

//         const data = await response.text()
        
//         let parsedDoc = new DOMParser().parseFromString(data, "text/xml") //application
//         console.log(parsedDoc)
//         console.log( parsedDoc.getElementsByTagName('NS1:TGCIGatewayResponse')[0])///.childNodes[5]) //application/xml
//         console.log( parsedDoc.getElementsByTagName('NS1:TransactionType')[0].childNodes[0].nodeValue)         
//         console.log( parsedDoc.getElementsByTagName('NS1:PartnerId')[0].childNodes[0].nodeValue) 
//         console.log( parsedDoc.getElementsByTagName('NS1:JobMessage')[0].childNodes[0].nodeValue) 



//         // const myHeaders = new Headers();
//         // myHeaders.append("Content-Type", "application/xml");
//         // myHeaders.append("Authorization", "Basic VEdDSV9GSUdVUkU4X0RFVjpqR3loR0h3cjc4NU9oU2FBMFE5Ow=="); ///
//         // myHeaders.append("Cookie", "PD-ID=mMDr2cZV6RhUCOjcPQDahqncSV0uQRxVCJXxA9aOKgfSAJlZEeChCH4SayITharJdPjpSy9O9XyNZ2z9p0vk8XegaVh4QuOmppDiwwDwiM31jpGgIyK9AVEeotpsZsbJ8nSPsbwn25a10aAzU63Ut0fe+TCb035/5Pmt5lX23aC6W5jRSgqrnedk33Ul+tWs51ShxFUi7c38PnKv/lqa1j6JzaaTSXlDymMAH624xHk0WcX7Os0/Sql9W6nRRXG+Orpr0H9UtbCpzHTZUhfPmw==; __cflb=02DiuJLt8zuAYbv68VehXiGorBqJHKSGc7RMedPzkZiVP");

//         // const raw ="<ns1:TGCIGatewayRequest xmlns:ns1=\"https://tgcs04.toshibacommerce.com/TGCIGatewayXSD\">\r\n<ns1:PartnerReferenceNumber></ns1:PartnerReferenceNumber>\r\n<ns1:TransactionType>VendorSearchActiveSR</ns1:TransactionType>\r\n<ns1:ServicePayload>\r\n<ns2:TGCISearchActiveSRJobRequest xmlns:ns2=\"https://tgcs04.toshibacommerce.com/TGCIMicroServiceXSD\">\r\n<ns2:SearchActiveSRRequest>\r\n<ns2:TGCSSRNumber>446320</ns2:TGCSSRNumber>\r\n<ns2:CustInternalSRNumber></ns2:CustInternalSRNumber>\r\n<ns2:CustContactFirstName></ns2:CustContactFirstName>\r\n<ns2:CustContactLastName></ns2:CustContactLastName>\r\n<ns2:TGCSMachineType></ns2:TGCSMachineType>\r\n<ns2:TGCSModel></ns2:TGCSModel>\r\n<ns2:TGCSItemSerialNo></ns2:TGCSItemSerialNo>\r\n<ns2:CustInternalPartNo></ns2:CustInternalPartNo>\r\n<ns2:CustStoreNo></ns2:CustStoreNo>\r\n<ns2:CustCountry></ns2:CustCountry>\r\n</ns2:SearchActiveSRRequest>\r\n</ns2:TGCISearchActiveSRJobRequest>\r\n</ns1:ServicePayload>\r\n</ns1:TGCIGatewayRequest> \r\n";

//         // const requestOptions = {
//         //   method: "POST",
//         //   headers: myHeaders,
//         //   body: raw,
//         //   redirect: "follow"
//         // };

//         // fetch("https://www.toshibacommerce.com/TGCI/uat/", requestOptions)
//         //   .then((response) => response.text())
//         //   .then((result) => console.log(result))
//         //   .catch((error) => console.error(error));


//     }
    
    //getting data for update
    
    
    const getPartsData = async(Url)=>{  //getCustomerData
        const response = await fetch(Url);
        if(response.status ==200){
            const responseCustomersData = await response.json();
            const part = responseCustomersData[0]; 
            DEBUG && console.log(Url, part)
    
            const keys= Object.keys(part)
            keys.forEach(key=>{setInputs(values=>({...values, [key]: part[key], isValidated: true,}))})
            setSelectedCustomer(part.customerName)    
            await getStockPartsData(id)                
            VALID.current = true;

        }else{
            DEBUG && console.log(`Error: status ${response.status} returned` )
        }

    };

    const handleClose=()=>{navigate(-1)}


    // adding new a part to db (not in stock though)
    const handleAdd=()=>{ 
        DEBUG && console.log(inputs)
        postNewUsertData(`${DOMAIN}${process.env.REACT_APP_API_ADD_NEW_PARTS_DATA}`, inputs)
        handleClose()
    };

    const handleOnMouseOver =(i, column)=>{ setBackGrounColor({
            color: 'pink', 
            border: '2px dotted blue',
            index:i, 
            column: column
        }
    ) };

    const handleOnMouseOut =(i, column)=>{ setBackGrounColor({
            color: null, 
            border: '1px solid gray',
            index:i, 
            column: column
        }
    )};

    //// PARTS IN STOCK (STOCK DATA)
    const getStockPartsData= async (id)=>{    
        const response = await fetch(`${DOMAIN}${'/api/stockpartsdata/'}${id}`); //     console.log(`${DOMAIN}${'/api/stockpartsdata/'}${id}`)
        if(response.status ==200){
            const responseData = await response.json();  
            //console.log(responseData)
            // const keys=  Object.keys(responseData)
            // const val =  Object.values(responseData)

            // val.forEach(v=>{
            //    console.log(Object.keys(v))
            //    //setPartInStockDetails(values=>({...values, [key]: responseData[key] }))
            // })      
            // setPartInStockDetails(val.map(v=>v))
            setPartInStockDetails(responseData)
        }else{
            DEBUG && console.log(`Error: status ${response.status} returned for ${'/api/stockpartsdata/'}${id}` )
        }    
    }

    const handleUpdate=()=>{ 
        DEBUG && console.log(inputs)
        updatePart(`${DOMAIN}${process.env.REACT_APP_API_UPDATE_PARTS_DATA}`, inputs)
        handleClose();
    } 

    const updatePart = async (Url, BodyInput)=> {    //console.log(BodyInput) /// text/plain; */*; charset=utf-8
        const requestOptions = {   
            method: 'PUT', 
            body: JSON.stringify({ BodyInput }),    
            headers: {'Content-Type':'application/json; charset=utf-8' },   
        } 
        const response = await fetch(Url, requestOptions);
        const responseData = await response.json();  
    }

    const postNewUsertData = async (Url, BodyInput)=> {    //console.log(BodyInput) /// text/plain; */*; charset=utf-8
        const requestOptions = {   
            method: 'POST', 
            headers: {'Content-Type':'application/json; charset=utf-8' }, 
            body: JSON.stringify({ BodyInput }),    
        } 
        const response = await fetch(Url, requestOptions);
        const responseData = await response.json();  
        DEBUG && console.log(responseData)
    }

    const validateField = (inputText, pattern)=>{
        return  pattern===""? true : 
                inputText.match(pattern)?true:
                inputText.length===0 ?true: false 
    }

    const handleChange=(e, validated)=>{
        const names= e.target.name
        const value = e.target.value
        setInputs(values=>({...values, [names]: value, isValidated: validated,}));  //value, valid: 
    }  

    const deleteData = async(a)=> {
        DEBUG && console.log(a)
        const response = await fetch(a, {method: 'DELETE'});
        const responseData = await response.json();  
        //console.log(responseData.ok)  `${DOMAIN}/api/${type}/delete/${id}`                         
    }
        

  return (
    

    <>        {/* //component="form"   //sx={{'& .MuiTextField-root':{ ml:0, width:'100%'} }}      //autoComplete="off" */}
        <Tabs             
            spacing='relative' //justifyContent='normal'  //marginLeft={10} //paddingTop={1} //border={'2px solid lightgray'} //height={'4.5ch'}
            justifyContent="space-around"
            onChange={(v)=>handleTaPartbClick(parseInt(v))}
        >                                                    
            <TabItem title={<Stack sx={{ flex:'1 1 100%', justifyContent:'center', alignItems:'center',}}><Typography fontWeight={600}>Part Detail</Typography></Stack>}  
                backgroundColor={0 === tabPartValue ?'aquamarine':'aliceblue'}
                border={borderSize( 0, tabPartValue)}
                borderRadius='5px'  
                height='4.65ch'            //width='15ch'                //onClick={getOrderedParts} 
            >
                <Stack overflow='auto' sx={{flex: '1 1 100%', width: '85ch', height:'50ch', boxShadow:'0 0 1px gray'}}>                 {/* <Stack name='TABLE' sx={{ width: '85ch', height:'50ch'}}>      */}
                    <Table
                         caption={
                            <Stack direction='row' backgroundColor= 'lightsteelblue' border='1px solid gray' paddingY={1} gap={2} justifyContent='center'>
                                <Typography color={'InfoText'} fontSize={13} fontWeight={400}>{action=='update' ?'Updating Part:':'Adding Part'} </Typography>
                                <Typography color={'red'} fontSize={13} fontWeight={600}>{inputs.jobPartsDescription}</Typography>
                            </Stack>
                        }
                        size="small"
                        highlightOnHover={true}
                        variation="striped"
                        border='1px solid lightgray'
                        backgroundColor={'antiquewhite'}                        
                    >    
                        <TableBody  >     

                            <TableRow >                                                
                                <TableCell border='1px solid lightgray'> <Typography style={{ fontWeight:500}}>Part Name</Typography></TableCell>

                                <TableCell border='1px solid lightgray' >
                                    <TextField  
                                        value={inputs.jobPartsDescription}                     
                                        required
                                        fullWidth
                                        autoFocus  
                                        autoComplete={true}    
                                        multiline                                                               
                                        size='small'     //        VALID.current = validateField(e.target.value, notEmpty)
                                        name ="jobPartsDescription"                                            //fontSize={'12'} //error={!VALID.current || inputs.jobPartsDescription === ''? true:false}//helperText={inputs.jobPartsDescription === ''? "populate with some value":''} 
                                        onChange={(e)=>{ handleChange(e) }}
                                    />
                                    
                                </TableCell>          

                            </TableRow>                                                                                                                                 

                            <TableRow>                                                  
                            <TableCell border='1px solid lightgray'>  <Typography fontWeight={600}>Customers</Typography></TableCell>
                                <TableCell border='1px solid lightgray' >  
                                    {action =='new'?
                                        <Autocomplete 
                                            name='customersList' 
                                            value={selectedCustomer} 
                                            freeSolo={true}
                                            required = {true}
                                            disabled ={action == 'update' && true}
                                            size='small'                                    
                                            options={ customersNames.current}
                                            autoHighlight
                                            onChange={(event, newInputValue) => {
                                                DEBUG && console.log(customersAll)
                                                customersAll.map( c => newInputValue === c.name && setInputs(values=>( {...values, ['customerId']: parseInt(c.id)})))
                                            } }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}                                                    //style={{width:'75ch'}}
                                                    sx={{boxShadow: `0 0 1px rgba(0, 0, 0, 0.3)`}}                
                                                    inputProps = {{...params.inputProps, type: 'search', }}       
                                                />
                                            )}
                                        /> 
                                    :
                                        <Typography >{inputs.customerName} </Typography>                                         
                                    }

                                </TableCell>                                         
                            </TableRow>


                            <TableRow >                                                 
                                <TableCell border='1px solid lightgray'><Typography style={{fontWeight:500}}>Part Number</Typography>  </TableCell>
                                <TableCell border='1px solid lightgray'>
                            
                                    <TextField  
                                        value={inputs.jobPartNumber}                     
                                        required  
                                        //disabled ={action !== 'new' ? true: false}                                          //autoFocus={true}                    
                                        size='small'
                                        fullWidth
                                        name ="jobPartNumber" 
                                        onChange={(e)=>{handleChange(e)}}
                                    />
                                                               
                                </TableCell>
                            </TableRow> 
                            
                            <TableRow >                                                
                                <TableCell border='1px solid lightgray'> <Typography style={{ fontWeight:500}}>Carton Cloud UUID</Typography></TableCell>

                                <TableCell border='1px solid lightgray' >
                                    <TextField  
                                        value={inputs.prodCCUUID}                     
                                        //required
                                        fullWidth                                        //autoFocus                                          //multiline                         
                                        size='small'     //        VALID.current = validateField(e.target.value, notEmpty)
                                        name ="prodCCUUID"    //style={{ fontSize:10}}  //error={!VALID.current || inputs.jobPartsDescription === ''? true:false}//helperText={inputs.jobPartsDescription === ''? "populate with some value":''} 
                                        onChange={(e)=>{ handleChange(e) }}
                                    />
                                    
                                </TableCell>          

                            </TableRow>   

                            <TableRow >        
                                <TableCell border='1px solid lightgray'><Typography style={{fontWeight:500,}}>Is Part Serialized </Typography> </TableCell>
                                <TableCell border='1px solid lightgray'>
                                    {/* {action =='new' ? */}
                                    {console.log(inputs.jobPartIsSerialized )}
                                        <CheckboxField
                                            checked ={inputs.jobPartIsSerialized} // =='Yes' ? true : false }                                           
                                            label={ <Typography style={{fontWeight:500}}> {inputs.jobPartIsSerialized ? 'Yes': "No"} </Typography> } 
                                            //size="large" //checked={action == 'update' ? inputs.jobPartIsSerialized == 'true'? true : false :null}                   onLoad ={()=>console.log('hhhhhhhhhhhhhhhhhhhhhh')}
                                            onChange={(e)=>{ 
                                                console.log(e.target.checked)
                                                setInputs(values=>({...values, ['jobPartIsSerialized']: e.target.checked })) //? 'Yes' : "No"
                                            }}
                                        />
                                    {/* // :                                            
                                    //     //<Typography>{inputs.jobPartIsSerialized} </Typography>              
                                    // }                                         */}
                                                                        
                                </TableCell>

                            </TableRow> 
                        </TableBody >  
                    </Table>  
                </Stack>

                
                <Stack name="BUTTONS Control" className='stackButtons'  sx={{flex: '1 1 100%', mt:2, mb:1}} direction="row" gap={0}  justifyContent="center" alignItems="center"  > 
                    <Stack direction="row" className="stackButtons" gap={5} >                   {/* spacing={4} paddingTop={3} sx={{flex:'1 1 100%', justifyContent:'center', alignItems:'center', mt: action =='update' ? 12 : -35}} > */}
                    
                            <Button 
                                className='Button1'
                                onClick={ action==='update'? handleUpdate : handleAdd }     
                                variant="outlined" 
                                sx={{ fontSize:11,  borderRadius:'5px', width:'20ch'}} 
                            > <Typography style={{fontSize:12,  fontWeight:600}}>{action==='update'? 'Update ' : 'Add '} </Typography>
                            </Button>
                        

                            {action==='update' && 
                                <Button 
                                    className='Button1' //onClick={openDialogAdd}//{active === 1? handleDelete: ReActivate} 
                                    variant="outlined" 
                                    sx={{color:'red',fontSize:11, borderRadius:'5px', width:'20ch'}} 
                                >  <Typography style={{ fontSize:12, fontWeight:600}}>Delete</Typography>
                                </Button>
                            }

                            <Button 
                                className='Button1'
                                onClick={handleClose} 
                                variant="outlined" 
                                sx={{ fontSize:11,  borderRadius:'5px', width:'20ch'}} 
                            > <Typography style={{fontSize:12, fontWeight:600}}>close</Typography>
                            </Button>


                            {/* <Button 
                                className='Button1'                        
                                onClick={()=>getAlert()}//() => setAlertActive(!alertActive)}  //onClick={handleXML} 
                                variant="outlined" 
                                sx={{ fontSize:7,  borderRadius:'5px'}} 
                            > <Typography style={{fontSize:9, fontWeight:400}}>TEST</Typography>   </Button> */}
                            
                    </Stack>
                </Stack>  

            </TabItem>

            {/*//////////////////////////////   INVENTORY  TAB ----------------             \\\\\\\\\\\ */}

            <TabItem    
                title={<Stack sx={{  flex:'1 1 100%', justifyContent:'center', alignItems:'center'}}><Typography fontWeight={600}>Parts Inventory</Typography></Stack>}  
                backgroundColor={1 ===tabPartValue ?'aquamarine':'aliceblue'}
                border={borderSize( 1, tabPartValue)}
                borderRadius='5px'
                height='4.6ch'                  //onClick={setAddPartInStockDetails(values=>({...values, ['partsQty']: 1}))}
            >

                <Stack overflow='auto' sx={{flex: '1 1 100%', width: '85ch', height:'50ch',  mt:.1, mb:0.1, boxShadow:'0 0 1px gray'}}>               {/* <Stack name='TABLE' overflow='auto'   sx={{boxShadow:'0 0 2px 1px gray', width: '85ch', height:'50ch'}}>  */}
                    <Table 
                        caption={
                            <Stack sx={{flex:'1 1 100%', border:'1px solid blue', paddingY:1, backgroundColor:'lightsteelblue'}}> 
                                {/* {console.log([...partInStockDetails?.map(p=>p.partQty)].reduce((total, num)=>total +num,0))} */}
                                <Typography fontWeight={600} >{'Parts Total: '}{partInStockDetails?.map(p=>p.partQty).reduce((total, num)=>total + num,0)}</Typography> 
                            </Stack>
                        }
                        size="small"   //highlightOnHover={true} //variation="bordered" //border='1px solid lightgray' //backgroundColor={'antiquewhite'}   
                        variation="striped"  
                        border='1px solid lightgray'    
                        highlightOnHover={true}               
                    >    
                        <TableHead>
                            <TableRow>
                                {inputs.jobPartIsSerialized == 'Yes' && <TableCell border='2px solid black' as="th"  ><Typography fontWeight={500}>Serial Number</Typography></TableCell>}
                                {inputs.jobPartIsSerialized == 'Yes' && <TableCell border='2px solid black' as="th" ><Typography fontWeight={500}>Asset Number</Typography></TableCell>}
                                <TableCell border='2px solid black' as="th"><Typography fontWeight={500}>Location</Typography></TableCell>
                                {inputs.jobPartIsSerialized == 'No' && <TableCell border='2px solid gray' as="th"><Typography fontWeight={500}>Qty </Typography></TableCell>}                                {/* <TableCell border='3px solid gray' as="th"  width='3ch' >Actions </TableCell>   */}
                            </TableRow>
                        </TableHead>

                        <TableBody> 
                            {partInStockDetails?.map((part, i)=> 

                                <TableRow >   
                                    {inputs.jobPartIsSerialized == 'Yes' &&                                              
                                        <TableCell 
                                            border={(backGrounColor.index == i && backGrounColor.column == 'Serial' ) ? backGrounColor.border : '1px solid lightgray' } 
                                            backgroundColor={(backGrounColor.index == i && backGrounColor.column == 'Serial' ) && backGrounColor.color}  
                                            onMouseOver={()=>handleOnMouseOver(i, 'Serial')}
                                            onMouseOut={()=>handleOnMouseOut(i, 'Serial')}                                                                                         
                                            onClick={()=>changePartsInStockValues('Serial Number', part?.partSerialNumber, part.stockpartsId)} 
                                        >{inputs.jobPartIsSerialized == 'Yes'? part.partSerialNumber: null}
                                        </TableCell>     
                                    }     
                                    {inputs.jobPartIsSerialized == 'Yes' && 
                                        <TableCell 
                                            backgroundColor={(backGrounColor.index == i && backGrounColor.column == 'Asset') && backGrounColor.color}  
                                            border={(backGrounColor.index == i && backGrounColor.column == 'Asset' ) ? backGrounColor.border : '1px solid lightgray' }   
                                            onMouseOver={()=>handleOnMouseOver(i, 'Asset')}
                                            onMouseOut={()=>handleOnMouseOut(i, 'Asset')}
                                            onClick={()=>changePartsInStockValues('Asset Number', part?.partsAssetNumber, part.stockpartsId)} //inputs.jobPartIsSerialized, 
                                        >{inputs.jobPartIsSerialized == 'Yes'? <Typography style={{fontSize:14}}>{part?.partsAssetNumber}</Typography> : null}</TableCell>    
                                    }

                                <TableCell 
                                    border={(backGrounColor.index == i && backGrounColor.column == 'Location' ) ? backGrounColor.border : '1px solid lightgray' }   
                                    backgroundColor={(backGrounColor.index == i && backGrounColor.column == 'Location') && backGrounColor.color} 

                                    onMouseOver={()=>handleOnMouseOver(i, 'Location')}
                                    onMouseOut={()=>handleOnMouseOut(i, 'Location')}                                        
                                    onClick={()=>changePartsInStockValues( 'Location',part?.locationId, part.stockpartsId)} 
                                ><Typography style={{fontSize:14}}>{part?.locationName} </Typography></TableCell> 

                                {inputs.jobPartIsSerialized == 'No' && 
                                    <TableCell 
                                        border={(backGrounColor.index == i && backGrounColor.column == 'Quantity' ) ? backGrounColor.border : '1px solid lightgray' }   
                                        backgroundColor={(backGrounColor.index == i && backGrounColor.column == 'Quantity') && backGrounColor.color} 
                                        onMouseOver={()=>handleOnMouseOver(i, 'Quantity')}
                                        onMouseOut={()=>handleOnMouseOut(i, 'Quantity')}                                            
                                        onClick={()=>changePartsInStockValues('Quantity', part?.partQty, part.stockpartsId)}                                             
                                    > {inputs.jobPartIsSerialized == 'No' ? <Typography style={{fontSize:14}}>{part?.partQty}</Typography> : null} </TableCell>   }          {/*<TableCell border='1px solid lightgray'> <Button className='ButtonSmall' variant="outlined" sx={{ fontSize:12 }} onClick={()=>changePartsInStockValues(inputs.jobPartIsSerialized, part?.partSerialNumber, part.stockpartsId)}                                        ><text style={{fontSize:10, fontFamily:'cursive',  fontWeight:500}}>Update</text> </Button>                    </TableCell>  */}

                                </TableRow> 
                            ) }                                
                        </TableBody >  
                    </Table>                    
                </Stack>


                <Stack name="BUTTONS Control" className='stackButtons'  sx={{flex: '1 1 100%', mt:2, mb:1}} direction="row" gap={0}  justifyContent="center" alignItems="center"  > 
                    <Stack direction="row" className="stackButtons" gap={5} >                   {/* spacing={4} paddingTop={3} sx={{flex:'1 1 100%', justifyContent:'center', alignItems:'center', mt: action =='update' ? 12 : -35}} > */}                        
                        <Button 
                            className='Button1'
                            onClick={()=>{        
                                    //setAlertActive(false)                                        
                                    //setDialogValue(values=>({...values, ['partsId']: addPartInStockDetails?.partsId}))
                                    setDialogValue(values=>({...values, ['jobPartIsSerialized']: inputs.jobPartIsSerialized}))
                                    setDialogValue(values=>({...values, ['partsId']: id}))  
                                    setDialogValue(values=>({...values, ['partQty']: 1}))  
                                    openDialogAdd()                                                
                                } }                             
                            variant="outlined" 
                            sx={{ fontSize:11,  borderRadius:'5px', width:'25ch'}} 
                        > <Typography style={{ fontSize:12, fontWeight:600}}>{'add to stock'} </Typography>
                        </Button>
                    
                        <Button 
                            className='Button1'
                            onClick={handleClose} 
                            variant="outlined" 
                            sx={{ fontSize:11, borderRadius:'5px', width:'20ch'}} 
                        > <Typography style={{ fontSize:12, fontWeight:6002}}>close</Typography>
                        </Button>
                        
                    </Stack>
                </Stack>  

            </TabItem>

        </Tabs>


        <Dialog open={openPartInStockAdd} onClose={handleCloseDialogAdd} fullScreen={false} maxWidth={true}>
            <form onSubmit={handleAddForPartInStock}>
                <DialogTitle >
                    <Stack direction='row' gap={2}>
                        <Typography fontSize={16} fontWeight={500}> {`Add to inventory:  `}</Typography>
                        <Typography color='blue' fontSize={16} fontWeight={500}> {inputs.jobPartsDescription}</Typography>
                    </Stack>
                </DialogTitle>  {/* {' partsId: ' + dialogValue?.partsId + ' , jobPartIsSerialized : '+ dialogValue?.jobPartIsSerialized + ' , partQty: ' + dialogValue?.partQty} */}                                
                <DialogContent>
                    
                    <Stack gap={3}>

                        {dialogValue?.jobPartIsSerialized == 'Yes' && 
                            <>
                                <Stack name='SERIAL NUM'>
                                    <DialogContentText> <Typography style={{fontWeight:500}}>{'Serial Number:'} </Typography></DialogContentText>
                                    <TextField
                                        autoFocus                        //margin="dense" //variant="standard"
                                        value={dialogValue.partSerialNumber} 
                                        type="text"                                    
                                        onChange={(e) => {setDialogValue(values=>({...values, ['partSerialNumber']: e.target.value}))}}
                                    />
                                </Stack> 

                                <Stack name='ASSET NUM'>
                                    <DialogContentText> <Typography style={{ fontWeight:500}}>{'Asset Number:'} </Typography></DialogContentText>                            
                                    <TextField                                                                    
                                        value={dialogValue.partsAssetNumber} //autoFocus //margin="dense" // console.log(addPartInStockDetails)  //variant="standard"
                                        type="text"                                    
                                        onChange={(e) => { setDialogValue(values=>({...values, ['partsAssetNumber']: e.target.value})) }}
                                    />
                                </Stack>
                            </> 
                        }
                        <Stack name='LOCATIONS'>     
                             
                            <DialogContentText> 
                                <Typography style={{fontWeight:500}}>{'Locations:'} </Typography>                    
                            </DialogContentText>
                            {/* {console.log(partInStockDetails?.map(p => p.locationName))} */}

                            <SelectField
                                labelHidden={true} //icon={false} //size={'small'}
                                value={dialogValue.locationName}                            
                                iconColor="red"
                                onChange={(e) => { setDialogValue(values=>({...values, ['locationId']: e.target.value})) }  } 
                            > 
                                {                        
                                    locationList?.map( l=> {  

                                        // let a =[]
                                        // partInStockDetails?.map((p, i)=>{  a.push(p.locationName)  } )   
                                        // console.log(a[0])                                        
                                        
                                        // a.find((b)=>{
                                        //     console.log( b )
                                        //     //console.log( b  === l.locationName && l.locationName)
                                        // })

                                        return  (<option value={l.locationId} > <Typography fontSize={12}>{l.locationName}</Typography></option> )
                                    } )
                                    
                                }
                                            
                            </SelectField>
                        </Stack>

                        <Stack name='QTY NUM'>     
                            <DialogContentText> <Typography style={{ fontWeight:500}}>{'Quantity: '} </Typography></DialogContentText>
                            <SelectField
                                labelHidden={true}   //disabled={true}  //icon={false}   //size={'small'} //value={dialogValue.partsQty}                            
                                iconColor="red"
                                icon={dialogValue?.jobPartIsSerialized == 'Yes' && true}
                                disabled={dialogValue?.jobPartIsSerialized == 'Yes' && true}
                                options={['1','2','3','4','5','6','7','8','9','10','11','12','13','14', '15']}
                                onChange={(e) => setDialogValue(values=>({...values, ['partQty']: parseInt(e.target.value)}))} 
                            ></SelectField>
                        </Stack>   
                    </Stack>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogAdd}><Typography fontWeight={500}>Cancel</Typography></Button>
                    <Button type="submit" ><Typography fontWeight={500}>OK</Typography></Button>
                </DialogActions>
            </form>
        </Dialog> 

         
        <Dialog open={openPartUpdate} onClose={handleCloseDialog} maxWidth={true}>
            <form onSubmit={handleChagesForPartInStock}>
                <DialogTitle>

                    <Stack direction={'row'} gap={2}> 
                        <Typography fontSize={18} fontWeight={800}>{`Updating: `} </Typography>
                        <Typography fontSize={18} fontWeight={600} color='blue' >{inputs.jobPartsDescription}</Typography>  
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText> <Typography style={{ fontWeight:600}} sx={{ color: 'text.primary' }}>{dialogText} :</Typography></DialogContentText>
                        <Stack gap={1}>
                            {(dialogText == 'Asset Number' || dialogText == 'Serial Number') &&  
                                <TextField
                                    autoFocus                                    //margin="dense"
                                    value={dialogValue[dialogFieldName]}
                                    type="text"                                    //variant="standard"
                                    onChange={(e) => {
                                        setDialogValue(values=>({...values, [dialogFieldName]: e.target.value}));
                                        setDialogValue(values=>({...values, ['fieldName']: dialogFieldName}));
                                        setDialogValue(values=>({...values, ['fieldValue']: e.target.value}));
                                    }}
                                />
                            }

                            {dialogText == 'Location' && 
                                <SelectField
                                    labelHidden={true}                                    //icon={false}                                                    
                                    size={'small'}                                    //value={dialogValue.locationName}                            
                                    iconColor="blue"                                    ///options={locationList?.map(l=> l.locationName)}//{['1','2','3','4','5','6','7']}
                                    onChange={(e) => {
                                        setDialogValue(values=>({...values, ['locationId']: e.target.value}))
                                        setDialogValue(values=>({...values, ['fieldName']: dialogFieldName}));
                                        setDialogValue(values=>({...values, ['fieldValue']: e.target.value}));
                                    }  } 
                                > 
                                    {locationList.map(l => { 
                                        return ( <option value={l.locationId}><Typography fontWeight={600}>{l.locationName}</Typography></option>)
                                    } )}
                                </SelectField>
                            }

                            {dialogText == 'Quantity' && 
                                <SelectField
                                    labelHidden={true}                                    //icon={false}                                                    
                                    size={'small'}
                                    value={dialogValue.partsQty}                            
                                    iconColor="red"
                                    //options={['','0','1','2','3','4','5','6','7','8','9','10','11','12','13','14','15']}
                                    onChange={(e) => {
                                        setDialogValue(values=>({...values, ['partQty']: e.target.value}))
                                        setDialogValue(values=>({...values, ['fieldName']: dialogFieldName}));
                                        setDialogValue(values=>({...values, ['fieldValue']: e.target.value}));
                                    
                                    }  } 
                                >       
                                    <option value="1">Select Qty</option>
                                    <option value="0">Remove the part from the stock</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>    
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>                    
                                </SelectField>
                            }
                        </Stack>                            
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}><Typography fontWeight={600}>Cancel</Typography></Button>
                    <Button type="submit"><Typography fontWeight={600}>OK</Typography></Button>
                </DialogActions>
            </form>
        </Dialog> 

        {alertActive && (
            <Alert 
                isDismissible={true}
                hasIcon={true} // 
                heading={alertDetails.heading}
                variation={alertDetails.variation} //"info" "error" warning success //colorTheme={alertDetails.colorTheme} //onDismiss ={()=> setAlertActive(!alertActive)}
            >  {alertDetails.text }
            </Alert>
        )}  

        <JustAlert isOpen= {alertActive} openOff= {setAlertActive}  title= {'title'} dialogueTxt= {'dialogueTxt'}></JustAlert> 

    </>  

  )
}
export default NewPart


                                        //     <Button  //className='ButtonSmall'                                                
                                        //         onClick={()=>{        
                                        //             //setAlertActive(false)                                        
                                        //             //setDialogValue(values=>({...values, ['partsId']: addPartInStockDetails?.partsId}))
                                        //             setDialogValue(values=>({...values, ['jobPartIsSerialized']: inputs.jobPartIsSerialized}))
                                        //             setDialogValue(values=>({...values, ['partsId']: id}))  
                                        //             setDialogValue(values=>({...values, ['partQty']: 1}))  
                                        //             openDialogAdd()                                                
                                        //         } }//action==='update'? handleUpdate : handleAdd }
                                        //         variant="outlined" 
                                        //         sx={{ fontSize:14, color:'blue', fontWeight:800, fontFamily:'cursive'}} 
                                        //     >ADD to Stock</Button> 
////////////////////////////////////



                                    //  <TableRow >                                                
                                    //      <TableCell border='1px solid lightgray' >
                                    //         {inputs.jobPartIsSerialized == 'Yes'  ?     
                                    //             <TextField  
                                    //                 value={addPartInStockDetails?.partSerialNumber}                                        
                                    //                 size='small'
                                    //                 style={{width:'20ch'}}
                                    //                 onChange={(e)=>{setAddPartInStockDetails(values=>({...values, ['partSerialNumber']: e.target.value}))     }}
                                    //             />
                                    //         : null}                                            
                                    //     </TableCell>          

                                    //     <TableCell border='1px solid lightgray'>
                                    //         {inputs.jobPartIsSerialized == 'Yes'  ?     
                                    //             <TextField  
                                    //                 value={addPartInStockDetails?.partsAssetNumber}                                                                               
                                    //                 size='small'
                                    //                 style={{width:'15ch'}}
                                    //                 onChange={(e)=>{ setAddPartInStockDetails(values=>({...values, ['partsAssetNumber']: e.target.value})) }}
                                    //             />
                                    //         : null}
                                            
                                    //     </TableCell>  

                                    //     <TableCell border='1px solid lightgray'>
                                    //         <TextField  
                                    //             value={addPartInStockDetails?.locationName}                                        
                                    //             size='small'
                                    //             //name ="jobPartsDescription" 
                                    //             style={{width:'15ch'}}
                                    //             onChange={(e)=>{ setAddPartInStockDetails(values=>({...values, ['locationName']: e.target.value})) }}
                                            
                                    //         />
                                            
                                    //     </TableCell> 

                                    //     <TableCell border='1px solid lightgray'> 
                                    //         {inputs.jobPartIsSerialized == 'No'  ?      
                                    //             <SelectField
                                    //                 labelHidden={true}                                        
                                    //                 size={'small'}
                                    //                 value={addPartInStockDetails?.partQty}                            
                                    //                 iconColor="red"
                                    //                 onChange={(e) => setAddPartInStockDetails(values=>({...values, ['partsQty']: parseInt(e.target.value)}))} 
                                    //             >
                                    //                 <option value="1">1</option>
                                    //                 <option value="2">2</option>
                                    //                 <option value="3">3</option>
                                    //                 <option value="4">4</option>
                                    //                 <option value="5">5</option>
                                    //                 <option value="6">6</option>
                                    //                 <option value="7">7</option>
                                    //                 <option value="8">8</option>
                                    //                 <option value="9">9</option>
                                    //             </SelectField>
                                    //         :
                                    //             <text style={{marginLeft:'1ch',  fontSize:16, fontWeight:600, fontFamily:'cursive'}}>1 </text>  
                                                
                                    //         }

                                    //     </TableCell>   

                                    //     <TableCell border='1px solid lightgray'>
                                    //         <Button 
                                    //             className='ButtonSmall'
                                    //             onClick={()=>console.log(addPartInStockDetails) }//action==='update'? handleUpdate : handleAdd }
                                    //             variant="outlined" 
                                    //             sx={{ fontSize:12 }} 
                                    //         ><text style={{fontSize:10, fontFamily:'cursive',  fontWeight:600}}>Add</text>
                                    //         </Button>                                                                        
                                    //     </TableCell>     

                                    // </TableRow>  



















                                            // <TextField  
                                            //     value={part?.locationName}                      
                                            //     //required
                                            //     //autoFocus={true}                    
                                            //     size='small'
                                            //     //name ="jobPartsDescription" 
                                            //     style={{width:'15ch'}}
                                            //     // error={!VALID.current || inputs.jobPartsDescription === ''? true:false}
                                            //     // helperText={inputs.jobPartsDescription === ''? "populate with some value":''} 
                                            //     onChange={(e)=>{
                                            //         // setPartDescription(e.target.value)
                                            //         VALID.current = validateField(e.target.value, notEmpty)
                                            //         handleChange(e)
                                            //     }}
                                            // /> 
                                                // <SelectField
                                                //     labelHidden={true}
                                                //     //disabled={true}
                                                //     //icon={false}                                                    
                                                //     size={'small'}
                                                //     value={part?.partQty}                            
                                                //     iconColor="red"
                                                //     //onChange={(e) => setNewOrderedPart(values=>({...values, ['partsQty']: parseInt(e.target.value)}))} 
                                                // >


                                                //     <option value="1">1</option>
                                                //     <option value="2">2</option>
                                                //     <option value="3">3</option>
                                                //     <option value="4">4</option>
                                                //     <option value="5">5</option>
                                                //     <option value="6">6</option>
                                                //     <option value="7">7</option>
                                                //     <option value="8">8</option>
                                                //     <option value="9">9</option>
                                                // </SelectField>
                                                // <TextField  
                                                //     value={part?.partsAssetNumber}                                                                                      
                                                //     size='small'                                                
                                                //     style={{width:'15ch'}}
                                                //     onChange={(e)=>{
                                                //         // setPartDescription(e.target.value)//VALID.current = validateField(e.target.value, notEmpty)
                                                //         handleChange(e)
                                                //     }}
                                                ///>

                        // {/* {alertActive ? (
                        //     <Alert 
                        //         isDismissible={true}
                        //         hasIcon={true}
                        //         heading="Alert heading 1"
                        //         variation="error"
                        //         //colorTheme="error"
                        //         onDismiss ={() => setAlertActive(!alertActive)}
                        //     >
                        //         This is the alert content.
                        //     </Alert>
                        // ) : null} */}


                        //     {/* <Alert variation="info">Info</Alert>
                        //         <Alert variation="error">Error</Alert>
                        //         <Alert variation="warning">Warning</Alert>
                        //         <Alert variation="success">Success</Alert> */}







                                    // {/* <TableRow height='6ch'>                                                
                                    //     <TableCell border='1px solid lightgray' width='20ch'>
                                    //         <text style={{marginLeft:'3ch', fontSize:14, fontWeight:600, fontFamily:'cursive'}}>Serial Number </text></TableCell>
                                    //     <TableCell border='1px solid lightgray'>
                                    //         <TextField  
                                    //             value={inputs.jobPartSerialNumber} 
                                    //             size='small'
                                    //             name ="jobPartSerialNumber" 
                                    //             style={{width:'75ch'}}                         //sx={{boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, mr:3}}
                                    //             onChange={(e)=>{                    
                                    //                 //VALID.current = validateField(e.target.value, '');     //setInputs(values=>({...values, ['customerLogo']: e.target.value ===''?'':e.target.value}))
                                    //                 handleChange(e);
                                    //             }}                                    //onBlur={e=>setInputs(values=>({...values, ['customerLogo']: e.target.value.trim() ===''?null:e.target.value.trim()}))}
                                    //         />  
                                    //     </TableCell>                                                
                                    // </TableRow> */}


                                    // {/* <TableRow height='6ch'>                                                
                                    //     <TableCell border='1px solid lightgray' width='15ch'>
                                    //         <text style={{marginLeft:'3ch', fontSize:14, fontWeight:600, fontFamily:'cursive'}}>Asset Number </text></TableCell>
                                    //     <TableCell border='1px solid lightgray'>
                                    //         <TextField  
                                    //             value={inputs.jobPartsAsset} 
                                    //             size='small'
                                    //             name ="jobPartsAsset" 
                                    //             style={{width:'75ch'}}                         
                                    //             onChange={(e)=>{handleChange(e)}}                                    
                                    //         />  
                                    //     </TableCell>                                                
                                    // </TableRow> */}




                                    // {/* <TableRow height='6ch'>                                                
                                    //     <TableCell border='1px solid lightgray' width='15ch'>
                                    //         <text style={{marginLeft:'3ch', fontSize:14, fontWeight:600, fontFamily:'cursive'}}>Manufacturer</text>
                                    //     </TableCell>

                                    //     <TableCell border='1px solid lightgray' width="25ch">                                    
                                    //         <TextField  
                                    //             value={inputs.jobPartsManufacturer}//{customerEmail} 
                                    //             name ="jobPartsManufacturer" 
                                    //             size='small'
                                    //             style={{width:'75ch'}}
                                    //             onChange={(e)=>{ handleChange(e, VALID.current) }}
                                    //         /> 
                                            
                                    //     </TableCell>                                                
                                    // </TableRow> */}




















// const myHeaders = new Headers();
// myHeaders.append("Content-Type", "application/xml");
// myHeaders.append("Authorization", "Basic VEdDSV9GSUdVUkU4X0RFVjpqR3loR0h3cjc4NU9oU2FBMFE5Ow==");
// myHeaders.append("Cookie", "PD-ID=mMDr2cZV6RhUCOjcPQDahqncSV0uQRxVCJXxA9aOKgfSAJlZEeChCH4SayITharJdPjpSy9O9XyNZ2z9p0vk8XegaVh4QuOmppDiwwDwiM31jpGgIyK9AVEeotpsZsbJ8nSPsbwn25a10aAzU63Ut0fe+TCb035/5Pmt5lX23aC6W5jRSgqrnedk33Ul+tWs51ShxFUi7c38PnKv/lqa1j6JzaaTSXlDymMAH624xHk0WcX7Os0/Sql9W6nRRXG+Orpr0H9UtbCpzHTZUhfPmw==; __cflb=02DiuJLt8zuAYbv68VehXiGorBqJHKSGc7RMedPzkZiVP");

// const raw ="<ns1:TGCIGatewayRequest xmlns:ns1=\"https://tgcs04.toshibacommerce.com/TGCIGatewayXSD\">\r\n<ns1:PartnerReferenceNumber></ns1:PartnerReferenceNumber>\r\n<ns1:TransactionType>VendorSearchActiveSR</ns1:TransactionType>\r\n<ns1:ServicePayload>\r\n<ns2:TGCISearchActiveSRJobRequest xmlns:ns2=\"https://tgcs04.toshibacommerce.com/TGCIMicroServiceXSD\">\r\n<ns2:SearchActiveSRRequest>\r\n<ns2:TGCSSRNumber>446320</ns2:TGCSSRNumber>\r\n<ns2:CustInternalSRNumber></ns2:CustInternalSRNumber>\r\n<ns2:CustContactFirstName></ns2:CustContactFirstName>\r\n<ns2:CustContactLastName></ns2:CustContactLastName>\r\n<ns2:TGCSMachineType></ns2:TGCSMachineType>\r\n<ns2:TGCSModel></ns2:TGCSModel>\r\n<ns2:TGCSItemSerialNo></ns2:TGCSItemSerialNo>\r\n<ns2:CustInternalPartNo></ns2:CustInternalPartNo>\r\n<ns2:CustStoreNo></ns2:CustStoreNo>\r\n<ns2:CustCountry></ns2:CustCountry>\r\n</ns2:SearchActiveSRRequest>\r\n</ns2:TGCISearchActiveSRJobRequest>\r\n</ns1:ServicePayload>\r\n</ns1:TGCIGatewayRequest> \r\n";

// const requestOptions = {
//   method: "POST",
//   headers: myHeaders,
//   body: raw,
//   redirect: "follow"
// };

// fetch("https://www.toshibacommerce.com/TGCI/uat/", requestOptions)
//   .then((response) => response.text())
//   .then((result) => console.log(result))
//   .catch((error) => console.error(error));