import React, {useState, useRef, useEffect } from 'react';
import {Stack, Button, TextField, Typography} from '@mui/material'; 
import Fab from '@mui/material/Fab';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit'; //import { mailformat, mobileNumber} from '../Regex.js';// import { useParams, useNavigate} from "react-router-dom";
import { TabItem, Tabs, Text, View, useTheme, Card } from '@aws-amplify/ui-react';
import {useNavigate } from "react-router-dom";
import styled from 'styled-components'; // import { Padding } from '@mui/icons-material';
import {regExpr} from '../Regex.js'

const Container = styled.div`
  background: ${props => props.bgc};
  color: ${props => props.primary ? "black" : "transparent"};
  margin-top:0ch;
  border: 3px solid ${props=> props.bc } ;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height:${props=>props.h};
  width:${props=>props.w};
  box-shadow:0 2px 6px gray;
`;

const {
    REACT_APP_API_HTTP,
    REACT_APP_API_LOCAL_DOMAIN,
    REACT_APP_API_LOCAL_PORT,
    REACT_APP_API_GET_CONTACT_DATA,
    REACT_APP_API_ADD_NEW_CONTACT,
    REACT_APP_API_CONTACT_UPDATE,
    REACT_APP_API_CONTACT_DELETE,
    NODE_ENV,
  } = process.env;


const NewContact = ({entityID, entitySearchField}) => {
    const [contactName, setContactName] = useState('');
    const [contacEmail, setContactEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');     //const [contactId, setContactId] =useState('')    //const [newContactId, setNewContactId] = useState('')    //const [entityName, setEntityName] = useState('contacts') 
    const [contactInputs, setContactInputs] = useState({});
    const [contactsData, setContactsData] = useState({});
    const [contactsNumbers,setContactsNumbers] =useState(0);
    const [value, setValue]=useState(0);    //const [contactKey,setContactKey] =useState(0)
    const [refresh, setRefresh] = useState(false);
    //const [isFieldValid, setIsFieldValid] = useState(false);
    
    const VALID= useRef(false)
    const VALID_Name = useRef(false);
    const VALID_MobNumber = useRef(false);    //const [loaded, setLoaded] = useState(true)
    const VALID_Email= useRef(false);
    

    const navigate = useNavigate();
    const { tokens } = useTheme();

    const productionHost = REACT_APP_API_HTTP + window.location.host;
    const localHost = REACT_APP_API_HTTP + REACT_APP_API_LOCAL_DOMAIN + REACT_APP_API_LOCAL_PORT;
    const DOMAIN = `${NODE_ENV==='production'?productionHost:localHost}`; 
    
    useEffect(()=>{      
        refresh && getContactData(`${DOMAIN}${REACT_APP_API_GET_CONTACT_DATA}${entitySearchField}/${entityID}`)///getContactData(`${DOMAIN}${REACT_APP_API_GET_CONTACT_DATA}${entitySearchField}/${entityID}`)
        handleNewTabClick()
        setRefresh(false)
        const a= Array.name
    }, [refresh]);

    useEffect(()=>{      
        setContactInputs(values=>({...values, ['entityID']: entityID }))
        setContactInputs(values=>({...values, ['entitySearchField']: entitySearchField }))
        getContactData(`${DOMAIN}${REACT_APP_API_GET_CONTACT_DATA}${entitySearchField}/${entityID}`)           //setLoaded(false)    
    }, []);

    const handleAddContact = ()=>{
        //console.log(contactInputs)// if (contactInputs.contactName === undefined || contactInputs.contactName === null){   alert(`In order to add a contact You have to provide at least Contact's Name`); //     return;        // }
        postNewContactData(DOMAIN + REACT_APP_API_ADD_NEW_CONTACT, contactInputs)
        setContactInputs('');
        setContactName('');
        setContactNumber('');
        setContactEmail('');        //getContactData(`${DOMAIN}${REACT_APP_API_GET_CONTACT_DATA}${entitySearchField}/${entityID}`)
        setRefresh(true);        //window.location.reload()
    }

    const handleUpdateContact=(contID)=>{
        putData(`${DOMAIN}${REACT_APP_API_CONTACT_UPDATE}${contID}`, contactInputs);       // console.log(contID) // console.log(entitySearchField, entityID)     //getContactData(`${DOMAIN}${REACT_APP_API_GET_CONTACT_DATA}${entitySearchField}/${entityID}`)
        setRefresh(true)   //window.location.reload()
    } 

    async function getContactData(Url) {      
        //console.log(Url)
        const response = await fetch(Url);
        console.log(response.status)
        const contacts = await response.json();  
        console.log(contacts)
        setContactsNumbers(contacts.length)
        setValue(contacts.length)
        setContactsData(contacts)
        const keys= Object.keys(contacts)
        //console.log(keys)       //setContactKey(contacts.length)
    }

    async function postNewContactData(Url, BodyInput) {
        //.log(BodyInput)
        const requestOptions = {   
            method: 'POST', 
            body: JSON.stringify({ BodyInput }),    
            headers: {'Content-Type':'application/json; charset=utf-8' },   
        } 
        const response = await fetch(Url, requestOptions);
        const responseData = await response.json();  

        //setNewContactId(responseData.insertId)
        //console.log(responseData.insertId)                                 
    }

    async function putData(Url, BodyInput) {    
        //console.log(BodyInput)
        const requestOptions = {   
            method: 'PUT', 
            body: JSON.stringify({ BodyInput }),    
            headers: {'Content-Type':'application/json; charset=utf-8' },   
        } 
        const response = await fetch(Url, requestOptions);
        const responseData = await response.json();  
        //console.log(responseData)
    }

    const handleDeleteContact=(contID)=>{
        // const a = prompt('Are You sure you want to delete this contact?','No')
        // console.log(a)
        // if (a === null || a === "No"){
        //     //console.log('contact ID : ' + contID, " client ID : " + id)
        // }else{
        DeleteData(`${DOMAIN}${REACT_APP_API_CONTACT_DELETE}${contID}`);
        // console.log(contID);
        // console.log(entityID, entityName);    
        //getContactData(`${DOMAIN}${REACT_APP_API_GET_CONTACT_DATA}${id}`);
        //window.location.reload()
        navigate(-1)
            
        
    } 

    async function DeleteData(Url) {    

        console.log(Url)
        const requestOptions = {method: 'DELETE'} 
        const response = await fetch(Url, requestOptions);
        const responseData = await response.json();  
    }

    // const validateField = (inputText, flag)=>{ 

    //     flag===''? VALID.current = true: inputText.match(flag)? VALID.current = true: VALID.current = false
    //     return  VALID.current 

    // }

    const isValidField = (inputText, flag, fieldName)=>{ 
        // console.log(fieldName)
        // console.log(inputText)

        // console.log("SEARCH ", inputText.search(flag)) /// if found ret 0 not found ret -1
        // console.log('MATCH ',  inputText.match(flag))  // if found ret array [0433333] not found ret null

        if (inputText.search(flag) === 0 ){

            switch (fieldName) {
                case 'NAME_ADD': VALID_Name.current=true; break;          
                case "MOBILENUMBER_ADD": VALID_MobNumber.current=true; break;
                case 'EMAIL_ADD': VALID_Email.current = true;  break;
            }        
            return true;

        }else{

            switch (fieldName) {
                case 'NAME_ADD':  VALID_Name.current=false; break;
                case "MOBILENUMBER_ADD":  VALID_MobNumber.current=false; break;
                case 'EMAIL_ADD': VALID_Email.current = false;  break;
            } 

            return false;
        }

        // flag===''? setIsFieldValid(false): inputText.match(flag)? setIsFieldValid(true): setIsFieldValid(false)
        // return flag===''? true: inputText.match(flag)? true:  false
        //return response
    }

    const handleContactChange=(e)=>{
        //action === 'new'?  setContactInputs(values=>({...values, ['clientId']: newClientId})) :  setContactInputs(values=>({...values, ['clientId']: id}))  
        setContactInputs(values=>({...values, ['entityID']: entityID }))
        setContactInputs(values=>({...values, ['entitySearchField']: entitySearchField }))
        const name= e.target.name ?? "KEY";
        const value = e.target.value ?? 'INVALID';
        setContactInputs(values=>({...values, [name]: value})) //: setContactInputs(values=>({...values, [name]: 'INVALID'}))   
    }

    const handleTabClick=(c, i)=>{
        //  console.log(contactsData)  
        //  console.log(value) 
        //  console.log(i) 
        //setContactKey(i)
        setContactName(c.contactName)
        setContactNumber(c.contactPhoneNumber)   
        setContactEmail(c.contactEmail) 
        //setContactId(c.contactId)   
        setContactInputs(values=>({...values, ['contactName']: c.contactName})) 
        setContactInputs(values=>({...values, ['contactPhoneNumber']: c.contactPhoneNumber})) 
        setContactInputs(values=>({...values, ['contactEmail']: c.contactEmail})) 
        setContactInputs(values=>({...values, ['contactId']: c.contactId}))         
        // VALID_contactNumber.current = isValidField(c.contactPhoneNumber, mobileNumber)
        // VALID_contacEmail.current = isValidField(c.contactEmail, mailformat)
    }

    const handleNewTabClick=()=>{
        //console.log(contactsData)  
        setContactName("")
        setContactNumber('')   
        setContactEmail('') 
        //setContactId('')   
        // VALID_contactNumber.current = false
        // VALID_contacEmail.current = false
        setContactInputs({}) 
        //console.log(contactsNumbers)
    }

    //if (action ==='update' && loaded ){
    // if (loaded ){
    //     setContactInputs(values=>({...values, ['entityID']: entityID }))
    //     setContactInputs(values=>({...values, ['entitySearchField']: entitySearchField }))
    //     getContactData(`${DOMAIN}${REACT_APP_API_GET_CONTACT_DATA}${entitySearchField}/${entityID}`)
        
    //     setLoaded(false)
    // }

    const AllContats = () => {     

        return (
            
            <Tabs
                justifyContent="flex-start"
                align-items = 'cente'
                justify-self= 'center'
                currentIndex={value}//{contactKey}
                //indicatorPosition="top"
                //defaultIndex={contactsNumbers===0 ? 0: contactsNumbers }
                //spacing='relative'
                //width={600} 
                height={30}
                onChange={(v)=>{
                    setValue(parseInt(v))
                    //console.log(v)
                } }
            >

                {contactsData.map((c, i) => (
                    <TabItem 
                        key = {i} 
                        title = { <Typography color="blueviolet" fontSize={12} fontWeight={i ===value ?800:400} marginTop='-8px'> {c.contactName }</Typography> } 
                        backgroundColor = {i === value ?'aquamarine':'aliceblue'}//
                        border = { i === value ?'3px solid blue':'3px dotted lightgreen'}
                        borderRadius = '10px'
                        //height={17}                
                        onClick={()=>handleTabClick(c, i) }
                    >
                        <Card 
                            variation="elevated"
                            backgroundColor='#ebfcfc'      //'#f7ffff' 
                            height={180} 
                            marginTop={0}                                
                            borderRadius={2}
                            //border='1px solid blue'
                            boxShadow="1px 1px 2px 2px var(--amplify-colors-blue-20)"
                        >
                            <Stack name="MAIN" direction="row" sx={{ml:10}}  >

                                <Stack name="FIELDS" direction="column" gap={1} > 
                                    <Stack direction="row" gap={2}>
                                        <Stack  width={300}>
                                            <TextField  
                                                label={<Typography style={{color:'#2d187be0'}}>Name</Typography>}
                                                value = {contactName}
                                                name ={`contactName`}
                                                size='small'
                                                // style={{fontFamily:"cursive", }}
                                                helperText={
                                                    contactName===''? 
                                                    <Typography style={{fontSize:10, fontWeight:400}}>Enter Contact Name</Typography>: !VALID_Name.current ? 
                                                    <Typography style={{fontSize:10, fontWeight:400,}}>Not Valid</Typography> 
                                                    :<Typography style={{fontSize:10, fontWeight:400,}}>Valid</Typography>
                                                }
                                                error={!isValidField(contactName, regExpr.notEmpty, 'NAME_ADD')}
                                                onChange={(e)=>{
                                                    setContactName(e.target.value) //isValidField(e.target.value, "")
                                                    handleContactChange(e)
                                                }}
                                            />
                                        </Stack>

                                        <Stack width={175}>
                                            <TextField                                         
                                                label={<Typography style={{color:'#2d187be0'}}>Mobile Number</Typography>} 
                                                value={contactNumber}
                                                name ="contactPhoneNumber" 
                                                //disabled={true}
                                                size='small'
                                                //style={{fontFamily:"cursive",}} //helperText = {contactNumber ===''? "Enter Mobile number": !isValidField(contactNumber, mobileNumber) ? "Number is invalid":''}                                    error = {!isValidField(contactNumber, regExpr.mobileNumber)}  ///{!VALID_contactNumber.current? true:false}   
                                                helperText={                                                                                                        
                                                    contactNumber===''? 
                                                    <Typography style={{fontSize:10, fontWeight:400,}}>Enter Mobile Number</Typography>:      !VALID_MobNumber.current ? 
                                                    <Typography style={{fontSize:10, fontWeight:400 }}>Number is Invalid</Typography> 
                                                    :<Typography style={{fontSize:10, fontWeight:400}}>Valid</Typography>   
                                                }  //phonenum
                                                error = {!isValidField(contactNumber, regExpr.mobileNumber, "MOBILENUMBER_ADD")} 
                                                onChange={(e)=>{
                                                    setContactNumber(e.target.value)    ///VALID_contactNumber.current = validateField(e.target.value, phonenum)
                                                    handleContactChange(e)
                                                } }
                                            /> 
                                        </Stack>
                                    </Stack>

                                    <Stack width={490} sx={{mt:1}}> 
                                        <TextField  
                                            label={<Typography style={{color:'#2d187be0'}}>Email</Typography>}
                                            size='small'
                                            value={contacEmail}                                        // style={{height:'5ch'}}
                                            sx={{zIndex:0}}
                                            name = "contactEmail" //helperText={contacEmail ===''?"Enter email": !isValidField(contacEmail, mailformat) ? "Email is invalid":''}  error = {!isValidField(contacEmail, mailformat)}   ///{!VALID_contacEmail.current? true:false}  
                                            helperText={
                                                contacEmail===''? <Typography style={{fontSize:10}}>Enter Email</Typography>:
                                                !VALID_Email.current ? <Typography style={{fontSize:10}}>Email is Invalid</Typography> :<Typography style={{fontSize:10}}>Valid</Typography>  
                                            }                                 
                                            error = {!isValidField(contacEmail, regExpr.mailformat, 'EMAIL_ADD')}
                                            onChange={(e)=>{
                                                setContactEmail(e.target.value)                              ///VALID_contacEmail.current = validateField(e.target.value, mailformat)
                                                handleContactChange(e)
                                            }}        
                                        />  

                                   </Stack>

                                </Stack>

                                <Stack  name='BUTTONS' gap={4} direction="column" sx={{ml:'2ch'}}>
                                    <Button 
                                        onClick= {()=>{
                                            handleUpdateContact(c.contactId)
                                            handleNewTabClick()
                                        }} 
                                        disabled={!VALID_MobNumber.current || !VALID_Name.current || !VALID_Email.current }
                                        sx={{ fontSize:11}}
                                    > <EditIcon sx={{mr:3, mb:.5}}/> <Typography fontWeight={600}>Update</Typography></Button>
                            
                                    <Button 
                                        onClick= {()=>handleDeleteContact(c.contactId)}       //variant="extended"  //size="small" 
                                        color="warning" 
                                        sx={{ fontSize:11}}
                                    > <DeleteIcon sx={{mr:3, mb:.5}}/><Typography fontWeight={600}>Delete</Typography>  </Button>

                                </Stack>

                            </Stack >

                        </Card>


                    </TabItem> 
                    ))
                }

                <TabItem 
                    title={<Typography color="red"  fontSize={10} fontWeight={contactsNumbers ===value ? 800:400} marginTop='-6px' >ADD NEW</Typography>} 
                    backgroundColor = {contactsNumbers === value ?'lavenderblush':'aliceblue' }
                    border = { parseInt(contactsNumbers) === value ?'3px solid red':'3px dotted pink' }                    
                    borderRadius = '10px'
                    //height={17}
                    //border='1px solid white'
                    onClick={()=>handleNewTabClick()}
                >
                    
                    <Card 
                        variation="elevated"
                        backgroundColor='antiquewhite'//'#fff7f7' 
                        height={180} 
                        marginTop={0}                                
                        borderRadius={2}
                        boxShadow="1px 1px 2px 2px var(--amplify-colors-red-20)"
                    >
                        <Stack name="MAIN" direction="row" sx={{ml:10}}  >
                            <Stack name="FIELDS" direction="column" gap={1} > 
                                <Stack direction="row" gap={3}>
                                    <Stack direction="row"  gap={2} > 
                                    
                                        <Stack direction="row" width={300}  >
                                            <TextField  
                                                label={<Typography style={{color:'#2d187be0', fontSize:14}}>Name</Typography>}
                                                value = {contactName} 
                                                name ="contactName"
                                                size='small'
                                                autoComplete='off'    // sx={{boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, zIndex:0}}
                                                helperText={
                                                    contactName===''? <Typography style={{fontSize:10, fontWeight:400, }}>Please Enter Contact Name</Typography>:!VALID_Name.current ? 
                                                    <Typography style={{fontSize:10, fontWeight:400, }}>Not Valid</Typography> : <Typography style={{fontSize:10, fontWeight:400, }}>Valid</Typography>
                                                }
                                            
                                                error={!isValidField(contactName, regExpr.notEmpty, 'NAME_ADD')}
                                                onChange={(e)=>{
                                                    setContactName(e.target.value)                                            // isValidField(e.target.value, regExpr.notEmpty, 'contactName')
                                                    handleContactChange(e)
                                                }}
                                            />
                                        </Stack>

                                        <Stack width={175} >
                                            <TextField  
                                                label={<Typography style={{color:'#2d187be0', fontSize:14}}>Mobile Number</Typography>} 
                                                value={contactNumber}
                                                name ="contactPhoneNumber" 
                                                size='small'                                      //disabled={!isValidField(contactNumber, regExpr.mobileNumber)}
                                                style={{ height:'5ch'}}      //sx={{boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`, zIndex:0}} // helperText={contactNumber ===''?"Please enter phone number": !VALID_contactNumber.current?"Phone number is invalid":''}
                                                helperText={
                                                    contactNumber===''? <Typography style={{fontSize:10, fontWeight:400}}>Please Enter Mobile Number</Typography>:
                                                    !VALID_MobNumber.current ?  <Typography style={{fontSize:10, fontWeight:400}}>Number is Invalid</Typography> 
                                                    :<Typography style={{fontSize:10, fontWeight:400}}>Valid</Typography>                                
                                                }  
                                                error = {!isValidField(contactNumber, regExpr.mobileNumber, "MOBILENUMBER_ADD")} 
                                                onChange={(e)=>{
                                                    setContactNumber(e.target.value) //     //VALID_MobNumber.current = isValidField(contactNumber, regExpr.mobileNumber, "MOBILENUMBER_ADD")///isValidField(contactNumber, regExpr.mobileNumber ,"contactPhoneNumber")
                                                    handleContactChange(e)
                                                } }
                                            /> 
                                        </Stack>
                                    </Stack>
                                </Stack>
                                
                                <Stack width={490} sx={{mt:1}}> 
                                    <TextField  
                                        label={<Typography style={{color:'#2d187be0', fontSize:14}}>Email</Typography>}
                                        size='small'
                                        value={contacEmail}                                    
                                        sx={{ zIndex:0}}
                                        name = "contactEmail"                                                            
                                        helperText={
                                            contacEmail===''? <Typography style={{fontSize:10}}>Please Enter Email</Typography>:
                                            !VALID_Email.current ? <Typography style={{fontSize:10,}}>Email is Invalid</Typography> 
                                            :<Typography style={{fontSize:10, }}>Valid</Typography>                                                                                                                          
                                        }                                 
                                        error = {!isValidField(contacEmail, regExpr.mailformat, 'EMAIL_ADD')}
                                        onChange={(e)=>{
                                            setContactEmail(e.target.value)                                         //VALID_contacEmail.current = isValidField(e.target.value, mailformat)// {contacEmail !=="" && !VALID_contacEmail.current? true:false}  
                                            handleContactChange(e)
                                        }}        
                                    />  
                                
                                </Stack>

                            </Stack>                

                            <Stack name='BUTTON' direction="row" sx={{ml:'1ch'}}>    
                                <Button 
                                    onClick= { handleAddContact} 
                                    disabled={!VALID_MobNumber.current || !VALID_Name.current || !VALID_Email.current }
                                    sx={{fontSize:12}}
                                > 
                                    <AddIcon sx={{mr:1, mb:.5}}/><Typography fontSize={14} fontWeight={600}>Add Contact </Typography>
                                </Button>
                            </Stack>    
                        </Stack>
                        
                    </Card>
                

                </TabItem>

            </Tabs>     
         );
    };


    return (

        <View
            //backgroundColor={'var(--amplify-colors-neutral-20)'}            //border="1px solid red"
            boxShadow=" 1px 1px 1px 1px gray"
            borderRadius={2}            //height={210} 
            width={'85ch'}
            textAlign="center"
        >
            {contactsData.length !== undefined && AllContats() }    
        </View>
    )

}
export default NewContact