import React, { useState, useEffect, useRef} from 'react';
import { Autocomplete } from '@react-google-maps/api';
import Stack from '@mui/material/Stack'; 
import Modal from 'react-modal';
import '../../src/styles/components/modal.scss';
//import '../../src/components/newitem/newitem.scss';   //import { Paper } from '@mui/material';
import {CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js';
import styled, { keyframes } from 'styled-components';
import { PasswordField, useTheme,} from '@aws-amplify/ui-react';
import { TextField } from '@mui/material';
//import {PasswordInput} from '../../src/components/Styles.jsx'

  /* global google */ 
const google = window.google      ////IMPORTANT FOR GOOGLE MAPS TO WORK


const Button = styled.button`
background: ${props => props.primary ? "#BF4F74" : "white"};
color: ${props => props.primary ? "white" : "#BF4F74"};
font-size: 1em;
margin: 1.4em;
padding: 0.3em 1em;
border: 2px solid #BF4F74;
border-radius: 5px;
cursor:pointer;
`;

const Input = styled.input.attrs(props => ({type: "text",  $margin: props.m || "1em",  $padding: props.p || ".5em",}))`
  color: #BF4F74;
  font-size: 1em;
  border: 2px solid #BF4F74;
  border-radius: 3px;
  margin: ${props => props.$margin};
  padding: ${props => props.$padding};
`;

// const PasswordInput = styled(Input).attrs({ type: "password",})`
//   border: 2px solid aqua;
// `;

const DEBUG = process.env.REACT_APP_DEBUG;

const customStyles = {

  overlay: {
    backgroundColor: 'lightgray', //'#a1d0b8',
    opacity:1,
    zIndex : 10
  },

  content: {
    position:'absolute',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-25%',
    //opacity:.,
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border :'1px solid #333333CC',
    boxShadow: '2px 2px 3px 2px rgba(100, 100, 111, 0.3)',
    width:'60ch', 
    height:'fit-content',
    borderRadius:'5px',
    color: 'steelblue',
    backgroundColor:'papayawhip',
    zIndex : 11
      
  },
};

export const ModChangePassword = ({isOpen, setIsOpen, userLoginName, setChangePswResponse}) => {
        Modal.setAppElement('#root');    
        const [open, setOpen] = useState(false);
        //const [returnValue, setReturnValue]= useState()
        const [currPassword, setCurrPassword]= useState('')
        const [newPassword, setNewPassword]= useState('')

        const closeModal = ()=>{ 
          setOpen(false) 
          setIsOpen(false)
        };    
        
        const openModal = ()=>{ setOpen(true) };

        useEffect(() => {
          isOpen &&  openModal()//:closeModal()
        },[isOpen]);

        const validationRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/;//  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
        const errorMessage = `Requires uppercase, lowercase, number and symbol, a minimum of 8 chars`;

        const changePassword = async(userName, currentUserPassword, newUserPassword)=>{

          const userPool = new CognitoUserPool({
              UserPoolId: process.env.REACT_APP_USER_POOL_ID, // Your user pool id here
              ClientId: process.env.REACT_APP_CLIENT_ID, // Your client id here
          })
          const cognitoUser = new CognitoUser({
              Username: userName,  //testaccount
              Pool: userPool,
          });
  
          const resultAuth = await authentUser(cognitoUser, userName, currentUserPassword); // if (flag==='confirm'){//const authenticationDetails = new AuthenticationDetails({Username : userName, Password : window.prompt("Please enter password","")})///"!Hello_11?"});   //authentUser();        //     //.deleteUser((a)=>{console.log(a)})); //console.log(user.getUsername());  //getUserSession();        // }else{        //     authentUser();        // }             
          if(resultAuth){
            await changePswd(cognitoUser, userName, currentUserPassword, newUserPassword)            
            setChangePswResponse(true)

          }else{
            setChangePswResponse(false)
            return false;
          }

      };
        
        return (
            <Modal
                isOpen={open}                
                 //portalClassName={'custom-modal-portal'}
                //className="Modal"                
                //overlayClassName="Overlay" // 
                //className={`custom-modal-content ${className} ${small && 'small'} ${extraSmall && 'extra-small'} ${big && 'big'}`}
                closeTimeoutMS={250}
                shouldCloseOnOverlayClick={false}
                onRequestClose={()=>closeModal()}
                style={customStyles}                // onAfterClose={onAfterClose}  //contentRef={contentRef}
            >
              <div
                  variant="elevation" 
                  elevation={8}
                //  sx={{ width:'60ch', height:'40ch'}}
              >
                    <Stack > 
                       <h2 style={{ fontSize:'20px', fontWeight:400, padding:'4px', marginLeft:'12ch', marginBottom:'1ch', marginTop:'1ch', }}> {'Change Password'} </h2>  
                    </Stack>

                    <Stack name ='PASSWORDS FRAME' direction='column' gap={2}>

                      <Stack name='CURRENT PASSWORD' direction='row'>
                                  {/* <h5 style={{padding:'5px', marginLeft:'1ch',  marginTop:'3ch',  width:'25ch', }}> Enter Current Password</h5>  <PasswordInput m='1em' p='.4em' onChange={(e)=>{setCurrPassword(e.target.value)}}  /> */}

                        <PasswordField
                            onChange={(e) => {setCurrPassword(e.target.value) }}
                            width='45ch'
                            label={<h4 style={{ fontWeight:400, padding:'1px', marginLeft:'0ch',  marginTop:'3ch', color: 'steelblue', }}>Current Password</h4>  }
                            value={currPassword}
                            hasError={!validationRegex.test(currPassword)}
                            //errorMessage={errorMessage}
                            autoComplete="current-password"                //descriptiveText="Password must have uppercase, lowercase, number and symbol, and be at least 8 characters long"//boxShadow= '2px 2px 4px 1px rgba(100, 100, 111, 0.3)'                    
                        />

                      </Stack>

                      <Stack name = 'NEW PASSWORD' direction='row'>
                        {/* <h5 style={{padding:'5px', marginLeft:'1ch',  marginTop:'3ch',  width:'25ch', }}> Enter New Password</h5>                    <PasswordInput m='1em' p='.4em' onChange={(e)=>{setNewPassword(e.target.value)}}/> */}

                        <PasswordField
                            onChange={(e) => {
                              setNewPassword(e.target.value);
                            }}
                            width='45ch'
                            label={<h4 style={{ fontWeight:400, padding:'1px', marginLeft:'0ch',  marginTop:'3ch',  color: 'steelblue', }}>New Password</h4>  }
                            value={newPassword}
                            hasError={!validationRegex.test(newPassword)}
                            //errorMessage={errorMessage}
                            //boxShadow= '2px 2px 4px 1px rgba(100, 100, 111, 0.3)'
                            descriptiveText={<text style={{ fontSize:12}}>Password must have uppercase, lowercase, number and symbol, and be at least 8 characters long</text>}
                        />


                      </Stack>

                    </Stack> 

                    <Stack direction='row' sx={{ml:'10ch', mt:'5ch'}} >
                      <Stack >
                        <Button  
                          primary
                          onClick={()=>{
                            DEBUG && console.log(currPassword)
                            DEBUG && console.log(newPassword)
                            changePassword(userLoginName, currPassword, newPassword)
                            closeModal()
                            // if (returnValue ){
                            //   setChangePswResponse(true) //                          
                            //   setOpen(false) 
                            //   setIsOpen(false)
                            // }

                          }}> Change </Button>
                      </Stack>

                      <Stack >
                        <Button 
                          onClick={()=>{
                            setChangePswResponse(false) 
                            closeModal()
                          } }
                        >Close</Button>
                      </Stack>
                    </Stack>
              </div>
            </Modal>

        );
};

///////////////////

export const ModAlert = ({ isOpen, setIsOpen, title, description, setNavigateFlag}) => {
  Modal.setAppElement('#root');       
  const [open, setOpen] = useState(false);

  const closeModal = ()=>{     
    setIsOpen(false)
    setOpen(false) 
    setNavigateFlag(true)
  };    
  
  const openModal = ()=>{ setOpen(true) };

  useEffect(() => {
    isOpen &&  openModal() //:closeModal()
  },[isOpen]);

  return (
      <Modal
        isOpen={open}                     // portalClassName={'custom-modal-portal'}
        //className="Modal"                //overlayClassName="Overlay" // className={`custom-modal-content ${className} ${small && 'small'} ${extraSmall && 'extra-small'} ${big && 'big'}`}
        closeTimeoutMS={250}
        shouldCloseOnOverlayClick={false}
        onRequestClose={()=>closeModal()}
        style={customStyles}                // onAfterClose={onAfterClose}  //contentRef={contentRef}
        //backgroundColor='gray'
      >
        <Stack
          direction="column" 
          justifyContent="center" 
          alignItems="center" 
          alignContent={"center" }
          sx={{ flex: '1 1 100%', mb:5 }} 
        >
            <Stack > 
              <h3 > {title} </h3>  
            </Stack>
        </Stack>

        <Stack                
          direction="column" 
          justifyContent="center" 
          alignItems="center" 
          alignContent={"center" }
          sx={{ flex: '1 1 100%' }} 
        >
          <Stack > 
              <div style={{ fontWeight:400, }}> {description}</div>  
          </Stack>                  

          <Stack width = '25ch' justifyContent={'center'} alignContent={'center'} style={{marginTop:'5ch', marginbottom:'5ch',}}>
            <Button onClick={closeModal}> OK </Button>
          </Stack>


        </Stack>
    
      </Modal>

  );
};

export const ModPrompt = ({isOpen, description,  setResponse, isPassword}) => {
  Modal.setAppElement('#root');       
  const [open, setOpen] = useState(false);
  const [returnValue, setReturnValue]= useState('');
    

  const closeModal = ()=>{setOpen(false)};        //setIsOpen(false)
  const openModal = ()=>setOpen(true);
  const response = (e)=>{setReturnValue(e.target.value)};        

  useEffect(() => {
     isOpen && openModal() 
     isOpen && setResponse('')
  },[isOpen]); 
  

  return (
      <Modal
          isOpen={open}
          // className="Modal"
          //overlayClassName="Overlay"
          // className={`custom-modal-content ${className} ${small && 'small'} ${extraSmall && 'extra-small'} ${big && 'big'}`}
          closeTimeoutMS={250}
          shouldCloseOnOverlayClick={false}
          onRequestClose={closeModal}
          style={customStyles}
          // onAfterClose={onAfterClose}
          // contentRef={contentRef}
      >
        
        {/* <Stack
          direction="column" 
          justifyContent="center" 
          alignItems="center" 
          alignContent={"center" }
          sx={{ flex: '1 1 100%', mt:5 }} 
        >
            <Stack > 
              <h3 style={{fontFamily:'cursive', }}> {title} </h3>  
            </Stack>
        </Stack> */}

        <Stack                
          direction="column" 
          justifyContent="flex-start" 
          alignItems="center" 
          sx={{ flex: '1 1 100%' }} 
        >
          <Stack           
            justifyContent="flex-start" 
            alignItems="center" 
            sx={{ flex: '1 1 100%' }} 
          > <text style={{ fontSize:13, fontWeight:400, marginTop:'2ch', marginbottom:'2ch'}}> {description}</text>  
          </Stack>  {/* <Input m='3em' onChange={response}  /> */}

          {isPassword  ?
              <PasswordField
                  onChange={response}
                  width='30ch'
                  //label={<h4 style={{ fontWeight:400,padding:'2px', marginLeft:'0ch',  marginTop:'3ch',  width:'25ch', color: 'lightsteelblue', }}></h4>  }
                  value={returnValue}
              />
          :
              <TextField 
                  //type= 'text' 
                  //margin='3em' 
                  onChange={response}
                  style={{width:'30ch',   marginTop:'2ch'}}
                  defaultValue={'No'}
                           
              />
          }
          <Stack direction='row'          
            //justifyContent="center" //alignItems="center" //alignContent={"flex-start" } //sx={{ flex: '1 1 100%' }} //gap={5}
          >
            <Stack style={{marginTop:'1px', marginbottom:'1px'}}>
              <Button  onClick={()=>{
                setResponse(returnValue) ;             
                closeModal();
            
              }}> OK </Button>
            </Stack>

            <Stack style={{marginTop:'1px', marginbottom:'1px'}}>
              <Button  onClick={()=>{
                setResponse('');          
                closeModal();
            
              }}> Cancel</Button>
            </Stack>
          </Stack>

        </Stack>
    
      </Modal>

  );
};

////////////////////

export const useUnload = fn => {
  const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload = (...args) => cb.current?.(...args);
    window.addEventListener("beforeunload", onUnload);
    return () => window.removeEventListener("beforeunload", onUnload);
  }, []);
};

export const useFetch = (url) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => setData(data));
  }, [url]);

  return [data];
};

export const dateConverter = (inputDate, flag = true) =>{
  let mainDate;
           //  console.log(typeof inputDate === 'number' && 'date passed as number' )
  if(flag){
    if( inputDate !== null ){
      mainDate = new Date(inputDate * 1000);
    }else{
      return '';
    }
  }else{
    mainDate=inputDate;

  }
  // convert from 10 numbers to 13 numbers   new Date(parseInt(inputDate.padEnd(13,"0"))) ///
  let options = {
      //weekday: "short",
      day:"numeric",
      month:"numeric",
      year:"numeric",
      hour: "numeric",
      minute: "numeric",
      //era: "long",
      //dayPeriod:"long",
      //dayPeriod: "short",
      
      //second: "numeric",
      //timeZone: "Australia/Sydney",
      hour12: false,
      //timeZoneName: "short",
      //       weekday         'narrow'        'M'
      //                 'short'         'Mon'
      //                 'long'          'Monday'

      // year            '2-digit'       '01'
      //                 'numeric'       '2001'

      // month           '2-digit'       '01'
      //                 'numeric'       '1'
      //                 'narrow'        'J'
      //                 'short'         'Jan'
      //                 'long'          'January'
      // day             '2-digit'       '01'
      //                 'numeric'       '1'
      // hour            '2-digit'       '12 AM'
      //                 'numeric'       '12 AM'
      // minute          '2-digit'       '0'
      //                 'numeric'       '0'
      // second          '2-digit'       '0'
      //                 'numeric'       '0'
      // timeZoneName    'short'         '1/1/2001 GMT+00:00'
      //                 'long'          '1/1/2001 GMT+00:00'
  };

  //  let a = new Date(parseInt(Date.parse(new Date).toString().slice(0,10))*1000)
  //  let b = new Date(Date.parse(new Date))

  //  console.log(inputDate)
  //  console.log(a.toLocaleString('en-AU', options))
  //  console.log(b.toLocaleString('en-AU', options))
  const date= new Date()
  const today = new Intl.DateTimeFormat('en-AU', {dateStyle:'short', timeStyle:'medium'}).format(date)
  //console.log(today)



  return mainDate.toLocaleString('en-AU', options)
};



export const timeZone= (state)=>{
  let zone;
  switch (state) {
    case 'VIC' ||  "TAS" || "ACT" || 'NSW':
      zone='Australia/Sydney'
        break;
    case 'WA':
      zone='Australia/Perth'
        break;
    case "SA":
      zone='Australia/Adelaide'
        break;
    case 'QLD':
      zone='Australia/Brisbane'
        break;
    case "NT":
      zone='Australia/Darwin'
        break;
  }

  return zone;

}

export function GetLocation({ setCenterSite, destination, setSiteLabel}){
    const [searchResult, setSearchResult] = useState('')
    const [newDest, setNewDest] = useState(destination);

    function onLoad(autocomplete) { 
      setSearchResult(autocomplete) 
      DEBUG &&  console.log(autocomplete)
      //  console.log(`Lng: ${lng}`);
      //  getLoc(autocomplete)
    }

    function onPlaceChanged() {
      DEBUG && console.log('eeeeeeeeeeeeeeeee')

      if (searchResult !== null || searchResult !== undefined ) {
        DEBUG && console.log(searchResult)
        const place = searchResult.getPlace();
        //variable to store the name from place details result 
        //const name = place.name;
        //variable to store the status from place details result
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()
        //variable to store the formatted address from place details result
        const formattedAddress = place.formatted_address;
        setNewDest(formattedAddress)

        // console.log(place);
        // console.log(`Name: ${name}`);
        // console.log(`Lat: ${lat}`);
        // console.log(`Lng: ${lng}`);
        // console.log(`Formatted Address: '${formattedAddress}'`);
        setCenterSite({lat: parseFloat(lat), lng: parseFloat(lng) })
        setSiteLabel(formattedAddress)

      } else {
          alert("Please enter text");
      }
    }

    return(
      <Autocomplete 
        onPlaceChanged={onPlaceChanged} 
        onLoad= {onLoad}
        restrictions={{country:['au','nz']}}
      >
        <input  
          type="text" 
          value={newDest}
          style={{
            //boxSizing: `border-box`,
            backgroundColor:'transparent',
            border: `1px solid transparent`,
            width:'73ch',
            height: `3.5ch`,
            marginLeft:'0px',
            padding: `10px`,
            // borderBottom:'1px solid blue',            // borderRadius: `3px`,            //boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `12px`,
            outline: `none`,
            //textOverflow: `ellipses`,
          }}
          onChange={(e)=>setNewDest(e.target.value)}
        />
    </Autocomplete>
    )

};

export function SearchBox({destination, setDestination, origin, setOrigin}){
  const [searchResult, setSearchResult] = useState('')

  function onLoad(autocomplete) { 
      setSearchResult(autocomplete) 
      //{console.log("JJJJJMMMMM origin : ", origin)}  
      //  const place =  autocomplete.getPlace()   // const name = place.name;
      // //variable to store the status from place details result
      // const lat = place.geometry.location.lat()
      // const lng = place.geometry.location.lng()
    //  console.log(place);      // console.log(`Lng: ${lng}`);
    
  }

  // function getLoc(au){
  //   if (searchResult != null) {
  //    //console.log(searchResult)
  //   //  console.log(au)
  //   //    const place = au.getPlace();
  //   //   console.log(place);

  //     }
  // }

  function onPlaceChanged() {

    if (searchResult !== null) {
      //variable to store the result
      const place = searchResult.getPlace();
      //variable to store the name from place details result 
      const name = place.name;
      //variable to store the status from place details result
      const lat = place.geometry.location.lat()
      const lng = place.geometry.location.lng()
      //variable to store the formatted address from place details result
      const formattedAddress = place.formatted_address;

      DEBUG && console.log(place);
      DEBUG && console.log(`Name: ${name}`);
      DEBUG && console.log(`Lat: ${lat}`);
      DEBUG && console.log(`Lng: ${lng}`);
      DEBUG && console.log(`Formatted Address: '${formattedAddress}' `);
    

    } else {
      alert("Please enter text");
    }
  }

  return(
        <>
          {origin !=='' &&     
      
            <Autocomplete 
              onPlaceChanged={onPlaceChanged} 
              onLoad= {onLoad}
              restrictions={{country:['au','nz']}}
              //types={['hospital', 'pharmacy', 'bakery', 'country', 'street_address']}
            >                              
                <input
                    type="text"
                    value={origin} ////{`${inputs.siteAddress1??''} ${inputs.siteSuburb??''} ${inputs.siteState??''} ${inputs.sitePostCode??''}`} ///ref={destinationRef}
                    onChange={(e)=>setOrigin(e.target.value)}
                    style={{
                        top:'2px',
                        boxSizing: `border-box`,
                        border: `1px solid gray`,
                        width :'425px',
                        height: `30px`,
                        padding: `0 10px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `13px`,
                        fontWeight: 500,
                        //outline: `none`,
                        textOverflow: `ellipses`,
                        position: "absolute",
                        left: "1%",
                        //marginLeft: "-120px"
                    }}
                />                    
            </Autocomplete>
          }

          <Autocomplete  restrictions={{country:['au','nz']}} >
            <input
                type="text"
                value={destination} ////{`${inputs.siteAddress1??''} ${inputs.siteSuburb??''} ${inputs.siteState??''} ${inputs.sitePostCode??''}`} 
                //ref={destinationRef}
                onChange={(e)=>{
                  setDestination(e.target.value)
                  console.log(e.target.value)
                }}
                style={{
                    top:'2px',
                    boxSizing: `border-box`,
                    border: `1px solid gray`,
                    width:`${origin === undefined ? '700px': '425px'} `,
                    height: `30px`,
                    padding: `0 5px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `13px`,
                    fontWeight: 500,
                    //outline: `none`,
                    textOverflow: `ellipses`,
                    position: "absolute",
                    left: `${origin === undefined ? '21%': '45%'} `,
                    marginLeft: "1px"
                }}
            />
          </Autocomplete>
      </>
  )

};

export function SearchAddress({destination, setDestination, setLat, setLng, setSiteState, setSitePostCode, setSiteSuburb, size}){
  const [searchResult, setSearchResult] = useState('')
  const [borderSize, setBorderSize] =useState(false)

  function onLoad(autocomplete) { 
    DEBUG && console.log('rrrrrrrrrrrrrrrrrrrreeeeeeeeeeeeeeeee')
    setSearchResult(autocomplete) 
  }

  function onPlaceChanged() {
    DEBUG && console.log('eeeeeeeeeeeeeeeee')
    if (searchResult !== null) {
      //variable to store the result
      const place = searchResult.getPlace();   //variable to store the name from place details result 
      const name = place.name;
      const compon =place.address_components;  //variable to store the status from place details result
      
      const lat = place.geometry.location.lat()
      const lng = place.geometry.location.lng()      //variable to store the formatted address from place details result
      const formattedAddress = place.formatted_address;      // DEBUG && console.log(place);      // DEBUG && console.log(place.address_components);

       for (let i = 0; i < place.address_components.length; i++){

        if (compon[i].types[0] ==='locality' ){
          setSiteSuburb(compon[i].short_name)
          DEBUG && console.log(compon[i].short_name)
        }else if(compon[i].types[0] ==='administrative_area_level_1'){
          setSiteState(compon[i].short_name)
          DEBUG && console.log(compon[i].short_name)
        }else if (compon[i].types[0] ==='postal_code'){
          setSitePostCode(compon[i].short_name)
          DEBUG && console.log(compon[i].short_name)
        }        
      }
      DEBUG && console.log(`Name: ${name}`);
      DEBUG && console.log(`Lat: ${lat}`);
      setLat.current=lat
      DEBUG && console.log(`Lng: ${lng}`);
      setLng.current=lng
      DEBUG && console.log(`Formatted Address: '${formattedAddress}' `);
      setDestination(formattedAddress)
    } else {
      alert("Please enter text");
    }
  }

  return(
    
    <Autocomplete  
      restrictions={{country:['au','nz']}} 
      onPlaceChanged={onPlaceChanged} 
      onLoad= {onLoad}
      fontWeight={800}
    >
      <input
          type="text"
          value={destination} ////{`${inputs.siteAddress1??''} ${inputs.siteSuburb??''} ${inputs.siteState??''} ${inputs.sitePostCode??''}`}               //ref={destinationRef}
          onChange={(e)=>setDestination(e.target.value)}
          onMouseOver={()=>setBorderSize(true)}
          onMouseOut={()=>setBorderSize(false)}
          style={{
              boxSizing: `border-box`,
              border: `1px solid lightgray`,
              width:`${size === undefined || size===null ? '60%': `${size}px`} `,
              height: `4ch`,
              padding: `0 10px`,
              borderRadius: `5px`,
              boxShadow: `${borderSize ?`0 0 2px 2px rgba(0, 0, 0, 0.5)`:`0 0 2px 1px rgba(0, 0, 0, 0.3)`}`,
              fontSize: `14px`,
              fontFamily:'Arial',
              fontWeight: 600,
              background:'transparent',
              outline: `none`,
              textOverflow: `ellipses`,
              //position: "absolute",
              //left: `${origin === undefined ? '21%': '45%'} `,
              marginLeft: "3ch",
              
          }}
      />
    </Autocomplete>
      
  )

};



// export const getLatLngFromAddress = async(address )=>{
//   const google = window.google  

//   let geocoder = new google.maps.Geocoder();

//   await geocoder.geocode( { 'address': address}, function(results, status) {
//     if (status == google.maps.GeocoderStatus.OK) {
//           let setLat = results[0].geometry.location.lat();
//           let setLng = results[0].geometry.location.lng();
//           console.log (setLat, setLng)
//           //return (`${setLat} ${setLng}`)

//         } 
//     }
//   ); 
// };


export const sendNote = async(text, userId, url)=>{

  const bodyNote={
      noteUserId:userId,
      noteDetails:text,
  }
  const requestOptions = {   
      method: 'POST', 
      headers: {'Content-Type':'application/json; charset=utf-8' }, 
      body: JSON.stringify( {bodyNote} ),    
  } 


  await fetch(url, requestOptions);
  // getNotes()

}

export const postActivity= async(name, lat, lng, address, employee, status = 1, update = '', activityUID)=>{
  // const AlexSUID = 'd68f0155-2406-4dd4-8dd8-e77a281b2e5b'
  // const FredSUID = 'a6c0fbfc-d118-4a67-a904-acb67ca1f223' 
  // const PeterSUID = '799e4ef1-d9d4-4dda-af44-3ff3f47de05b'  
  DEBUG && console.log(name);
  DEBUG && console.log(lat ?? 'not available', lng ?? 'not available');
  DEBUG && console.log( employee);
  DEBUG && console.log(status, update);
  DEBUG && console.log(activityUID);

  let isLatLngAvailable= false;
  let bodyAdd;
  if(lat == undefined || lng == undefined ){
    DEBUG && console.log('lat and lng undefined')
    isLatLngAvailable=false
    bodyAdd = {
      "externalId": `${name}`,
      "name": `Job# ${name}`,
      "plannedTimeUTC": new Date(),
      "plannedDurationMinutes": 360,
      "activityType": "Daily Work Tasks",
      "employees": [`${employee}`],
      "requiredNumberOfEmployees": 1,
      "address": `${address}`,
    }
  }else{
    isLatLngAvailable=true
    bodyAdd = {
      "externalId": `${name}`,
      "name": `Job# ${name}`,
      "latitude": `${String(lat ?? '')}`,
      "longitude": `${String(lng ?? '')}`,
      "plannedTimeUTC": new Date(),
      "plannedDurationMinutes": 360,
      "activityType": "Daily Work Tasks",
      "employees": [`${employee}`],
      "requiredNumberOfEmployees": 1,
      "address": `${address}`,    
    }
  }

  const ActivityUID = update === '' ? '' : update === 'status' ? `/${activityUID}` : update === 'employees' ? `/${activityUID}/employees`: '';
  const url= `${process.env.REACT_APP_SHEQSY_ACTIVITY_URL}${ActivityUID}`;
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json; text/plain; charset=utf-8");
  myHeaders.append("Authorization", `${process.env.REACT_APP_SHEQSY_AUTH}`);

  //const bodyAdd = {
  //   "externalId": `${name}`,
  //   "name": `Job# ${name}`,
  //   // "latitude": `${String(lat ?? '')}`,
  //   // "longitude": `${String(lng ?? '')}`,
  //   "plannedTimeUTC": new Date(),
  //   "plannedDurationMinutes": 360,
  //   "activityType": "Daily Work Tasks",
  //   "employees": [`${employee}`],
  //   "requiredNumberOfEmployees": 1,
  //   "address": `${address}`,
  
  // }

  const bodyChangeStatus = {
    "status": status,
    "employees": [`${employee}`]
  }

  const bodyChangeUser = {
    // "name": `Job# ${name}`,
    // "plannedTimeUTC": new Date(),
    // "plannedDurationMinutes": 360,
    // "activityType": "Daily Work Tasks",
    "employees": [`${employee}`],
    
  }

  const requestOptions_POST = {
    method:'POST',
    headers: myHeaders,
    body: JSON.stringify(update ==='' ? bodyAdd : update ==='status' ?  bodyChangeStatus: bodyChangeUser),
    redirect: 'follow'
  };

  const requestOptions_GET = {
    method:'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  DEBUG && console.log(requestOptions_POST.body, url)

  const response = await fetch(url, update === 'GET' ? requestOptions_GET : requestOptions_POST)
  const responseData = await (update ==='' || update === 'GET'? response.json(): response.text())
  DEBUG && console.log(responseData)

  return update ==='' ? responseData.activityGuid : update ==='status' ? 'status dokei  : ' + responseData : update ==='employees' ? 'employee dokei ' +  responseData : responseData         //responseData.activityGuid
    
    //return 'Testing Success: activityGuid' 
   // fetch(url, requestOptions)
  // .then(response => response.text())
  // .then(result => console.log(result))
  // .catch(error => console.log('error', error));
}

export const calculateTime= (slaHours, isWeekends, ...start)=>{
  const oneHour=3600000/1000/60  // number of min 
  const totalNumMin = oneHour * slaHours
  const startDate = start[0] !== undefined ? new Date(start[0] * 1000) : new Date(Date.now())

  //console.log(startDate)
  //const endDate = new Date(Date.now() + nHour)
  const toFindEndDate = new Date(Date.now() ) 

  // console.log(endDate)
  //console.log(nHour)

  let minToCount = 0;
//  if (endDate < startDate) { return 0; }

  // Loop from your Start to End dates (by hour)
  const current = startDate;

  // Define work range
  const workHoursStart = 9;
  const workHoursEnd = 17;
  const includeWeekends = isWeekends;

  // Loop while currentDate is less than end Date (by minutes)
  //console.log(current)

  for(let a=0;  minToCount < totalNumMin;  a++) {
      // Is the current time within a work day (and if it occurs on a weekend or not) 

      if(current.getHours() >= workHoursStart && current.getHours() < workHoursEnd && (includeWeekends ? current.getDay() !== 0 && current.getDay() !== 6 : true)){
        minToCount++;
        toFindEndDate.setTime(current.getTime() + 1000 * 60);
      }
      current.setTime(current.getTime() + 1000 * 60);           // Increment current time by one min ( + 1000 * 60)      
  }

  //console.log(toFindEndDate )
  return toFindEndDate //minutesWorked / 60;
};

export const addTime = (date, timeIntervals, flag, state)=> {
  DEBUG && console.log(date)

  let options = {
    day:"numeric",
    month:"numeric",
    year:"numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
    timeZoneName: "long",
    timeZone: timeZone(state)
  }

  const m = (f)=> {
    if(f == 'm'){
      return 60000
    }else if(f=='h'){
      return 3600000
    }else if(f=='d'){
      return 3600000*24
    }else{
      return 10000
    }
  }
  const calculatedTime = new Date(date.getTime() + timeIntervals * m(flag));
  return calculatedTime.toLocaleString('en-AU', options)
};



export const calculateZone=(siteLng, siteLat, siteState)=>{


  DEBUG && console.log(siteLng, siteLat, siteState)

  let dist =0;
  let gpoLat = 0;
  let gpoLng = 0;

  const vicGpoLat = -37.813250012122204;
  const vicGpoLng = 144.963352137594; 

  const qldGpoLat = -27.467938659782607;
  const qldGpoLng = 153.02791280031772;

  const nswGpoLat = -33.86757842094773;
  const nswGpoLng = 151.2072171868791;

  const waGpoLat = -31.952274084889623;
  const waGpoLng = 115.85901122083074;

  const tasGpoLat = -42.88238456614443;
  const tasGpoLng = 147.33014194802675;

  const saGpoLat = -34.92691220801934;
  const saGpoLng = 138.59879324157237;

  const ntGpoLat = -12.460974351019471;
  const ntGpoLng =  130.8419695369975;

  const actGpoLat = -35.27803484431844; 
  const actGpoLng = 149.12834504448048;

  switch (siteState.toUpperCase()) {
      case 'VIC':
          gpoLat = vicGpoLat;
          gpoLng = vicGpoLng;
          break;

      case "TAS":
          gpoLat = tasGpoLat;
          gpoLng = tasGpoLng;
          break;

      case 'NSW':
          gpoLat = nswGpoLat;
          gpoLng = nswGpoLng;
          break;

      case "ACT":
          gpoLat = actGpoLat;
          gpoLng = actGpoLng;
          break;

      case 'WA':
          gpoLat = waGpoLat;
          gpoLng = waGpoLng;
          break;

      case "SA":
          gpoLat = saGpoLat;
          gpoLng = saGpoLng;
          break;

      case 'QLD':
          gpoLat = qldGpoLat;
          gpoLng = qldGpoLng;
          break;

      case "NT":
          gpoLat = ntGpoLat;
          gpoLng = ntGpoLng;
          break;
  }

  dist = Math.sqrt(Math.pow(Math.abs(Math.abs(siteLng) - Math.abs(gpoLng)) * 87.8, 2) + Math.pow(Math.abs(Math.abs(siteLat) - Math.abs(gpoLat)) * 110, 2)).toFixed(0) * 1 

  if (dist <= 50) {
    DEBUG && console.log("Metro ", dist)
      return 'Metro';
  }else{
      if (dist >= 51 && dist <= 100){
        DEBUG && console.log("Regional ", dist)
          return 'Regional';
      }else if(dist >=101 && dist <= 150){
        DEBUG && console.log("Remote ", dist)
        return 'Remote';
      }else{
        DEBUG && console.log("Remote+ ", dist)
        return 'Remote+';
      }        
  }
  //return Math.sqrt(Math.pow(Math.abs(Math.abs(siteLng) - Math.abs(gpoLng)) * 87.8, 2) + Math.pow(Math.abs(Math.abs(siteLat) - Math.abs(gpoLat)) * 111, 2)).toFixed(2)*1 
  //return dist
};

export const sendEmails = async (fromEmail, toEmail, title, templateName, ...arr) => {

  ///NEW JOB EMAIL'S FIELDS     contractorName, siteName, jobName, startDate, siteAddress, faultDescription, confirmationTime, contact, contactNumber

  const{REACT_APP_API_HTTP, REACT_APP_API_LOCAL_DOMAIN, REACT_APP_API_LOCAL_PORT, NODE_ENV} = process.env
  const productionHost = REACT_APP_API_HTTP + window.location.host;
  const localHost = REACT_APP_API_HTTP + REACT_APP_API_LOCAL_DOMAIN + REACT_APP_API_LOCAL_PORT;
  const DOMAIN = `${NODE_ENV==='production' ? productionHost : localHost}`; 
  const sendEmailsUrl = `${DOMAIN}${process.env.REACT_APP_API_SENDEMAILS}`;

  let emailText='';
  switch (templateName ) {
    case 'newJob' :
      let contractorNameInEmail = arr[0].replaceAll(' ', '_')
      let contractorPhoneInEmail = arr[10].replaceAll(' ', '_')
      const acceptUrl = `${DOMAIN}${process.env.REACT_APP_API_CONFIRMJOB}${arr[2]}/${arr[9]}/${contractorNameInEmail}/${fromEmail}/${contractorPhoneInEmail}`; //
      const rejectUrl = `${DOMAIN}${process.env.REACT_APP_API_REJECTJOB}${arr[2]}/${arr[9]}/${contractorNameInEmail}`;
      emailText= `<p><span style="color: rgb(206, 145, 120);">Dear ${arr[0]},</span></p>
      <p><br></p>
      <p><span style="color: rgb(206, 145, 120);">I hope you are well. </span></p>
      <p> </p>
      <p><span style="color: rgb(206, 145, 120);">We are pleased to inform you that a new job has been created and requires your expertise. </span></p>
      <p><span style="color: rgb(206, 145, 120);">As a valued member of our team, we believe your skills align perfectly with the requirements for this task.</span></p>
      <p><br></p>
      <p><span style="color: rgb(206, 145, 120);">Job Details:</span></p>
      <p><span style="color: rgb(206, 145, 120);">- Site Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${arr[1]}</span></p>
      <p><span style="color: rgb(206, 145, 120);">- Job Number:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${arr[2]}</p>
      <p><span style="color: rgb(206, 145, 120);">- SLA Expiry:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${arr[3]}</span></p>
      <p><span style="color: rgb(206, 145, 120);">- Address:&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${arr[4] ?? ''}</span></span></p>
      <p><span style="color: rgb(206, 145, 120);">- Point of Contact:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${arr[7] ?? ''}</span></span></p>
      <p><span style="color: rgb(206, 145, 120);">- Contact Number:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${arr[8] ?? ''}</span></span></p>
      <p><br></p>
      <p><span style="color: rgb(206, 145, 120);">Scope of Work: &nbsp;</span></p>
      <p><span style="color: rgb(206, 145, 120);">${arr[5] ?? ""}&nbsp;</span></p>
      <p><br></p>
      <p><span style="color: rgb(206, 145, 120);">Next Steps:</span></p>
      <p><span style="color: rgb(206, 145, 120);">1. Please confirm your availability for this assignment by <strong style="text-align:right">${arr[6]}</strong></span></p>
      <p style="text-align:left"><span style="color: rgb(206, 145, 120);">
      <form action=${acceptUrl} novalidate><button type="submit" style=" padding: 15px 22px; background-color: lightgreen; color:steelblue; cursor:pointer;">Accept</button></form>&nbsp;&nbsp;&nbsp;
      <p></p>   
      <form action=${rejectUrl} novalidate><button cursor='pointer' type="submit" style="padding: 15px 22px; background-color: coral; color: steelblue; cursor:pointer;">Decline</button></form></span></p>
      <p></p>
      <p><span style="color: rgb(206, 145, 120);">2. If you have any questions or need further details, feel free to reach out on (03) 9964 8941 Head of Field Services</span></p>
      <p><span style="color: rgb(206, 145, 120);">&nbsp; &nbsp; or send email to support@figure8services.com.au.</span></p>
      <p><br></p>
      <p><span style="color: rgb(206, 145, 120);">We will provide you with any necessary onboarding instructions and additional project-related information in the coming days.</span></p>
      <p></p>
      <p><span style="color: rgb(206, 145, 120);">We appreciate your dedication and support. We are confident that your expertise will greatly contribute to our overall success.</span></p>
      <p></p>
      <p><span style="color: rgb(206, 145, 120);">Thank you for your continued partnership, and we look forward to working with you.</span></p>
      <p><br></p>
      <p><span style="color: rgb(206, 145, 120);">Best regards.</span></p></main>
      `;
      break;
    case 'updateJob' :
      emailText=`<p><span style="color: rgb(206, 145, 120);">Dear,</span></p>
      <p><br></p>
      <p><span style="color: rgb(206, 145, 120);">I hope you are well. </span></p>
      <p><br></p>
      <p><span style="color: rgb(206, 145, 120);">We are pleased to inform you that a new job has been created and requires your expertise. </span></p>
      <p><span style="color: rgb(206, 145, 120);">As a valued member of our team, we believe your skills align perfectly with the requirements for this task.</span></p>
      <p><br></p>
      <p><span style="color: rgb(206, 145, 120);">We will provide you with any necessary onboarding instructions and additional project-related information in the coming days.</span></p>
      <p><br></p>
      <p><span style="color: rgb(206, 145, 120);">We appreciate your dedication and support. We are confident that your expertise will greatly contribute to our overall success.</span></p>
      <p><br></p>
      <p><span style="color: rgb(206, 145, 120);">Thank you for your continued partnership, and we look forward to working with you.</span></p>
      <p><br></p>
      <p><span style="color: rgb(206, 145, 120);">Best regards.</span></p></main>
      `;
      break;
    case 'newUser':
      emailText=`
      <p>Hi ${arr[2]}&nbsp;</p>
      <p><br></p>
      <p>We hope you're well.</p>
      <p><br></p>
      <p>Welcome to Autom8 Systems on&nbsp;behalf of Figure8 Technology Services</p>
      <p>Below are your temporary login details that will enable you access the system.</p>
      <p><strong style="color: rgb(80, 0, 80);">Your Login Name is :</strong><span style="color: rgb(80, 0, 80);">&nbsp;${arr[0]}</span></p>
      <p><strong style="color: rgb(80, 0, 80);">Your Temporary Password is :</strong><span style="color: rgb(80, 0, 80);">&nbsp; ${arr[1]}</span></p>
      <p><br></p>
      <p>Once you have logged in, you will be prompted to change your password.</p>
      <p>The password requirements are:</p>
      <p><span style="background-color: rgb(255, 255, 255); color: rgb(80, 0, 80);">Contains at least 1 number</span></p>
      <p><span style="background-color: rgb(255, 255, 255); color: rgb(80, 0, 80);">Contains at least 1 special character</span></p>
      <p><span style="background-color: rgb(255, 255, 255); color: rgb(80, 0, 80);">Contains at least 1 uppercase letter</span></p>
      <p><span style="background-color: rgb(255, 255, 255); color: rgb(80, 0, 80);">Contains at least 1 lowercase letter</span></p>
      <p><br></p>
      <p>When you are ready navigate to&nbsp;<a href="https://autom8systems.com.au/" rel="noopener noreferrer" target="_blank" style="color: rgb(102, 163, 224);">https://autom8systems.com.au/</a>&nbsp;and follow the prompts</p>
      `;    
      break;
  }
  DEBUG && console.log(emailText)

  const sendEmailOptions = {   
    method: 'POST', 
    headers: {'Content-Type':'application/json; charset=utf-8' }, 
    body: JSON.stringify({ 
      'toEmail': toEmail,
      'fromEmail':fromEmail,
      'title': title,     
      'emailText': emailText,  //emailText 
      'jobNo': arr[2] ?? '',
      'SeniorTechnician' : arr[11] ??'',

    }),    
  } 

  try {
    const resp = await fetch(sendEmailsUrl, sendEmailOptions);
    DEBUG && console.log(resp.status)
    const response = await resp.text()    
    DEBUG && console.log(response)

  } catch (error) {
    console.error('Error sending emails:', error);
  }

};


export const changePswd = async(cognitoUser, userName, oldUserPassword, newUserPassword)=>{

  // const userPool = new CognitoUserPool(poolData)
  // const cognitouser = new CognitoUser({
  //     Username: userName,  //testaccount
  //     Pool: userPool,
  // });  )Z7hCLsf23o@1H3#d1_*
  
  await new Promise((resolve, reject) =>
    cognitoUser.changePassword( oldUserPassword, newUserPassword, (err, result)=>{
          if (err) {
              alert( err.message || JSON.stringify(err));
              reject(err.message || JSON.stringify(err));      
              alert(err.message)              
              return false;
          }
          resolve(result) // -- returns  CognitoUserSession                +
          DEBUG && console.log(result)
          return result;
      },  
    )
  )
} ;  


export const authentUser = (cognitoUser, userName, oldUserPassword) => {    
  let psw = oldUserPassword //prompt("Please enter password","")

  if (psw?.length > 0){
      const authenticationDetails = new AuthenticationDetails( {
                                          Username : userName, 
                                          Password : psw
                                        });     


      return new Promise((resolve, reject) =>
           cognitoUser.authenticateUser( authenticationDetails, 
              {
                  onSuccess: result =>{ 
                      resolve(result);  //                          console.log(result, '  -----   success');
                      //setInputs(values=>({...values, ['active']:1}))                       
                      return false;
                  },
                  onFailure: err => {
                          reject(err)
                          const missUserName='Missing required parameter USERNAME'
                          const userNotConfirm='User is not confirmed'
                          const incorectNamePsw ='Incorrect username or password'
                          //setInputs(values=>({...values, ['active']:0}))                                
                          if (err.message.includes(missUserName)){
                              alert( "You have not supplied the user name for confirmation" )
                          }else if(err.message.includes(userNotConfirm)){
                              alert( "User is not confirmed. Validation link has been sent to the user. User has not used the supplied lik to validate their account yet" )
                          }else if(err.message.includes(incorectNamePsw)){
                              alert( "You have entered incorrect password" )
                          }else{
                              alert( "Something went wrong, please try again later" )
                          }

                          return true;            //alert(`Password you entered is different \nto the password we have for this user. This could either mean: \n\n1. You entered wrong password\n2. Password you entered is an original password \nand it assumed that user logged in to Autom8 \nand changed password successfuly`)
                  }                            
              } 
          
          ));
  } 
}; 



//   const addNewUser =`<p><span style="color: rgb(102, 163, 224);"> Dear ${jobName}</span></p>
// <p><br></p>
// <p><span style="color: rgb(102, 163, 224);"> Welcome to A8!</span></p>
// <p><br></p>
// <p><span style="color: rgb(102, 163, 224);">Your Login Name is :${contractorName}</span></p>
// <p><span style="color: rgb(102, 163, 224);">Your Temporary Password is : ${siteName}</span></p>
// <p><br></p>
// <p><span style="color: rgb(102, 163, 224);">On the first login you will be asked to change password</span></p>
// <p><span style="color: rgb(102, 163, 224);">&nbsp; &nbsp; </span></p>
// <p><span style="color: rgb(102, 163, 224);">			&nbsp; &nbsp; &nbsp; Password requirements</span></p>
// <p><span style="color: rgb(102, 163, 224);">&nbsp; &nbsp; Contains at least 1 number</span></p>
// <p><span style="color: rgb(102, 163, 224);">&nbsp; &nbsp; Contains at least 1 special character</span></p>
// <p><span style="color: rgb(102, 163, 224);">&nbsp; &nbsp; Contains at least 1 uppercase letter</span></p>
// <p><span style="color: rgb(102, 163, 224);">&nbsp; &nbsp; Contains at least 1 lowercase letter </span></p>
// <p><br></p>
// <p><span style="color: rgb(102, 163, 224);">When you are ready navigate to </span>
// <a href="https://autom8systems.com.au/" rel="noopener noreferrer" target="_blank" style="color: rgb(102, 163, 224);">https://autom8systems.com.au/</a>
// </p>
// <p><br></p>
// <p><span style="color: rgb(102, 163, 224);">Kind Regards</span></p>
// <p><br></p>`

  // const emailTextTest= `


  // Dear ${contractorName},

  // I hope this message finds you well. We are excited to inform you about a new job assignment that requires your expertise. 
  // As one of our valued contractors, we believe your skills align perfectly with the requirements for this project.

  // Job Details:

  // - Site Name:\t${siteName}
  // - Job Title: \t\b\b${jobName}
  // - Start Date: \t\b${startDate}
  // - Location: \t\b${siteAddress}

  // Job Description:  

  // ${faultDescription ?? ""}

  // Next Steps:

  // 1. Confirmation: Please confirm your availability for this assignment by ${confirmationTime}.
  //   If you choose to accept this assignment please click on the following link to confirm your acceptance of this job: 
  //   ${acceptUrl} 

  //   Or if you can reject the offer to accept this job by clicking the link bellow:
  //   ${rejectUrl}

  // 2. Additional Information: If you have any questions or need further details, feel free to reach out on (03) 9964 8941 Head of Field Services
  //     or send email to support@figure8services.com.au.

  // 3. Onboarding: We will provide you with any necessary onboarding instructions and additional project-related information in the coming days.

  // We appreciate your dedication to our projects and are confident that your expertise will greatly contribute to our success.

  // Thank you for your continued partnership, and we look forward to working with you on this exciting assignment.

  // Best regards,`


// export const alert=(userRoles, userCurrentlyAddedRole )=>{

//     console.log('---------------', userRoles)
//     console.log(userCurrentlyAddedRole)

//   return(
         
//       <Stack sx={{mt:'.5ch', ml:'.5ch'}}  > 
          
//               <Alert
//                   isDismissible={true}
//                   //hasIcon={true}                  heading=""
//                   backgroundColor={'lightgray'}
//                   color={'black'}
//                   fontWeight="bold"
//                   fontFamily={'cursive'}
//                   fontSize={14}
//                   height='5ch'
//                   width='20ch'
//                   border={`1px solid gray`}

//                   // onDismiss={() =>{
//                   //     setIsSelected(false)
//                   //     subRoles.current.shift()
//                   //     //setUserSelectedSubTypes(  )   
//                   //     setUserCurrentlySelectedSubType('')                              // if (newInputValue.length>0){
//                   //     setInputs(values=>( {...values, ['userSubType']: ''} ) )
//                   // }}

//               >  {userRoles[0]}</Alert>
          
//       </Stack> 
  
//   )




// }

// export const emailAttributes= (fromEmail, toEmail, title, contractorName, siteName, jobName, startDate, siteAddress, confirmationDate, faultDescription, confirmationLink)=>{
//   const{REACT_APP_API_HTTP, REACT_APP_API_LOCAL_DOMAIN, REACT_APP_API_LOCAL_PORT, NODE_ENV} = process.env
//   console.log('kkkkkkkkkkkkkkkkkkkkkkkkkkk')

//   const productionHost = REACT_APP_API_HTTP + window.location.host;
//   const localHost = REACT_APP_API_HTTP + REACT_APP_API_LOCAL_DOMAIN + REACT_APP_API_LOCAL_PORT;
//   const DOMAIN = `${NODE_ENV==='production' ? productionHost : localHost}`; 

//   const url = `${DOMAIN}/api/confirmJob`;

//   const emailText= `
//         Dear ${contractorName},

//         I hope this message finds you well. We are excited to inform you about a new job assignment that requires your expertise. 
//         As one of our valued contractors, we believe your skills align perfectly with the requirements for this project.

//         Job Details:

//         - Site Name:\t${siteName}
//         - Job Title: \t\b\b ${jobName}
//         - Start Date:\t${startDate}
//         - Location:\t${siteAddress}

//         Job Description:  ${faultDescription}

//         Next Steps:

//         1. Confirmation: Please confirm your availability for this assignment by ${confirmationDate.toUpperCase()}.If you choose to accept this assignment please click on the following link to confirm your acceptance of this job
//              ${url}  

//         2. Additional Information: If you have any questions or need further details, feel free to reach out on (03) 9964 8941 Head of Field Services
//            or send email to support@figure8services.com.au.

//         3. Onboarding: We will provide you with any necessary onboarding instructions and additional project-related information in the coming days.

//         We appreciate your dedication to our projects and are confident that your expertise will greatly contribute to our success.

//         Thank you for your continued partnership, and we look forward to working with you on this exciting assignment.

//         Best regards,
//   `

//   return( 
    
//     {
//       fromEmail:fromEmail,
//       toEmail: toEmail, 
//       title: title,
//       emailText:emailText,
  
//     }
//   )
// }


        //ReactModal.defaultStyles.overlay.backgroundColor = 'cornsilk';
        //ReactModal.defaultStyles.overlay.position='fixed'
        // // ReactModal.defaultStyles.overlay.top=60
        // ReactModal.defaultStyles.overlay.backgroundColor = '#333333AA';
        // ReactModal.defaultStyles.overlay.zIndex =2030;
        // ReactModal.defaultStyles.content.border = '#333333CC';
        // ReactModal.defaultStyles.content.borderRadius='5px';
        // ReactModal.defaultStyles.content.display = 'flex';




// export const getActivity = async(ActivityID, method)=>{

//   if(method==='callback'){

//     let message ='';

//     const requestOptionsGET = {   
//       method: 'GET' , 
//       headers: {'Content-Type':'application/json; charset=utf-8' },  
//     } 


//     const response = await fetch(`https://autom8systems.com.au/api/callbacks_res` );
//     const responseData =  await response.json();  
  

//     await responseData.forEach((activity) => {

//       if (activity.ActivityUId === ActivityID){
//         message = `${message} ${activity.Message}`  // + activity.StatusCode + " - " + 
//       }

//     })

//   }else if (method==='api'){
//     return null;

//   }else{

//     return null;
//   }
  
// }


////////////////////---------------------------------------------------------------------

// ReactModal.defaultStyles = {
//   overlay: {
//     ...ReactModal.defaultStyles.overlay,
//     // backgroundColor: 'rgba(0,0,0,.3)',
//     // display: 'flex',
//     // flexDirection: 'column',
//     // zIndex: 2030,
//   },
//   content: {
//     ...ReactModal.defaultStyles.content,
//     // background: 'none',
//     // border: 'none',
//     // padding: '20px 15px',
//     // top: 0,
//     // left: 0,
//     // right: 0,
//     // bottom: 0,
//   },
// };


// import {CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js';

// const poolData = {
//   UserPoolId: process.env.REACT_APP_USER_POOL_ID, // Your user pool id here
//   ClientId: process.env.REACT_APP_CLIENT_ID, // Your client id here
// };

// export function blah (flag){

//         const userPool = new CognitoUserPool(poolData)
//         const userData = {
//             Username: userName,
//             Pool: userPool,
//         };
//         const user = new CognitoUser(userData);

//         const confirmRegistration=() =>{        
//             let authCode = prompt('Please enter auhentication number \nthat we sent you in email','')
//             if (authCode !==null) {
//                new Promise((resolve, reject) =>
//                 user.confirmRegistration(authCode, false, function(err, result){
//                         if (err) {
//                             reject(err.message || JSON.stringify(err))
//                             alert( err.message || JSON.stringify(err));
//                             return;
//                         }
//                         resolve(result)
//                         console.log(result);
//                         if(result==='SUCCESS'){
//                             // authCode =''

//                         }
//                 } ))   
//                 console.log(authCode)
//             }
//             else{
//                 ///console.log(FormDialog)
//                 console.log('FormDialog')
//             }
//         };  

//         const authentUser = () => {
//             console.log(user.getUsername());
//             if (user.getUsername() !=="" ) {
        
//                 let psw = !isNew ? passWord : prompt("Please enter password or leave it blank for the Original Password","")
                
//                 const authenticationData = {Username : userName, Password : psw};
//                 const authenticationDetails = new AuthenticationDetails(authenticationData);     

//                 return new Promise((resolve, reject) =>
//                     user.authenticateUser(authenticationDetails, {
//                         onSuccess: result =>{ 
//                                                 resolve(result); 
//                                                 console.log(result, '  -----   success');
//                                                 setInputs(values=>({...values, ['active']:1}))
//                                             },

//                         onFailure: err => {
//                                             reject(err)
//                                             console.log(err.message)
//                                             const missUserName='Missing required parameter USERNAME'
//                                             const userNotConfirm='User is not confirmed'
//                                             const incorectNamePsw ='Incorrect username or password'

//                                             if (err.message.includes(missUserName)){
//                                                     alert( "You have not supplied the user name for confirmation" )
//                                             }else if(err.message.includes(userNotConfirm)){
//                                                 alert( "User is not confirmed. Validation link has been sent to the user. User has not used the supplied lik to validate their account yet" )
//                                             }else if(err.message.includes(incorectNamePsw)){
//                                                 alert( "You have tried to confirm before Signing Up User" )
//                                             }
//                                             //alert(`Password you entered is different \nto the password we have for this user. This could either mean: \n\n1. You entered wrong password\n2. Password you entered is an original password \nand it assumed that user logged in to Autom8 \nand changed password successfuly`)
//                                         }
//                     })
//                 );
//             }else{
//                 console.log("test")
//             }
//         };

//         const getUserSession = () => {
//             return new Promise((resolve, reject) =>
//                 user.getSession( function (err, result) {
//                             if (err) {
//                                 alert( err.message || JSON.stringify(err));
//                                 reject(err.message || JSON.stringify(err));
//                                 return;
//                             }
//                             resolve(result)
//                             console.log(result)
//                             // setInputs(values=>({...values, ['sub']: subAttrib})) 
//                             setInputs(values=>({...values, ['acive']: 1})) 
//                         },
//                 )
//             );
//         }

//             // console.log(cognitoUser.changePassword())
//     // user.getUserAttributes(function(err, result) {
//     //     if (err) {
//     //         alert(err.message || JSON.stringify(err));
//     //         return;
//     //     }
//     //     for (i = 0; i < result.length; i++) {
//     //         console.log(
//     //             'attribute ' + result[i].getName() + ' has value ' + result[i].getValue()
//     //         );
//     //     }
//     // });

//         const updateAttributes = ()=>{
//             const attributeList = [];
//             // const AttribFullName = {
//             //     Name: 'name',
//             //     Value: userFullName,
//             // };

//             const AttribDataRole = {
//                 Name: 'custom:Role',
//                 Value: userSelectedType//'alexiampolski@optusnet.com.au',
//             };

//             const AttribDataCustRole = {
//                 Name: 'custom:CustomerRole',
//                 Value: userFullName//'alexiampolski@optusnet.com.au',
//             };

//             //const attributeFullName = new CognitoUserAttribute(AttribFullName);
//             const attributeRole = new CognitoUserAttribute(AttribDataRole);
//             const attribute2 = new CognitoUserAttribute(AttribDataCustRole);
//             console.log(attributeRole.getValue())

//             //attributeList.push(AttribFullName);
//             attributeList.push(attributeRole);
//             attributeList.push(attribute2);

//             return new Promise((resolve, reject) =>
//                 user.updateAttributes( attributeList, function(err, result) {
//                     if (err) {
//                         reject(err.message || JSON.stringify(err));
//                         alert( err.message || JSON.stringify(err));
//                         return;
//                     }
//                     resolve(result)
//                     console.log(result);
//                     navigate(-1);

//                 })
//             )    
//         }
//                                 // confirmRegistration()
//         if (flag==='confirm'){
            
//             ///const authenticationDetails = new AuthenticationDetails({Username : userName, Password : window.prompt("Please enter password","")})///"!Hello_11?"});  
//             authentUser();
//             //console.log(user.authenticateUser(authenticationDetails, (a)=>console.log(a)))    //.deleteUser((a)=>{con0sole.log(a)}));
//             //console.log(user.getUsername());
//             //getUserSession();

//         }else{
//             //authentUser();
//             //getUserSession();
            
//             console.log(user.getUsername(), '------test')
//             action==='update' && handleUpdate()
//             ///updateAttributes();    
//         }     
//                         // const {authStatus} = useAuthenticator();
//                         // console.log(authStatus)

//                         // const params = {
//                         //     UserPoolId: REACT_APP_USER_POOL_ID, 
//                         //     Username: userName, 
//                         //     UserAttributes: [  {
//                         //         Name: 'email',
//                         //         Value: cognEmail,
//                         //       },  ],
//                         //     ValidationData: [
//                         //       {
//                         //         Name: 'custom:Role', /* required */
//                         //         Value: 'tech'
//                         //       }, ]
//                         //   };

//                         //   cognitoidentityserviceprovider.adminCreateUser(params, function(err, data) {
//                         //     if (err){
//                         //         console.log(err, err.stack)
//                         //         return;
//                         //     }
//                         //     console.log(data);          
//                         //   });
            
//                         // cognitoUser.forgotPassword({
//                             //                         onSuccess: function (result) {
//                             //                             console.log('call result: ' + result);
//                             //                         },
//                             //                         onFailure: function(err) {
//                             //                             alert(err);
//                             //                         },
//                             //                         inputVerificationCode() {
//                             //                             const verificationCode = prompt('Please input verification code ' ,'');
//                             //                             const newPassword = prompt('Enter new password ' ,'');
//                             //                             cognitoUser.confirmPassword(verificationCode, newPassword, this);
//                             //                         }
//                             //     }
//                             // );
// };

// function FormDialog(){
//   alert("hello")
//   const [open, setOpen] = React.useState(true);
  
//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <div>
//       <Dialog open={open} onClose={handleClose}>
//         <DialogTitle>Subscribe</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             To subscribe to this website, please enter your email address here. We
//             will send updates occasionally.
//           </DialogContentText>
//           <TextField
//             autoFocus
//             margin="dense"
//             id="name"
//             label="Email Address"
//             type="email"
//             fullWidth
//             variant="standard"
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>Cancel</Button>
//           <Button onClick={handleClose}>Subscribe</Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// }



// export  function CountrySelect() {

//   return (
//         <Autocomplete
//           sx={{ width: '30ch' }}
//           options={countries}
//           autoHighlight
//           getOptionLabel={(option) => option.label}
          
//           renderOption={(props, option) => (
//             <Box component="li" sx={{ '& > img': { mr: 1, flexShrink: 0} }} {...props}>
//               <img
//                 loading="lazy"
//                 width="20"
//                 src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
//                 srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
//                 alt=""
//               />
//               {option.label} ({option.code}) +{option.phone}
//             </Box>
//           )}
//           renderInput={(params) => (
//             <TextField
//               {...params}
//               label="Choose a country"
//               inputProps={{
//                 ...params.inputProps,
//                 autoComplete: 'new-password',
//                 type: 'search', // disable autocomplete and autofill
//               }}
//             />
//           )}
//       />
//   );
// }






// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js

// const countries = [
//   { code: 'AD', label: 'Andorra', phone: '376' },
//   { code: 'AF', label: 'Afghanistan', phone: '93' },
//   { code: 'AG', label: 'Antigua and Barbuda',  phone: '1-268',  },
//   { code: 'AI', label: 'Anguilla', phone: '1-264' },
//   { code: 'AL', label: 'Albania', phone: '355' },
//   { code: 'AM', label: 'Armenia', phone: '374' },
//   { code: 'AO', label: 'Angola', phone: '244' },
//   { code: 'AQ', label: 'Antarctica', phone: '672' },
//   { code: 'AR', label: 'Argentina', phone: '54' },
//   { code: 'AS', label: 'American Samoa', phone: '1-684' },
//   { code: 'AT', label: 'Austria', phone: '43' },
//   { code: 'AU', label: 'Australia',  phone: '61', suggested: true, },
//   { code: 'AW', label: 'Aruba', phone: '297' },
//   { code: 'AX', label: 'Alland Islands', phone: '358' },
//   { code: 'AZ', label: 'Azerbaijan', phone: '994' },
//   { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387',},
//   { code: 'BB', label: 'Barbados', phone: '1-246' },
//   { code: 'BD', label: 'Bangladesh', phone: '880' },
//   { code: 'BE', label: 'Belgium', phone: '32' },
//   { code: 'BF', label: 'Burkina Faso', phone: '226' },
//   { code: 'BG', label: 'Bulgaria', phone: '359' },
//   { code: 'BH', label: 'Bahrain', phone: '973' },
//   { code: 'BI', label: 'Burundi', phone: '257' },
//   { code: 'BJ', label: 'Benin', phone: '229' },
//   { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
//   { code: 'BM', label: 'Bermuda', phone: '1-441' },
//   { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
//   { code: 'BO', label: 'Bolivia', phone: '591' },
//   { code: 'BR', label: 'Brazil', phone: '55' },
//   { code: 'BS', label: 'Bahamas', phone: '1-242' },
//   { code: 'BT', label: 'Bhutan', phone: '975' },
//   { code: 'BV', label: 'Bouvet Island', phone: '47' },
//   { code: 'BW', label: 'Botswana', phone: '267' },
//   { code: 'BY', label: 'Belarus', phone: '375' },
//   { code: 'BZ', label: 'Belize', phone: '501' },
//   { code: 'CA', label: 'Canada', phone: '1', suggested: true, },
//   {
//     code: 'CC',
//     label: 'Cocos (Keeling) Islands',
//     phone: '61',
//   },
//   {
//     code: 'CD',
//     label: 'Congo, Democratic Republic of the',
//     phone: '243',
//   },
//   {
//     code: 'CF',
//     label: 'Central African Republic',
//     phone: '236',
//   },
//   {
//     code: 'CG',
//     label: 'Congo, Republic of the',
//     phone: '242',
//   },
//   { code: 'CH', label: 'Switzerland', phone: '41' },
//   { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
//   { code: 'CK', label: 'Cook Islands', phone: '682' },
//   { code: 'CL', label: 'Chile', phone: '56' },
//   { code: 'CM', label: 'Cameroon', phone: '237' },
//   { code: 'CN', label: 'China', phone: '86' },
//   { code: 'CO', label: 'Colombia', phone: '57' },
//   { code: 'CR', label: 'Costa Rica', phone: '506' },
//   { code: 'CU', label: 'Cuba', phone: '53' },
//   { code: 'CV', label: 'Cape Verde', phone: '238' },
//   { code: 'CW', label: 'Curacao', phone: '599' },
//   { code: 'CX', label: 'Christmas Island', phone: '61' },
//   { code: 'CY', label: 'Cyprus', phone: '357' },
//   { code: 'CZ', label: 'Czech Republic', phone: '420' },
//   {
//     code: 'DE',
//     label: 'Germany',
//     phone: '49',
//     suggested: true,
//   },
//   { code: 'DJ', label: 'Djibouti', phone: '253' },
//   { code: 'DK', label: 'Denmark', phone: '45' },
//   { code: 'DM', label: 'Dominica', phone: '1-767' },
//   {
//     code: 'DO',
//     label: 'Dominican Republic',
//     phone: '1-809',
//   },
//   { code: 'DZ', label: 'Algeria', phone: '213' },
//   { code: 'EC', label: 'Ecuador', phone: '593' },
//   { code: 'EE', label: 'Estonia', phone: '372' },
//   { code: 'EG', label: 'Egypt', phone: '20' },
//   { code: 'EH', label: 'Western Sahara', phone: '212' },
//   { code: 'ER', label: 'Eritrea', phone: '291' },
//   { code: 'ES', label: 'Spain', phone: '34' },
//   { code: 'ET', label: 'Ethiopia', phone: '251' },
//   { code: 'FI', label: 'Finland', phone: '358' },
//   { code: 'FJ', label: 'Fiji', phone: '679' },
//   {
//     code: 'FK',
//     label: 'Falkland Islands (Malvinas)',
//     phone: '500',
//   },
//   {
//     code: 'FM',
//     label: 'Micronesia, Federated States of',
//     phone: '691',
//   },
//   { code: 'FO', label: 'Faroe Islands', phone: '298' },
//   {
//     code: 'FR',
//     label: 'France',
//     phone: '33',
//     suggested: true,
//   },
//   { code: 'GA', label: 'Gabon', phone: '241' },
//   { code: 'GB', label: 'United Kingdom', phone: '44' },
//   { code: 'GD', label: 'Grenada', phone: '1-473' },
//   { code: 'GE', label: 'Georgia', phone: '995' },
//   { code: 'GF', label: 'French Guiana', phone: '594' },
//   { code: 'GG', label: 'Guernsey', phone: '44' },
//   { code: 'GH', label: 'Ghana', phone: '233' },
//   { code: 'GI', label: 'Gibraltar', phone: '350' },
//   { code: 'GL', label: 'Greenland', phone: '299' },
//   { code: 'GM', label: 'Gambia', phone: '220' },
//   { code: 'GN', label: 'Guinea', phone: '224' },
//   { code: 'GP', label: 'Guadeloupe', phone: '590' },
//   { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
//   { code: 'GR', label: 'Greece', phone: '30' },
//   {
//     code: 'GS',
//     label: 'South Georgia and the South Sandwich Islands',
//     phone: '500',
//   },
//   { code: 'GT', label: 'Guatemala', phone: '502' },
//   { code: 'GU', label: 'Guam', phone: '1-671' },
//   { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
//   { code: 'GY', label: 'Guyana', phone: '592' },
//   { code: 'HK', label: 'Hong Kong', phone: '852' },
//   {
//     code: 'HM',
//     label: 'Heard Island and McDonald Islands',
//     phone: '672',
//   },
//   { code: 'HN', label: 'Honduras', phone: '504' },
//   { code: 'HR', label: 'Croatia', phone: '385' },
//   { code: 'HT', label: 'Haiti', phone: '509' },
//   { code: 'HU', label: 'Hungary', phone: '36' },
//   { code: 'ID', label: 'Indonesia', phone: '62' },
//   { code: 'IE', label: 'Ireland', phone: '353' },
//   { code: 'IL', label: 'Israel', phone: '972' },
//   { code: 'IM', label: 'Isle of Man', phone: '44' },
//   { code: 'IN', label: 'India', phone: '91' },
//   {
//     code: 'IO',
//     label: 'British Indian Ocean Territory',
//     phone: '246',
//   },
//   { code: 'IQ', label: 'Iraq', phone: '964' },
//   {
//     code: 'IR',
//     label: 'Iran, Islamic Republic of',
//     phone: '98',
//   },
//   { code: 'IS', label: 'Iceland', phone: '354' },
//   { code: 'IT', label: 'Italy', phone: '39' },
//   { code: 'JE', label: 'Jersey', phone: '44' },
//   { code: 'JM', label: 'Jamaica', phone: '1-876' },
//   { code: 'JO', label: 'Jordan', phone: '962' },
//   {
//     code: 'JP',
//     label: 'Japan',
//     phone: '81',
//     suggested: true,
//   },
//   { code: 'KE', label: 'Kenya', phone: '254' },
//   { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
//   { code: 'KH', label: 'Cambodia', phone: '855' },
//   { code: 'KI', label: 'Kiribati', phone: '686' },
//   { code: 'KM', label: 'Comoros', phone: '269' },
//   {
//     code: 'KN',
//     label: 'Saint Kitts and Nevis',
//     phone: '1-869',
//   },
//   {
//     code: 'KP',
//     label: "Korea, Democratic People's Republic of",
//     phone: '850',
//   },
//   { code: 'KR', label: 'Korea, Republic of', phone: '82' },
//   { code: 'KW', label: 'Kuwait', phone: '965' },
//   { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
//   { code: 'KZ', label: 'Kazakhstan', phone: '7' },
//   {
//     code: 'LA',
//     label: "Lao People's Democratic Republic",
//     phone: '856',
//   },
//   { code: 'LB', label: 'Lebanon', phone: '961' },
//   { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
//   { code: 'LI', label: 'Liechtenstein', phone: '423' },
//   { code: 'LK', label: 'Sri Lanka', phone: '94' },
//   { code: 'LR', label: 'Liberia', phone: '231' },
//   { code: 'LS', label: 'Lesotho', phone: '266' },
//   { code: 'LT', label: 'Lithuania', phone: '370' },
//   { code: 'LU', label: 'Luxembourg', phone: '352' },
//   { code: 'LV', label: 'Latvia', phone: '371' },
//   { code: 'LY', label: 'Libya', phone: '218' },
//   { code: 'MA', label: 'Morocco', phone: '212' },
//   { code: 'MC', label: 'Monaco', phone: '377' },
//   {
//     code: 'MD',
//     label: 'Moldova, Republic of',
//     phone: '373',
//   },
//   { code: 'ME', label: 'Montenegro', phone: '382' },
//   {
//     code: 'MF',
//     label: 'Saint Martin (French part)',
//     phone: '590',
//   },
//   { code: 'MG', label: 'Madagascar', phone: '261' },
//   { code: 'MH', label: 'Marshall Islands', phone: '692' },
//   {
//     code: 'MK',
//     label: 'Macedonia, the Former Yugoslav Republic of',
//     phone: '389',
//   },
//   { code: 'ML', label: 'Mali', phone: '223' },
//   { code: 'MM', label: 'Myanmar', phone: '95' },
//   { code: 'MN', label: 'Mongolia', phone: '976' },
//   { code: 'MO', label: 'Macao', phone: '853' },
//   {
//     code: 'MP',
//     label: 'Northern Mariana Islands',
//     phone: '1-670',
//   },
//   { code: 'MQ', label: 'Martinique', phone: '596' },
//   { code: 'MR', label: 'Mauritania', phone: '222' },
//   { code: 'MS', label: 'Montserrat', phone: '1-664' },
//   { code: 'MT', label: 'Malta', phone: '356' },
//   { code: 'MU', label: 'Mauritius', phone: '230' },
//   { code: 'MV', label: 'Maldives', phone: '960' },
//   { code: 'MW', label: 'Malawi', phone: '265' },
//   { code: 'MX', label: 'Mexico', phone: '52' },
//   { code: 'MY', label: 'Malaysia', phone: '60' },
//   { code: 'MZ', label: 'Mozambique', phone: '258' },
//   { code: 'NA', label: 'Namibia', phone: '264' },
//   { code: 'NC', label: 'New Caledonia', phone: '687' },
//   { code: 'NE', label: 'Niger', phone: '227' },
//   { code: 'NF', label: 'Norfolk Island', phone: '672' },
//   { code: 'NG', label: 'Nigeria', phone: '234' },
//   { code: 'NI', label: 'Nicaragua', phone: '505' },
//   { code: 'NL', label: 'Netherlands', phone: '31' },
//   { code: 'NO', label: 'Norway', phone: '47' },
//   { code: 'NP', label: 'Nepal', phone: '977' },
//   { code: 'NR', label: 'Nauru', phone: '674' },
//   { code: 'NU', label: 'Niue', phone: '683' },
//   { code: 'NZ', label: 'New Zealand', phone: '64' },
//   { code: 'OM', label: 'Oman', phone: '968' },
//   { code: 'PA', label: 'Panama', phone: '507' },
//   { code: 'PE', label: 'Peru', phone: '51' },
//   { code: 'PF', label: 'French Polynesia', phone: '689' },
//   { code: 'PG', label: 'Papua New Guinea', phone: '675' },
//   { code: 'PH', label: 'Philippines', phone: '63' },
//   { code: 'PK', label: 'Pakistan', phone: '92' },
//   { code: 'PL', label: 'Poland', phone: '48' },
//   {
//     code: 'PM',
//     label: 'Saint Pierre and Miquelon',
//     phone: '508',
//   },
//   { code: 'PN', label: 'Pitcairn', phone: '870' },
//   { code: 'PR', label: 'Puerto Rico', phone: '1' },
//   {
//     code: 'PS',
//     label: 'Palestine, State of',
//     phone: '970',
//   },
//   { code: 'PT', label: 'Portugal', phone: '351' },
//   { code: 'PW', label: 'Palau', phone: '680' },
//   { code: 'PY', label: 'Paraguay', phone: '595' },
//   { code: 'QA', label: 'Qatar', phone: '974' },
//   { code: 'RE', label: 'Reunion', phone: '262' },
//   { code: 'RO', label: 'Romania', phone: '40' },
//   { code: 'RS', label: 'Serbia', phone: '381' },
//   { code: 'RU', label: 'Russian Federation', phone: '7' },
//   { code: 'RW', label: 'Rwanda', phone: '250' },
//   { code: 'SA', label: 'Saudi Arabia', phone: '966' },
//   { code: 'SB', label: 'Solomon Islands', phone: '677' },
//   { code: 'SC', label: 'Seychelles', phone: '248' },
//   { code: 'SD', label: 'Sudan', phone: '249' },
//   { code: 'SE', label: 'Sweden', phone: '46' },
//   { code: 'SG', label: 'Singapore', phone: '65' },
//   { code: 'SH', label: 'Saint Helena', phone: '290' },
//   { code: 'SI', label: 'Slovenia', phone: '386' },
//   { code: 'SJ',
//     label: 'Svalbard and Jan Mayen',
//     phone: '47',
//   },
//   { code: 'SK', label: 'Slovakia', phone: '421' },
//   { code: 'SL', label: 'Sierra Leone', phone: '232' },
//   { code: 'SM', label: 'San Marino', phone: '378' },
//   { code: 'SN', label: 'Senegal', phone: '221' },
//   { code: 'SO', label: 'Somalia', phone: '252' },
//   { code: 'SR', label: 'Suriname', phone: '597' },
//   { code: 'SS', label: 'South Sudan', phone: '211' },
//   {
//     code: 'ST',
//     label: 'Sao Tome and Principe',
//     phone: '239',
//   },
//   { code: 'SV', label: 'El Salvador', phone: '503' },
//   { code: 'SX',
//     label: 'Sint Maarten (Dutch part)',
//     phone: '1-721',
//   },
//   { code: 'SY',
//     label: 'Syrian Arab Republic',
//     phone: '963',
//   },
//   { code: 'SZ', label: 'Swaziland', phone: '268' },
//   {
//     code: 'TC',
//     label: 'Turks and Caicos Islands',
//     phone: '1-649',
//   },
//   { code: 'TD', label: 'Chad', phone: '235' },
//   { code: 'TF',
//     label: 'French Southern Territories',
//     phone: '262',
//   },
//   { code: 'TG', label: 'Togo', phone: '228' },
//   { code: 'TH', label: 'Thailand', phone: '66' },
//   { code: 'TJ', label: 'Tajikistan', phone: '992' },
//   { code: 'TK', label: 'Tokelau', phone: '690' },
//   { code: 'TL', label: 'Timor-Leste', phone: '670' },
//   { code: 'TM', label: 'Turkmenistan', phone: '993' },
//   { code: 'TN', label: 'Tunisia', phone: '216' },
//   { code: 'TO', label: 'Tonga', phone: '676' },
//   { code: 'TR', label: 'Turkey', phone: '90' },
//   { code: 'TT', label: 'Trinidad and Tobago',  phone: '1-868',  },
//   { code: 'TV', label: 'Tuvalu', phone: '688' },
//   { code: 'TW', label: 'Taiwan, Republic of China', phone: '886',  },
//   { code: 'TZ',label: 'United Republic of Tanzania', phone: '255',  },
//   { code: 'AE', label: 'United Arab Emirates', phone: '971', },
//   { code: 'UA', label: 'Ukraine', phone: '380' },
//   { code: 'UG', label: 'Uganda', phone: '256' },
//   { code: 'US', label: 'United States',  phone: '1', suggested: true, },
//   { code: 'UY', label: 'Uruguay', phone: '598' },
//   { code: 'UZ', label: 'Uzbekistan', phone: '998' },
//   { code: 'VA', label: 'Holy See (Vatican City State)', phone: '379', },
//   { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784', },
//   { code: 'VE', label: 'Venezuela', phone: '58' },
//   { code: 'VG', label: 'British Virgin Islands',  phone: '1-284', },
//   { code: 'VI', label: 'US Virgin Islands', phone: '1-340', },
//   { code: 'VN', label: 'Vietnam', phone: '84' },
//   { code: 'VU', label: 'Vanuatu', phone: '678' },
//   { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
//   { code: 'WS', label: 'Samoa', phone: '685' },
//   { code: 'XK', label: 'Kosovo', phone: '383' },
//   { code: 'YE', label: 'Yemen', phone: '967' },
//   { code: 'YT', label: 'Mayotte', phone: '262' },
//   { code: 'ZA', label: 'South Africa', phone: '27' },
//   { code: 'ZM', label: 'Zambia', phone: '260' },
//   { code: 'ZW', label: 'Zimbabwe', phone: '263' },
// ];






// import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
// //import Autocomplete from '@mui/material/Autocomplete';
// import Button from '@mui/material/Button';     
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';

        // ReactModal.setAppElement('#root'); 
        //  ReactModal.defaultStyles.overlay.backgroundColor = 'cornsilk';
        // ReactModal.defaultStyles.overlay.position='fixed'
        // // ReactModal.defaultStyles.overlay.top=60
        // ReactModal.defaultStyles.overlay.backgroundColor = '#333333AA';
        // ReactModal.defaultStyles.overlay.zIndex =2030;
        // ReactModal.defaultStyles.content.border = '#333333CC';
        // ReactModal.defaultStyles.content.borderRadius='5px';
        // ReactModal.defaultStyles.content.display = 'flex';