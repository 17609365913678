import React, { useState, useContext, useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import { UserLogInContext } from "../App";
import AppBar from "@mui/material/AppBar";
import { Box, Toolbar, IconButton, Stack } from "@mui/material"; // import  from '@mui/material/Toolbar';// import  from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem"; // import {AccountCircle, MenuIcon } from '@mui/icons-material';
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase"; ///import AdbIcon from '@mui/icons-material/Adb';                           //// import Navbar from '../../components/Navbar.jsx';// import Sidebar from '../../components/Sidebar.jsx';
import { useLocation } from "react-router-dom"; //import { ModChangePassword } from '../../util/Functions.jsx'; // ModAlert, authentUser, changePswd, dateConverter //import { Paper } from "@mui/material";// import { Button, Heading } from '@aws-amplify/ui-react'; // import ExitToAppIcon from "@mui/icons-material/ExitToApp";  //  import Main from '../../components/Main.jsx'; // import CountrySelect from '../../util/CountryProvider.jsx'//{signOut, loggedInUser, loggedInGroup}///
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import pic from "../resource/images/Fred.png";
import "../components/jobgrid.scss";
import logo from "../resource/images/logo.png";
import { setHeader } from "./jobGridTableName";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: "flex-start",
  justifyContent: "flex-start",
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing("10px"),
  marginLeft: "10px",
  // Override media queries injected by theme.mixins.toolbar
  "@media all": { height: 60 },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": { backgroundColor: alpha(theme.palette.common.white, 0.25) },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // [theme.breakpoints.up('md')]: {width: '2ch'},
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("xs")]: { width: "3ch" },
    [theme.breakpoints.up("md")]: { width: "12ch" },
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": { marginTop: theme.spacing(0) },
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": { backgroundColor: alpha(theme.palette.common.white, 0.25) },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    color: "inherit", //backgroundColor:'inherit',//theme.palette.info,
    border: "1px dotted white",
    fontSize: 14,
    padding: "5px 15px 5px 20px",
    //marginRight: "10px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      //     '-apple-system',
      //     'BlinkMacSystemFont',
      //     '"Segoe UI"',
      //     'Roboto',
      //     '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ],
    //].join(','),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

//, children
const ApplicationBar = ({
  tableName,
  setMessage,
  modalResponce,
  setModalResponse,
}) => {
  const [ancorElAdmin, setAncorElAdmin] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [isJobsMenu, setIsJobsMenu] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [msg, setMsg] = useState("");
  const [modal, setModal] = useState(""); //const [fields, setFields] =useState([])
  const { group, role } = useContext(UserLogInContext); //const hostname=window.location.hostname; // const port ='3002'; // const host = hostname +":"+port  //const [DOMAIN1, setDOMAIN1]= useState(`http://${NODE_ENV==='production'?window.location.host:window.location.hostname +':3002'}`)
  const { user, signOut, route, authStatus } = useAuthenticator((context) => [
    context.user,
    context.route,
    context.authStatus,
  ]);
  // console.log(route, authStatus, tableName)    // console.log(sessionStorage.getItem("username"))

  useEffect(() => {
    window.addEventListener("beforeunload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("contextmenu", handlResize);
    return () => {
      window.removeEventListener("contextmenu", handlResize);
    }; //auxclick
  }, []);

  useEffect(() => {
    function test() {
      console.log(document.activeElement.localName);
      //   console.log(document.activeElement.id);
      //   console.log(document.activeElement.textContent);
    }
    window.addEventListener("click", test);
    return () => {
      window.removeEventListener("click", test);
    };
  }, []);

  useEffect(() => {
    function handleMove(e) {
      setPosition({ x: e.clientX, y: e.clientY });
    }
    window.addEventListener("pointermove", handleMove);
    return () => {
      window.removeEventListener("pointermove", handleMove);
    };
  }, []);

  let fields = setHeader(tableName).map((f, i) => {
    return (
      <MenuItem
        disabled={f.disabled}
        value={`${f.tName == undefined ? "" : f.tName}${f.id}`}
        key={i}
      >
        {f.label}
      </MenuItem>
    ); //disabled={f.disableSelection}
  });
  //fields= [<MenuItem value='' >{''}</MenuItem>, ...fields]

  const isAdmin =
    group[0] == "Admin" ||
    user.attributes["custom:Role"] == "Administration" ||
    user.attributes["custom:Role"] == "Administrator";
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    navigate("/");
    signOut();
  };

  const handleOpenAdminMenu = (q) => (e) => {
    setIsJobsMenu(q);
    setAncorElAdmin(e.currentTarget);
  };

  const handleOpenUserMenu = (e) => {
    setAnchorElUser(e.currentTarget);
  };

  const handleCloseAdminMenu = () => {
    setAncorElAdmin(null);
    setIsJobsMenu(false);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handler = (flag) => {
    try {
      console.log("ffffffffff::::::: " + msg, flag);
      const a = isNaN(msg); // :console.log('table name is not supplied'); //console.log(isNaN(msg))        //console.log(tableName, msg);
      tableName !== undefined
        ? tableName === "jobbook"
          ? setMessage(msg)
          : setMessage(msg)
        : setMsg("");
      //tableName !== undefined  ? setMessage(false ) : setMessage(msg);
      if (!flag) {
        console.log("HANDLER CLICK FUNCTION", modalResponce, modal);
        setMsg("");
        msg == "" && setModalResponse(undefined);
        setModal("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const doNavigate = (path) => {
    navigate(path);
    console.log(modalResponce);
    if (modalResponce == undefined) {
      // setModalResponse(undefined);
      setModal("");
      setMessage("");
      handleCloseAdminMenu();
    }
  };

  const handleTabClose = (e) => {
    e.preventDefault();
    console.log("BEFORE UNLOAD event triggered ", e); //const confirm_result = confirm("Are you sure you want to quit?");      //getHome()      //localStorage.clear()      //window.open('', '_self', '').close();
    console.log(authStatus); //logout();
    handleClose();

    // navigate('/');
    // signOut();
    return (e.returnValue = "Are you sure you want to exit ?");
  };

  const handlResize = (e) => {
    e.preventDefault();
    console.log(e);
    console.log(
      "Screen Y = " + e.screenY,
      ",  Screen X = " + e.screenX,
      ",  Viewpost Y = " + e.y,
      ",  ViewPort X = " + e.x
    );
    return (e.returnValue = "hello ");
  };

  const handleFieldSelecrion = (event) => {
    setModal(event.target.value);
    setModalResponse(event.target.value);
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  const blur =(val)=>(e , extr)=>{
    console.log(val)
    console.log(e.target.value)
    console.log(extr)
  }

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
        width: 200,
        fontWeight: 600,
      },
    },
  };
  ///   ego sum optimus
  try {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" color="primary" enableColorOnDark>
          <div
            style={{
              position: "absolute",
              backgroundColor: "pink",
              borderRadius: "50%",
              opacity: 0.6,
              transform: `translate(${position.x}px, ${position.y}px)`,
              pointerEvents: "none",
              left: -15,
              top: -15,
              width: 30,
              height: 30,
              zIndex: 10,
            }}
          />
          <StyledToolbar>
            <Stack sx={{ mt: ".6ch", width: "11ch" }}>
              <img src={logo} alt="logo" height={"51ch"} />
            </Stack>
            {/*    <Stack  sx={{mt:'.5ch', ml:'-2.5ch', width:'16ch',}}> <Typography>{sessionStorage.getItem("username")}</Typography> */}
            <Stack
              direction="row"
              marginLeft="5ch"
              marginTop="1ch"
              gap={1}
              width={"30ch"}
              justifyContent="center"
              alignItems="center"
            >
              <Stack
                color="gold"
                fontSize={13}
                width={"15ch"}
                fontFamily="Arial"
              >
                Local time
              </Stack>
              <input
                className="input"
                type="datetime-local"
                id="r"
                onBlur={blur('hello')}
              />
            </Stack>

            <Container>
              <Toolbar
                disableGutters={true}
                variant="dense"
                sx={{
                  height: "6.5ch",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }} //{sx:'0ch', sm:"0ch", md:'0ch'}}}    //, border :'1px solid red'          alignItems: 'flex-start',                            justifyContent:'flex-start',
              >
                {fields.length > 0 && (
                  <Stack
                    direction="row"
                    sx={{
                      display: "flex",
                      width: "45ch",
                      height: "5.2ch",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Stack
                      sx={{
                        display: tableName !== undefined ? "flex" : "none",
                      }}
                    >
                      <Search>
                        <Select
                          displayEmpty
                          sx={{ color: "inherit" }}
                          value={modal}
                          onChange={handleFieldSelecrion}
                          input={<StyledInputBase />}
                          MenuProps={MenuProps}
                        >
                          {fields.length > 0 && (
                            <MenuItem value="">{""}</MenuItem>
                          )}
                          {fields}
                        </Select>
                      </Search>
                    </Stack>

                    <Stack
                      sx={{
                        display: tableName !== undefined ? "flex" : "none",
                        ml: "-4ch",
                      }}
                    >
                      <Search>
                        <SearchIconWrapper>
                          <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                          autoFocus
                          value={msg}
                          placeholder="Search . . ."
                          inputProps={{ "aria-label": "searching" }}
                          onChange={(e) => {
                            setMsg(e.target.value);
                          }} //setModal(true)    ///{(e)=>message !== undefined && setMessage(e.target.value) }
                          onKeyDown={(k) => {
                            k.key === "Enter" && handler(false);
                          }}
                          onClick={() => {
                            handler(true);
                          }}
                          //onDoubleClick={()=>setModal(false)}
                        />
                      </Search>
                    </Stack>
                  </Stack>
                )}
                <Box
                  name="MENU ICON TRIGGER"
                  sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                >
                  <IconButton
                    size="large"
                    onClick={handleOpenAdminMenu(true)}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>

                <Box name="MAIN MENU ADMIN ITEMS and JOBS ">
                  <Menu
                    id="menu-admin"
                    anchorEl={ancorElAdmin}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(ancorElAdmin)}
                    onClose={handleCloseAdminMenu}
                    sx={{ my: 0.8, color: "white", display: "block" }}
                  >
                    {isJobsMenu && (
                      <MenuItem onClick={() => doNavigate("/jobs")}>
                        <Typography
                          fontSize={16}
                          fontWeight={600}
                          textAlign="center"
                        >
                          Jobs
                        </Typography>
                      </MenuItem>
                    )}

                    {isAdmin && (
                      <MenuItem onClick={() => doNavigate("/clients")}>
                        <Typography
                          fontSize={16}
                          fontWeight={600}
                          textAlign="center"
                        >
                          Clients
                        </Typography>
                      </MenuItem>
                    )}
                    {isAdmin && (
                      <MenuItem onClick={() => doNavigate("/customers")}>
                        <Typography
                          fontSize={16}
                          fontWeight={600}
                          textAlign="center"
                        >
                          Customers
                        </Typography>
                      </MenuItem>
                    )}
                    {isAdmin && (
                      <MenuItem onClick={() => doNavigate("/users")}>
                        <Typography
                          fontSize={16}
                          fontWeight={600}
                          textAlign="center"
                        >
                          Users
                        </Typography>
                      </MenuItem>
                    )}
                    {isAdmin && (
                      <MenuItem onClick={() => doNavigate("/sites")}>
                        <Typography
                          fontSize={16}
                          fontWeight={600}
                          textAlign="center"
                        >
                          Sites
                        </Typography>
                      </MenuItem>
                    )}
                    {isAdmin && (
                      <MenuItem onClick={() => doNavigate("/jobparts")}>
                        <Typography
                          fontSize={16}
                          fontWeight={600}
                          textAlign="center"
                        >
                          Parts
                        </Typography>
                      </MenuItem>
                    )}
                    {isAdmin && (
                      <MenuItem onClick={() => doNavigate("/warehouses")}>
                        <Typography
                          fontSize={16}
                          fontWeight={600}
                          textAlign="center"
                        >
                          Warehouses
                        </Typography>
                      </MenuItem>
                    )}
                    {isAdmin && (
                      <MenuItem onClick={() => doNavigate("/contractors")}>
                        <Typography
                          fontSize={16}
                          fontWeight={600}
                          textAlign="center"
                        >
                          Service Providers
                        </Typography>
                      </MenuItem>
                    )}
                    <Divider />
                    {isAdmin && (
                      <MenuItem onClick={() => navigate("/batches")}>
                        <Typography
                          fontSize={16}
                          fontWeight={600}
                          textAlign="center"
                        >
                          Batch Upload
                        </Typography>
                      </MenuItem>
                    )}
                    <Divider />
                    {isAdmin && (
                      <MenuItem onClick={() => navigate("/staff")}>
                        <Typography
                          fontSize={16}
                          fontWeight={600}
                          textAlign="center"
                        >
                          Staff Location
                        </Typography>
                      </MenuItem>
                    )}
                    {isAdmin && (
                      <MenuItem onClick={() => navigate("/suppliers")}>
                        <Typography
                          fontSize={16}
                          fontWeight={600}
                          textAlign="center"
                        >
                          SP Location
                        </Typography>
                      </MenuItem>
                    )}
                  </Menu>
                </Box>

                <Box
                  name="MENU BUTTONS 2: jobs admin"
                  sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                >
                  <Tooltip title="Job's List">
                    <Button
                      onClick={() => navigate("/jobs")}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      Jobs
                    </Button>
                  </Tooltip>

                  {isAdmin && (
                    <Tooltip title="Administration's List Menu">
                      <Button
                        onClick={handleOpenAdminMenu(false)}
                        sx={{ my: 2, color: "white", display: "block" }}
                      >
                        Administration
                      </Button>
                    </Tooltip>
                  )}
                </Box>

                <Box name="USER MENUS AND AVATAR" sx={{ display: "flex" }}>
                  <Tooltip title="Navigate to Home Screen">
                    <Link
                      sx={{
                        mr: 1,
                        mt: 3,
                        display: { xs: "none", md: "flex" },
                        flexGrow: 1,
                        fontWeight: 400,
                        fontFamily: "Arial",
                        color: "inherit", //"text.secondary",//
                        textDecoration: "none",
                      }} //component="button"
                      //href=""
                      onClick={() => navigate("/")}
                      underline="hover"
                    >
                      {user.attributes.name}
                    </Link>
                  </Tooltip>
                  <Tooltip title="Open settings menu">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 2 }}>
                      <Avatar {...stringAvatar(user.attributes.name)} />
                      {/* <AccountCircle />   alt={user} src="../src/resource/images/Fred.png" */}
                    </IconButton>
                  </Tooltip>

                  <Menu
                    sx={{ mt: "50px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem onClick={() => navigate("/dashboard")}>
                      <Typography>Dashboard</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/profile")}>
                      <Typography>Profile</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/about")}>
                      <Typography>About </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <Typography>Logout</Typography>
                    </MenuItem>
                  </Menu>

                  {/* </Typography> */}
                </Box>
              </Toolbar>
            </Container>
          </StyledToolbar>
        </AppBar>
        <Toolbar />
      </Box>
    );
  } catch (err) {
    console.log(err);
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" color="primary" enableColorOnDark>
          <div
            style={{
              position: "absolute",
              backgroundColor: "pink",
              borderRadius: "50%",
              opacity: 0.6,
              transform: `translate(${position.x}px, ${position.y}px)`,
              pointerEvents: "none",
              left: -15,
              top: -15,
              width: 30,
              height: 30,
              zIndex: 10,
            }}
          >
            {err}
          </div>
        </AppBar>

        <Toolbar />
      </Box>
    );
  }
};
export default ApplicationBar;

// useEffect(()=>{

//     console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAA useEffect: ', modal,  ' ---------  ')
//     console.log(modalResponce)
//     //modal =='' && setModalResponse('');
//     // console.log(tableName)
//     // console.log('-------------------' ,   children,  ) //modalResponce
//     // console.log(fields)
//     // console.log(window.navigation.activation.navigationType)
//     // console.log('AAAAAAAAAAA vvvvvvvvvvvvvvvv ', user, location.pathname)
//     // if(window.navigation.activation.navigationType =='reload')navigate('/')
// },[modal, msg, modalResponce])

// {/* <Typography
//         variant="h6"
//         noWrap
//         component="a"
//         href={'/'}
//         sx={{ mr: 2,
//             display: { xs: 'none', md: 'flex' },            //fontFamily: 'monospace',
//             fontWeight: 600,
//             letterSpacing: '.2rem',
//             color: 'inherit',
//             textDecoration: 'none'
//         }}
//     >Autom 8</Typography>

//     <Typography
//         variant="h5"
//         noWrap
//         component="a"
//         href={'/'}
//         sx={{
//             mr: 0,
//             display: { xs: 'flex', md: 'none' },
//             flexGrow: 1,
//             fontWeight: 600,
//             // letterSpacing: '.3rem',
//             color: 'inherit',
//             textDecoration: 'none',
//         }}
//     > A8 </Typography> */}

// {/* <Button
//                             onClick={()=>navigate('/jobs')}
//                             sx={{ color: 'white', display: 'block' }}
//                         >Jobs</Button>
//                         {isAdmin &&
//                             <IconButton
//                             size="large"
//                             onClick={handleOpenAdminMenu}
//                             color="inherit"
//                             >    <MenuIcon />
//                             </IconButton>
//                         } */}

// {/* <Typography
//             // variant="h7"
//             onClick={handleOpenUserMenu}
//             noWrap

//             sx={{
//                 mr: 1,
//                 mt:3,
//                 display: { xs: 'none', md: 'flex' },
//                 flexGrow: 1,
//                 fontWeight: 400,
//                 fontFamily:'Arial',
//                 color: 'inherit',
//                 textDecoration: 'none',
//             }}
//         >  */}
