import { Typography } from "@mui/material";



export const setHeader=(tableName)=>{
    let headCells = []
    
    switch (tableName) {

        case "clients":   
            headCells = [
                {
                    id: 'clientId',
                    label: 'ID'
                },
                {
                    id: 'clientName',            
                    label: 'Business Name'
                },
                {
                    id: 'clientEmail',
                    label: 'Email'
                },
                {
                    id: 'clientABN',
                    label: 'ABN'
                },
                {
                    id: 'clientPhoneNumber',
                    label: 'Phone'
                },

            ];  // tableID="row.clientId"
        break;

        case "contractors":   
            headCells = [
                {
                    id: 'supplierId',
                    label: 'ID'
                },
                {
                    id: 'supplier',
                    label: 'Business Name'
                },
                {
                    id: 'supplierEmail',
                    label: 'Email'
                },
                {
                    id: 'supplierState',
                    label: 'State'
                },
                {
                    id: 'supplierPhone',
                    label: 'Phone'
                },
                {
                    id: 'supplierContactPerson',
                    label: 'Contact'
                },
            ];  // tableID="row.clientId"
        break;
    
        case "sites":   
            headCells = [
                {
                    id: 'siteName',            
                    label: 'Name'
                },
                {
                    id: 'siteAddress1',
                    label: 'Address'
                },
                {
                    id: 'siteState',
                    label: 'State'
                },
                {
                    id: 'siteZone',
                    label: 'Zone'
                },
                {
                    id: 'customerName',
                    label: 'Customer'
                },
                // {                // id: 'position',                // // numeric: false,                // // disablePadding: true,                // label: 'Position',                // },
                {
                    id: 'sitePhone',                    //numeric: false,                    disablePadding: true,
                    label:'Phone'
                },
                // {
                //     id: 'status',
                //     numeric: false,
                //     disablePadding: true,
                //     label: 'Status',
                //     },
                // {
                // id: 'posX',
                // numeric: false,
                // disablePadding: true,
                // label: 'Pos X',
                // },
                // {
                // id: 'posY',
                // numeric: false,
                // disablePadding: true,
                // label: 'Pos Y',
                // },
            ];      //tableID="row.siteID"
        break;
    
        case "users":   
            headCells = [
                {
                    id: 'userId',
                    label: 'ID'
                },
                {
                    id: 'name',
                    label: 'Loggin Name'
                },
                {
                    id: 'userFullName',
                    label: 'Full Name'
                },
                {
                    id: 'userEmail',
                    label: 'Email'
                },
                {
                    id: 'sub',
                    label: 'Cognito ID'
                },
                {
                    id: 'userType',
                    label: 'Type'
                },
     
            ];      //tableID="userId"
        break;
    
        case "customers":   
            headCells = [
                {
                    id: 'customerId',
                    label:'ID'
                },
                {
                    id: 'customerName',
                    label: 'Name'
                },
                {
                    id: 'clientName',
                    label: 'Client'
                },
                {
                    id: 'customerLogo',
                    label: 'Logo'
                },
                {
                    id: 'customerEmail',
                    label: 'Email'
                },
                {
                    id: 'customerCCUUID',
                    label: 'Carton Cloud ID'
                },

                // {
                //     id: 'status',
                //     label: 'Status',
                // },
            ];    //tableID="customerId"
        break;

        case "jobparts":   
            headCells = [
                {
                    id: 'partsId',
                    label:'ID'
                },
                
                {
                    id: 'jobPartsDescription',
                    label: 'Parts Name'
                },

                {
                    id: 'jobPartNumber',
                    label: 'Parts Number'
                },
                {
                    id: 'prodCCUUID',
                    label:'CC ID'
                },
                {
                    id: 'jobPartsCustomer',
                    label: 'Customer'
                },

                {
                    id: 'jobPartQty',
                    disabled:true,
                    label: 'Available Qty'
                },


            ];  
        break;
    
        case "warehouses":   
            headCells = [
                {
                    id: 'warehouseId',
                    disabled:true,
                    label: 'ID'
                },
                {
                    id: 'warehouseName',
                    label: 'Name'
                },
                {
                    id: 'warehouseDescription',
                    disabled:true,
                    label: 'Description'
                }
            ]
            break;

        case "jobbook":   
            headCells = [
            {
                id: 'jobNo',

                label: 'Job Number'
            },
            
            {
                id: 'customerName',
            
                label:'Customer Name'
            },

            {
                id: 'siteName',
            
                label:'Site Name'
            },

            {
                id: 'siteState',
                disabled:true,
                
                label: 'Site State'
            },

            {
                id: 'status',
                
                label: 'Status'
            },

            {
                id: 'priority',
            
                label: 'Priority'
            },

            {
                id: 'requestType',
            
                label: 'Request'
            }, 

            {
                id: 'supplier',
            
                label: 'Service Provider'
            },

            {
                id: 'slaDate',
                disabled:true,
                label: 'SLA Date'
            },

                
            //     id: 'deviceName',
            //     label: 'Device',
            // },

            // {
            //     id: 'dateCreated',
            //     label: <Typography style={{ fontWeight:600, color:'steelblue'}}>Created'
            // },

            
            ];     //tableID="jobNo"
        break;
        
        default: break; 

        

    }

    return headCells;
}

// import TableCell from '@mui/material/TableCell';
// import Checkbox from '@mui/material/Checkbox';     


// const fontFamily='Ariel'
// const fontSize=16
// const border= ".1px dotted lightgray"

// export const TableNames=( {isItemSelected, row})=>{

//     {console.log(isItemSelected )}
//     {console.log(row.siteId)}
    
//     // <> 
//     //     <TableCell sx={{width: '1%',  border: {border}}}>
//     //         <Checkbox checked={isItemSelected}/> 
//     //     </TableCell>  
//     //     <TableCell sx={{width: '2%', border: {border},fontFamily:{fontFamily}, fontSize:{fontSize}}} >{row.siteId}</TableCell>
//     //     <TableCell sx={{width: '10%', border: {border},fontFamily:{fontFamily}, fontSize:{fontSize}}}>{row.siteName}</TableCell>
//     //     <TableCell sx={{width: '25%', border: {border}, fontFamily:{fontFamily}, fontSize:{fontSize}}}>
//     //         {`${row.siteAddress1}, ${row.siteSuburb}, ${row.siteState}, ${row.sitePostCode }, ${row.country} `}
//     //     </TableCell>
//     //     <TableCell sx={{ width: '1%', border: {border},fontFamily:{fontFamily}, fontSize:{fontSize}}}>{row.siteZone}</TableCell>
//     //     <TableCell sx={{ width: '1%', border: {border},fontFamily:{fontFamily}, fontSize:{fontSize}}}>{row.siteOutLetType}</TableCell>
//     //     <TableCell sx={{ width: '9%', border: {border},fontFamily:{fontFamily}, fontSize:{fontSize}}}>{row.siteManager}</TableCell>
//     //     <TableCell sx={{ width: '5%', border: {border},fontFamily:{fontFamily}, fontSize:{fontSize}}}>{row.sitePhone}</TableCell>
//     //     <TableCell sx={{ width:'.1%', border: {border},fontFamily:{fontFamily}, fontSize:{fontSize}}}>{row.siteSupplierId}</TableCell>
//     //     <TableCell sx={{ width:'1%', border: {border},fontFamily:{fontFamily}, fontSize:{fontSize}}}>{row.position ?? 0.0}</TableCell> 
//     //     <TableCell sx={{ width:'1%', border:{border}, fontFamily:{fontFamily}, fontSize:{fontSize}}}>{row.position ?? 0.0}</TableCell> 
//     // </>

// };
