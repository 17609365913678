import  { useState, useEffect}from 'react'
import './single.scss'
import Sidebar from "../../components/Sidebar.jsx";
import Navbar from "../../components/Navbar.jsx";
import Button from '@mui/material/Button';     
import Stack from '@mui/material/Stack'; 
import { useParams, useNavigate } from "react-router-dom";

export const Single = ({type, action}) => {
  //const [URL, setURL]= useState(`${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_API_PORT}`);  
  const [dat, setDat]= useState(false);  
  const [data, setData]= useState([{}]);  
  //const ID = document.location.pathname.split("/");
  let { id } = useParams();
  const navigate = useNavigate();

  const {
          REACT_APP_API_HTTP, 
          REACT_APP_API_LOCAL_DOMAIN,
          REACT_APP_API_LOCAL_PORT, NODE_ENV,
          REACT_APP_API_UPDATE_USERS_ACTIVE 
        } = process.env

  const productionHost =REACT_APP_API_HTTP + window.location.host
  const localHost = REACT_APP_API_HTTP + REACT_APP_API_LOCAL_DOMAIN + REACT_APP_API_LOCAL_PORT
  const DOMAIN = NODE_ENV==='production'?productionHost:localHost;
  
  const url=`${DOMAIN}/api/${type}/${id}`/// `http://localhost:3002/api/${type}/${id}`;
  const url_delete=`${DOMAIN}/api/${type}/delete/${id}`/// `http://localhost:3002/api/${type}/${id}`;

  async function getData(a) {
    const response = await fetch(a)
    //,{method: 'POST', headers: {'Content-Type': 'application/json; text/plain; */*; charset=utf-8'},  body: ''  });
    const responseData = await response.json();                                         
    setData(responseData)
    setDat(true)
  }

  async function deleteData(a) {
    console.log(a)
    const response = await fetch(a, {method: 'DELETE'});
    const responseData = await response.json();  
    //console.log(responseData.ok)  `${DOMAIN}/api/${type}/delete/${id}`                               
  }


  const handleDelete = ()=>{
    deleteData(url_delete) //`${DOMAIN}/api/${type}/delete/${id}`/ `http://localhost:3002/api/${type}/delete/${id}`
    navigate(-1)
  }

  const handleUpdate = ()=>{
    navigate(-1)
    //return <New type={type} action = {action}/>
  }

  const handleRemoveActive = async()=>{
    const response = await fetch(`${DOMAIN}${REACT_APP_API_UPDATE_USERS_ACTIVE}${id}`,
        {   
            method: 'PUT', 
            body: JSON.stringify({'active': 0 }),    
            headers: {'Content-Type':'application/json; charset=utf-8'},   
        } );
    const responseData = await response.json();   
    console.log(responseData)
    navigate(-1)
    //responseData.affectedRows ===1 ? setIsAdded(true): setIsAdded(false)
}
 
  useEffect(()=>{getData(url)},[url, dat])
  
  return (
      <div className='single'>
        <Sidebar />
        <div className="singleContainer">
          <Navbar />       

            {dat ?
                <div className="singleWrapper">
                  {action==='delete' && <h1 className='delete' >Delete {
                                            type==='clients'?'Client with ID ': 
                                            type==='customers'?'Customer with ID ':
                                            type==='jobbook'?'Job Number ':
                                            type==='jobs'?'Job Number ':
                                            type==='users'?'User with ID ':
                                            type==='sites'?'Site with ID':
                                            'ID '}: {id}
                                          </h1>
                  }
                  <div>
                        {data.map(d =>{return(
                            Object.keys(d).map((key, index) => {
                                return (
                                        <Stack 
                                          className="title" 
                                          direction="row" 
                                          spacing={2} 
                                          //paddingLeft ="1px" 
                                          //justifyContent='flex-start'
                                          alignItems='center'
                                          height='4.5ch'
                                          width='140ch'
                                          key={index} 
                                        >   
                                          <h5 className="key"> {key} </h5>
                                          <h5 className="value">{d[key] === null || d[key] === ' '? 'null' :d[key] }</h5>
                                        </Stack>   
                                    ); 
                                  }
                                )
                              )
                            }
                          )
                        }
                  </div>

                  <Stack direction="row" 
                        justifyContent='space-evenly'
                        //gap='1ch'
                        alignItems='center'
                        height='15ch'
                        width='100ch'   
                      //  border="1px solid red"      
                  >

                    {/* {
                      type==='users' && data[0].active ?
                        <Button 
                            className='Btn'
                            variant="outlined"  
                            size='small'
                            sx={{ fontWeight:700, fontSize:16, }} 
                            onClick={handleRemoveActive} 
                        > Disactivate</Button>
                        :''
                    } */}
                    {action==='delete' ?
                      <Button   
                          className='Btn'
                          variant="outlined" 
                          size='small'
                          sx={{fontWeight:700, fontSize:16}} 
                          onClick={ handleDelete} 
                      > Delete</Button>
                      :
                      <Button 
                          className='Btn'
                          variant="outlined"  
                          size='small'
                        //  sx={{marginLeft:20, width:150, fontWeight:800, fontFamily:'cursive'}} 
                          onClick={ handleUpdate} 
                      > Update</Button>
                    }
                    <Button   
                      className='Btn'
                      variant="outlined" 
                      size='small'
                      onClick={ handleUpdate} 
                      sx={{fontWeight:700, fontSize:16}}  //onClick={handleClose}
                    > Close</Button>

                  </Stack>
                </div>

              :
                <h3>No Data available</h3>
            }
          
        </div>
      </div>
  )
}


//import { Link } from "react-router-dom";
//import NewClient from '../../components/newitem/NewClient.jsx';
//import {New} from '../../pages/new/New.jsx';
//import {  } from '@aws-amplify/ui-react';
//import Messages from '../../components/Messages.jsx'



    
    // updateData(`http://localhost:3002/api/clients/update/${id}`)
    // console.log(`Updated client with ID : ${id}`)  


      // async function updateData(a) {
  //   //const response = await fetch(a, {method: 'PUT'});
  //   //const responseData = await response.json();  
  //   //console.log(response)                                 
  // }


    // const classname = type==='clients'?'singleClient': 
  //                   type==='customers'?'singleCustomer':
  //                   type==='jobbook'?'singleJob':
  //                   type==='jobs'?'singleJob':
  //                   type==='users'?'singleUser':
  //                   'newUnknown' 

  //const table = type ==='jobs'? 'jobbook': ID[1] hh
  //const param = ID[2]

 // console.log(`${process.env.REACT_APP_API_DOMAIN}${type}/${id}`)